import * as constants from './constants';

export const name = 'user';

const initialState = {
  userData: {
    profiles: {},
    loginMethod: undefined,
    backupAuth: false,
    webappState: {},
  },
  ledFamilies: {},
  ledGroups: {},
  userFamilies: {},
  userGroups: {},
  settings: {
    theme: 'light',
  },
  isFetchingUser: false,
  isFetchingProfile: false,
  isFetchingLedFamilies: false,
  isFetchingLedGroups: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.FB_USER_TREE_REQUEST:
      return {
        ...state,
        isFetchingUser: true,
      };

    case constants.FB_USER_TREE_SUCCESS:
      return {
        ...state,
        isFetchingUser: false,
        userData: action.payload.userData,
      };

    case constants.FB_USER_TREE_FAILURE:
      return {
        ...state,
        isFetchingUser: false,
      };

    case constants.FB_USER_FAMILY_TREES_SUCCESS:
      return {
        ...state,
        userFamilies: action.payload.userFamilies,
      };

    case constants.FB_USER_GROUP_TREES_SUCCESS:
      return {
        ...state,
        userGroups: action.payload.userGroups,
      };

    case constants.RESUME_SESSION_SUCCESS:
      return {
        ...state,
      };

    case constants.SETTINGS_CHANGE_THEME:
      return {
        ...state,
        settings: {
          ...state.settings,
          theme: action.payload.theme,
        },
      };

    case constants.FB_MY_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingProfile: true,
      };

    case constants.FB_MY_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingProfile: false,
        userData: {
          ...state.userData,
          profiles: {
            ...state.userData.profiles,
            [action.payload.directoryId]: action.payload.profile,
          },
        },
      };

    case constants.FB_MY_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingProfile: false,
      };

    case constants.FB_MY_LED_GROUPS_REQUEST:
      return {
        ...state,
        isFetchingLedGroups: true,
      };

    case constants.FB_MY_LED_GROUPS_SUCCESS:
      return {
        ...state,
        isFetchingLedGroups: false,
        ledGroups: action.payload.groups,
      };

    case constants.FB_MY_LED_GROUPS_FAILURE:
      return {
        ...state,
        isFetchingLedGroups: false,
      };

    case constants.FB_MY_LED_FAMILIES_REQUEST:
      return {
        ...state,
        isFetchingLedFamilies: true,
      };

    case constants.FB_MY_LED_FAMILIES_SUCCESS:
      return {
        ...state,
        isFetchingLedFamilies: false,
        ledFamilies: action.payload.families,
      };

    case constants.FB_MY_LED_FAMILIES_FAILURE:
      return {
        ...state,
        isFetchingLedFamilies: false,
      };

    case constants.FB_SWITCH_DIR_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          webappState: {
            ...state.userData.webappState,
            activeDirectory: action.payload.directoryId,
          },
        },
      };

    default:
      return state;
  }
}
