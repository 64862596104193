// 

import * as React from 'react';
import Select from 'react-select';

// $FlowFixMe
import defaultPersonImage from 'assets/images/default-avatar.png';

import { classie } from '../../../../utils';

import styles from './styles.css';

class CustomOption extends React.PureComponent {
  render() {
    const {
      className,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
      data,
    } = this.props;

    const imageSrc = data.imageUrl && data.imageUrl.length ? data.imageUrl : defaultPersonImage;

    return (
      <div
        ref={innerRef}
        className={classie(['option', className], {
          'option-is-disabled': isDisabled,
          'option-is-focused': isFocused,
          'option-is-selected': isSelected,
        })}
        {...innerProps}>
        <div className={styles.optionChildrenWrapper}>
          <div className={styles.optionImageWrapper}>
            <img
              className={styles.optionImage}
              src={imageSrc}
              alt={data.label} />
          </div>

          <span className={styles.optionLabel}>{data.label}</span>
        </div>
      </div>
    );
  }
}



class GroupSelect extends React.Component {
  static defaultProps = {
    className: '',
  };

  render() {
    const {
      onChange,
      value,
      isMulti,
      className,
      options,
    } = this.props;

    const placeholder = isMulti ? 'Select Groups...' : 'Select Group...';

    return (
      <Select
        components={{ Option: CustomOption }}
        className={classie(['react-select info', className])}
        classNamePrefix="react-select"
        isMulti={isMulti}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        name="groupSelect"
        value={value}
        options={options}
        onChange={onChange} />
    );
  }
}

export default GroupSelect;
