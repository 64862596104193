import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { getUser, getUserDirs } from 'modules/user/selectors';
import { fbUserTreeRequest } from 'modules/user/actions';
import { withUserDb } from 'modules/core/hoc';

import { MobileMenuSwitcher } from 'modules/core/components';

import * as actions from 'modules/directory/actions';
import * as selectors from 'modules/directory/selectors';


const mapStateToProps = createStructuredSelector({
  directoryId: selectors.getActiveDirectoryId,
  user: getUser,
  userDirs: getUserDirs,
  directoryTrees: selectors.getDirectories,
});

const mapDispatchToProps = {
  getDirectories: actions.fbMultiDirectoryRequest,
  getUser: fbUserTreeRequest,
  onSelect: actions.switchDirectoryRequest,
};

const enhance = compose(withRouter, withUserDb, connect(
  mapStateToProps,
  mapDispatchToProps,
));

export default enhance(MobileMenuSwitcher);
