import PropTypes from 'prop-types';
import React from 'react';


const BulletList = ({ strokeWidth, ...rest }) => (
  <React.Fragment>
    <circle
      cx="28"
      cy="25"
      r="2.24"
      fill={rest.fill}
    />
    <path
      strokeWidth={strokeWidth}
      {...rest}
      d="M28,29c-1.46,0-4.38.72-4.48,2.16a5.46,5.46,0,0,0,9,0C32.38,29.72,29.46,29,28,29Z"
    />
    <path
      strokeWidth={strokeWidth}
      {...rest}
      d="M30,16.18V6.54L15,0,0,6.54v9.82C0,25.44,6.4,33.94,15,36a18.33,18.33,0,0,0,3.2-1.1A12,12,0,1,0,30,16.18ZM16,28a11.53,11.53,0,0,0,.46,3.24,13.17,13.17,0,0,1-1.46.6A16.42,16.42,0,0,1,4,16.36V9.16l11-4.8,11,4.8v7A12,12,0,0,0,16,28Zm12,8a8,8,0,1,1,8-8A8,8,0,0,1,28,36Z"
    />
  </React.Fragment>
);

BulletList.propTypes = {
  strokeWidth: PropTypes.number,
};

BulletList.defaultProps = {
  strokeWidth: 1,
};


export default BulletList;
