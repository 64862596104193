const BASE_ROUTE = '/api';

class AuthRoutes {
  static login() {
    return '/auth/login';
  }
  static loginWithSms() {
    return '/auth/login/phone';
  }
  static verifyLoginWithSms() {
    return '/auth/login/phone/verify';
  }
  static logout() {
    return '/auth/logout';
  }
  static setPassword() {
    return '/auth/setPassword';
  }
}

class UserRoutes {
  static users(
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/users`;
  }

  static usersMe(
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/users/me`;
  }

  static userDirectories(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/users/${userId}/directories`;
  }

  static userFamilies(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/users/${userId}/families`;
  }

  static userGroups(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/users/${userId}/groups`;
  }

  static group(
    shortCode = ':shortCode',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/groups/${shortCode}`;
  }

  static searchDirectory(
    directoryId = ':directoryId(\\d+)',
    type = ':type',
  ) {
    return `/dir/${directoryId}/directories/${directoryId}/search/${type}`;
  }

  static members(
    shortCode = ':shortCode',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/members/${shortCode}`;
  }

  static family(
    shortCode = ':shortCode',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/families/${shortCode}`;
  }

  static families(
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/families`;
  }
}

class AdminRoutes {
  static userDataForShortCode(
    userId = ':userId(\\d+)',
    userShortCode = ':userShortCode',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/users/${userId}/userData/${userShortCode}`;
  }

  static families(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/families`;
  }

  static groups(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/groups`;
  }

  static users(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/users`;
  }

  static usersMulti(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/users/multi`;
  }

  static userImages(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
    shortCode = ':shortCode(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/userImages`;
  }

  static customFields(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/customFields`;
  }

  static customField(
    userId = ':userId(\\d+)',
    fieldId = ':fieldId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/customFields/${fieldId}`;
  }

  static internalFields(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/internalFields`;
  }

  static internalField(
    userId = ':userId(\\d+)',
    fieldId = ':fieldId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/internalFields/${fieldId}`;
  }

  static userCategories(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/userCategories`;
  }

  static userCategory(
    userId = ':userId(\\d+)',
    catId = ':categoryId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/userCategories/${catId}`;
  }

  static messages(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/messages`;
  }

  static emails(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/emails`;
  }

  static smsMessages(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/smsMessages`;
  }

  static exports(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
    type = ':type',
  ) {
    return `/dir/${dirId}/admin/${userId}/exports/${type}`;
  }

  static adminUsers(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/adminUsers`;
  }

  static adminUser(
    userId = ':userId(\\d+)',
    dirId = ':dirId(\\d+)',
    memberId = ':memberId(\\d+)',
  ) {
    return `/dir/${dirId}/admin/${userId}/adminUsers/${memberId}`;
  }
}

export {
  BASE_ROUTE,
  AuthRoutes,
  UserRoutes,
  AdminRoutes,
};
