export const containerStyles = {
  borderRadius: 'input',
  borderWidth: 'med',
  borderStyle: 'solid',
  width: '100%',
  [global.Breakpoint.laptop]: {
    alignSelf: 'flex-end',
    width: '300px',
  },
};

export const inputStyles = {
  boxShadow: 'input',
  color: 'searchText',
  paddingLeft: 'grid8',
  height: 'controlHeightThin',
  borderWidth: 'med',
  ':focus': {
    boxShadow: 'inputHover',
    borderWidth: 'med',
  },
};

export const iconContainerStyles = {
  borderWidth: 'med',
  borderStyle: 'solid',
  height: 'controlHeightThin',
  width: 'controlHeight',
};
