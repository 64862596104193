import PropTypes from 'prop-types';
import React from 'react';

import { dashboardRoutePaths } from 'modules/router/constants';

import Flex from '../Flex';
import Box from '../Box';
import Link, { LinkNewPage } from '../Link';
import Logo from '../Logo';
import Text from '../Text';


const Footer = ({ sx }) => {
  const ref = React.useRef();
  return (
    <Flex
      ref={ref}
      flexDirection="column"
      sx={{
        ...sx,
        backgroundColor: 'backgroundDarker',
        paddingTop: 'grid24',
        paddingBottom: 'grid16',
        paddingLeft: 'grid16',
        paddingRight: 'grid24',
      }}
    >
      <Flex
        flexDirection="column"
        sx={{
          marginBottom: 'grid16',
        }}
      >
        <Box
          sx={{
            marginBottom: 'grid8',
            textAlign: 'center',
          }}
        >
          <Link href={dashboardRoutePaths.dirInfo}>
            Directory Info
          </Link>
        </Box>
        <Box
          sx={{
            color: 'text',
            marginBottom: 'grid8',
            textAlign: 'center',
          }}
        >
          <LinkNewPage href="mailto:support@nalcnetwork.com">
            Contact Support
          </LinkNewPage>
        </Box>
        <Box
          sx={{
            color: 'text',
            marginBottom: 'grid8',
            textAlign: 'center',
          }}
        >
          <Link href="/privacy.html">
            Privacy
          </Link>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Link href="/terms.html">
            Terms
          </Link>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
      >
        <Box
          sx={{
            alignSelf: 'center',
            height: 'logoDefaultHeight',
            width: 'logoDefaultWidth',
            marginBottom: 'grid16',
          }}
        >
          <Logo />
        </Box>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Text>
            {String.fromCharCode(169)} {new Date().getFullYear()}, Jubilee
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

Footer.propTypes = {
  sx: PropTypes.shape(),
};

Footer.defaultProps = {
  sx: {},
};


export default Footer;
