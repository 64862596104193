import PropTypes from 'prop-types';
import React from 'react';

import * as colors from 'modules/core/colors';
import { Appearance } from 'modules/core/constants';
import { IconSize } from 'modules/core/sizes';

import { IconNames } from 'modules/core/components/svg';
import Box from 'modules/core/components/Box';
import IconButton from 'modules/core/components/IconButton';

import * as styles from './styles';


const HeaderSwitchDir = (props) => {
  const { onSwitchClick } = props;

  return (
    <Box sx={styles.containerStyles}>
      <IconButton
        appearance={Appearance.NONE}
        buttonColor="transparent"
        iconColor={colors.ebonyLightest}
        iconHoverColor={colors.ebonyLightest}
        iconName={IconNames.SWITCH}
        iconSize={IconSize.ICON_LARGE}
        onClick={onSwitchClick}
        sx={styles.buttonStyles}
      />
    </Box>
  );
};

HeaderSwitchDir.propTypes = {
  onSwitchClick: PropTypes.func.isRequired,
};


export default HeaderSwitchDir;
