import * as constants from './constants';

export const name = 'directory';

const initialState = {
  config: {
    email: false,
    isFetching: false,
    sms: false,
  },
  directories: {},
  directoryIds: [],
  activeDirectoryId: undefined,
  searchResults: [],
  searchFetching: false,
  activeDirectory: {},
  fetchingSwitch: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.FB_DIRECTORIES_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case constants.FB_DIRECTORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activeDirectory: action.payload.directoryData,
        directories: {
          ...state.directories,
          [action.payload.directoryId]: action.payload.directoryData,
        },
      };

    case constants.FB_DIRECTORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case constants.FB_MULTI_DIRECTORIES_SUCCESS:
      return {
        ...state,
        directories: action.payload.directoriesData,
      };

    case constants.DIRECTORIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.DIRECTORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        directories: action.payload.directories,
        directoryIds: Object.keys(action.payload.directories),
        activeDirectoryId: action.payload.activeDirectoryId,
      };

    case constants.DIRECTORIES_FAILURE:
      return {
        ...state,
        directories: {},
        directoryIds: [],
        activeDirectoryId: undefined,
        isFetching: false,
      };

    case constants.SWITCH_DIRECTORY:
      return {
        ...state,
        activeDirectoryId: action.payload.directoryId,
        activeDirectory: state.directories[action.payload.directoryId] || {},
      };

    case constants.SWITCH_DIRECTORY_REQUEST:
      return {
        ...state,
        fetchingSwitch: true,
      };

    case constants.SWITCH_DIRECTORY_SUCCESS:
      return {
        ...state,
        activeDirectoryId: action.payload.directoryId,
        activeDirectory: state.directories[action.payload.directoryId] || {},
        fetchingSwitch: false,
      };


    case constants.SWITCH_DIRECTORY_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.message,
        errorName: action.payload.error.name,
        fetchingSwitch: false,
      };

    case constants.SEARCH_MEMBERS_REQUEST:
    case constants.SEARCH_GROUPS_REQUEST:
    case constants.SEARCH_FAMILIES_REQUEST:
      return {
        ...state,
        searchFetching: true,
      };

    case constants.SEARCH_MEMBERS_SUCCESS:
      return {
        ...state,
        searchFetching: false,
        searchResults: action.payload.members,
      };

    case constants.SEARCH_FAMILIES_SUCCESS:
      return {
        ...state,
        searchFetching: false,
        searchResults: action.payload.families,
      };

    case constants.SEARCH_GROUPS_SUCCESS:
      return {
        ...state,
        searchFetching: false,
        searchResults: action.payload.groups,
      };

    case constants.SEARCH_MEMBERS_FAILURE:
    case constants.SEARCH_GROUPS_FAILURE:
    case constants.SEARCH_FAMILIES_FAILURE:
      return {
        ...state,
        searchResults: [],
        searchFetching: false,
      };

    case constants.DIR_FEATURES_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          isFetching: true,
        },
      };

    case constants.DIR_FEATURES_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload,
          isFetching: false,
        },
      };

    case constants.DIR_FEATURES_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          isFetching: false,
        },
      };

    default:
      return state;
  }
}
