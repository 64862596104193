import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SweetAlertVendor from 'react-bootstrap-sweetalert';

import * as colors from 'modules/core/colors';

import { Heading } from '../Text';
import { CheckIcon } from '../Icon';


const typeIcons = {
  success: <CheckIcon color={colors.aqua} />,
};

const SweetAlert = (props) => {
  const {
    className,
    onCancel,
    onConfirm,
    show,
    showCancel,
    showCloseButton,
    showConfirm,
    text,
    title,
    type,
    ...rest
  } = props;

  return (
    <SweetAlertVendor
      {...rest}
      customClass={classNames('swal', {
        [className]: className,
      })}
      confirmBtnStyle={{
        backgroundColor: colors.aqua,
        borderColor: colors.aqua,
      }}
      customIcon={<CheckIcon />}
      onCancel={onCancel}
      onConfirm={onConfirm}
      show={show}
      showCancel={showCancel}
      showCloseButton={showCloseButton}
      showConfirm={showConfirm}
      style={{
        backgroundColor: colors.whitePointerLightVariant,
        padding: '24px',
      }}
      text={text}
      title={<Heading className="j-alert-heading">{title}</Heading>}
      type={type}
    />
  );
};

const SuccessSWAL = (props) => (
  <SweetAlert
    {...props}
    showCancel={false}
    type="success"
  />
);

SweetAlert.propTypes = {
  className: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  showCancel: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showConfirm: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'danger', 'error', 'input', 'custom', 'controlled']),
};

SuccessSWAL.propTypes = {
  className: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  showCancel: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showConfirm: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SweetAlert.defaultProps = {
  className: undefined,
  onCancel: undefined,
  onConfirm: undefined,
  show: undefined,
  showCancel: undefined,
  showCloseButton: undefined,
  showConfirm: undefined,
  text: undefined,
  type: 'default',
};

SuccessSWAL.defaultProps = {
  className: undefined,
  onCancel: undefined,
  onConfirm: undefined,
  show: undefined,
  showCancel: undefined,
  showCloseButton: undefined,
  showConfirm: undefined,
  text: undefined,
};


export { SuccessSWAL };
export default SweetAlert;
