import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { User } from '../shapes';


const UserSvg = (props) => (
  <g>
    <IconBounds />
    <User {...props} />
  </g>
);

UserSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

UserSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default UserSvg;
