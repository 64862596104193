import * as constants from './constants';

export const name = 'files';

const initialState = {
  isUploading: false,
  uploadIds: [],
  failedIds: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        isUploading: true,
        uploadIds: [action.payload.shortCode],
      };

    case constants.UPLOAD_IMAGE_SUCCESS:
      return {
        isUploading: false,
        uploadIds: [],
        failedIds: [],
      };

    case constants.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        isUploading: false,
        uploadIds: [],
        failedIds: [...state.uploadIds],
      };

    default:
      return { ...state };
  }
}
