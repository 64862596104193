export const getLinkWrapperStyles = ({ sx }) => ({
  ...sx,
  color: 'text',
  fontSize: 'font18',
  fontWeight: 'semibold',
});

export const getLinkContentStyles = ({ forceListStyle }) => ({
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  paddingBottom: 'grid16',
  paddingTop: 'grid16',
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    paddingBottom: 'grid24',
    paddingTop: 'grid24',
    width: '100%',
  },
});

export const getAvatarStyles = ({ forceListStyle }) => ({
  alignSelf: 'center',
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    width: '70%',
    height: '70%',
  },
});

export const getTextContainerStyles = ({ forceListStyle }) => ({
  alignSelf: 'center',
  flexDirection: 'column',
  paddingLeft: 'grid16',
  paddingRight: 'grid4',
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    marginTop: 'grid8',
    maxHeight: 'calc(30% - 8px)',
    maxWidth: '100%',
    paddingLeft: 'grid0',
    paddingRight: 'grid0',
    textAlign: 'center',
  },
});

export const getTitleStyles = ({ forceListStyle }) => ({
  color: 'text',
  lineHeight: 'title',
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    fontSize: 'font14',
  },
});

export const getSubtitleStyles = ({ forceListStyle }) => ({
  color: 'textLight',
  fontSize: 'font14',
  lineHeight: 'subtitle',
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    fontSize: 'font12',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
});

export const caretContainerStyles = {
  flexDirection: 'column',
  justifyContent: 'center',
};

export const getAvatarAndTitlesStyles = ({ forceListStyle }) => ({
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: '100%',
    width: '100%',
  },
});
