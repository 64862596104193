import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Box as RebassBox } from 'rebass';


/**
 * Generic div. Accepts normal DOM props, as well as Rebass props.
 * @param {ThemedProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const Box = (props) => {
  const { className, setRef, sx, ...rest } = props;

  return (
    <RebassBox
      className={classNames({
        'j-box': true,
        [className]: className,
      })}
      ref={setRef}
      sx={{ ...sx }}
      {...rest}
    />
  );
};

Box.propTypes = {
  /**
   * @type {ClassName}
   */
  className: PropTypes.string,
  sx: PropTypes.shape({}),
};

Box.defaultProps = {
  className: undefined,
  sx: {},
};

const BoxWithRef = React.forwardRef((props, ref) => (
  <Box setRef={ref} {...props} />
));


export { Box };
export default BoxWithRef;
