export const LOGIN_REQUEST = '@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@auth/LOGIN_FAILURE';

export const FB_LOGIN_REQUEST = '@auth/FB_LOGIN_REQUEST';
export const FB_LOGIN_SUCCESS = '@auth/FB_LOGIN_SUCCESS';
export const FB_LOGIN_FAILURE = '@auth/FB_LOGIN_FAILURE';

export const FB_USER_FOUND = '@auth/FB_USER_FOUND';

export const START_PHONE_LOGIN_REQUEST = '@auth/START_PHONE_LOGIN_REQUEST';
export const START_PHONE_LOGIN_SUCCESS = '@auth/START_PHONE_LOGIN_SUCCESS';
export const START_PHONE_LOGIN_FAILURE = '@auth/START_PHONE_LOGIN_FAILURE';

export const VERIFY_PHONE_LOGIN_REQUEST = '@auth/VERIFY_PHONE_LOGIN_REQUEST';
export const VERIFY_PHONE_LOGIN_SUCCESS = '@auth/VERIFY_PHONE_LOGIN_SUCCESS';
export const VERIFY_PHONE_LOGIN_FAILURE = '@auth/VERIFY_PHONE_LOGIN_FAILURE';

export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = '@auth/LOGOUT_FAILURE';

export const SIGNUP_REQUEST = '@auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = '@auth/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = '@auth/SIGNUP_FAILURE';

export const SET_AUTH_EXPIRATION = '@auth/SET_AUTH_EXPIRATION';

export const RESUME_SESSION_REQUEST = '@auth/RESUME_SESSION_REQUEST';
export const RESUME_SESSION_SUCCESS = '@auth/RESUME_SESSION_SUCCESS';
export const RESUME_SESSION_FAILURE = '@auth/RESUME_SESSION_FAILURE';

export const SET_PASSWORD_REQUEST = '@auth/SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = '@auth/SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = '@auth/SET_PASSWORD_FAILURE';

export const TOGGLE_REMEMBER_ME = '@auth/TOGGLE_REMEMBER_ME';

export const setPasswordStatus = {
  NOT_STARTED: 'NOT_STARTED',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};
