import { createSelector } from 'reselect';

import { name } from './reducer';

const getState = state => state[name];

export const getToken = createSelector(
  [getState],
  state => state.token,
);

export const getIsAuthenticated = createSelector(
  [getState],
  state => state.isAuthenticated,
);

export const getIsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getIsFetchingSmsCode = createSelector(
  [getState],
  state => state.isFetchingSmsCode,
);

export const getSmsCode = createSelector(
  [getState],
  state => state.smsCode,
);

export const getSmsCodePhone = createSelector(
  [getSmsCode],
  smsCode => smsCode.phone,
);

export const getSmsCodeExpiration = createSelector(
  [getSmsCode],
  smsCode => smsCode.expiration,
);

export const getExpiration = createSelector(
  [getState],
  state => state.expiration,
);

export const getSetPasswordStatus = createSelector(
  [getState],
  state => state.setPasswordStatus,
);

export const getFirebaseUser = createSelector(
  [getState],
  state => state.fbUser || {},
);

export const getRememberMe = createSelector(
  [getState],
  state => state.rememberMe,
);
