import merge from 'lodash/merge';
import theme from '@rebass/preset';

import * as colors from './colors';
import { IconSize, FontEmSize } from './sizes';

import desktopTheme from './desktopTheme';
import { purpleDarkened, whitePointerLightVariant } from './colors';


export const shadowXLight = 'rgba(0, 0, 0, 0.008)';
const shadowLight = 'rgba(0, 0, 0, 0.01)';
const shadowDark = 'rgba(0, 0, 0, 0.02)';
const shadowBoxLight = 'rgba(0,0,0,0.06)';
const shadowBoxHover = 'rgba(0,0,0,0.12)';
const shadowBoxDark = 'rgba(0,0,0,0.2)';

const font = 'Red Hat Display';

const spacings = [0, 1, 2, 4, 8, 12, 16, 24, 32, 40, 48, 64, 128, 256];
[
  spacings.grid0,
  spacings.grid1,
  spacings.grid2,
  spacings.grid4,
  spacings.grid8,
  spacings.grid12,
  spacings.grid16,
  spacings.grid24,
  spacings.grid32,
  spacings.grid40,
  spacings.grid48,
  spacings.grid64,
] = spacings;

spacings.small = spacings[2];
spacings.xmed = spacings[3];
spacings.med = spacings[5];
spacings.large = spacings[6];
spacings.xlarge = spacings[7];

const fontSizes = [
  `${FontEmSize.FONT12}em`,
  `${FontEmSize.FONT14}em`,
  `${FontEmSize.FONT16}em`,
  `${FontEmSize.FONT18}em`,
  `${FontEmSize.FONT20}em`,
  `${FontEmSize.FONT24}em`,
  `${FontEmSize.FONT32}em`,
  `${FontEmSize.FONT36}em`,
  `${FontEmSize.FONT40}em`,
  `${FontEmSize.FONT48}em`,
];

[
  fontSizes.font12,
  fontSizes.font14,
  fontSizes.font16,
  fontSizes.font18,
  fontSizes.font20,
  fontSizes.font24,
  fontSizes.font32,
  fontSizes.font36,
  fontSizes.font40,
  fontSizes.font48,
] = fontSizes;

fontSizes.font12 = fontSizes[0];
fontSizes.font14 = fontSizes[1];
fontSizes.font16 = fontSizes[2];
fontSizes.font18 = fontSizes[3];
fontSizes.font20 = fontSizes[4];
fontSizes.font24 = fontSizes[5];
fontSizes.font32 = fontSizes[6];
fontSizes.font36 = fontSizes[7];
fontSizes.font40 = fontSizes[8];
fontSizes.font48 = fontSizes[9];

const fontWeights = {
  light: 300,
  normal: 400,
  semibold: 500,
  bold: 600,
  bolder: 700,
};

fontWeights.button = fontWeights.semibold;

const mobileHeaderTextBarHeight = 24;
const logoDefaultWidth = 86;
const logoDefaultWidth2x = logoDefaultWidth * 2;
const logoDefaultHeight = 28;
const logoDefaultHeight2x = logoDefaultHeight * 2;

const transitionTiming = 0.2;
const transitionTimingLong = 0.3;
const transitionBorderColor = `border-color ${transitionTiming}s`;
const transitionBorderColorLong = `border-color ${transitionTimingLong}s`;
const transitionBoxShadow = `box-shadow ${transitionTiming}s`;
const transitionBoxShadowLong = `box-shadow ${transitionTimingLong}s`;
const transitionBg = `background-color ${transitionTiming}s`;
const transitionBgLong = `background-color ${transitionTimingLong}s`;
const transitionSlideFromRight = `right ${transitionTimingLong}`;
const transitionColor = `color ${transitionTiming}`;
const transitionColorLong = `color ${transitionTimingLong}`;
const transitionTransform = `transform ${transitionTiming}`;
const transitionTransformLong = `transform ${transitionTimingLong}`;

const textVariantButton = {
  fontSize: 'font16',
  fontWeight: 'semibold',
};

const iconSizes = {
  [IconSize.ICON_PANEL]: 48,
  [IconSize.ICON_HUGE]: 40,
  [IconSize.ICON_XLARGE]: 24,
  [IconSize.ICON_LARGE]: 20,
  [IconSize.ICON_MED]: 16,
  [IconSize.ICON_SMALL]: 12,
};

const appTheme = merge(theme, {
  borderWidths: {
    none: '0',
    small: '1px',
    med: '2px',
    large: '3px',
  },
  borderStyles: {
    default: 'solid',
    dotted: 'dotted',
  },
  colors: {
    active: colors.purple,
    activeContrast: colors.white,
    activeHover: colors.whitePointer,
    activeHoverLight: colors.whitePointerLightVariant,
    activeHoverContrast: colors.whitePointer,
    background: colors.selago,
    backgroundDarker: colors.selagoDarker,
    backgroundLarge: colors.athensGreyLight,
    border: colors.chatelle,
    controlIcon: colors.mamba,
    danger: colors.salmon,
    dangerAlpha10: colors.salmonAlpha10,
    disabled: colors.gallery,
    disabledText: colors.silver,
    fieldLabel: colors.mamba,
    headingBg: colors.titanWhite,
    infoValue: colors.mamba,
    focusBorder: colors.silver,
    foreground: colors.white,
    foregroundSmall: colors.transparent,
    foregroundLarge: colors.selago,
    inactiveText: colors.gallery,
    info: colors.dodger,
    infoAlpha10: colors.dodgerAlpha10,
    label: colors.black,
    lightSubtext: colors.alto,
    lightText: colors.white,
    overlay: colors.ebonyLight,
    primary: colors.purple,
    primaryAlpha10: colors.purpleAlpha10,
    primaryAlpha50: colors.purpleAlpha50,
    primaryDarkened: colors.purpleDarkened,
    primaryHover: colors.heliotrope,
    searchText: colors.mamba,
    secondary: colors.webOrange,
    secondaryIcon: colors.mamba,
    sectionDivider: colors.selagoDarker,
    subtext: colors.mamba,
    success: colors.aqua,
    successAlpha10: colors.aquaAlpha10,
    systemMenu: colors.wildSand,
    systemText: colors.ebonyLight,
    systemTextLight: colors.ebonyLightest,
    systemTextEmphasis: colors.ebonyMed,
    tertiary: colors.silver,
    tertiaryEmphasis: colors.silverMed,
    text: colors.ebony,
    textLight: colors.mamba,
    transparent: colors.transparent,
    warning: colors.macaroni,
    warningAlpha10: colors.macaroniAlpha10,
  },
  fonts: {
    body: `system-ui, ${font}`,
  },
  fontSizes,
  fontWeights,
  lineHeights: {
    mobileHeaderTextBarHeight: `${mobileHeaderTextBarHeight}px`,
    navLinkMobile: fontSizes[1],
    switcherLinkMobile: fontSizes[2],
    title: fontSizes.font24,
    subtitle: fontSizes.font16,
    [IconSize.ICON_HUGE]: `${iconSizes[IconSize.ICON_HUGE]}px`,
    [IconSize.ICON_XLARGE]: `${iconSizes[IconSize.ICON_XLARGE]}px`,
    [IconSize.ICON_LARGE]: `${iconSizes[IconSize.ICON_LARGE]}px`,
    [IconSize.ICON_MED]: `${iconSizes[IconSize.ICON_MED]}px`,
    [IconSize.ICON_SMALL]: `${iconSizes[IconSize.ICON_SMALL]}px`,
  },
  radii: {
    block: 2,
    button: 4,
    circle: '50%',
    default: 8,
    image: 2,
    input: 8,
    pane: 4,
    sheetView: 16,
  },
  shadows: {
    none: 'none',
    card: `0 20px 20px ${shadowBoxLight}`,
    sheetView: `0 -10px 32px ${shadowBoxDark}`,
    cardHover: `0 40px 40px ${shadowBoxHover}`,
    cardSmall: `0 10px 10px ${shadowBoxLight}`,
    cardSmallHover: `0 20px 20px ${shadowBoxHover}`,
    cardXS: `0 6px 6px ${shadowBoxLight}`,
    cardXSHover: `0 10px 10px ${shadowBoxHover}`,
    input: `0px 2px 2px ${shadowLight}, 0px 6px 5px ${shadowLight}, 0px 12px 10px ${shadowLight}, 0px 22px 18px ${shadowLight}, 0px 32px 22px ${shadowLight}, 0px 50px 40px ${shadowDark}`,
    inputHover: `0px 2px 2px ${shadowLight}, 0px 6px 5px ${shadowLight}, 0px 12px 10px ${shadowLight}, 0px 22px 18px ${shadowLight}, 0px 32px 22px ${shadowLight}, 0px 50px 40px ${shadowLight}`,
    inputLight: `0px 1px 1px ${shadowXLight}, 0px 5px 4px ${shadowXLight}, 0px 10px 8px ${shadowXLight}, 0px 20px 16px ${shadowXLight}, 0px 28px 18px ${shadowXLight}, 0px 36px 24px ${shadowXLight}`,
    inputLightHover: `0px 2px 2px ${shadowXLight}, 0px 6px 5px ${shadowXLight}, 0px 12px 10px ${shadowXLight}, 0px 22px 18px ${shadowXLight}, 0px 32px 22px ${shadowXLight}, 0px 50px 40px ${shadowXLight}`,
    bottomSheet: `0px -2px 2px ${shadowLight}, 0px -6px 5px ${shadowLight}, 0px -12px 10px ${shadowLight}, 0px -22px 18px ${shadowLight}, 0px -32px 22px ${shadowLight}, 0px -50px 40px ${shadowDark}`,
    pane: `0 1px 2px ${colors.selagoDarker}`,
  },
  space: spacings,
  sizes: {
    alphabetList: 24,
    controlHeight: 40,
    controlHeightThin: 32,
    controlHeightLarge: 60,
    full: '100%',
    fullHeight: '100vh',
    fullWidth: '100vw',
    logoDefaultHeight,
    logoDefaultHeight2x,
    logoDefaultWidth,
    logoDefaultWidth2x,
    mobileHeaderTextBarHeight,
    [IconSize.ICON_PANEL]: iconSizes[IconSize.ICON_PANEL],
    [IconSize.ICON_HUGE]: iconSizes[IconSize.ICON_HUGE],
    [IconSize.ICON_XLARGE]: iconSizes[IconSize.ICON_XLARGE],
    [IconSize.ICON_LARGE]: iconSizes[IconSize.ICON_LARGE],
    [IconSize.ICON_MED]: iconSizes[IconSize.ICON_MED],
    [IconSize.ICON_SMALL]: iconSizes[IconSize.ICON_SMALL],
  },
  transitions: {
    bg: transitionBg,
    bgBoxShadow: `${transitionBg}, ${transitionBoxShadow}`,
    bgBoxShadowLong: `${transitionBgLong}, ${transitionBoxShadowLong}`,
    bgLong: transitionBgLong,
    borderColor: transitionBorderColor,
    borderColorBoxShadow: `${transitionBorderColor}, ${transitionBoxShadow}`,
    borderColorBoxShadowLong: `${transitionBorderColorLong}, ${transitionBoxShadowLong}`,
    borderColorLong: transitionBorderColorLong,
    boxShadow: transitionBoxShadow,
    boxShadowLong: transitionBoxShadowLong,
    color: transitionColor,
    colorLong: transitionColorLong,
    none: 'none',
    slideFromRight: transitionSlideFromRight,
    transform: transitionTransform,
    transformLong: transitionTransformLong,
  },
});

export const getReactSelectTheme = (rsTheme) => ({
  ...rsTheme,
  borderRadius: appTheme.radii.input,
  colors: {
    ...rsTheme.colors,
    danger: colors.salmon,
    dangerLight: colors.salmonAlpha10,
    primary: colors.purple,
    primary25: colors.purpleAlpha10,
    primary50: colors.purpleAlpha50,
    primary75: colors.purpleAlpha50,
    neutral0: colors.white,
    neutral5: colors.whitePointer,
    neutral10: colors.titanWhite,
    neutral20: colors.alto,
    neutral30: colors.chatelle,
    neutral40: colors.frenchGrey,
    neutral50: colors.silver,
    neutral60: colors.silverMed,
    neutral70: colors.ebonyLightest,
    neutral80: colors.ebonyLight,
    neutral90: colors.ebonyMed,
  },
});

export default appTheme;
export { desktopTheme };
