import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import { shadowXLight, getReactSelectTheme } from '../../theme';


class MultiSelection extends React.Component {
  state = {
    selectedItems: [],
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({ selectedItems: value });
  }

  handleChange = (value, action) => {
    const { isMulti, onChange, name } = this.props;
    const { selectedItems } = this.state;

    let nextValue = value || [];
    if (!isMulti && !value) {
      nextValue = null;
    }

    if (onChange) {
      onChange(nextValue, name, selectedItems, action);
    }

    this.setState({ selectedItems: nextValue });
  };

  render() {
    const {
      className,
      closeMenuOnSelect,
      errors,
      isClearable,
      isCreatable,
      isDisabled,
      isLoading,
      isMulti,
      name,
      onCreateOption,
      options,
      placeholder,
      selectRef,
      style,
      sx,
      value,
    } = this.props;

    const { selectedItems } = this.state;

    const filteredOptions = Array.isArray(selectedItems)
      ? options.filter(o => !selectedItems.includes(o))
      : options.filter(o => o.value !== selectedItems?.value);

    const SelectComponent = isCreatable ? Creatable : Select;

    return (
      <SelectComponent
        className={classNames({
          'j-multi-selection': true,
          [className]: className,
        })}
        closeMenuOnSelect={closeMenuOnSelect}
        createOptionPosition={isCreatable ? 'first' : undefined}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        name={name}
        onCreateOption={onCreateOption}
        options={filteredOptions}
        placeholder={placeholder}
        value={value}
        onChange={this.handleChange}
        ref={selectRef}
        styles={{
          control: (rsStyles) => ({
            ...rsStyles,
            minHeight: '40px',
            boxShadow: `0px 1px 1px ${shadowXLight}, 0px 5px 4px ${shadowXLight}, 0px 10px 8px ${shadowXLight}, 0px 20px 16px ${shadowXLight}, 0px 28px 18px ${shadowXLight}, 0px 36px 24px ${shadowXLight}`,
            height: 'auto',
          }),
        }}
        theme={getReactSelectTheme}
      />
    );
  }
}

MultiSelection.propTypes = {
  className: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  selectRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  style: PropTypes.shape(),
  sx: PropTypes.shape(),
  value: PropTypes.any,
};

MultiSelection.defaultProps = {
  className: undefined,
  closeMenuOnSelect: undefined,
  errors: undefined,
  isClearable: true,
  isCreatable: undefined,
  isDisabled: undefined,
  isLoading: undefined,
  isMulti: true,
  options: [],
  onChange: undefined,
  onCreateOption: undefined,
  placeholder: 'Select...',
  selectRef: undefined,
  style: {},
  sx: {},
  value: undefined,
};

export default MultiSelection;
