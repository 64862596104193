import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserRole } from '../../../../common/enums';
import { AppRoutes } from '../../constants';

import { propTypes, defaultProps } from './props';

class AdminRoute extends React.Component {
  render() {
    const {
      path,
      Component,
      isAuthenticated,
      isFetching,
      role,
      ...props
    } = this.props;

    if (isFetching) {
      return <div />;
    }

    if (!isAuthenticated) {
      return <Redirect to={AppRoutes.PUBLIC.LOGIN.path} />;
    }

    return (
      <Route path={path} render={() => (
        UserRole[role] && UserRole[role].isAdmin ?
          <Component {...props} /> :
          <Redirect to={AppRoutes.AUTH.DASHBOARD.path} />
      )} />
    );
  }
}

AdminRoute.propTypes = propTypes;
AdminRoute.defaultProps = defaultProps;

export default AdminRoute;
