const PhoneType = {
  CELL: 'cell',
  HOME: 'home',
  NONE: 'none',
  WORK: 'work',
};

const MessageType = {
  EMAIL: 'email',
  SMS: 'sms',
};


module.exports.PhoneType = PhoneType;
module.exports.MessageType = MessageType;
