//
import * as React from 'react';
import Select from 'react-select';

import { classie } from '../../../../utils';

import styles from './styles.css';


class MemberCategorySelect extends React.Component {
  static defaultProps = {
    className: '',
  };

  render() {
    const {
      onChange,
      value,
      isMulti,
      className,
      options,
      closeMenuOnSelect = false,
    } = this.props;

    return (
      <Select
        className={classie(['react-select info', className])}
        classNamePrefix="react-select"
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        placeholder="Select Category..."
        name="categories"
        value={value}
        options={options}
        onChange={onChange} />
    );
  }
}

export default MemberCategorySelect;
