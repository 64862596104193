import { call, put, take, select, spawn } from 'redux-saga/effects';

import { getActiveDirectoryId } from '../directory/selectors';
import { getUser } from '../user/selectors';
import { RequestUtils } from '../../utils';
import {
  FireFunctions,
  functionNames,
} from '../services';

import * as actions from './actions';
import * as parse from './parse';

/**
 * Request all sent messages.
 * @param fromDate {?string} Beginning of requested date range
 * @param toDate {?string} End of requested date range
 */
export function* getSentMessages(fromDate, toDate) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const user = yield select(getUser);
    const userId = user ? user.id : null;

    if (!userId) {
      yield put(actions.getSentMessagesRoutine.failure({ message: 'No user' }));
      return;
    }

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getSentMessages,
      payload,
    );

    const { error, results } = response.data;

    const userMessages = Object.keys(results || {}).reduce((obj, id) => {
      const message = results[id];

      return {
        ...obj,
        [id]: parse.message(id, message),
      };
    }, {});

    if (error) {
      yield put(actions.getSentMessagesRoutine.failure(error.message, error));
    } else {
      yield put(actions.getSentMessagesRoutine.success({ messages: userMessages || {} }));
    }
  } catch (error) {
    yield put(actions.getSentMessagesRoutine.failure(error.message, error));
  }
}

/**
 * Request all saved message drafts.
 * @param fromDate {?string} Beginning of requested date range
 * @param toDate {?string} End of requested date range
 */
export function* getDraftMessages(fromDate, toDate) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const user = yield select(getUser);
    const userId = user ? user.id : null;

    if (!userId) {
      yield put(actions.getDraftMessagesRoutine.failure({ message: 'No user' }));
      return;
    }

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getSavedDrafts,
      payload,
    );

    const { error, results } = response.data;

    const userMessages = Object.keys(results || {}).reduce((obj, id) => {
      const message = results[id];

      return {
        ...obj,
        [id]: parse.messageDraft(id, message),
      };
    }, {});

    if (error) {
      yield put(actions.getDraftMessagesRoutine.failure(error.message, error));
    } else {
      yield put(actions.getDraftMessagesRoutine.success({ drafts: userMessages || {} }));
    }
  } catch (error) {
    yield put(actions.getDraftMessagesRoutine.failure(error.message, error));
  }
}

/**
 * Request sending either a text or an email.
 * @param {Object} data
 * @property types {string[]} 'EMAIL', 'SMS'... message types
 * @property people {string[]} Id of the recipient
 * @property families {string[]} Id of the recipient
 * @property groups {string[]} Id of the recipient
 * @property categories {string} Id of the recipient
 * @property subject {string} Subject line
 * @property message {string} Message body
 */
export function* sendMessage(data) {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    const payload = RequestUtils.getEntityReqPayload(directoryId, data);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.sendMessage,
      payload,
    );

    const { results, message, success } = response.data;

    if (success) {
      if (Array.isArray(results) && results.length !== 0) {
        const newMessages = results.reduce((obj, log) => ({
          ...obj,
          [log.id]: log,
        }));
        yield put(actions.sendMessageRoutine.success(newMessages));
      } else {
        yield put(actions.sendMessageRoutine.success(results));
      }
    } else {
      yield put(actions.sendMessageRoutine.failure(message));
    }
  } catch (error) {
    yield put(actions.sendMessageRoutine.failure(error.message, error));
  }
}

/**
 * Request sending either a text or an email.
 * @param {Object} messageData
 * @property types {string[]} 'EMAIL', 'SMS'... message types
 * @property people {string[]} People ids
 * @property groups {string[]} Group ids
 * @property families {string[]} family ids
 * @property categories {string[]} Category ids
 * @property subject {string} Subject line
 * @property message {string} Message body
 */
export function* saveDraft(messageData) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const user = yield select(getUser);

    const result = yield call(
      FireFunctions.httpsCallable,
      functionNames.saveMessageDraft,
      { directoryId, userId: user.id, ...messageData },
    );

    if (result?.error) {
      yield put(actions.saveDraftMessageRoutine.failure(result.error.message, result.error));
    } else {
      yield put(actions.saveDraftMessageRoutine.success());
    }
  } catch (error) {
    yield put(actions.saveDraftMessageRoutine.failure(error.message, error));
  }
}

function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      actions.getDraftMessagesRoutine.TRIGGER,
      actions.getSentMessagesRoutine.TRIGGER,
      actions.saveDraftMessageRoutine.TRIGGER,
      actions.sendMessageRoutine.TRIGGER,
    ]);

    switch (type) {
      case actions.getDraftMessagesRoutine.TRIGGER:
        yield spawn(getDraftMessages, payload.fromDate, payload.toDate);
        break;

      case actions.getSentMessagesRoutine.TRIGGER:
        yield spawn(getSentMessages, payload.fromDate, payload.toDate);
        break;

      case actions.saveDraftMessageRoutine.TRIGGER:
        yield spawn(saveDraft, payload);
        break;

      case actions.sendMessageRoutine.TRIGGER:
        yield spawn(sendMessage, payload);
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
