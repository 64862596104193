import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { withIsFetchingLoader } from 'modules/core/hoc';

import { getPathname } from 'modules/router/selectors';

import * as selectors from '../../selectors';
import * as actions from '../../actions';

import ResultsPage from './ResultsPage';

const mapStateToProps = createStructuredSelector({
  isFetching: selectors.getSearchFetching,
  pathname: getPathname,
  searchResults: selectors.getSearchResults,
  searchType: selectors.getSearchType,
  searchTypeName: selectors.getSearchTypeNameForPathname,
});

const mapDispatchToProps = {
  search: actions.searchRequest,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withIsFetchingLoader,
);

export default enhance(ResultsPage);
