export const CLEAR_COMPOSE_STATE = '@messages/CLEAR_COMPOSE_STATE';
export const GET_DRAFT_MESSAGES = '@messages/GET_DRAFT_MESSAGES';
export const GET_SENT_MESSAGES = '@messages/GET_SENT_MESSAGES';
export const SAVE_DRAFT = '@messages/SAVE_DRAFT';
export const SEND_MESSAGE = '@messages/SEND_MESSAGE';

export const messageTextForType = {
  email: 'Email',
  sms: 'SMS',
};

export const messageComposeStates = {
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IDLE: 'IDLE',
};
