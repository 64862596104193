import PropTypes from 'prop-types';
import React from 'react';


const PersonCheck = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M16.84,19.47a8.42,8.42,0,1,0-8.42-8.42A8.41,8.41,0,0,0,16.84,19.47Zm0-12.63a4.21,4.21,0,1,1-4.21,4.21A4.22,4.22,0,0,1,16.84,6.84ZM4.21,32.11c.42-1.33,5.41-3.54,10.44-4.09L19,23.81a20.87,20.87,0,0,0-2.11-.13C11.22,23.68,0,26.51,0,32.11v4.21H19l-4.21-4.21ZM37.05,20.53,26.25,31.41,21.89,27,19,30l7.3,7.37L40,23.49Z"
  />
);

PersonCheck.propTypes = {
  strokeWidth: PropTypes.number,
};

PersonCheck.defaultProps = {
  strokeWidth: 1,
};


export default PersonCheck;
