import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import { withRouter } from 'react-router-dom';

import { getQuery, getQueries, makeQuery } from 'utils';


class PaginationBar extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    this.handleCheckPageQuery(prevLocation, location);
  }

  handleCheckPageQuery = (prevLocation, location) => {
    const { current, onChange, pageSize, total } = this.props;

    if (location.search !== prevLocation.search) {
      const page = getQuery(location.search, 'page');

      if (page) {
        const pageNum = parseInt(page, 10);

        if (current !== pageNum) {
          onChange(pageNum, pageSize);
        }
      } else {
        this.handleSyncUrl(1);
      }
    }
  };

  handleSyncUrl = (page) => {
    const { history, location } = this.props;

    const currentQueries = getQueries(location.search);
    const nextQueries = {
      ...currentQueries,
      page,
    };
    const nextQueryString = makeQuery(nextQueries);
    const newUrl = `${location.pathname}?${nextQueryString}`;

    history.push(newUrl);
  };

  handleChange = (page, pageSize) => {
    const { onChange, syncUrl } = this.props;

    if (syncUrl) {
      this.handleSyncUrl(page);
    } else {
      onChange(page, pageSize);
    }
  };

  render() {
    const {
      location,
      isFetching,
      ...rest
    } = this.props;

    return (
      <Pagination
        disabled={isFetching}
        {...rest}
        onChange={this.handleChange}
      />
    );
  }
}

PaginationBar.propTypes = {
  history: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool,
  location: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  simple: PropTypes.bool,
  size: PropTypes.oneOf(['small', undefined]),
  syncUrl: PropTypes.bool,
};

PaginationBar.defaultProps = {
  isFetching: false,
  simple: false,
  size: undefined,
  syncUrl: true,
};


export default withRouter(PaginationBar);
