import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Text as RebassText } from 'rebass';


const Text = (props) => {
  const {
    className,
    setRef,
    showAsHidden,
    sx,
    ...rest
  } = props;

  const { ':hover': hoverSx } = sx;

  return (
    <RebassText
      className={classNames({
        'j-text': true,
        [className]: className,
      })}
      ref={setRef}
      sx={{
        textDecoration: showAsHidden ? 'line-through' : 'none',
        ...sx,
        ':hover': {
          textDecoration: 'none',
          ...hoverSx,
        },
      }}
      {...rest}
    />
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  showAsHidden: PropTypes.bool,
  sx: PropTypes.shape({}),
};

Text.defaultProps = {
  children: undefined,
  className: undefined,
  setRef: undefined,
  showAsHidden: undefined,
  sx: {},
};

const TextWithRef = React.forwardRef((props, ref) => (
  <Text setRef={ref} {...props} />
));


export { Text };
export default TextWithRef;
