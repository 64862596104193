export const CLEAR_NEXT_ROUTE = '@meta/CLEAR_NEXT_ROUTE';

export const IconPaths = {
  HELP: {
    path: 'M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm0 1.41a10.59 10.59 0 1 0 0 21.18A10.59 10.59 0 0 0 12 1.4zm.12 13.27H10.5v-.53c0-.6.11-1.11.33-1.51.22-.4.61-.82 1.16-1.25a6.39 6.39 0 0 0 1.27-1.21c.2-.29.29-.63.29-1.03 0-.46-.16-.81-.47-1.06-.3-.25-.75-.37-1.33-.37-.52 0-1 .07-1.45.22-.45.15-.89.33-1.31.54l-.7-1.46a7.28 7.28 0 0 1 3.6-.93c1.08 0 1.94.26 2.57.79.64.53.96 1.26.96 2.2 0 .4-.06.77-.19 1.1-.12.32-.3.63-.55.92-.24.3-.66.67-1.27 1.14-.5.4-.85.74-1.03 1-.17.27-.26.62-.26 1.07v.37zm-1.64 3.58c.2.22.5.33.9.33s.7-.11.9-.34c.22-.23.32-.54.32-.93 0-.4-.1-.7-.31-.92-.21-.22-.51-.33-.91-.33-.81 0-1.22.42-1.22 1.25 0 .4.1.71.32.94z',
    transform: '',
  },
};

export const storyPadding = {
  grid0: { name: 'None', value: '0px', default: true },
  grid4: { name: 'Grid4', value: '4px' },
  grid8: { name: 'Grid8', value: '8px' },
  grid16: { name: 'Grid16', value: '16px' },
  grid32: { name: 'Grid32', value: '32px' },
  grid64: { name: 'Grid64', value: '64px' },
};

export const storyPaddings = [
  storyPadding.grid0,
  storyPadding.grid4,
  storyPadding.grid8,
  storyPadding.grid16,
  storyPadding.grid32,
  storyPadding.grid64,
];

export const Appearance = {
  DEFAULT: 'DEFAULT',
  GHOST: 'GHOST',
  NONE: 'NONE',
};

export const Intent = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  DANGER: 'DANGER',
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  NONE: 'NONE',
};

export const IntentColor = {
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  DEFAULT: 'primary',
  SUCCESS: 'success',
  NONE: 'disabledText',
};

export const ButtonSize = {
  DEFAULT: 'default',
  BIG: 'big',
  BIGGER: 'bigger',
};

export const ElementShape = {
  RECT: 'RECT',
  ROUND: 'ROUND',
};

export const KeyboardKey = {
  ESCAPE: 'Escape',
};
