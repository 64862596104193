import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { getPathname, getQuery } from 'modules/router/selectors';

import * as selectors from '../../selectors';
import * as actions from '../../actions';

const mapStateToProps = createStructuredSelector({
  searchType: selectors.getSearchType,
  searchResults: selectors.getSearchResults,
  searchFetching: selectors.getSearchFetching,
  pathname: getPathname,
  searchTypeName: selectors.getSearchTypeNameForPathname,
  urlQuery: getQuery,
});

const mapDispatchToProps = {
  clearSearch: actions.clearSearch,
  search: actions.searchRequest,
};

export default presenter => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(presenter));
