export const IconSize = {
  ICON_PANEL: 'ICON_PANEL',
  ICON_HUGE: 'ICON_HUGE',
  ICON_XLARGE: 'ICON_XLARGE',
  ICON_LARGE: 'ICON_LARGE',
  ICON_MED: 'ICON_MED',
  ICON_SMALL: 'ICON_SMALL',
};

export const FontEmSize = {
  FONT12: 0.75,
  FONT14: 0.875,
  FONT16: 1,
  FONT18: 1.15,
  FONT20: 1.25,
  FONT24: 1.5,
  FONT32: 2,
  FONT36: 2.25,
  FONT40: 2.5,
  FONT48: 3,
};
