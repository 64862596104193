import * as React from 'react';
import { createPortal } from 'react-dom';

import Box from '../Box';

import { styles } from './styles';


const Subroute = ({ children }) => {
  const subrouteEl = document.getElementById('subroute');

  React.useEffect(() => {
    document.querySelector('#app').classList.add('scroll-lock');

    return () => {
      document.querySelector('#app').classList.remove('scroll-lock');
    };
  });

  const contents = (
    <Box sx={styles.container}>
      {children}
    </Box>
  );

  if (subrouteEl) {
    return createPortal(contents, subrouteEl);
  }

  return contents;
};


export default Subroute;
