import admin from '../admin/saga';
import auth from '../auth/saga';
import defs from '../definitions/saga';
import directory from '../directory/saga';
import error from '../error/saga';
import families from '../families/saga';
import files from '../files/saga';
import groups from '../groups/saga';
import members from '../members/saga';
import messages from '../messages/saga';
import navigation from '../router/sagas';
import panel from '../panel/sagas';
import search from '../search/saga';
import tags from '../tags/saga';
import user from '../user/sagas';

export default function* rootSaga() {
  yield [
    admin(),
    auth(),
    defs(),
    directory(),
    error(),
    families(),
    files(),
    groups(),
    members(),
    messages(),
    navigation(),
    panel(),
    search(),
    tags(),
    user(),
  ];
}
