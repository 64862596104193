import { take, spawn } from 'redux-saga/effects';

import { navService } from 'modules/services';

import * as constants from './constants';


export function* pushToHistory(payload) {
  try {
    const { url, state } = payload;
    navService.push(url, state);
    yield true;
  } catch (err) {
    yield false;
  }
}

function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.HISTORY_PUSH,
    ]);

    switch (type) {
      case constants.HISTORY_PUSH:
        yield spawn(
          pushToHistory,
          payload,
        );
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
