import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import { IconSize } from '../../sizes';

import Box from '../Box';
import { Shapes, IconNames } from '../svg';

import withIconProps from './withIconProps';


const Icon = (props) => {
  const {
    className,
    name,
    onBlur,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    setRef,
    size,
    svgProps,
    sx,
    ...rest
  } = props;

  const theme = useTheme();

  const sizeValue = String(theme.sizes[size]);

  return (
    <Box
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={onBlur || onFocus ? '0' : undefined}
      sx={{
        display: 'inline-block',
        ...sx,
      }}
    >
      <svg
        className={classNames({
          'j-icon': true,
          [`j-icon-${name.toLowerCase()}`]: true,
          [className]: className,
        })}
        height={sizeValue}
        ref={setRef}
        viewBox="0 0 40 40"
        width={sizeValue}
        {...svgProps}
        style={{
          maxHeight: `${sizeValue}px`,
          maxWidth: `${sizeValue}px`,
          ...(svgProps.style || {})
        }}
      >
        {React.createElement(Shapes[name] ?? React.Fragment, rest)}
      </svg>
    </Box>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.oneOf(Object.values(IconNames)).isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  size: PropTypes.oneOf(Object.values(IconSize)),
  svgProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Icon.defaultProps = {
  className: undefined,
  color: undefined,
  onBlur: undefined,
  onFocus: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  setRef: undefined,
  size: IconSize.ICON_HUGE,
  svgProps: {},
  sx: {},
};

const IconWithProps = withIconProps(Icon);

const IconWithRef = React.forwardRef((props, ref) => (
  <IconWithProps setRef={ref} {...props} />
));


export { Icon, IconNames };
export default IconWithRef;
