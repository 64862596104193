import { all, call, fork, put, take, spawn, select } from 'redux-saga/effects';

import { DataNormalizers, RequestUtils } from 'utils';

import {
  FireFunctions,
  functionNames,
  PubsubService,
  pubsubTopics,
} from '../services';

import { familySummariesRequest } from '../families/actions';
import { groupsSummariesRequest } from '../groups/actions';
import { getPersonSummariesRequest } from '../members/actions';
import { getActiveDirectoryId } from '../directory/selectors';
import * as adminActions from '../admin/actions';
import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import * as parse from './parse';


export function* requestTags() {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const response = yield call(api.requestTags, directoryId);
    const { results } = response.data;
    yield put(actions.tagsSuccess(results));
  } catch (error) {
    yield put(actions.tagsFailure(error.message, error));
  }
}

export function* requestDirSettings() {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getDirectorySettings,
      payload,
    );

    const { results } = response.data;

    const settings = results ? parse.dirSettings(results) : {
      defaultCountry: {},
      defaultRegion: {},
      defaultDate: {},
      defaultCountryCode: {},
    };

    yield put(actions.dirSettingsSuccess(settings));
  } catch (error) {
    yield put(actions.dirSettingsFailure(error.message, error));
  }
}

export function* requestDeleteUser(userId) {
  const notifData = { message: '', type: '' };
  let actionObject;

  try {
    const directoryId = yield select(getActiveDirectoryId);
    const result = yield call(api.requestDeleteUser, directoryId, userId);

    const { success, message } = result.data;

    if (success) {
      actionObject = actions.deleteUserSuccess(userId);
      notifData.message = 'Person was successfully removed.';
      notifData.type = 'success';
    } else {
      actionObject = actions.deleteUserFailure(message);
      notifData.message = 'There was an error removing this person.';
      notifData.type = 'error';
    }
  } catch (error) {
    PubsubService.publish(pubsubTopics.NOTIFS, {
      message: 'There was an error removing this person.',
    });
    actionObject = actions.deleteUserFailure(error.message, error);
    notifData.message = 'There was an error removing this person.';
    notifData.type = 'error';
  }

  PubsubService.publish(pubsubTopics.NOTIFS, notifData);
  yield all([
    put(actionObject),
    put(getPersonSummariesRequest()),
    put(groupsSummariesRequest()),
    put(familySummariesRequest()),
  ]);
}

export function* requestDeleteFamily(familyId) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const result = yield call(api.requestDeleteFamily, directoryId, familyId);
    const { success, message } = result.data;

    if (success) {
      yield put(actions.deleteFamilySuccess(familyId));
    } else {
      yield put(actions.deleteFamilyFailure(message));
    }
  } catch (error) {
    yield put(actions.deleteFamilyFailure(error.message, error));
  }
}

export function* requestDeleteGroup(groupId) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const result = yield call(api.requestDeleteGroup, directoryId, groupId);
    const { success, message } = result.data;

    if (success) {
      yield put(actions.deleteGroupSuccess(groupId));
    } else {
      yield put(actions.deleteGroupFailure(message));
    }
  } catch (error) {
    yield put(actions.deleteGroupFailure(error.message, error));
  }
}

export function* requestAdmins() {
  try {
    const dirId = yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.getAdminsFailure('No directory id'));
      return;
    }

    const payload = RequestUtils.getEntityReqPayload(dirId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getAdmins,
      payload,
    );

    const { results } = response.data;

    const admins = DataNormalizers.mapFromArray(results);

    yield put(actions.getAdminsSuccess(admins));
  } catch (error) {
    yield put(actions.getAdminsFailure(error.message, error));
  }
}

export function* updateDirFields(fields, fieldId, updates) {
  try {
    const dirId = yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.getAdminsFailure('No directory id'));
      return;
    }

    const payload = RequestUtils.getEntityReqPayload(dirId, {
      fields,
      fieldId,
      updates,
    });

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.updateDirectoryFields,
      payload,
    );

    const { success, message } = response.data;

    if (success) {
      yield all([
        put(actions.updateDirFieldsSuccess()),
        put(adminActions.adminDirectoryFieldsRequest()),
      ]);
    } else {
      yield put(actions.updateDirFieldsFailure(message, {}));
    }
  } catch (error) {
    yield put(actions.updateDirFieldsFailure(error.message, error));
  }
}

export function* updateDirCategories(categories, categoryId, updates) {
  try {
    const dirId = yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.getAdminsFailure('No directory id'));
      return;
    }

    const payload = RequestUtils.getEntityReqPayload(dirId, {
      categories,
      categoryId,
      updates,
    });

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.updateDirectoryCategories,
      payload,
    );

    const { success, message } = response.data;

    if (success) {
      yield all([
        put(actions.updateDirCategoriesSuccess()),
        put(adminActions.adminUserCatsRequest()),
      ]);
    } else {
      yield put(actions.updateDirCategoriesFailure(message, {}));
    }
  } catch (error) {
    yield put(actions.updateDirCategoriesFailure(error.message, error));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.DELETE_FAMILY_REQUEST,
      constants.DELETE_GROUP_REQUEST,
      constants.DELETE_USER_REQUEST,
      constants.DIR_SETTINGS_REQUEST,
      constants.GET_ADMINS_REQUEST,
      constants.TAGS_REQUEST,
      constants.UPDATE_DIR_FIELDS_REQUEST,
      constants.UPDATE_DIR_CATEGORIES_REQUEST,
    ]);

    switch (type) {
      case constants.TAGS_REQUEST:
        yield fork(
          requestTags,
        );
        break;

      case constants.DIR_SETTINGS_REQUEST:
        yield fork(
          requestDirSettings,
        );
        break;

      case constants.DELETE_USER_REQUEST:
        yield fork(
          requestDeleteUser,
          payload.userId,
        );
        break;

      case constants.DELETE_FAMILY_REQUEST:
        yield fork(
          requestDeleteFamily,
          payload.familyId,
        );
        break;

      case constants.DELETE_GROUP_REQUEST:
        yield fork(
          requestDeleteGroup,
          payload.groupId,
        );
        break;

      case constants.GET_ADMINS_REQUEST:
        yield fork(
          requestAdmins,
        );
        break;

      case constants.UPDATE_DIR_FIELDS_REQUEST:
        yield spawn(
          updateDirFields,
          payload.fields,
          payload.fieldId,
          payload.updates,
        );
        break;

      case constants.UPDATE_DIR_CATEGORIES_REQUEST:
        yield spawn(
          updateDirCategories,
          payload.categories,
          payload.categoryId,
          payload.updates,
        );
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
