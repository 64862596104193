import * as React from 'react';
import { connect } from 'react-redux';

import { fbSwitchDirRequest } from '../../user/actions';
import { FireDb, FireAuth } from '../../services';

function withUserDb(Component) {
  class ComponentWithUserDb extends React.Component {
    render() {
      return (
        <Component
          generateCustomFieldId={FireDb.generateCustomFieldId}
          getCurrentUser={FireAuth.getCurrentUser}
          getUserTree={FireDb.getDirectoryUserTree}
          updateUserTree={FireDb.updateUserTree}
          setUserPhoneNumber={FireDb.setUserPhoneNumber}
          {...this.props} />
      );
    }
  }

  const mapDispatchToProps = {
    switchDirectory: fbSwitchDirRequest,
  };

  return connect(null, mapDispatchToProps)(ComponentWithUserDb);
}

export default withUserDb;
