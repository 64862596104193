import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import Box from '../../../../Box';
import { CheckIcon } from '../../../../Icon';
import Text from '../../../../Text';


const isOverflowed = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => (
  scrollHeight > clientHeight || scrollWidth > clientWidth
);

const MobileSwitcherLink = ({ children, identifier, isActive, onClick }) => {
  const [isHover, setHover] = React.useState(false);

  return (
    <Box
      className={classNames({
        'j-mobile-switcher-link': true,
        'j-switcher-link-active': isActive,
        'j-hovered': isHover,
        'cursor-pointer': true,
      })}
      onClick={() => {
        setHover(true);
        onClick();
        ReactTooltip.hide();
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        color: 'lightText',
        fontSize: 'font18',
        fontWeight: 'bold',
        lineHeight: 'switcherLinkMobile',
        paddingLeft: 'grid0',
        paddingBottom: 'grid16',
        paddingTop: 'grid16',
        position: 'relative',
        transition: 'background-color 0.2s',
        ':last-child': {
          marginBottom: 'grid0',
        },
        ':hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: 'lightSubtext',
        },
      }}
      variant="nav"
    >
      {isActive && (
        <Box
          sx={{
            position: 'absolute',
            left: 'grid16',
            top: 'grid16',
            bottom: 0,
          }}
        >
          <CheckIcon
            color={colors.white}
            size={IconSize.ICON_SMALL}
          />
        </Box>
      )}
      <Text
        data-tip
        data-for={identifier}
        sx={{
          display: 'inline-block',
          paddingLeft: 'grid40',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: 'grid16',
        }}
      >
        {children}
      </Text>
      <ReactTooltip effect="solid" id={identifier}>
        {children}
      </ReactTooltip>
    </Box>
  );
};

MobileSwitcherLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  identifier: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

MobileSwitcherLink.defaultProps = {
  children: undefined,
  href: undefined,
  identifier: undefined,
  isActive: undefined,
};


export default MobileSwitcherLink;
