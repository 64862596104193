import * as constants from './constants';

export function tagsRequest() {
  return {
    type: constants.TAGS_REQUEST,
  };
}

export function tagsSuccess(tags) {
  return {
    type: constants.TAGS_SUCCESS,
    payload: {
      tags,
    },
  };
}

export function tagsFailure(message, error) {
  return {
    type: constants.TAGS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function membersByTagRequest(tags) {
  return {
    type: constants.MEMBERS_BY_TAG_REQUEST,
    payload: {
      tags,
    },
  };
}

export function membersByTagSuccess(members) {
  return {
    type: constants.MEMBERS_BY_TAG_SUCCESS,
    payload: {
      members,
    },
  };
}

export function membersByTagFailure(message, error) {
  return {
    type: constants.MEMBERS_BY_TAG_FAILURE,
    payload: {
      message,
      error,
    },
  };
}
