// 

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography } from 'antd';

import AddResourceButton from '../AddResourceButton';


import styles from './styles.css';

class ResourceCard extends React.PureComponent {
  render() {
    const {
      resourceName,
      resourcePlural,
      itemCount,
      createTo,
      to,
    } = this.props;

    return (
      <Card className={styles.card}>
        <Link to={to}>
          <React.Fragment>
            <Typography.Text>{resourcePlural}</Typography.Text>
            <br />
            <Typography.Text>
              ({itemCount} {itemCount === 1 ? resourceName : resourcePlural})
            </Typography.Text>
          </React.Fragment>
        </Link>

        <AddResourceButton to={createTo} />
      </Card>
    );
  }
}

export default ResourceCard;
