import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { black, purpleDarkened } from '../../colors';

import Flex from '../Flex';
import Icon from '../Icon';
import Link from '../Link';
import Text from '../Text';

import { IconSize } from '../../sizes';


const PanelOverviewIconButton = (props) => {
  const {
    children,
    iconName,
    iconSize,
    iconSx,
    isActive,
    href,
    sx,
  } = props;

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Link
      className={classNames({ active: isActive })}
      href={href}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: 'block',
        marginTop: 'grid8',
        ...sx,
      }}
    >
      <Flex
        flexDirection="column"
        sx={{
          textAlign: 'center',
        }}
      >
        <Icon
          color={isHovered || isActive ? purpleDarkened : black}
          name={iconName}
          size={iconSize}
          sx={{
            ...iconSx,
            alignSelf: 'center',
          }}
        />
        <Text
          sx={{
            alignSelf: 'center',
            fontWeight: 'bold',
            fontSize: 'font18',
          }}
        >
          {children}
        </Text>
      </Flex>
    </Link>
  );
};

PanelOverviewIconButton.propTypes = {
  children: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  iconSx: PropTypes.shape(),
  isActive: PropTypes.bool,
  sx: PropTypes.shape(),
};

PanelOverviewIconButton.defaultProps = {
  iconSize: IconSize.ICON_HUGE,
  iconSx: {},
  isActive: undefined,
  sx: {},
};


export default PanelOverviewIconButton;
