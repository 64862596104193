import PropTypes from 'prop-types';
import React from 'react';

import { roleHelpers } from 'helpers';

import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import { IconNames } from 'modules/core/components/svg';
import Flex from 'modules/core/components/Flex';
import Icon from 'modules/core/components/Icon';
import Link from 'modules/core/components/Link';
import Text from 'modules/core/components/Text';

import { dashboardRoutePaths, panelRoutePaths } from 'modules/router/constants';

import * as styles from './styles';


const LaptopMenu = ({ filters, hasAnyMessaging, role }) => {
  const isAdmin = roleHelpers.isAdminRole(role);
  const isGroupLeader = roleHelpers.isGroupLeaderRole(role);

  console.log("isGroupLeader: " + isGroupLeader);
  console.log("hasAnyMessaging" + hasAnyMessaging);

  return (
    <Flex sx={styles.containerStyles}>
      <Link href={`${dashboardRoutePaths.people}/${filters?.views?.people?.type}`} sx={styles.linkTextStyles}>
        <Text>
          People
        </Text>
      </Link>

      <Link
        href={dashboardRoutePaths.groups}
        sx={{ ...styles.linkTextStyles, marginRight: isAdmin ? 'grid16' : 'grid24' }}
      >
        <Text>
          Groups
        </Text>
      </Link>

      {isAdmin && (
        <Link href={panelRoutePaths.panel} sx={{ ...styles.linkTextStyles, marginRight: 'grid24' }}>
          <Text>
            Admin Panel
          </Text>
        </Link>
      )}
      {hasAnyMessaging && (isAdmin || isGroupLeader) && (
        <Link href={dashboardRoutePaths.messages} sx={styles.linkButtonStyles}>
          <Icon
            color={colors.ebonyLightest}
            hoverColor={colors.ebonyLightest}
            name={IconNames.MAIL_OUTLINE}
            size={IconSize.ICON_LARGE}
            sx={styles.buttonStyles}
          />
        </Link>
      )}

      <Link href={dashboardRoutePaths.userAccount} sx={styles.linkButtonStyles}>
        <Icon
          color={colors.ebonyLightest}
          hoverColor={colors.ebonyLightest}
          name={IconNames.USER}
          size={IconSize.ICON_LARGE}
          sx={styles.buttonStyles}
        />
      </Link>
    </Flex>
  );
};

LaptopMenu.propTypes = {
  filters: PropTypes.shape().isRequired,
  hasAnyMessaging: PropTypes.bool,
  role: PropTypes.string.isRequired,
};

LaptopMenu.defaultProps = {
  hasAnyMessaging: undefined,
};


export default LaptopMenu;
