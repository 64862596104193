import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button as RebassButton } from 'rebass';

import { Appearance, ButtonSize, Intent, IntentColor } from 'modules/core/constants';

import Box from '../Box';

const buttonDefaultSx = {
  borderColor: 'primary',
  borderStyle: 'default',
  borderWidth: 'med',
  borderRadius: 'button',
  boxShadow: 'cardXS',
  position: 'relative',
};

const getButtonSx = (color = 'primary') => ({
  ...buttonDefaultSx,
  backgroundColor: color,
  borderColor: color,
});

const buttonGhostSx = {
  ...buttonDefaultSx,
  backgroundColor: 'transparent',
  borderColor: 'primary',
  borderStyle: 'default',
  borderWidth: 'med',
  boxShadow: 'cardXS',
};

const getButtonGhostSx = (color = 'primary') => ({
  ...buttonGhostSx,
  borderColor: color,
  color,
});

const buttonNoneSx = {
  ...buttonDefaultSx,
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  borderStyle: 'default',
  borderWidth: 'med',
  boxShadow: 'none',
  color: 'disabledText',
};

const buttonDefaultHoverSx = {
  backgroundColor: 'transparent',
  borderColor: 'primary',
  boxShadow: 'cardXSHover',
  color: 'primary',
};

const getButtonDefaultHoverSx = (color = 'primary') => ({
  ...buttonDefaultHoverSx,
  borderColor: color,
  color,
});

const buttonDefaultActiveSx = {
  backgroundColor: 'transparent',
  borderColor: 'primary',
  boxShadow: 'cardXSHover',
  color: 'primary',
};

const getButtonDefaultActiveSx = (color = 'primary') => ({
  ...buttonDefaultActiveSx,
  backgroundColor: 'transparent',
  borderColor: color,
  color,
});

const buttonGhostActiveSx = {
  backgroundColor: 'primary',
  borderColor: 'primary',
  boxShadow: 'cardXSHover',
  color: 'white',
};

const getButtonGhostActiveSx = (color = 'primary') => ({
  ...buttonGhostActiveSx,
  backgroundColor: 'transparent',
  borderColor: color,
  color,
});

const buttonGhostHoverSx = {
  backgroundColor: 'primary',
  borderColor: 'primary',
  boxShadow: 'cardXSHover',
  color: 'white',
};

const getButtonGhostHoverSx = (color = 'primary') => ({
  ...buttonGhostHoverSx,
  backgroundColor: color,
  borderColor: color,
  color: 'lightText',
});

const buttonNoneHoverSx = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  boxShadow: 'none',
  color: 'primary',
};

const buttonDefaultDisabledSx = {
  backgroundColor: 'disabled',
  borderColor: 'disabled',
  boxShadow: 'none',
  color: 'disabledText',
  transition: 'none',
};

const buttonGhostDisabledSx = {
  backgroundColor: 'transparent',
  borderColor: 'disabled',
  boxShadow: 'none',
  color: 'disabledText',
  transition: 'none',
};

const buttonNoneDisabledSx = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  boxShadow: 'none',
  color: 'disabledText',
  transition: 'none',
};

const buttonSizeBigSx = {
  paddingBottom: 'grid4',
  paddingTop: 'grid4',
};

const buttonSizeBiggerSx = {
  paddingBottom: 'grid8',
  paddingTop: 'grid8',
};

const buttonSizeSx = {
  [ButtonSize.DEFAULT]: {},
  [ButtonSize.BIG]: buttonSizeBigSx,
  [ButtonSize.BIGGER]: buttonSizeBiggerSx,
};

const getSx = (appearance, intent) => {
  const color = IntentColor[intent];

  switch (appearance) {
    case Appearance.NONE:
      return buttonNoneSx;

    case Appearance.GHOST:
      return getButtonGhostSx(color);

    default:
      return getButtonSx(color);
  }
};

const getHoverSx = (appearance, intent) => {
  const color = IntentColor[intent];

  switch (appearance) {
    case Appearance.NONE:
      return buttonNoneHoverSx;

    case Appearance.GHOST:
      return getButtonGhostHoverSx(color);

    default:
      return getButtonDefaultHoverSx(color);
  }
};

const getDisabledSx = (appearance) => {
  switch (appearance) {
    case Appearance.NONE:
      return buttonNoneDisabledSx;

    case Appearance.GHOST:
      return buttonGhostDisabledSx;

    default:
      return buttonDefaultDisabledSx;
  }
};

const getActiveSx = (appearance, intent) => {
  const color = IntentColor[intent];

  switch (appearance) {
    case Appearance.NONE:
      return buttonNoneHoverSx;

    case Appearance.GHOST:
      return getButtonGhostActiveSx(color);

    default:
      return getButtonDefaultActiveSx(color);
  }
};

const Button = (props) => {
  const {
    animate,
    appearance,
    children,
    className,
    intent,
    isDisabled,
    isHoverTransition,
    isLoading,
    onClick,
    setRef,
    size,
    sx,
    ...rest
  } = props;

  const [t, setT] = React.useState(null);

  const defaultSx = getSx(appearance, intent);
  const hoverSx = getHoverSx(appearance, intent);
  const disabledSx = getDisabledSx(appearance);
  const activeSx = getActiveSx(appearance, intent);
  const focusSx = getActiveSx(appearance, intent);
  const sizeSx = buttonSizeSx[size];

  const handleClick = React.useCallback((evt) => {
    if (!isDisabled) {
      onClick?.(evt);
    }
  }, [isDisabled]);

  React.useEffect(() => () => {
    clearTimeout(t);
    setT(null);
  }, [t]);

  return (
    <RebassButton
      {...rest}
      className={classNames({
        'j-button': true,
        // 'anim-bop-down': isClicked,
        [`appear-${appearance?.toLowerCase()}`]: appearance,
        [className]: className,
      })}
      disabled={isDisabled}
      onClick={handleClick}
      ref={setRef}
      sx={{
        ...defaultSx,
        ...sizeSx,
        cursor: 'pointer',
        fontWeight: 'semibold',
        paddingLeft: 'grid16',
        paddingRight: 'grid16',
        ...sx,
        ':hover': {
          ...(isHoverTransition ? hoverSx : {}),
          ...sx[':hover'],
        },
        ':disabled': {
          ...disabledSx,
          ...sx[':disabled'],
        },
        ':active': {
          ...activeSx,
          ...sx[':active'],
        },
        ':focus': {
          ...activeSx,
          ...sx[':focus'],
        },
      }}
    >
      {children}

      {isLoading && (
        <Box
          sx={{
            backgroundColor: 'pink',
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            top: '0',
          }}
        />
      )}
    </RebassButton>
  );
};

Button.propTypes = {
  animate: PropTypes.bool,
  appearance: PropTypes.oneOf(Object.values(Appearance)),
  children: PropTypes.node,
  className: PropTypes.string,
  intent: PropTypes.oneOf(Object.values(Intent)),
  isDisabled: PropTypes.bool,
  isHoverTransition: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  sx: PropTypes.shape({}),
};

Button.defaultProps = {
  animate: true,
  appearance: Appearance.DEFAULT,
  children: undefined,
  className: undefined,
  intent: Intent.DEFAULT,
  isDisabled: undefined,
  isHoverTransition: true,
  isLoading: false,
  onClick: undefined,
  setRef: undefined,
  size: ButtonSize.DEFAULT,
  sx: {},
};

const ButtonWithRef = React.forwardRef((props, ref) => (
  <Button setRef={ref} {...props} />
));


export { Button };
export default ButtonWithRef;
