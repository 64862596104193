import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox as RebassCheckbox } from '@rebass/forms';

import { useControlled } from 'hooks';

import Box from '../Box';
import Label from '../Label';


const Checkbox = (props) => {
  const {
    children,
    className,
    defaultValue,
    disabled,
    id,
    name,
    onChange,
    setRef,
    sx,
    value,
    ...rest
  } = props;

  const [val, setVal] = useControlled({
    default: defaultValue,
    onChange,
    value,
  });

  return (
    <Label sx={{ ...sx, color: disabled ? 'disabledText' : undefined, display: 'flex' }}>
      <RebassCheckbox
        className={classNames({
          'j-checkbox': true,
          'j-checked': Boolean(val),
          [className]: className,
        })}
        checked={val}
        disabled={disabled}
        id={id}
        name={name}
        onChange={(evt) => {
          setVal(evt, !val);
        }}
        ref={setRef}
        {...rest}
      />
      <Box sx={{ alignSelf: 'center' }}>
        {children}
      </Box>
    </Label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  setRef: PropTypes.func,
  sx: PropTypes.shape(),
  value: PropTypes.bool,
};

Checkbox.defaultProps = {
  children: undefined,
  className: undefined,
  defaultValue: false,
  disabled: undefined,
  id: undefined,
  name: undefined,
  onChange: undefined,
  setRef: undefined,
  sx: {},
  value: undefined,
};

const CheckboxWithRef = React.forwardRef((props, ref) => (
  <Checkbox setRef={ref} {...props} />
));


export { Checkbox };
export default CheckboxWithRef;
