export const container = {
  paddingBottom: 'grid8',
  paddingTop: 'grid8',
  paddingRight: 'grid16',
  paddingLeft: 'grid16',
};

export const navButton = {
  textAlign: 'left',
  paddingLeft: 'grid0',
};
