import * as React from 'react';

import { Intent } from 'modules/core/constants';

import ChildrenIfRole from '../ChildrenIfRole';
import Box from '../Box';
import Callout from '../Callout';


const AdminCallout = ({ role }) => (
  <ChildrenIfRole
    role={role}
    allowedRoles={['SYSTEM_ADMIN', 'ADMIN']}>
    <Box
      sx={{
        marginBottom: 'grid24',
      }}
    >
      <Callout intent={Intent.DEFAULT}>
        Since you are an admin, fields will display on this profile that may be hidden or protected.
      </Callout>
    </Box>
  </ChildrenIfRole>
);


export default AdminCallout;
