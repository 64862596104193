import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';

import styles from './styles.css';


const ActionsDropdown = ({ isDisabled, onSelect, type }) => (
  <Dropdown
    disabled={isDisabled}
    overlay={(
      <Menu onClick={onSelect}>
        <Menu.Item key="delete">
          <a href="#">Remove {type}</a>
        </Menu.Item>
      </Menu>
    )}
    trigger={['click']}
  >
    <Button>
      More Actions <DownOutlined className={styles.downIcon} />
    </Button>
  </Dropdown>
);


export default ActionsDropdown;
