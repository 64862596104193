function registerSw() {
  // Install servicerWorker if supported.
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
  //     .then((reg) => {
  //       // Registration worked.
  //       console.log(`Registration succeeded. Scope is ${reg.scope}`);
  //     }).catch((error) => {
  //     // Registration failed.
  //       console.log(`Registration failed with ${error.message}`);
  //     });
  // }
}

export default registerSw;
