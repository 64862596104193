import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';


class InfiniteScroller extends React.Component {
  state = {
    showLoader: false,
  };

  containerRef = React.createRef();

  handleScroll = (evt) => {
    const { error, hasMore, isLoading, loadMore, threshold } = this.props;
    const { showLoader } = this.state;

    const docScrollAmount = window.innerHeight + document.documentElement.scrollTop;
    const containerEnd = this.containerRef.current
      ? this.containerRef.current.offsetTop + this.containerRef.current.offsetHeight
      : 0;

    if (!error && !isLoading && !showLoader && hasMore && this.containerRef.current) {
      if (
        // window.innerHeight + document.documentElement.scrollTop
      // >= this.containerRef.current.offsetTop - threshold
        docScrollAmount >= containerEnd
      ) {
        this.setState({ showLoading: true });
        setTimeout(() => {
          loadMore();
        }, 150);
      }
    }
  };

  scrollDebouncer = _debounce(this.handleScroll, 750);

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDebouncer);
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;
    const { isLoading: wasLoading } = prevProps;
    if (!isLoading && wasLoading) {
      this.setState({ showLoader: false });
    }
  }

  render() {
    const { children, loader } = this.props;
    const { showLoader } = this.state;

    return (
      <div ref={this.containerRef}>
        {children}

        {showLoader && loader}
      </div>
    );
  }
}

InfiniteScroller.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  loader: PropTypes.node,
  loadMore: PropTypes.func.isRequired,
  threshold: PropTypes.number,
};

InfiniteScroller.defaultProps = {
  children: undefined,
  error: undefined,
  hasMore: false,
  isLoading: false,
  loader: undefined,
  threshold: 250,
};


export default InfiniteScroller;
