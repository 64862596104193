import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import defaultImage from 'assets/images/default-avatar.png';

import { propTypeHelpers } from 'helpers';

import { DeviceUtils } from 'utils';

import AddToCalendar from '../AddToCalendar';
import Flex from '../Flex';
import { RoundAvatar } from '../Avatar';
import Text from '../Text';

import { createHeaderStyles } from './styles';


const isMobile = DeviceUtils.isMobile.any();

const ProfileHeader = (props) => {
  const {
    AvatarComponent,
    avatarSize,
    basicsVisible,
    birthdate,
    fullName,
    imageUrl,
    isAdmin,
    isLaptopOrLarger,
    onIcsEvent,
  } = props;

  const displayBirthdate = Boolean(birthdate && (basicsVisible || isAdmin));

  let bDate;
  let bDateDisplay;
  let bdayEventTitle;

  const thisYear = moment().year();

  if (displayBirthdate) {
    bDate = moment(birthdate);
    bDateDisplay = moment(birthdate).year(thisYear);
    bdayEventTitle = fullName ? `${fullName}'s Birthday` : undefined;
  }

  const styles = createHeaderStyles(isLaptopOrLarger);

  return (
    <Flex sx={styles.header}>
      <Flex sx={styles.avatarContainer}>
        <AvatarComponent
          alt={fullName}
          size={avatarSize}
          src={imageUrl || defaultImage}
          sx={styles.avatar}
        />
      </Flex>

      <Flex sx={styles.textContainer}>
        <Text sx={styles.nameText}>
          {fullName}
        </Text>

        {displayBirthdate && (
          <Flex sx={styles.birthdateContainer}>
            <AddToCalendar
              allDayEvent
              duration={24 * 60}
              isMobile={Boolean(isMobile && isMobile.length)}
              onIcsEvent={onIcsEvent}
              recurringRule={'RRULE:FREQ=YEARLY'}
              start={bDateDisplay.toDate()}
              title={bdayEventTitle}
            />

            <Text sx={styles.birthdateText}>
              {bDate.format('MMMM Do')}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

ProfileHeader.propTypes = {
  AvatarComponent: PropTypes.elementType,
  avatarSize: propTypeHelpers.oneOfOrArrayOfPropType(PropTypes.number, PropTypes.string),
  basicsVisible: PropTypes.bool,
  birthdate: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isAdmin: PropTypes.bool,
  isLaptopOrLarger: PropTypes.bool,
  onIcsEvent: PropTypes.func,
};

ProfileHeader.defaultProps = {
  AvatarComponent: RoundAvatar,
  avatarSize: 160,
  basicsVisible: undefined,
  birthdate: undefined,
  imageUrl: undefined,
  isAdmin: undefined,
  isLaptopOrLarger: undefined,
  onIcsEvent: undefined,
};


export default ProfileHeader;
