export const containerStyles = { paddingBottom: 'grid4' };

export const linkTextStyles = {
  color: 'systemText',
  fontWeight: 'semibold',
  marginRight: 'grid16',
};

export const linkButtonStyles = {
  marginRight: 'grid16',
  '&:last-child': {
    marginRight: 'grid0',
  },
};

export const buttonStyles = {
  color: 'text',
  paddingBottom: 'grid2',
  paddingLeft: 'grid0',
  paddingRight: 'grid0',
  paddingTop: 'grid2',
  ':hover': {
    backgroundColor: 'transparent',
  },
};
