import firebase from 'firebase';


let storage;

const paths = {
  user: uid => `users/${uid}`,
  directory: did => `directories/${did}`,
  pendingInvites: did => `directories/${did}/pendingInvites`,
  family: fid => `families/${fid}`,
  group: gid => `groups/${gid}`,
};

class FireStorageService {
  /**
   * @param {firebase.storage.Storage} fireStorage
   */
  static init(fireStorage) {
    storage = fireStorage;
  }

  /**
   * @param pathString
   * @return {firebase.storage.Reference}
   */
  static createChildRef(pathString) {
    /**
     * @type {firebase.storage.Reference}
     */
    const ref = storage.ref();
    return ref.child(pathString);
  }

  /**
   * @param urlString
   * @return {firebase.storage.Reference}
   */
  static createChildRefFromUrl(urlString) {
    return storage.refFromURL(urlString);
  }

  // returns the download url or null
  static async uploadFile(file, pathString) {
    try {
      const childRef = FireStorageService.createChildRef(pathString);

      const metadata = {
        contentType: file.type,
      };

      await childRef.put(file, metadata);

      const ref = FireStorageService.createChildRef(pathString);

      const downloadUrl = await ref.getDownloadURL();

      return {
        downloadUrl,
        name: ref.name,
        fullPath: ref.fullPath,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, error.message);
      return null;
    }
  }

  static async deleteFile(pathString) {
    try {
      const childRef = FireStorageService.createChildRef(pathString);

      return childRef.delete();
    } catch (error) {
      console.log(error, error.message);
      return null;
    }
  }

  static async deleteFileByUrl(urlString) {
    try {
      const components = new URL(urlString);
      const split = decodeURIComponent(components.pathname).split('/o/');

      if (split.length > 1) {
        const path = split[1];
        await FireStorageService.deleteFile(path);
      }

      return null;
    } catch (error) {
      console.log(error, error.message);
      return null;
    }
  }
}

export default FireStorageService;
