import PropTypes from 'prop-types';
import React from 'react';

import { FormattingUtils } from 'utils';

import Box from '../Box';
import Flex from '../Flex';
import Input from '../Input';
import { MultiSelection } from '../MultiSelection';


const PhoneInputWithCode = ({
  codes,
  codeValue,
  disabled,
  errors,
  extName,
  extPlaceholder,
  extValue,
  isExtDisabled,
  isInputDisabled,
  isSelectDisabled,
  name,
  onBlur,
  onChange,
  onChangeExt,
  onSelect,
  placeholder,
  selectName,
  showExtension,
  value,
}) => (
  <Flex>
    <Box
      sx={{
        marginRight: 'grid4',
        width: '200px',
      }}
    >
      <MultiSelection
        isClearable={false}
        isCreatable={false}
        isDisabled={disabled || isSelectDisabled}
        isMulti={false}
        name={selectName}
        onChange={onSelect}
        options={codes}
        placeholder="Country"
        value={codeValue ? codes.find((c) => c.value === codeValue) : null}
      />
    </Box>

    <Input
      autoComplete="on"
      errors={errors}
      isDisabled={disabled || isInputDisabled}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      sx={{
        marginRight: showExtension ? 'grid4' : 'grid0',
      }}
      type="tel"
      value={FormattingUtils.maxLength(value, 10)}
    />

    {showExtension && (
      <Input
        autoComplete="on"
        isDisabled={disabled || isExtDisabled}
        name={extName}
        onChange={onChangeExt}
        placeholder={extPlaceholder}
        sx={{ width: '100px' }}
        type="text"
        value={FormattingUtils.maxLength(extValue, 10)}
      />
    )}
  </Flex>
);

PhoneInputWithCode.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.shape({})),
  codeValue: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  extName: PropTypes.string,
  extPlaceholder: PropTypes.string,
  extValue: PropTypes.string,
  isExtDisabled: PropTypes.bool,
  isInputDisabled: PropTypes.bool,
  isSelectDisabled: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onChangeExt: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  selectName: PropTypes.string,
  showExtension: PropTypes.bool,
  value: PropTypes.string,
};

PhoneInputWithCode.defaultProps = {
  codes: [],
  codeValue: undefined,
  disabled: undefined,
  errors: undefined,
  extName: undefined,
  extPlaceholder: 'Ext',
  extValue: undefined,
  isExtDisabled: undefined,
  isInputDisabled: undefined,
  isSelectDisabled: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  onChangeExt: undefined,
  onSelect: undefined,
  placeholder: 'Phone number',
  selectName: undefined,
  showExtension: undefined,
  value: undefined,
};

export default PhoneInputWithCode;
