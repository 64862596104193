//
import * as React from 'react';
import { notification } from 'antd';

import { PubsubService, pubsubTopics } from '../../../services';


class NotifsWatchdog extends React.Component {
  state = {};


  componentDidMount() {
    this._token = PubsubService.makeSub(
      pubsubTopics.NOTIFS,
      this.handleNotification,
    );
  }

  componentWillUnmount() {
    PubsubService.cancelSub(pubsubTopics.NOTIFS);
  }

  handleNotification = (topic, data) => {
    if (data) {
      const {
        message,
        type = 'info',
        // autoDismiss = 10,
        // place = 'tc',
      } = data;

      let fn;

      if (type === 'info') fn = notification.info;
      else if (type === 'error') fn = notification.error;
      else if (type === 'warn') fn = notification.warn;
      else if (type === 'open') fn = notification.open;
      else if (type === 'none') fn = notification.open;

      if (fn) {
        fn({ message });
      }
    }
  };

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

export default NotifsWatchdog;
