import PropTypes from 'prop-types';

const propTypes = {
  path: PropTypes.string.isRequired,
  ledFamilies: PropTypes.shape(),
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

const defaultProps = {
  ledFamilies: {},
};

export { propTypes, defaultProps };
