import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { Business } from '../shapes';


const BusinessSvg = (props) => (
  <g>
    <IconBounds />
    <Business {...props} />
  </g>
);

BusinessSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

BusinessSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default BusinessSvg;
