import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Asterisk from '../Asterisk';
import Box from '../Box';
import Label from '../Label';
import Input from './presenter';


const InputWithLabel = (props) => {
  const {
    isRequired,
    label,
    ...rest
  } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Label
        sx={{
          color: 'fieldLabel',
          fontSize: 'font14',
        }}
      >
        {label}
        {isRequired && (
          <React.Fragment>
            {` `}
            <Asterisk />
          </React.Fragment>
        )}
      </Label>
      <Input
        {...rest}
        isRequired={isRequired}
        sx={{ marginTop: 'grid4', ...(rest.sx || {}) }}
      />
    </Box>
  );
};

InputWithLabel.propTypes = {
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  value: PropTypes.string,
};

InputWithLabel.defaultProps = {
  isRequired: undefined,
  onChange: undefined,
  setRef: undefined,
  value: undefined,
};

const InputWithLabelWithRef = React.forwardRef((props, ref) => (
  <InputWithLabel setRef={ref} {...props} />
));


export { InputWithLabel };
export default InputWithLabelWithRef;
