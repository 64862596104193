import { countries } from 'country-data';
import { sortBy, uniqBy } from 'lodash';

export const allCountriesMap = () => countries.all.reduce((obj, country) => ({
  ...obj,
  [country.alpha2]: {
    alpha2: country.alpha2,
    alpha3: country.alpha3,
    callingCodes: country.countryCallingCodes,
    emoji: country.emoji,
    ioc: country.ioc,
    name: country.name,
  },
}), {});

export const countrySelectOptions = (labelKey) => countries.all.map(country => ({
  [labelKey]: country.name,
  value: country.alpha2,
}));

export const countryEmojiSelectOptions = (
  labelKey,
) => uniqBy(
  sortBy(
    countries.all,
    c => (c.countryCallingCodes.length >= 1 ? c.countryCallingCodes[0] : c.alpha2),
  ),
  c => (c.countryCallingCodes.length >= 1 ? c.countryCallingCodes[0] : c.alpha2),
).reduce((arr, country) => {
  if (country.countryCallingCodes.length >= 1) {
    return [...arr, {
      [labelKey]: country.countryCallingCodes[0],
      value: country.alpha2,
      code: country.countryCallingCodes[0],
    }];
  }
  return arr;
}, []);
