import PropTypes from 'prop-types';
import React from 'react';

import * as colors from '../../../../colors';
import { IconSize } from '../../../../sizes';

import { CaretRightIcon } from '../../../Icon';
import Flex from '../../../Flex';
import Text from '../../../Text';
import Link from '../../../Link';

import * as styles from './styles';


const ListItem = (props) => {
  const {
    AvatarComponent,
    avatarSx,
    forceListStyle,
    imageUrl,
    href,
    showCaret,
    subtitle,
    title,
    sx,
  } = props;

  return (
      <Link href={href} sx={styles.getLinkWrapperStyles({ forceListStyle, sx })}>
        <Flex sx={styles.getLinkContentStyles({ forceListStyle })}>
          <Flex className="avatar-and-styles" sx={styles.getAvatarAndTitlesStyles({ forceListStyle })}>
            <AvatarComponent
              alt={title}
              src={imageUrl}
              sx={{ ...styles.getAvatarStyles({ forceListStyle }), ...avatarSx }}
            />

            <Flex sx={styles.getTextContainerStyles({ forceListStyle })}>
              <Text sx={styles.getTitleStyles({ forceListStyle })}>
                {title}
              </Text>

              {Boolean(subtitle) && (
                <Text sx={styles.getSubtitleStyles({ forceListStyle })}>
                  {subtitle}
                </Text>
              )}
            </Flex>
          </Flex>

          {showCaret && (
            <Flex sx={styles.caretContainerStyles}>
              <CaretRightIcon color={colors.mamba} size={IconSize.ICON_MED} />
            </Flex>
          )}
        </Flex>
    </Link>
  );
};

ListItem.propTypes = {
  AvatarComponent: PropTypes.elementType.isRequired,
  avatarSx: PropTypes.shape({}),
  href: PropTypes.string.isRequired,
  forceListStyle: PropTypes.bool,
  imageUrl: PropTypes.string,
  showCaret: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

ListItem.defaultProps = {
  avatarSx: {},
  forceListStyle: undefined,
  imageUrl: undefined,
  showCaret: undefined,
  subtitle: undefined,
  sx: {},
};


export default ListItem;
