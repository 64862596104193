import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import defaultAvatar from 'assets/images/default-profile.png';

import { dashboardRoutePaths } from 'modules/router/constants';

import { SortUtils, getQuery } from 'utils';

import {
  Box,
  EntityList,
  Heading,
  RectAvatar,
  RoundAvatar,
  Text,
} from 'modules/core/components';


const noResults = (
  <Text sx={{ color: 'searchText', marginBottom: 'grid8', marginTop: 'grid8', paddingLeft: 'grid16' }}>
    No results
  </Text>
);

const ResultsPage = ({ isFetching, loader, location, searchResults }) => {
  const {
    users: people = [],
    families = [],
    groups = [],
  } = searchResults;

  const { search } = location;

  const term = getQuery(search, 's');

  return (
    <Box>
      <Text sx={{ color: 'searchText', marginBottom: 'grid8', paddingLeft: 'grid16' }}>
        {!term ? (
          'Enter a search term above'
        ) : (
          `Search results for "${term}"`
        )}
      </Text>

      {loader}

      {!isFetching && (
        <React.Fragment>
          <Heading sx={{ fontSize: 'font20', paddingLeft: 'grid16' }}>Families</Heading>

          {families.length < 1 && noResults}

          <EntityList
            items={SortUtils
              .baseEntitySort(families, ['name'], ['asc'])
              .map((entity) => ({
                AvatarComponent: RectAvatar,
                href: dashboardRoutePaths.familiesId(entity.id),
                imageUrl: entity.imageUrl || defaultAvatar,
                key: entity.id,
                title: entity.name,
              }))
            }
            showCarets={global.queryBreakpoints.isLtTablet()}
          />

          <Heading sx={{ fontSize: 'font20', paddingLeft: 'grid16' }}>Individuals</Heading>

          {people.length < 1 && noResults}

          <EntityList
            items={SortUtils
              .baseEntitySort(people, ['name'], ['asc'])
              .map((entity) => ({
                AvatarComponent: RoundAvatar,
                href: dashboardRoutePaths.individualsId(entity.id),
                imageUrl: entity.imageUrl || defaultAvatar,
                key: entity.id,
                title: entity.name,
              }))
            }
            showCarets={global.queryBreakpoints.isLtTablet()}
          />

          <Heading sx={{ fontSize: 'font20', paddingLeft: 'grid16' }}>Groups</Heading>

          {groups.length < 1 && noResults}

          <EntityList
            items={SortUtils
              .baseEntitySort(groups, ['name'], ['asc'])
              .map((entity) => ({
                AvatarComponent: RectAvatar,
                href: dashboardRoutePaths.groupsId(entity.id),
                imageUrl: entity.imageUrl || defaultAvatar,
                key: entity.id,
                title: entity.name,
              }))
            }
            showCarets={global.queryBreakpoints.isLtTablet()}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

ResultsPage.propTypes = {
  isFetching: PropTypes.bool,
  loader: PropTypes.node,
  location: PropTypes.shape({}).isRequired,
  searchResults: PropTypes.shape().isRequired,
};

ResultsPage.defaultProps = {
  isFetching: false,
  loader: undefined,
};


export default ResultsPage;
