import PropTypes from 'prop-types';
import * as React from 'react';
import { DeleteFilled } from '@ant-design/icons';

import { Appearance, Intent } from 'modules/core/constants';

import Flex from '../Flex';

import Button from './presenter';


const RemoveProfilePhotoButton = (props) => {
  const { onClick } = props;

  return (
    <Button
      appearance={Appearance.GHOST}
      intent={Intent.DANGER}
      onClick={onClick}
      sx={{
        borderWidth: 'small',
        marginLeft: 'grid16',
        maxHeight: '32px',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <DeleteFilled />
      </Flex>
    </Button>
  );
};

RemoveProfilePhotoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};


export default RemoveProfilePhotoButton;
