import React from 'react';
import PropTypes from 'prop-types';

import { dashboardRoutePaths } from 'modules/router/constants';

import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import {
  Box,
  Flex,
  Text,
  Link,
  Icon,
} from 'modules/core/components';


const Email = (props) => {
  const {
    email,
    iconName,
    id,
    isAdmin,
    isFetching,
    label,
    sx,
  } = props;

  const showInvisibleIcon = isAdmin && !email.isVisible;
  const linkToCompose = isAdmin && email.sendMessages;
  const linkElement = linkToCompose ? Link : 'a';
  const linkTo = linkToCompose
    ? dashboardRoutePaths.composeTo(id, 'email')
    : `mailto:${email.email}`;

  const linkProps = {
    href: linkTo,
  };

  if (linkToCompose) {
    linkProps.sx = { color: 'infoValue' };
  } else {
    linkProps.rel = 'noopener noreferrer';
    linkProps.target = '_blank';
    linkProps.role = 'presentation';
    linkProps.style = { color: colors.mamba };
  }

  return (
    <Flex>
      <Flex>
        <Icon
          color={colors.ebonyLightest}
          name={iconName}
          size={IconSize.ICON_MED}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: 'grid16',
          }}
        />

        <Text sx={{ color: 'systemTextLight' }}>
          {label}
        </Text>
      </Flex>

      <Box>
        <Box
          sx={{
            width: '65%',
          }}
        >
          <Text sx={{ color: 'infoValue' }}>
            {React.createElement(linkElement, linkProps, email.email)}

            {/* {showInvisibleIcon && ( */}
            {/*  <EyeOffIcon */}
            {/*    color={colors.mambaLight} */}
            {/*    size={IconSize.ICON_MED} */}
            {/*    svgProps={{ */}
            {/*      style: { */}
            {/*        marginBottom: '2px', */}
            {/*      }, */}
            {/*    }} */}
            {/*    sx={{ */}
            {/*      display: 'inline-block', */}
            {/*      marginLeft: 'grid4', */}
            {/*    }} */}
            {/*  /> */}
            {/* )} */}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

Email.propTypes = {
  email: PropTypes.shape({}),
  iconName: PropTypes.string,
  id: PropTypes.string,
  isAdmin: PropTypes.bool,
  isFetching: PropTypes.bool,
  label: PropTypes.string,
  sx: PropTypes.shape({}),
};

Email.defaultProps = {
  email: {},
  iconName: undefined,
  id: undefined,
  isAdmin: undefined,
  isFetching: undefined,
  label: undefined,
  sx: {},
};


export default Email;
