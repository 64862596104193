import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import { ClearIcon, SearchIcon } from '../Icon';
import Box from '../Box';
import Input from '../Input';

import * as styles from './styles';


const SearchInput = (props) => {
  const {
    inputSx,
    onChange,
    onClear,
    onSearch,
    setRef,
    sx,
    value: propValue = '',
    ...rest
  } = props;

  const innerRef = React.useRef(null);

  const [value, setValue] = React.useState(propValue);
  const [isFocused, setIsFocused] = React.useState(false);

  const handleClear = React.useCallback(() => {
    setValue('');
    onClear();

    if (setRef?.current) {
      setRef.current.focus();
    } else {
      innerRef.current?.focus();
    }
  }, [onClear, setRef]);

  const handleKeyDown = React.useCallback((evt) => {
    if (evt.key === 'Enter') {
      onSearch(value);
    }
  });

  const IconClass = value ? ClearIcon : SearchIcon;

  return (
    <Box
      className="j-search"
      sx={{
        ...styles.containerStyles,
        borderColor: isFocused ? 'primary' : 'transparent',
        ...sx,
      }}
    >
      <Input
        onBlur={() => setIsFocused(false)}
        onChange={(evt) => setValue(evt.target.value)}
        onFocus={() => setIsFocused(true)}
        onKeyDown={handleKeyDown}
        ref={setRef || innerRef}
        sx={{
          ...styles.inputStyles,
          ...inputSx,
          ':focus': {
            ...styles.inputStyles[':focus'],
            borderColor: isFocused ? 'transparent' : undefined,
            ...(inputSx?.[':focus'] || {}),
          },
        }}
        type="search"
        value={value}
        {...rest}
      />
      <Box
        className={classNames({
          'j-search-icon': true,
          'flex-col-align-center': true,
          'cursor-pointer': value,
          'pointer-events-none': !value,
        })}
        onClick={handleClear}
        sx={{
          ...styles.iconContainerStyles,
          backgroundColor: isFocused ? 'primary' : 'transparent',
          borderColor: isFocused ? 'primary' : 'transparent',
          opacity: isFocused ? '1' : '0.5',
        }}
      >
        <IconClass
          color={isFocused ? colors.white : colors.mamba}
          size={IconSize.ICON_SMALL}
        />
      </Box>
    </Box>
  );
};

SearchInput.propTypes = {
  inputSx: PropTypes.shape(),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
};

SearchInput.defaultProps = {
  inputSx: {},
  onChange: undefined,
  onClear: undefined,
  value: undefined,
  setRef: undefined,
  sx: {},
};

const SearchInputWithRef = React.forwardRef((props, ref) => (
  <SearchInput setRef={ref} {...props} />
));


export { SearchInput };
export default SearchInputWithRef;
