export const alto = '#D5D5D5';
export const aqua = 'rgb(41, 186, 165)';
export const aquaAlpha10 = 'rgba(41, 186, 165, 0.1)';
export const aquaDark = 'rgb(11, 156, 155)';
export const athensGrey = '#E1E0E5';
export const athensGreyLight = '#F0F2F5';
export const black = '#000000';
export const chatelle = '#D3C6D7';
export const dodger = 'rgb(30, 167, 253)';
export const dodgerAlpha10 = 'rgba(30, 167, 253, 0.1)';
export const dodgerDark = 'rgb(0, 137, 223)';
export const ebony = '#120E21';
export const ebonyLight = '#4d4a59';
export const ebonyLightest = '#6f6c7b';
export const ebonyMed = 'rgb(67, 64, 79)';
export const frenchGrey = '#C8C7CC';
export const gallery = '#ECECEC';
export const heliotrope = '#a98bfc';
export const macaroni = 'rgb(235, 173, 100)';
export const macaroniAlpha10 = 'rgba(235, 173, 100, 0.1)';
export const macaroniDark = 'rgb(205, 143, 70)';
export const mamba = '#99879D';
export const mambaAlpha50 = 'rgba(153, 135, 157, 0.5)';
export const mambaLight = 'rgb(163, 145, 167)';
export const mischka = '#DCDBE0';
export const purple = '#916AFF';
export const purpleDarkened = '#8059EE';
export const purpleAlpha10 = 'rgba(145, 106, 255, 0.1)';
export const purpleAlpha50 = 'rgba(145, 106, 255, 0.5)';
export const salmon = 'rgb(245, 110, 110)';
export const salmonAlpha10 = 'rgba(245, 110, 110, 0.1)';
export const salmonDark = 'rgb(215, 80, 80)';
export const selago = '#FAF9FE';
export const selagoDarker = '#F6F4FF';
export const selagoMed = '#F7F4FF';
export const selagoVariant = '#F8F7FC';
export const silver = '#BCBCBC';
export const silverMed = 'rgb(168, 168, 168)';
export const titanWhite = '#EDE9FF';
export const transparent = 'transparent';
export const webOrange = '#FFA600';
export const white = '#FFFFFF';
export const whitePointer = '#FBEEFF';
export const whitePointerLightVariant = '#FDFEFE';
export const wildSand = '#F4F4F4';
