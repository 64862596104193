import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../Flex';

import Loader from './Loader';


const ProfileLoader = (props) => {
  const {
    isFetching,
    sx,
    ...rest
  } = props;

  if (!isFetching) {
    return null;
  }

  return (
    <Flex sx={{ justifyContent: 'center', ...sx }}>
      <Loader
        {...rest}
        isFetching={isFetching}
        useWrapper
      />
    </Flex>
  );
};

ProfileLoader.propTypes = {
  isFetching: PropTypes.bool,
  sx: PropTypes.shape({}),
};

ProfileLoader.defaultProps = {
  isFetching: undefined,
  sx: {},
};


export default ProfileLoader;
