import PropTypes from 'prop-types';
import React from 'react';


const Download = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M22.35,4.71V18.82h2.76L20,23.93l-5.11-5.11h2.76V4.71h4.7M27.06,0H12.94V14.12H3.53L20,30.59,36.47,14.12H27.06Zm9.41,35.29H3.53V40H36.47Z"
  />
);

Download.propTypes = {
  strokeWidth: PropTypes.number,
};

Download.defaultProps = {
  strokeWidth: 1,
};


export default Download;
