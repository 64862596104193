import _set from 'lodash/set';

import { filterConstants } from '../../constants';

import * as constants from './constants';

export const name = 'filters';

export const initialState = {
  views: {
    people: {
      type: filterConstants.PeopleViewType.individuals,
    },
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_FILTER: {
      const nextState = { ...state };
      _set(nextState, action.payload.keyPath, action.payload.value);
      return nextState;
    }

    default:
      return state;
  }
}
