import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';


const withSort = (Component, getSort, setSort) => {
  class ComponentWithSort extends React.Component {
    state = {
      currentPage: 0,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      const { currentPage } = prevState;
      const { pagination } = nextProps;

      if (pagination.page > 0 && currentPage !== pagination.page) {
        return {
          ...prevState,
          currentPage: pagination.page,
        };
      }

      return prevState;
    }

    handleSortChange = (sort, callback) => {
      const { onSetSort, sort: prevSort } = this.props;
      const { by: sortBy, dir: sortDir } = sort;
      if (prevSort.by[0] !== sortBy || prevSort.dir[0] !== sortDir) {
        onSetSort(sortBy, sortDir);
        callback({ by: sortBy, dir: sortDir });
      }
    };

    handlePaginationChange = (page, callback) => {
      this.setState({ currentPage: page }, () => {
        if (callback) {
          callback();
        }
      });
    };

    render() {
      const {
        onSetSort,
        pagination,
        sort,
        ...rest
      } = this.props;

      const { currentPage } = this.state;

      return (
        <Component
          {...rest}
          pagination={pagination}
          currentPage={currentPage}
          onPaginationChange={this.handlePaginationChange}
          onSortChange={this.handleSortChange}
          sort={sort}
        />
      );
    }
  }

  ComponentWithSort.propTypes = {
    sort: PropTypes.shape({}).isRequired,
    onSetSort: PropTypes.func.isRequired,
    pagination: PropTypes.shape({}).isRequired,
  };

  const mapState = createStructuredSelector({
    sort: getSort,
  });

  const mapDispatch = {
    onSetSort: setSort,
  };

  return connect(mapState, mapDispatch)(ComponentWithSort);
};


export default withSort;
