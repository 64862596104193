import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Textarea as RebassTextarea } from '@rebass/forms';


const Textarea = (props) => {
  const {
    className,
    isRequired,
    onChange,
    setRef,
    sx,
    value,
    ...rest
  } = props;

  return (
    <RebassTextarea
      className={classNames({
        'j-input': true,
        [className]: className,
      })}
      onChange={onChange}
      ref={setRef}
      required={isRequired}
      sx={{
        borderColor: 'border',
        borderRadius: 'input',
        boxShadow: 'inputLight',
        marginTop: 'grid4',
        minHeight: 'controlHeightLarge',
        paddingBottom: 'grid4',
        paddingLeft: 'grid8',
        paddingRight: 'grid8',
        paddingTop: 'grid4',
        transition: 'borderColorBoxShadow',
        ...sx,
        ':focus': {
          borderColor: 'focusBorder',
          boxShadow: 'inputLightHover',
          ...(sx[':focus'] || {}),
        },
      }}
      value={value}
      {...rest}
    />
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
  value: PropTypes.string,
};

Textarea.defaultProps = {
  className: undefined,
  isRequired: undefined,
  onChange: undefined,
  setRef: undefined,
  sx: {},
  value: undefined,
};

const TextareaWithRef = React.forwardRef((props, ref) => (
  <Textarea setRef={ref} {...props} />
));


export { Textarea };
export default TextareaWithRef;
