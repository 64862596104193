import PubSub from 'pubsub-js';


export const pubsubTopics = {
  NOTIFS: '@notifs',
};

class PubsubService {
  static makeSub(topic, listener) {
    return PubSub.subscribe(topic, listener);
  }

  static cancelSub(token) {
    PubSub.unsubscribe(token);
  }

  static publish(topic, data) {
    PubSub.publish(topic, data);
  }

  static cancelTopicSubs(topic) {
    PubSub.unsubscribe(topic);
  }
}

export default PubsubService;
