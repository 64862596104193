import { ReduxUtils } from 'utils';

import * as constants from './constants';


export function setMembersSort(by, dir) {
  return {
    type: constants.SET_MEMBERS_SORT,
    payload: {
      by,
      dir,
    },
  };
}

export function getMembersRequest(term) {
  return {
    type: constants.GET_MEMBERS_REQUEST,
    payload: {
      term,
    },
  };
}

export function getMembersSuccess(members) {
  return {
    type: constants.GET_MEMBERS_SUCCESS,
    payload: {
      members,
    },
  };
}

export function getMembersFailure(message, error) {
  return {
    type: constants.GET_MEMBERS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function getPersonSummariesRequest(include) {
  return {
    type: constants.GET_PERSON_SUMMARIES_REQUEST,
    payload: {
      include,
    },
  };
}

export function getPersonSummariesFetch() {
  return {
    type: constants.GET_PERSON_SUMMARIES_FETCH,
  };
}

export function getPersonSummariesSuccess(summaries, ids) {
  return {
    type: constants.GET_PERSON_SUMMARIES_SUCCESS,
    payload: {
      ids,
      summaries,
    },
  };
}

export function getPersonSummariesFailure(message, error) {
  return {
    type: constants.GET_PERSON_SUMMARIES_FAILURE,
    payload: {
      message,
    },
  };
}

export function fbMembersRequest(directoryId, pageNumber, sort, sortDir, indexStart, indexEnd, include) {
  return {
    type: constants.FB_MEMBERS_REQUEST,
    payload: {
      directoryId,
      include,
      indexEnd,
      indexStart,
      pageNumber,
      sort,
      sortDir,
    },
  };
}

export const fbMembersAbortRequest = ReduxUtils.makeAbortActionCreator(constants.FB_MEMBERS_REQUEST);

export function fbMembersFetch() {
  return {
    type: constants.FB_MEMBERS_FETCH,
  };
}

export function fbMembersRequestNext(directoryId) {
  return {
    type: constants.FB_MEMBERS_REQUEST_NEXT,
    payload: {
      directoryId,
    },
  };
}

export function fbMembersRequestPrev(directoryId) {
  return {
    type: constants.FB_MEMBERS_REQUEST_PREV,
    payload: {
      directoryId,
    },
  };
}

export function fbMembersSuccess(members, memberIds, pagination) {
  return {
    type: constants.FB_MEMBERS_SUCCESS,
    payload: {
      members,
      memberIds,
      pagination,
    },
  };
}

export function fbMembersFailure(message, error) {
  return {
    type: constants.FB_MEMBERS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function memberInfoRequest(memberShortCode) {
  return {
    type: constants.MEMBER_INFO_REQUEST,
    payload: {
      memberShortCode,
    },
  };
}

export function memberInfoSuccess(member, activeMemberShortCode) {
  return {
    type: constants.MEMBER_INFO_SUCCESS,
    payload: {
      member,
      activeMemberShortCode,
    },
  };
}

export function memberInfoFailure(message, error) {
  return {
    type: constants.MEMBER_INFO_FAILURE,
    payload: {
      message,
      error,
    },
  };
}
