import PropTypes from 'prop-types';
import React from 'react';

import { ParseUtils } from 'utils';

import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import {
  Box,
  Flex,
  Icon,
  IconNames,
  Text,
  LinkNewPage,
} from 'modules/core/components';


const addressTextSx = {
  color: 'infoValue',
};

const Address = (props) => {
  const { address } = props;

  if ( address.country.value ) {
    address.country = address.country.value;
  }

  if ( address.state.value ) {
    address.state = address.state.value;
  }

  const addressObj = ParseUtils.parseAddressFromObjectToDisplayObject(address);
  const mapsUrl = ParseUtils.parseAddressFromObjectToMapsUrl(address);

  return (
    <Flex>
      {Boolean(address.isVisible) && (
        <Box>
          <Icon
            color={colors.ebonyLightest}
            name={IconNames.MAP_PIN}
            size={IconSize.ICON_LARGE}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginRight: 'grid16',
              marginTop: 'grid4',
            }}
          />
        </Box>
      )}
      {Boolean(address.isVisible) && (
        <Flex flexDirection="column">
          <LinkNewPage as="a" href={mapsUrl}>
            {Boolean(addressObj.street1) && (
              <Text sx={addressTextSx}>
                {addressObj.street1}
              </Text>
            )}

            {Boolean(addressObj.street2) && (
              <Text sx={addressTextSx}>
                {addressObj.street2}
              </Text>
            )}

            {Boolean(addressObj.cityLine) && (
              <Text sx={addressTextSx}>
                {addressObj.cityLine}
              </Text>
            )}

            {Boolean(addressObj.country) && (
              <Text sx={addressTextSx}>
                {addressObj.country}
              </Text>
            )}
          </LinkNewPage>
        </Flex>
      )}
     
    </Flex>
  );
};

Address.propTypes = {
  address: PropTypes.shape({}).isRequired,
};


export default Address;
