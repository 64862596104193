import PropTypes from 'prop-types';
import React from 'react';

import { Appearance, Intent } from 'modules/core/constants';
import { panelRoutePaths } from 'modules/router/constants';

import { roleConstants } from '../../../../constants';

import { ButtonLink } from '../Button';
import ChildrenIfRole from '../ChildrenIfRole';
import Flex from '../Flex';
import MobileNavHeader from '../MobileNavHeader';


const ProfileActionBar = (props) => {
  const {
    backText,
    entityType,
    id,
    onClose,
    role,
  } = props;

  return (
    <MobileNavHeader isBack onClose={onClose} text={backText}>
      <ChildrenIfRole
        role={role}
        allowedRoles={roleConstants.ADMIN_ROLES}
      >
        <Flex sx={{ justifyContent: 'flex-end' }}>
          <ButtonLink
            buttonProps={{
              appearance: Appearance.DEFAULT,
              intent: Intent.DEFAULT,
              sx: {
                fontSize: 'font14',
                marginRight: 'grid8',
              },
            }}
            href={panelRoutePaths.resourceEdit(entityType, id)}
          >
            Edit
          </ButtonLink>
        </Flex>
      </ChildrenIfRole>
    </MobileNavHeader>
  );
};

ProfileActionBar.propTypes = {
  entityType: PropTypes.string,
  id: PropTypes.string,
  role: PropTypes.string,
};

ProfileActionBar.defaultProps = {
  entityType: undefined,
  id: undefined,
  role: undefined,
};


export default ProfileActionBar;
