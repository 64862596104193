import { Enum } from 'enumify';

/**
 * Enumeration for date types
 */
class PhoneType extends Enum {}

/**
 * The values of the enumeration
 */
PhoneType.initEnum({
  CELL: {
    get displayName() {
      return 'Cell';
    },
    get canGetSms() {
      return true;
    },
  },
  HOME: {
    get displayName() {
      return 'Home';
    },
    get canGetSms() {
      return false;
    },
  },
  WORK: {
    get displayName() {
      return 'Work';
    },
    get canGetSms() {
      return false;
    },
  },
  OTHER: {
    get displayName() {
      return 'Other';
    },
    get canGetSms() {
      return false;
    },
  },
});

export default PhoneType;
