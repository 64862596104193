import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { getFamilies } from 'modules/families/selectors';
import { getMembers } from 'modules/members/selectors';
import { getGroups } from 'modules/groups/selectors';
import { getActiveDirectoryName } from 'modules/directory/selectors';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  directoryName: getActiveDirectoryName,
  families: getFamilies,
  groups: getGroups,
  members: getMembers,
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(presenter);
