import * as React from 'react';
import { Popover } from 'antd';

import { Button } from 'modules/core/components';
import { Appearance, Intent } from 'modules/core/constants';


class CreateAccessory extends React.PureComponent {
  state = {};

  handleCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  };

  handleOkay = () => {
    const { onOkay } = this.props;
    onOkay();
  };

  render() {
    const { title, children, content, show, toggle } = this.props;

    const contents = (
      <React.Fragment>
        {content}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}>
          <Button
            appearance={Appearance.GHOST}
            intent={Intent.DANGER}
            onClick={this.handleCancel}
            sx={{
              borderWidth: 'small',
              fontWeight: 'normal',
              fontSize: 'font14',
            }}
          >
            Cancel
          </Button>
          <Button
            intent={Intent.INFO}
            onClick={this.handleCancel}
            sx={{
              borderWidth: 'small',
              fontWeight: 'normal',
              fontSize: 'font14',
            }}
          >
            Okay
          </Button>
        </div>
      </React.Fragment>
    );

    return (
      <Popover
        onVisibleChange={toggle}
        visible={show}
        title={title}
        content={contents}
        placement="right"
        trigger="click"
      >
        {children}
      </Popover>
    );
  }
}

export default CreateAccessory;
