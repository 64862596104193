import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { Appearance, KeyboardKey } from 'modules/core/constants';
import { IconSize } from 'modules/core/sizes';

import { Button } from '../Button';
import Flex from '../Flex';
import Icon, { IconNames } from '../Icon';
import Text from '../Text';

import * as styles from './styles';


const MobileNavHeader = ({ children, history, isBack, onClose, text }) => {
  const isLaptopOrLarger = useMediaQuery({
    query: global.BreakpointQuery.laptop,
  });

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      history.goBack();
    }
  }, [history]);

  const handleKeyDown = React.useCallback((evt) => {
    if (evt.key === KeyboardKey.ESCAPE) {
      handleClose();
    }
  }, [handleClose]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Flex justifyContent="space-between" sx={styles.container}>
      <Flex>
        <Button
          appearance={Appearance.NONE}
          onClick={handleClose}
          sx={styles.navButton}
        >
          <Icon
            name={isLaptopOrLarger || isBack ? IconNames.CARET_LEFT : IconNames.CLEAR}
            size={IconSize.ICON_LARGE}
          />
        </Button>

        <Text sx={{ display: 'inline-block', verticalAlign: 'middle', fontWeight: 'semibold', fontSize: 'font18' }}>
          {text}
        </Text>
      </Flex>

      <Flex justifyContent="flex-end">
        {children}
      </Flex>
    </Flex>
  );
};

MobileNavHeader.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape().isRequired,
  isBack: PropTypes.bool,
  onClose: PropTypes.func,
  text: PropTypes.node,
};

MobileNavHeader.defaultProps = {
  children: undefined,
  isBack: undefined,
  onClose: undefined,
  text: undefined,
};

export default MobileNavHeader;
