import { FetchService, FireFunctions, functionNames, FireDb } from '../services';

export async function requestTags(directoryId) {
  return FireFunctions.httpsCallable(
    functionNames.getTags,
    { directoryId },
  );
}

export async function requestSettings(directoryId) {
  return FireDb.getDirectorySettings(directoryId);
}

export async function requestCreateUser(directoryId, creationData, user, image) {
  return FireFunctions.httpsCallable(
    functionNames.createNewUser,
    {
      directoryId,
      creationData,
      user,
      image,
    },
  );
}

export async function saveNewUserImage(adminId, directoryId, file) {
  try {
    if (window.firebase) {
      const { currentUser } = await window.firebase.auth();
      const idToken = currentUser.getIdToken();
      FetchService.setAuthHeader(adminId, directoryId, idToken);
      return FetchService.post(`/dir/${directoryId}/admin/${adminId}/userImage`);
    }
  } catch (err) {
    console.log(err, err.response);
  }

  return undefined;
}

export async function requestDeleteUser(directoryId, userId) {
  return FireFunctions.httpsCallable(
    functionNames.deleteUser,
    {
      directoryId,
      userId,
    },
  );
}

export async function requestDeleteFamily(directoryId, familyId) {
  return FireFunctions.httpsCallable(
    functionNames.deleteFamily,
    {
      directoryId,
      familyId,
    },
  );
}

export async function requestDeleteGroup(directoryId, groupId) {
  return FireFunctions.httpsCallable(
    functionNames.deleteGroup,
    {
      directoryId,
      groupId,
    },
  );
}
