import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../Box';


const FullScreenBox = ({children, className, sx, ...rest}) => (
  <Box
    className={classNames({
      'j-box-full-screen': true,
      [className]: className,
    })}
    sx={sx}
    {...rest}
  >
    {children}
  </Box>
);

FullScreenBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sx: PropTypes.shape({}),
};

FullScreenBox.defaultProps = {
  children: undefined,
  className: undefined,
  sx: {},
};


export default FullScreenBox;
