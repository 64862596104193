import { createSelector } from 'reselect';
import _ from 'lodash';

import { SortUtils } from 'utils';

import { name } from './reducer';

const getState = state => state[name];

export const getGroups = createSelector(
  [getState],
  state => state.groups,
);

export const getGroupsByInitial = createSelector(
  [getGroups],
  groups => _.groupBy(groups, g => g.name.substring(0, 1).toUpperCase()),
);


export const getGroupIds = createSelector(
  [getGroups],
  groups => Object.keys(groups),
);

export const getActiveGroupId = createSelector(
  [getState],
  state => state.activeGroupId,
);

export const getIsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getViewing = createSelector(
  [getState],
  state => state.viewing,
);

export const getActiveGroupShortCode = createSelector(
  [getState],
  state => state.activeGroupShortCode,
);

export const getGroupsPagination = createSelector(
  [getState],
  state => state.pagination,
);

export const getGroupsPaginationPage = createSelector(
  [getGroupsPagination],
  pagination => pagination.page,
);

export const getGroupsPaginationPages = createSelector(
  [getGroupsPagination],
  pagination => pagination.pages,
);

export const getGroupsPaginationCount = createSelector(
  [getGroupsPagination],
  pagination => pagination.count,
);

export const getGroupsPaginationPageSize = createSelector(
  [getGroupsPagination],
  pagination => pagination.pageSize,
);

export const getGroupsPaginationHasMore = createSelector(
  [getGroupsPaginationPage, getGroupsPaginationPages],
  (page, pages) => pages - page > 0,
);

export const getGroupsPaginationDataRange = createSelector(
  [getGroupsPagination],
  pagination => pagination.dataRange,
);

export const getGroupsSummaries = createSelector(
  [getState],
  state => state.groupsSummaries,
);

export const getAllGroupsSummaries = createSelector(
  [getGroupsSummaries],
  summ => summ.summaries,
);

export const getAllGroupsSummariesSortedByName = createSelector(
  [getGroupsSummaries],
  (summ) => {
    const summariesList = Object.values(summ.summaries);
    return SortUtils.sortByName(summariesList);
  },
);

export const getGroupsSummariesIds = createSelector(
  [getGroupsSummaries],
  summ => summ.ids,
);

export const getGroupsSummariesIsFetching = createSelector(
  [getGroupsSummaries],
  summ => summ.isFetching,
);

export const getGroupsLastFetched = createSelector(
  [getState],
  state => state.lastFetched,
);

export const getGroupsSort = createSelector(
  [getState],
  state => state.sort,
);
