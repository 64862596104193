import * as React from 'react';

import { FireDb, FireStorage } from '../../services';

function withStorage(Component) {
  return class ComponentWithUserDb extends React.Component {
    render() {
      return (
        <Component
          uploadFile={FireStorage.uploadFile}
          {...this.props} />
      );
    }
  };
}

export default withStorage;
