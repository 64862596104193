export const containerStyles = { paddingBottom: 'grid4' };

export const buttonStyles = {
  color: 'text',
  paddingBottom: 'grid2',
  paddingLeft: 'grid0',
  paddingRight: 'grid0',
  paddingTop: 'grid2',
  ':hover': {
    backgroundColor: 'transparent',
  },
};
