const contentBodyPaddingH = 'grid32';

export const contentBodyStyles = {
  minHeight: 'fullHeight',
  [global.Breakpoint.tablet]: {
    margin: '0 auto',
    paddingLeft: contentBodyPaddingH,
    paddingRight: contentBodyPaddingH,
  },
};
