import { Enum } from 'enumify';

/**
 * Enumeration for date types
 */
class SignificantDateType extends Enum {}

/**
 * The values of the enumeration
 */
SignificantDateType.initEnum({
  BIRTHDATE: {
    get displayName() {
      return 'Birthdate';
    },
  },
  ANNIVERSARY: {
    get displayName() {
      return 'Anniversary';
    },
  },
  JOINED: {
    get displayName() {
      return 'Join Date';
    },
  },
  OTHER: {
    get displayName() {
      return 'Other';
    },
  },
});

export default SignificantDateType;
