import { createSelector } from 'reselect';

import { name } from './reducer';

const getState = state => state[name];

export const getIsUploading = createSelector(
  [getState],
  state => state.isUploading,
);

export const geetUploadIds = createSelector(
  [getState],
  state => state.uploadIds,
);

export const getFailedIds = createSelector(
  [getState],
  state => state.failedIds,
);
