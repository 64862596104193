import _groupBy from 'lodash/groupBy';

import { FB_MEMBERS_FAILURE } from 'modules/members/constants';

import * as constants from './constants';

export const name = 'search';

const initialState = {
  isFetching: false,
  results: {},
  searchType: null,
  show: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SEARCH_CLEAR:
      return initialState;

    case constants.TOGGLE_SHOW:
      return {
        ...state,
        show: !state.show,
      };

    case constants.SEARCH_REQUEST:
      return {
        ...state,
        results: initialState.results,
        searchType: action.payload.searchType,
        isFetching: true,
      };

    case constants.SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        searchType: action.payload.searchType,
        results: _groupBy(action.payload.results, 'type'),
      };

    case FB_MEMBERS_FAILURE:
      return {
        ...state,
        results: [],
        isFetching: false,
      };

    default:
      return state;
  }
}
