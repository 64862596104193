import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { getActiveDirectory } from 'modules/directory/selectors';
import { getRole, getUserDirs, getSettingsTheme } from 'modules/user/selectors';
import { getNextRoute } from 'modules/core/selectors';

import { requestDefs } from 'modules/definitions/actions';

import { fbLoginSuccess, logoutRequest, logoutSuccess } from 'modules/auth/actions';
import { fbUserTreeRequest } from 'modules/user/actions';
import { fbMultiDirectoryRequest } from 'modules/directory/actions';

import { withTheme } from 'modules/core/hoc';

import { getAppRoutes } from '../../selectors';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  directory: getActiveDirectory,
  appRoutes: getAppRoutes,
  role: getRole,
  nextRoute: getNextRoute,
  userDirs: getUserDirs,
  settingsTheme: getSettingsTheme,
});

const mapDispatchToProps = {
  onLoginSuccess: fbLoginSuccess,
  onLogoutRequest: logoutRequest,
  onLogoutSuccess: logoutSuccess,
  onMultiDirectoryRequest: fbMultiDirectoryRequest,
  onRequestDefs: requestDefs,
  onUserRequest: fbUserTreeRequest,
  onUserRequestTreeRequest: fbUserTreeRequest,
};

const enhance = compose(withRouter, withTheme, connect(mapStateToProps, mapDispatchToProps));


export default enhance(presenter);
