import PropTypes from 'prop-types';
import React from 'react';


const Messages = ({ strokeWidth, ...rest }) => (
  <React.Fragment>
    <path
      strokeWidth={strokeWidth}
      {...rest}
      d="M4,4H36V28H6.34L4,30.34V4M4,0A4,4,0,0,0,0,4L0,40l8-8H36a4,4,0,0,0,4-4V4a4,4,0,0,0-4-4ZM8,20H32v4H8Zm0-6H32v4H8ZM8,8H32v4H8Z"
    />
  </React.Fragment>
);

Messages.propTypes = {
  strokeWidth: PropTypes.number,
};

Messages.defaultProps = {
  strokeWidth: 1,
};


export default Messages;
