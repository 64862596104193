import PropTypes from 'prop-types';
import React from 'react';


const Menu = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M1 11.56V7.33h38v4.23zm0 10.55h38v-4.22H1zm0 10.56h38v-4.23H1z"
  />
);

Menu.propTypes = {
  strokeWidth: PropTypes.number,
};

Menu.defaultProps = {
  strokeWidth: 1,
};


export default Menu;
