import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { Text } from './presenter';


const HiddenAsterisk = (props) => {
  const {
    className,
    setRef,
    sx,
    ...rest
  } = props;

  return (
    <Text
      className={classNames({
        'j-hidden-star': true,
        [className]: className,
      })}
      setRef={setRef}
      sx={{
        display: 'inline-block',
        color: 'primary',
        ...sx,
      }}
      {...rest}
    >
      *
    </Text>
  );
};

HiddenAsterisk.propTypes = {
  className: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  sx: PropTypes.shape({}),
};

HiddenAsterisk.defaultProps = {
  className: undefined,
  setRef: undefined,
  sx: {},
};


export default HiddenAsterisk;
