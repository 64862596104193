import PropTypes from 'prop-types';
import React from 'react';


const Cake = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M20,10.91a3.64,3.64,0,0,0,3.64-3.64,3.51,3.51,0,0,0-.53-1.87L20,0,16.89,5.4a3.51,3.51,0,0,0-.53,1.87A3.65,3.65,0,0,0,20,10.91Zm10.91,5.45H21.82V12.73H18.18v3.63H9.09a5.45,5.45,0,0,0-5.45,5.46V38.18A1.82,1.82,0,0,0,5.45,40h29.1a1.82,1.82,0,0,0,1.81-1.82V21.82A5.45,5.45,0,0,0,30.91,16.36Zm1.82,20H7.27V30.91a6.27,6.27,0,0,0,4.37-1.84l2-1.94,1.94,1.94a6.44,6.44,0,0,0,8.89,0l2-1.94,1.94,1.94a6.27,6.27,0,0,0,4.37,1.84Zm0-8.18a3.5,3.5,0,0,1-2.46-1L26.4,23.27l-3.89,3.88a3.66,3.66,0,0,1-5,0L13.6,23.27,9.71,27.15a3.5,3.5,0,0,1-2.44,1V21.82A1.83,1.83,0,0,1,9.09,20H30.91a1.83,1.83,0,0,1,1.82,1.82Z"
  />
);

Cake.propTypes = {
  strokeWidth: PropTypes.number,
};

Cake.defaultProps = {
  strokeWidth: 1,
};


export default Cake;
