// 

import React from 'react';
import {
  Container,
  Navbar,
  NavbarToggler,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import styles from './styles.css';

class Header extends React.Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => (
    this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  );

  render() {
    return (
      <Navbar
        expand="lg"
        className={
          this.state.isOpen
            ? 'bg-white navbar-absolute'
            : 'navbar-transparent navbar-absolute'
        }>
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <Link to="/login" className="navbar-brand">
              Go to Login
            </Link>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
