

import { call, fork, put, take, all, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import moment from 'moment';
import { REHYDRATE } from 'redux-persist';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

import { getToken, getRememberMe } from './selectors';
import { getActiveDirectoryId } from '../directory/selectors';

import { FireAuth } from '../services';
import { loginSuccess as userLoginSuccess, fbUserTreeRequest } from '../user/actions';
import { directoriesSuccess, fbDirectoryRequest } from '../directory/actions';
import { directoriesMap } from '../directory/parse';
import { familiesRequest } from '../families/actions';
import { groupsRequest } from '../groups/actions';

let resumeSessionTask = null;

function* setExpirationTimeout(expiration) {
  const millisToExpiration = (expiration - moment().unix()) * 1000;

  // notify the user before we end the session
  // if this number is negative, the notification displays immediately
  const millisUntilNotify = millisToExpiration - 60000;

  yield delay(millisUntilNotify);

  alert('For security reasons, you\'ll be logged out in about one minute.');

  yield delay(60000);

  yield put(actions.logoutRequest());
}

/**
 * Send credentials to the server to be verified and exchanged for tokens.
 * @param email {string} Given email address for login.
 * @param password {string} Given password for login.
 */
export function* requestLogin(email, password) {
  try {
    if (resumeSessionTask) {
      resumeSessionTask.cancel();
      resumeSessionTask = null;
    }

    const response = yield call(api.requestLogin, email, password);

    if (response.error) {
      yield put(actions.loginFailure(response.error.message));
    } else {
      const {
        sessionToken,
        expiration,
        id,
        email: userEmail,
        firstName,
        lastName,
        imageUrl,
        directories,
        userRole,
      } = response.data;

      /**
       * Length of login processing time is controlled serverside, as a timing-attack prevention.
       * This results in a duration of load-time that is *just* perceptible.
       *
       * Defer turning off the isFetching state for 250ms after request sent, or until
       * response arrives. This is enough time to show some loading state, without it
       * blinking on and off so quickly that it looks janky.
       */
      yield delay(250);

      const dirMap = directoriesMap(directories);

      // on login success, request the data needed to populated dashboard content that
      // is immediately rendered in the ui
      yield put(actions.loginSuccess(sessionToken, id, expiration, userRole));
      yield put(actions.setAuthExpiration(expiration));
      yield put(userLoginSuccess({
        id, userEmail, firstName, lastName, userRole, imageUrl,
      }));
      yield put(directoriesSuccess(dirMap, directories.length ? directories[0].id : null));
      yield all([
        // put(actions.loginSuccess(sessionToken, id, expiration, userRole)),
        // put(actions.setAuthExpiration(expiration)),
        // put(userLoginSuccess({
        //   id, userEmail, firstName, lastName, userRole, imageUrl,
        // })),
        // put(directoriesSuccess(dirMap, directories.length ? directories[0].id : null)),
        put(familiesRequest(sessionToken, id)),
        put(groupsRequest(sessionToken, id)),
      ]);
    }
  } catch (error) {
    yield put(actions.loginFailure(error.message));
  }
}

/**
 * Send credentials to firebase to be verified.
 * @param email {string} Given email address for login.
 * @param password {string} Given password for login.
 */
export function* requestFireAuth(email, password) {
  try {
    const user = yield call(FireAuth.login, email, password);
  } catch (error) {
    yield put(actions.loginFailure(error.message));
  }
}

export function* requestResumeSession() {
  try {
    const token = yield select(getToken);
    const dirId = yield select(getActiveDirectoryId);

    let response;
    if (!token || !dirId) {
      yield put(actions.resumeSessionFailure('No state'));
    } else {
      response = yield call(api.requestMe, token, dirId);
    }

    if (response.error) {
      yield put(actions.resumeSessionFailure(response.error.message));
    } else {
      yield null;
    }
  } catch (error) {
    yield put(actions.resumeSessionFailure(error.message));
  }
}

export function* requestSetPassword(token, password) {
  try {
    const response = yield call(api.requestSetPassword, token, password);

    if (response.error) {
      yield put(actions.setPasswordFailure(response.error.message));
    } else {
      yield put(actions.setPasswordSuccess());
    }
  } catch (error) {
    yield put(actions.setPasswordFailure(error.message));
  }
}

/**
 * Starts the auth flow with SMS code.
 * @param phone {string} Given phone number for login.
 */
export function* requestStartPhoneLogin(phone) {
  try {
    const response = yield call(api.requestPhoneLogin, phone);

    if (response.error) {
      yield put(actions.startPhoneLoginFailure(response.error.message));
    } else {
      const {
        phone: phoneNumber,
        expiration,
      } = response.data;

      /**
       * Delay for consistency between login actions.
       */
      yield delay(250);

      yield put(actions.startPhoneLoginSuccess(phoneNumber, expiration));
    }
  } catch (error) {
    yield put(actions.startPhoneLoginFailure(error.message));
  }
}

/**
 * Completes the auth flow with SMS code.
 * @param phone {string} Given phone number for login.
 * @param code {string} Verification code, as entered.
 */
export function* requestVerifyPhoneLogin(phone, code) {
  try {
    const response = yield call(api.verifyPhoneLogin, phone, code);

    if (response.error) {
      yield put(actions.verifyPhoneLoginFailure(response.error.message));
    } else {
      const {
        sessionToken,
        expiration,
        id,
        email: userEmail,
        firstName,
        lastName,
        directories,
        userRole,
      } = response.data;

      /**
       * Delay for consistency between login actions.
       */
      yield delay(250);

      // on login success, request the data needed to populated dashboard content that
      // is immediately rendered in the ui
      yield all([
        put(actions.loginSuccess(sessionToken, id, expiration)),
        put(userLoginSuccess(id, userEmail, firstName, lastName, userRole)),
        put(directoriesSuccess(directories, directories.length ? directories[0].id : null)),
        put(familiesRequest(sessionToken, id)),
        put(groupsRequest(sessionToken, id)),
      ]);
    }
  } catch (error) {
    yield put(actions.verifyPhoneLoginFailure(error.message));
  }
}

/**
 * Request logout. Strips XSRF token from cookies.
 */
export function* requestLogout() {
  try {
    const result = yield FireAuth.logout();

    if (result.error) {
      yield put(actions.logoutFailure('', result.error));
    } else {
      if (global.localStorage) {
        localStorage.clear();
      }
      if (global.sessionStorage) {
        sessionStorage.clear();
      }

      yield put(actions.logoutSuccess());
    }
  } catch (error) {
    yield put(actions.logoutFailure(error.message));
  }
}

export function* handleRehydrate() {
  yield put(actions.resumeSessionRequest());
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.LOGIN_REQUEST,
      constants.FB_LOGIN_REQUEST,
      constants.LOGOUT_REQUEST,
      constants.START_PHONE_LOGIN_REQUEST,
      constants.VERIFY_PHONE_LOGIN_REQUEST,
      constants.SET_AUTH_EXPIRATION,
      // constants.RESUME_SESSION_REQUEST,
      constants.SET_PASSWORD_REQUEST,
      // REHYDRATE,
    ]);

    switch (type) {
      case constants.LOGIN_REQUEST:
        yield fork(requestLogin, payload.email, payload.password);
        break;

      case constants.FB_LOGIN_REQUEST:
        yield fork(requestFireAuth, payload.email, payload.password);
        break;

      case constants.LOGOUT_REQUEST:
        yield fork(requestLogout);
        break;

      case constants.START_PHONE_LOGIN_REQUEST:
        yield fork(requestStartPhoneLogin, payload.phone);
        break;

      case constants.VERIFY_PHONE_LOGIN_REQUEST:
        yield fork(requestVerifyPhoneLogin, payload.phone, payload.code);
        break;

      case constants.SET_AUTH_EXPIRATION:
        yield fork(setExpirationTimeout, payload.expiration);
        break;

      case constants.SET_PASSWORD_REQUEST:
        yield fork(requestSetPassword, payload.token, payload.password);
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
