import { connect } from 'react-redux';

import { setFilter } from '../../../filters/actions';

import { pushLocationHistory } from '../../actions';

import RouteWatchdog from './RouteWatchdog';


const mapDispatchToProps = {
  pushLocationHistory,
  setFilter,
};


export default connect(null, mapDispatchToProps)(RouteWatchdog);
