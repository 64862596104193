import * as React from 'react';

import { FireFunctions, functionNames } from '../../services';

function withFireFunctions(Component) {
  return class ComponentWithAdminDb extends React.Component {
    render() {
      return (
        <Component
          functionNames={functionNames}
          callRemoteFunction={FireFunctions.httpsCallable}
          {...this.props} />
      );
    }
  };
}

export default withFireFunctions;
