//
import * as React from 'react';
import { Select } from 'antd';


const { Option: Opt, OptGroup } = Select;


class GroupedSelect extends React.Component {
  static defaultProps = {
    className: '',
    style: {},
  };

  filterOption = (input, option) => (
    typeof option.props.children === 'string'
      ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false
  );

  render() {
    const {
      onChange,
      value,
      isMulti,
      className,
      groups,
      placeholder,
    } = this.props;

    const children = groups.map((group, idx) => (
      <OptGroup key={`${group.label}-group-${idx}`} label={group.label}>
        {group.data.map((opt, i) => (
          <Opt key={`${group.type}_${opt.value}`}>{opt.label}</Opt>
        ))}
      </OptGroup>
    ));

    return (
      <Select
        defaultValue={[]}
        filterOption={this.filterOption}
        labelInValue
        mode="multiple"
        onChange={onChange}
        placeholder={placeholder}
        style={{ width: '100%' }}
        value={value}
      >
        {children}
      </Select>
    );
  }
}

export default GroupedSelect;
