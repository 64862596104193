import PropTypes from 'prop-types';
import React from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { Link as RebassLink } from 'rebass';

import Text from '../Text';

import { getSx } from './sx';


const Link = ({ as, children, href, sx, textSx, ...rest }) => (
  <RebassLink
    as={as || RRDLink}
    href={href}
    to={href}
    sx={getSx(sx)}
    {...rest}
  >
    {typeof children === 'string' ? (
      <Text sx={textSx}>
        {children}
      </Text>
    ) : children}
  </RebassLink>
);

Link.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  sx: PropTypes.shape(),
  textSx: PropTypes.shape(),
};

Link.defaultProps = {
  as: undefined,
  sx: {},
  textSx: {},
};


export default Link;
