const getListItemSize = ({ rowItemCount }) => `calc((100vw - ${32 * (rowItemCount + 1)}px) / ${rowItemCount})`;

const grid3Size = getListItemSize({ rowItemCount: 3 });
const grid4Size = getListItemSize({ rowItemCount: 4 });
const grid5Size = getListItemSize({ rowItemCount: 5 });

const minListItemSize = '200px';
const listItemMarginH = 'grid32';
const listItemMarginV = 'grid32';

const getListItemMarginRight = ({ gridSize, index }) => {
  const itemNum = index + 1;
  return itemNum % gridSize === 0 ? 'grid0' : listItemMarginH;
};

export const getEntityListStyles = ({ forceListStyle }) => ({
  flexDirection: 'column',
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export const getListItemStyles = ({ background, forceListStyle, foreground, index, isStriped }) => ({
  backgroundColor: index % 2 !== 0 ? background : foreground,
  paddingLeft: isStriped ? 'grid16' : 'grid0',
  paddingRight: isStriped ? 'grid16' : 'grid0',
  [global.Breakpoint.tablet]: forceListStyle ? {} : {
    backgroundColor: 'background',
    display: 'block',
    height: grid3Size,
    marginBottom: listItemMarginV,
    marginRight: getListItemMarginRight({ gridSize: 3, index }),
    minHeight: minListItemSize,
    minWidth: minListItemSize,
    paddingLeft: 'grid24',
    paddingRight: 'grid24',
    width: grid3Size,
  },
  [global.Breakpoint.laptop]: forceListStyle ? {} : {
    borderRadius: 'block',
    height: grid4Size,
    marginRight: getListItemMarginRight({ gridSize: 4, index }),
    width: grid4Size,
  },
  [global.Breakpoint.desktop]: forceListStyle ? {} : {
    height: grid5Size,
    marginRight: getListItemMarginRight({ gridSize: 5, index }),
    width: grid5Size,
  },
});
