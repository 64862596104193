import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { getDefsCountryCodes } from 'modules/definitions/selectors';

import presenter from './presenter';


const getCodeOptions = createSelector(
  [getDefsCountryCodes],
  codes => codes.map(c => ({
    ...c,
    name: `${c.code} (${c.value})`,
  })),
);

const mapStateToProps = createStructuredSelector({
  codes: getCodeOptions,
});

const mapDispatchToProps = {};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(presenter);
