export default {
  // 8/25/19 add families pagination
  0: state => ({
    ...state.families,
    families: {},
    pagination: {
      count: 1,
      page: 1,
      pages: 1,
      pageSize: 3,
    },
  }),
  // 9/3/19 update family ids state
  1: state => ({
    ...state.families,
    familyIds: [],
  }),
  // 9/3/19 update family ids state
  2: state => ({
    ...(state.families || {}),
    pagination: {
      count: 1,
      page: 1,
      pages: 1,
      pageSize: 25,
    },
  }),
  3: state => ({
    ...(state.families || {}),
    familySummaries: {
      ids: [],
      isFetching: false,
      summaries: {},
    },
  }),
  4: state => ({
    ...(state.families || {}),
    lastFetched: null,
    familySummaries: {
      ...(state.families ? state.families.familySummaries || {} : {}),
      lastFetched: null,
    },
  }),
  // 9/11/20 save sort selection in state
  5: state => ({
    ...(state.families || {}),
    sort: {
      by: ['name'],
      dir: ['asc'],
    },
  }),
};
