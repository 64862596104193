import PropTypes from 'prop-types';
import React from 'react';


const Mail = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M36,4H4A4,4,0,0,0,0,8L0,32a4,4,0,0,0,4,4H36a4,4,0,0,0,4-4V8A4,4,0,0,0,36,4Zm0,8L20,22,4,12V8L20,18,36,8Z"
  />
);

Mail.propTypes = {
  strokeWidth: PropTypes.number,
};

Mail.defaultProps = {
  strokeWidth: 1,
};


export default Mail;
