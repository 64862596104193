import PropTypes from 'prop-types';
import React from 'react';


const BulletList = ({ strokeWidth, ...rest }) => (
  <React.Fragment>
    <path
      strokeWidth={strokeWidth}
      {...rest}
      d="M3.24,16.76A3.24,3.24,0,1,0,6.49,20,3.24,3.24,0,0,0,3.24,16.76Zm0-13A3.25,3.25,0,1,0,6.49,7,3.24,3.24,0,0,0,3.24,3.78Zm0,26A3.25,3.25,0,1,0,6.49,33,3.25,3.25,0,0,0,3.24,29.73Zm6.49,5.41H40V30.81H9.73Zm0-13H40V17.84H9.73Zm0-17.3V9.19H40V4.86Z"
    />
  </React.Fragment>
);

BulletList.propTypes = {
  strokeWidth: PropTypes.number,
};

BulletList.defaultProps = {
  strokeWidth: 1,
};


export default BulletList;
