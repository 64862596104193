import { isEmpty } from 'lodash';


export const hasAnyPhone = (group = {}) => {
  const { phone = {} } = group;
  return isEmpty(phone.number);
};

export const hasAnyAddress = (group = {}) => {
  const { address = {} } = group;

  const addressValues = Object.values(address);

  return addressValues.some((property) => Boolean(property));
};
