import axios from 'axios';
import { Routing } from '../../common/constants';

export async function requestUserData(token, userId, dirId, userShortCode) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.userDataForShortCode(
    userId,
    userShortCode,
    dirId,
  )}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.get(path, options);
}

export async function requestUpdateMembers(token, userId, dirId, updates) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.usersMulti(
    userId,
    dirId,
  )}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.patch(path, { users: updates }, options);
}

export async function requestSaveUserUpdates(token, userId, dirId, userShortCode, updates) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.userDataForShortCode(
    userId,
    userShortCode,
    dirId,
  )}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, updates, options);
}

export async function requestAddMember(token, userId, dirId, memberData) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.users(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, memberData, options);
}

export async function requestAddMultiMember(token, userId, dirId, members) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.usersMulti(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, { users: members }, options);
}

export async function requestAddFamily(token, userId, dirId, familyData) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.families(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, familyData, options);
}

export async function requestAddGroup(token, userId, dirId, groupData) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.groups(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, groupData, options);
}

export async function requestCustomFields(token, userId, dirId) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.customFields(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.get(path, options);
}

export async function requestInternalFields(token, userId, dirId) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.internalFields(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.get(path, options);
}

export async function requestUserCategories(token, userId, dirId) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.userCategories(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.get(path, options);
}

export async function requestSaveCustomField(token, userId, dirId, fieldId, updates) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.customField(userId, fieldId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.patch(path, updates, options);
}

export async function requestSaveInternalField(token, userId, dirId, fieldId, updates) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.internalField(userId, fieldId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.patch(path, updates, options);
}

export async function requestSaveCategory(token, userId, dirId, catId, updates) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.userCategory(userId, catId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.patch(path, updates, options);
}

export async function requestDeleteCustomField(token, userId, dirId, fieldId) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.customField(userId, fieldId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.delete(path, options);
}

export async function requestDeleteInternalField(token, userId, dirId, fieldId) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.internalField(userId, fieldId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.delete(path, options);
}

export async function requestDeleteCategory(token, userId, dirId, catId) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.userCategory(userId, catId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.delete(path, options);
}

export async function requestCreateCustomField(token, userId, dirId, data) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.customFields(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, data, options);
}

export async function requestCreateInternalField(token, userId, dirId, data) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.internalFields(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, data, options);
}

export async function requestCreateCategory(token, userId, dirId, data) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.userCategories(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, data, options);
}

export async function requestAllSentMessages(token, userId, dirId, fromDate, toDate) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.messages(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
    params: {
      fromDate,
      toDate,
    },
  };

  return axios.get(path, options);
}

export async function requestCsvExport(token, userId, dirId, type, exportOptions = {}) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.exports(userId, dirId, 'csv')}`;
  const requestOptions = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, { ...exportOptions, type }, requestOptions);
}

export async function requestUpdateAdmin(
  token, userId, dirId, adminId, roleNames,
) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.adminUser(
    userId, dirId, adminId,
  )}`;
  const requestOptions = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.patch(path, { roles: roleNames }, requestOptions);
}

export async function requestChangePermissions(
  token, userId, dirId, memberId, accessRoles,
) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.adminUsers(
    userId, dirId,
  )}`;
  const requestOptions = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, { memberId, accessRoles }, requestOptions);
}

export async function requestRevokePermissions(
  token, userId, dirId, memberId,
) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.adminUser(
    userId, dirId, memberId,
  )}`;
  const requestOptions = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.delete(path, requestOptions);
}

export async function requestRevokeSpecialAccess(
  token, userId, dirId, memberId,
) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.adminUsers(
    userId, dirId, memberId,
  )}`;
  const requestOptions = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.delete(path, requestOptions);
}

export async function requestSendMessage({
  token, userId, dirId, to, subject, text, types,
}) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.messages(
    userId, dirId,
  )}`;

  const requestOptions = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  const data = {
    to,
    subject,
    text,
    types,
  };

  return axios.post(path, data, requestOptions);
}
