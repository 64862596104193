import React from 'react';
import PropTypes from 'prop-types';
import { FadeLoader } from 'react-spinners';

import { propTypeHelpers } from 'helpers';

import { Flex } from 'modules/core/components';

import * as colors from 'modules/core/colors';


const Loader = (props) => {
  const {
    color,
    css,
    height,
    isFetching,
    margin,
    radius,
    size,
    sx,
    useWrapper,
    width,
  } = props;

  const loader = (
    <FadeLoader
      color={color}
      css={css}
      height={height}
      loading={isFetching}
      margin={margin}
      radius={radius}
      size={size}
      width={width}
    />
  );

  if (useWrapper) {
    return (
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          ...sx,
        }}
      >
        {loader}
      </Flex>
    );
  }

  return loader;
};

Loader.propTypes = {
  color: PropTypes.string,
  css: PropTypes.any,
  height: propTypeHelpers.stringOrNumberPropType,
  isFetching: PropTypes.bool,
  margin: propTypeHelpers.stringOrNumberPropType,
  radius: propTypeHelpers.stringOrNumberPropType,
  size: propTypeHelpers.stringOrNumberPropType,
  sx: PropTypes.shape({}),
  useWrapper: PropTypes.bool,
  width: propTypeHelpers.stringOrNumberPropType,
};

Loader.defaultProps = {
  color: colors.mamba,
  css: undefined,
  height: undefined,
  isFetching: undefined,
  margin: undefined,
  radius: undefined,
  size: undefined,
  sx: {},
  useWrapper: undefined,
  width: undefined,
};


export default Loader;
