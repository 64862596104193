export const emptyOrEndSpace = (str) => (
  str ? `${str} ` : ''
);

export const emptyOrStartSpace = (str) => (
  str ? ` ${str}` : ''
);

export const emptyOrWrapSpaces = (str) => (
  str ? ` ${str} ` : ''
);

export const notEmpty = (str) => (
  str !== null && str !== undefined
);
