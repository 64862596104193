import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Text from '../Text';


const styles = {
  color: 'subtext',
  fontWeight: 'bold',
  paddingLeft: 'grid16',
  [global.Breakpoint.tablet]: {
    paddingLeft: 'grid0',
  },
};

const LetterHeading = ({ letter, sx }) => (
  <Box sx={sx}>
    <Text
      sx={styles}
    >
      {letter.toUpperCase()}
    </Text>
  </Box>
);

LetterHeading.propTypes = {
  letter: PropTypes.string,
  sx: PropTypes.shape({}),
};

LetterHeading.defaultProps = {
  letter: '',
  sx: {},
};


export default LetterHeading;
