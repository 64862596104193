import * as constants from './constants';

import { SWITCH_DIRECTORY } from '../directory/constants';

export const name = 'groups';

export const initialState = {
  activeGroupId: undefined,
  activeGroupShortCode: undefined,
  groupIds: [],
  groups: {},
  groupsSummaries: {
    ids: [],
    isFetching: false,
    lastFetched: null,
    summaries: {},
  },
  isFetching: false,
  lastFetched: null,
  pagination: {
    count: 0,
    dataRange: [],
    page: 1,
    pages: 1,
    pageSize: 25,
  },
  sort: {
    by: ['name'],
    dir: ['asc'],
  },
  viewing: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_GROUPS_SORT:
      return {
        ...state,
        sort: {
          by: action.payload.by || state.sort.by,
          dir: action.payload.dir || state.sort.dir,
        },
      };

    case constants.FB_GROUPS_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case constants.FB_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastFetched: new Date(),
        groups: action.payload.groups,
        groupIds: action.payload.groupIds,
        pagination: action.payload.pagination,
      };

    case constants.GROUPS_FAILURE:
      return {
        ...state,
        groups: {},
        isFetching: false,
      };

    case constants.GROUP_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.GROUP_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        viewing: action.payload.group,
        activeGroupShortCode: action.payload.activeGroupShortCode,
      };

    case constants.GROUP_INFO_FAILURE:
      return {
        ...state,
        activeGroupShortCode: undefined,
        viewing: {},
        isFetching: false,
      };

    case constants.GROUPS_SUMMARIES_FETCH:
      return {
        ...state,
        groupsSummaries: {
          ...state.groupsSummaries,
          isFetching: true,
        },
      };

    case constants.GROUPS_SUMMARIES_SUCCESS:
      return {
        ...state,
        groupsSummaries: {
          ...state.groupsSummaries,
          ids: action.payload.ids,
          isFetching: false,
          lastFetched: new Date(),
          summaries: action.payload.summaries,
        },
      };

    case constants.GROUPS_SUMMARIES_FAILURE:
      return {
        ...state,
        groupsSummaries: {
          ...state.groupsSummaries,
          isFetching: false,
        },
      };

    case SWITCH_DIRECTORY:
      return {
        ...state,
        groups: {},
      };

    case constants.APPEND_GROUP:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.id]: action.payload,
        },
        groupIds: [...state.groupIds, action.payload.id],
      };

    default:
      return state;
  }
}
