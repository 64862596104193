import PropTypes from 'prop-types';
import React from 'react';

import { Appearance } from 'modules/core/constants';

import { Button } from 'modules/core/components/Button';
import Flex from 'modules/core/components/Flex';
import Text from 'modules/core/components/Text';


const PickerOption = (props) => {
  const {
    isActive,
    isMobile,
    label,
    onClick,
  } = props;

  let color;

  if (!isMobile) {
    color = isActive ? 'systemText' : 'systemTextLight';
  } else {
    color = isActive ? 'lightText' : 'inactiveText';
  }

  return (
    <Flex
      justifyContent="center"
      sx={{
        marginBottom: 'grid8',
        ':last-child': {
          marginBottom: 'grid0',
        },
      }}
    >
      <Button
        animate={false}
        appearance={Appearance.NONE}
        onClick={onClick}
        sx={{
          color,
          height: '100%',
          margin: 'grid0',
          paddingLeft: 'grid0',
          paddingRight: 'grid0',
          paddingTop: 'grid0',
          paddingBottom: 'grid0',
          textAlign: 'center',
          textDecoration: isActive ? 'underline' : 'none',
          transition: 'color',
          width: '100%',
          ':hover': {
            color: isMobile ? 'activeHover' : 'systemTextEmphasis',
          },
        }}
      >
        <Text
          sx={{
            fontWeight: 'semibold',
            fontSize: 'font16',
            userSelect: 'none',
          }}
        >
          {label}
        </Text>
      </Button>
    </Flex>
  );
};

PickerOption.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};


export default PickerOption;
