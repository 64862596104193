import { createSelector } from 'reselect';

import { name } from './reducer';

const getState = state => state[name];

export const getTagsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getTags = createSelector(
  [getState],
  state => state.tags || [],
);

export const getMembersByTag = createSelector(
  [getState],
  state => state.membersByTag || [],
);
