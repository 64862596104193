import * as React from 'react';

import { FireDb, FireFunctions, FireStorage, functionNames } from '../../services';

function withDirectoryEditing(Component) {
  return class ComponentWithAdminDb extends React.Component {
    render() {
      return (
        <Component
          functionNames={functionNames}
          callRemoteFunction={FireFunctions.httpsCallable}
          getDirectoryData={FireDb.getDirectoryTree}
          uploadFile={FireStorage.uploadFile}
          {...this.props} />
      );
    }
  };
}

export default withDirectoryEditing;
