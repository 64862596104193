import { sortBy } from 'lodash';

export const membersMap = (data) => (
  data.reduce((obj, member) => ({
    ...obj,
    [member.shortCode]: member,
  }), {})
);

export const sortedMembers = (data) => (sortBy(data, ['firstName', 'lastName']));

export const directoriesMap = (data) => (
  data.reduce((obj, item) => ({
    ...obj,
    [item.id]: item,
  }), {})
);

export const familyMember = (data) => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  imageUrl: data.imageUrl,
  shortCode: data.shortCode,
  isLeader: data.isLeader,
});

export const family = (data) => ({
  id: data.id,
  shortCode: data.shortCode,
  members: data.members.reduce((obj, m) => ({
    ...obj,
    [m.id]: familyMember(m),
  })),
  leader: data.leader,
  name: data.name,
});

export const famsMap = (data) => (
  data.reduce((obj, f) => ({
    ...obj,
    [f.shortCode]: family(f),
  }), {})
);

export const sortedFams = (data) => (sortBy(data, ['name']));

export const groupsMap = (data) => (
  data.reduce((obj, g) => ({
    ...obj,
    [g.shortCode]: g,
  }), {})
);

export const sortedGroups = (data) => (sortBy(data, ['name']));
