import React from 'react';

import { dashboardRoutePaths } from 'modules/router/constants';

import { filterConstants } from '../../../../constants';

const adminBgClass = 'app-bg-panel';
const appDiv = document.querySelector('#app');
const peopleTypeViewFilterPath = 'views.people.type';

export const usePanelClassnames = (props, prevPathname) => {
  const { location, pushLocationHistory } = props;

  return React.useEffect(() => {
    if (location?.pathname !== prevPathname) {
      pushLocationHistory(location);

      if (location?.pathname?.includes?.('panel')) {
        appDiv.classList.add(adminBgClass);
      } else if (appDiv.classList.contains(adminBgClass)) {
        appDiv.classList.remove(adminBgClass);
      }
    }
  }, [location.pathname]);
};

export const usePeopleTypeViewFilter = (props, prevPathname) => {
  const { location, setFilter } = props;

  return React.useEffect(() => {
    if (location?.pathname !== prevPathname) {
      if (location?.pathname?.endsWith?.(dashboardRoutePaths.families)) {
        setFilter(peopleTypeViewFilterPath, filterConstants.PeopleViewType.families);
      } else if (location?.pathname?.endsWith?.(dashboardRoutePaths.individuals)) {
        setFilter(peopleTypeViewFilterPath, filterConstants.PeopleViewType.individuals);
      }
    }
  }, [location.pathname]);
};
