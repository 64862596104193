import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';


const ICal = ({ onClick }) => (
  <a
    className="icon-ical"
    href="#"
    onClick={(evt) => {
      evt.preventDefault();
      onClick();
    }}
  >
    <Button
      type="primary"
      size="small"
      style={{ width: '140px', textAlign: 'center', marginBottom: '4px' }}
    >
      Apple Calendar
    </Button>
  </a>
);

ICal.propTypes = {
  onClick: PropTypes.func.isRequired,
};


export default ICal;
