import PropTypes from 'prop-types';
import React from 'react';


const MailOutline = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M40,8a4,4,0,0,0-4-4H4A4,4,0,0,0,0,8V32a4,4,0,0,0,4,4H36a4,4,0,0,0,4-4ZM36,8,20,18,4,8Zm0,24H4V12L20,22,36,12Z"
  />
);

MailOutline.propTypes = {
  strokeWidth: PropTypes.number,
};

MailOutline.defaultProps = {
  strokeWidth: 1,
};


export default MailOutline;
