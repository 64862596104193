import PropTypes from 'prop-types';
import * as React from 'react';

import { Box, MultiSelection } from 'modules/core/components';


class LinkToEntities extends React.Component {
  selectRef = React.createRef();

  removeGenerationFromName = (name) => {
    const regex = /(Jr\.|Sr\.|I{1,3})$/;

    const modifiedName = name.replace(regex, '').trim();
  
    return modifiedName;
  }

  onSelectChange = (val) => {
    const { setValues } = this.props;

    setValues({
      selected: val,
    });
  };

  reset = () => {
    const { setValues } = this.props;

    setValues({
      selected: [],
    });
  };

  render() {
    const {
      entities,
      entityIds,
      getEntityName,
      isFetching,
      name,
      placeholder,
      selectedEntities,
    } = this.props;

    const options = entityIds.reduce((opts, key) => {
      const entity = entities[key];

      if (entity) {
        return [
          ...opts,
          {
            value: key,
            label: getEntityName(entity),
          },
        ];
      }

      return opts;
    }, []);

    if ( name === 'families' || name === 'groups') {
      options.sort((a, b) => {
        const nameA = a.label.replace(" ", '_').trim().toLowerCase();
        const nameB = b.label.replace(" ", "_").trim().toLowerCase();
  
        if (nameA === nameB) {
          return 0;
        }
      
        if (nameA > nameB) {
          return 1;
        }
      
        return -1;
      });
    } else {
      options.sort((a, b) => {
        const aNameRemovedGeneration = this.removeGenerationFromName(a.label.toLowerCase());
        const bNameRemovedGeneration = this.removeGenerationFromName(b.label.toLowerCase());
        
        const aSplits = aNameRemovedGeneration.split(" ");
        const bSplits = bNameRemovedGeneration.split(" ");
  
        const nameALen = aSplits.length;
        const nameBLen = bSplits.length;
  
        const lastnameA = aSplits[nameALen-1];
        const lastnameB = bSplits[nameBLen-1];
  
        const firstnameA = aSplits[0];
        const firstnameB = bSplits[0];
  
        if (lastnameA > lastnameB) {
          return 1;
        } else if (lastnameA < lastnameB) {
          return -1;
        } else {
          if (firstnameA > firstnameB) {
            return 1;
          } else if (firstnameA < firstnameB) {
            return -1;
          } else {
            return 0;
          }    
        }
      });
    }

    const value = selectedEntities || [];

    return (
      <Box>
        <MultiSelection
          closeMenuOnSelect={false}
          isCreatable={false}
          isLoading={isFetching}
          name={name}
          onChange={this.onSelectChange}
          options={options}
          placeholder={placeholder}
          value={value}
          ref={this.selectRef}
        />
      </Box>
    );
  }
}

LinkToEntities.propTypes = {
  entities: PropTypes.PropTypes.shape({}),
  entityIds: PropTypes.arrayOf(PropTypes.string),
  getEntityName: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selectedEntities: PropTypes.arrayOf(PropTypes.shape()),
  setValues: PropTypes.func.isRequired,
};

LinkToEntities.defaultProps = {
  placeholder: 'Type to search or create',
  selectedEntities: undefined,
};


export default LinkToEntities;
