import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';


const Outlook = ({ onClick }) => (
  <a
    className="icon-ical"
    href="#"
    onClick={(evt) => {
      evt.preventDefault();
      onClick();
    }}
  >
    <Button
      type="primary"
      size="small"
      style={{ width: '140px', textAlign: 'center' }}
    >
      Outlook Calendar
    </Button>
  </a>
);

Outlook.propTypes = {
  onClick: PropTypes.func.isRequired,
};


export default Outlook;
