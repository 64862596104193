import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';

import Box from '../../Box';

import MobileMenuBase from '../MobileMenuBase';

import { MobileMenuLink } from './components';


const MobileMenuNav = ({ items, ...rest }) => (
  createPortal((
    <MobileMenuBase {...rest}>
      {({ onClose }) => (
        <Box
          className={classNames({
            'flex-col-start': true,
            'full-screen': true,
            'j-nav-links': true,
            'pos-abs': true,
          })}
          sx={{
            paddingBottom: 'grid48',
            paddingLeft: 'grid16',
            paddingRight: 'grid16',
            paddingTop: 'grid48',
          }}
        >
          <Box
            className={classNames({
              'pos-rel': true,
              'j-menu-links-scroll': true,
            })}
          >
            {items.map((item) => (
              <MobileMenuLink
                href={item.href}
                key={item.text}
                onClose={onClose}
              >
                {item.text}
              </MobileMenuLink>
            ))}
          </Box>
        </Box>
      )}
    </MobileMenuBase>
  ), document.getElementById('modal') || document.getElementById('root'))
);

MobileMenuNav.propTypes = {
  ...MobileMenuBase.propTypes,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
};

MobileMenuNav.defaultProps = {
  ...MobileMenuBase.defaultProps,
  className: undefined,
  items: [],
  isOpen: undefined,
  sx: {},
};


export default MobileMenuNav;
