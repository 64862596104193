import { IconNames } from 'modules/core/components';

import { dashboardRoutePaths, panelRoutePaths } from '../router/constants';

export const TAGS_REQUEST = '@panel/TAGS_REQUEST';
export const TAGS_SUCCESS = '@panel/TAGS_SUCCESS';
export const TAGS_FAILURE = '@panel/TAGS_FAILURE';

export const CREATE_USER_REQUEST = '@panel/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = '@panel/CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = '@panel/CREATE_USER_FAILURE';

export const DIR_SETTINGS_REQUEST = '@panel/DIR_SETTINGS_REQUEST';
export const DIR_SETTINGS_SUCCESS = '@panel/DIR_SETTINGS_SUCCESS';
export const DIR_SETTINGS_FAILURE = '@panel/DIR_SETTINGS_FAILURE';

export const DELETE_USER_REQUEST = '@panel/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = '@panel/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = '@panel/DELETE_USER_FAILURE';

export const DELETE_FAMILY_REQUEST = '@panel/DELETE_FAMILY_REQUEST';
export const DELETE_FAMILY_SUCCESS = '@panel/DELETE_FAMILY_SUCCESS';
export const DELETE_FAMILY_FAILURE = '@panel/DELETE_FAMILY_FAILURE';

export const DELETE_GROUP_REQUEST = '@panel/DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = '@panel/DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = '@panel/DELETE_GROUP_FAILURE';

export const GET_ADMINS_REQUEST = '@panel/GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCESS = '@panel/GET_ADMINS_SUCCESS';
export const GET_ADMINS_FAILURE = '@panel/GET_ADMINS_FAILURE';

export const UPDATE_DIR_FIELDS_REQUEST = '@panel/UPDATE_DIR_FIELDS_REQUEST';
export const UPDATE_DIR_FIELDS_SUCCESS = '@panel/UPDATE_DIR_FIELDS_SUCCESS';
export const UPDATE_DIR_FIELDS_FAILURE = '@panel/UPDATE_DIR_FIELDS_FAILURE';

export const UPDATE_DIR_CATEGORIES_REQUEST = '@panel/UPDATE_DIR_CATEGORIES_REQUEST';
export const UPDATE_DIR_CATEGORIES_SUCCESS = '@panel/UPDATE_DIR_CATEGORIES_SUCCESS';
export const UPDATE_DIR_CATEGORIES_FAILURE = '@panel/UPDATE_DIR_CATEGORIES_FAILURE';

export const resourceTypes = {
  PEOPLE: 'PEOPLE',
  GROUPS: 'GROUPS',
  FAMILIES: 'FAMILIES',
  DATA: 'DATA',
  DIRECTORY_INFO: 'DIRECTORY_INFO',
  CATEGORIES: 'CATEGORIES',
  CUSTOM_FIELDS: 'CUSTOM_FIELDS',
  PRIVATE_FIELDS: 'PRIVATE_FIELDS',
  PERMISSIONS: 'PERMISSIONS',
  EXPORTS: 'EXPORTS',
  SETTINGS: 'SETTINGS',
};

export const panelResources = {
  [resourceTypes.PEOPLE]: {
    icon: IconNames.PERSON_CHECK,
    name: 'Person',
    plural: 'People',
    to: panelRoutePaths.members,
    createTo: panelRoutePaths.resourceCreate(resourceTypes.PEOPLE.toLowerCase()),
    prop: 'personSummaries',
    resourceType: resourceTypes.PEOPLE,
  },
  [resourceTypes.GROUPS]: {
    icon: IconNames.PERSON,
    name: 'Group',
    plural: 'Groups',
    to: panelRoutePaths.groups,
    createTo: panelRoutePaths.resourceCreate(resourceTypes.GROUPS.toLowerCase()),
    prop: 'groupsSummaries',
    resourceType: resourceTypes.GROUPS,
  },
  [resourceTypes.FAMILIES]: {
    icon: IconNames.PEOPLE,
    name: 'Family',
    plural: 'Families',
    to: panelRoutePaths.families,
    createTo: panelRoutePaths.resourceCreate(resourceTypes.FAMILIES.toLowerCase()),
    prop: 'familySummaries',
    resourceType: resourceTypes.FAMILIES,
  },
  data: {
    icon: IconNames.FOLDER,
    name: 'Data',
    plural: 'Data',
    to: panelRoutePaths.data,
    prop: '',
    resourceType: resourceTypes.DATA,
  },
  [resourceTypes.PERMISSIONS]: {
    icon: IconNames.ADMIN_SECURITY,
    name: 'Permission',
    plural: 'Permissions',
    to: panelRoutePaths.permissions,
    resourceType: resourceTypes.PERMISSIONS,
  },
  [resourceTypes.EXPORTS]: {
    icon: IconNames.DOWNLOAD,
    name: 'Export',
    plural: 'Exports',
    to: panelRoutePaths.exports,
    resourceType: resourceTypes.EXPORTS,
  },
  [resourceTypes.SETTINGS]: {
    icon: IconNames.SETTINGS,
    name: 'Setting',
    plural: 'Settings',
    to: panelRoutePaths.settings,
    resourceType: resourceTypes.SETTINGS,
  },
  [resourceTypes.DIRECTORY_INFO]: {
    icon: IconNames.BUSINESS,
    name: 'Directory Info',
    plural: 'Directory Info',
    to: dashboardRoutePaths.dirInfo,
    resourceType: resourceTypes.DIRECTORY_INFO,
  },
};
