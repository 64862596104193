import * as adminConstants from 'modules/admin/constants';

import * as constants from './constants';

export const name = 'panel';

const initialState = {
  admins: {
    admins: {},
    ids: [],
    isFetching: false,
    isUpdating: false,
  },
  directorySettings: {
    defaultCountry: {
      name: null,
      alpha2: null,
    },
    defaultDate: {
      format: null,
      separator: null,
    },
    defaultRegion: {
      name: null,
    },
    defaultCountryCode: {
      code: null,
      alpha2: null,
    },
    default_basicsVisible: false,
    default_phoneVisible: false,
    default_addressVisible: false,
    isFetching: false,
  },
  editor: {
    isDeleting: false,
  },
  data: {
    isUpdatingCategories: false,
    isUpdatingFields: false,
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_ADMINS_REQUEST:
      return {
        ...state,
        admins: {
          ...state.admins,
          isFetching: true,
        },
      };

    case constants.GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: {
          ...state.admins,
          admins: action.payload.admins,
          ids: Object.keys(action.payload.admins || {}),
          isFetching: false,
        },
      };

    case constants.GET_ADMINS_FAILURE:
      return {
        ...state,
        admins: {
          ...state.admins,
          isFetching: false,
        },
      };

    case constants.DIR_SETTINGS_REQUEST:
      return {
        ...state,
        directorySettings: {
          ...state.directorySettings,
          isFetching: true,
        },
      };

    case constants.DIR_SETTINGS_SUCCESS:
      return {
        ...state,
        directorySettings: {
          ...action.payload.settings,
          isFetching: false,
        },
      };

    case constants.DIR_SETTINGS_FAILURE:
      return {
        ...state,
        directorySettings: {
          ...state.directorySettings,
          isFetching: false,
        },
      };

    case constants.DELETE_USER_REQUEST:
    case constants.DELETE_FAMILY_REQUEST:
    case constants.DELETE_GROUP_REQUEST:
      return {
        ...state,
        editor: {
          ...state.editor,
          isDeleting: true,
        },
      };

    case constants.DELETE_USER_SUCCESS:
    case constants.DELETE_USER_FAILURE:
    case constants.DELETE_FAMILY_SUCCESS:
    case constants.DELETE_FAMILY_FAILURE:
    case constants.DELETE_GROUP_SUCCESS:
    case constants.DELETE_GROUP_FAILURE:
      return {
        ...state,
        editor: {
          ...state.editor,
          isDeleting: false,
        },
      };

    case constants.UPDATE_DIR_FIELDS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          isUpdatingFields: true,
        },
      };

    case constants.UPDATE_DIR_FIELDS_SUCCESS:
    case constants.UPDATE_DIR_FIELDS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          isUpdatingFields: false,
        },
      };

    case constants.UPDATE_DIR_CATEGORIES_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          isUpdatingCategories: true,
        },
      };

    case constants.UPDATE_DIR_CATEGORIES_SUCCESS:
    case constants.UPDATE_DIR_CATEGORIES_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          isUpdatingCategories: false,
        },
      };

    case adminConstants.ADMIN_CHANGE_PERMS_REQUEST:
      return {
        ...state,
        admins: {
          ...state.admins,
          isUpdating: true,
        },
      };

    case adminConstants.ADMIN_CHANGE_PERMS_FAILURE:
    case adminConstants.ADMIN_CHANGE_PERMS_SUCCESS:
      return {
        ...state,
        admins: {
          ...state.admins,
          isUpdating: false,
        },
      };

    default:
      return state;
  }
}
