import * as constants from './constants';

export function loginRequest(email, password) {
  return {
    type: constants.LOGIN_REQUEST,
    payload: {
      email,
      password,
    },
  };
}

export function loginSuccess(token, userId, expiration, userRole) {
  return {
    type: constants.LOGIN_SUCCESS,
    payload: {
      token,
      userId,
      expiration,
      userRole,
    },
  };
}

export function loginFailure(message) {
  return {
    type: constants.LOGIN_FAILURE,
    payload: {
      message,
    },
  };
}

export function fbLoginRequest(email, password) {
  return {
    type: constants.FB_LOGIN_REQUEST,
    payload: {
      email,
      password,
    },
  };
}

export function fbLoginSuccess(authUser) {
  return {
    type: constants.FB_LOGIN_SUCCESS,
    payload: {
      authUser,
    },
  };
}

export function fbLoginFailure(message) {
  return {
    type: constants.FB_LOGIN_FAILURE,
    payload: {
      message,
    },
  };
}

export function startPhoneLoginRequest(phone) {
  return {
    type: constants.START_PHONE_LOGIN_REQUEST,
    payload: {
      phone,
    },
  };
}

export function startPhoneLoginSuccess(phone, expiration) {
  return {
    type: constants.START_PHONE_LOGIN_SUCCESS,
    payload: {
      phone,
      expiration,
    },
  };
}

export function startPhoneLoginFailure(message) {
  return {
    type: constants.START_PHONE_LOGIN_FAILURE,
    payload: {
      message,
    },
  };
}

export function verifyPhoneLoginRequest(phone, code) {
  return {
    type: constants.VERIFY_PHONE_LOGIN_REQUEST,
    payload: {
      phone,
      code,
    },
  };
}

export function verifyPhoneLoginSuccess(token, userId, expiration) {
  return {
    type: constants.VERIFY_PHONE_LOGIN_SUCCESS,
    payload: {
      token,
      userId,
      expiration,
    },
  };
}

export function verifyPhoneLoginFailure(message) {
  return {
    type: constants.VERIFY_PHONE_LOGIN_FAILURE,
    payload: {
      message,
    },
  };
}

export function logoutRequest() {
  return {
    type: constants.LOGOUT_REQUEST,
  };
}

export function logoutSuccess() {
  return {
    type: constants.LOGOUT_SUCCESS,
  };
}

export function logoutFailure(message, error) {
  return {
    type: constants.LOGOUT_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function signupRequest(email, password, firstName, lastName) {
  return {
    type: constants.SIGNUP_REQUEST,
    payload: {
      email,
      password,
      firstName,
      lastName,
    },
  };
}

export function signupSuccess(token) {
  return {
    type: constants.SIGNUP_SUCCESS,
    payload: {
      token,
    },
  };
}

export function signupFailure(message) {
  return {
    type: constants.SIGNUP_FAILURE,
    payload: {
      message,
    },
  };
}

export function setAuthExpiration(expiration) {
  return {
    type: constants.SET_AUTH_EXPIRATION,
    payload: {
      expiration,
    },
  };
}

export function resumeSessionRequest() {
  return {
    type: constants.RESUME_SESSION_REQUEST,
  };
}

export function resumeSessionSuccess(userId, expiration, userRole) {
  return {
    type: constants.RESUME_SESSION_SUCCESS,
    payload: {
      userId,
      expiration,
      userRole,
    },
  };
}

export function resumeSessionFailure(message) {
  return {
    type: constants.RESUME_SESSION_FAILURE,
    payload: {
      message,
    },
  };
}

export function setPasswordRequest(token, password) {
  return {
    type: constants.SET_PASSWORD_REQUEST,
    payload: {
      token,
      password,
    },
  };
}

export function setPasswordSuccess() {
  return {
    type: constants.SET_PASSWORD_SUCCESS,
  };
}

export function setPasswordFailure(message) {
  return {
    type: constants.SET_PASSWORD_FAILURE,
    payload: {
      message,
    },
  };
}

export function toggleRememberMe() {
  return {
    type: constants.TOGGLE_REMEMBER_ME,
  };
}
