import AdminSecuritySvg from './AdminSecuritySvg';
import BulletListSvg from './BulletListSvg';
import BusinessSvg from './BusinessSvg';
import CakeSvg from './CakeSvg';
import CaretRightSvg from './CaretRightSvg';
import CaretLeftSvg from './CaretLeftSvg';
import CheckSvg from './CheckSvg';
import ClearSvg from './ClearSvg';
import DownloadSvg from './DownloadSvg';
import DraftsSvg from './DraftsSvg';
import EyeOffSvg from './EyeOffSvg';
import FilterAltSvg from './FilterAltSvg';
import FilterSvg from './FilterSvg';
import FolderSvg from './FolderSvg';
import MailOutlineSvg from './MailOutlineSvg';
import MailSvg from './MailSvg';
import MapPinSvg from './MapPinSvg';
import MenuSvg from './MenuSvg';
import MessagesSvg from './MessagesSvg';
import MobileSvg from './MobileSvg';
import PeopleSvg from './PeopleSvg';
import PersonCheckSvg from './PersonCheckSvg';
import PersonSvg from './PersonSvg';
import PhoneSvg from './PhoneSvg';
import PrivateInfoSvg from './PrivateInfoSvg';
import SearchSvg from './SearchSvg';
import SettingsSvg from './SettingsSvg';
import SortSvg from './SortSvg';
import SwitchSvg from './SwitchSvg';
import UploadSvg from './UploadSvg';
import UserSvg from './UserSvg';

import withIconProps from './withIconProps';


export const IconNames = {
  ADMIN_SECURITY: 'ADMIN_SECURITY',
  BULLET_LIST: 'BULLET_LIST',
  BUSINESS: 'BUSINESS',
  CAKE: 'CAKE',
  CARET_LEFT: 'CARET_LEFT',
  CARET_RIGHT: 'CARET_RIGHT',
  CHECK: 'CHECK',
  CLEAR: 'CLEAR',
  DOWNLOAD: 'DOWNLOAD',
  DRAFTS: 'DRAFTS',
  EYE_OFF: 'EYE_OFF',
  FILTER: 'FILTER',
  FILTER_ALT: 'FILTER_ALT',
  FOLDER: 'FOLDER',
  MAIL: 'MAIL',
  MAIL_OUTLINE: 'MAIL_OUTLINE',
  MAP_PIN: 'MAP_PIN',
  MENU_LINES: 'MENU_LINES',
  MESSAGES: 'MESSAGES',
  MOBILE: 'MOBILE',
  PEOPLE: 'PEOPLE',
  PERSON: 'PERSON',
  PERSON_CHECK: 'PERSON_CHECK',
  PHONE: 'PHONE',
  PRIVATE_INFO: 'PRIVATE_INFO',
  SEARCH: 'SEARCH',
  SETTINGS: 'SETTINGS',
  SORT: 'SORT',
  SWITCH: 'SWITCH',
  UPLOAD: 'UPLOAD',
  USER: 'USER',
};

export const Shapes = {
  [IconNames.ADMIN_SECURITY]: AdminSecuritySvg,
  [IconNames.BULLET_LIST]: BulletListSvg,
  [IconNames.BUSINESS]: BusinessSvg,
  [IconNames.CAKE]: CakeSvg,
  [IconNames.CARET_RIGHT]: CaretRightSvg,
  [IconNames.CARET_LEFT]: CaretLeftSvg,
  [IconNames.CHECK]: CheckSvg,
  [IconNames.CLEAR]: ClearSvg,
  [IconNames.DOWNLOAD]: DownloadSvg,
  [IconNames.DRAFTS]: DraftsSvg,
  [IconNames.EYE_OFF]: EyeOffSvg,
  [IconNames.FILTER]: FilterSvg,
  [IconNames.FILTER_ALT]: FilterAltSvg,
  [IconNames.FOLDER]: FolderSvg,
  [IconNames.MAP_PIN]: MapPinSvg,
  [IconNames.MAIL]: MailSvg,
  [IconNames.MAIL_OUTLINE]: MailOutlineSvg,
  [IconNames.MENU_LINES]: MenuSvg,
  [IconNames.MESSAGES]: MessagesSvg,
  [IconNames.MOBILE]: MobileSvg,
  [IconNames.PEOPLE]: PeopleSvg,
  [IconNames.PERSON]: PersonSvg,
  [IconNames.PERSON_CHECK]: PersonCheckSvg,
  [IconNames.PHONE]: PhoneSvg,
  [IconNames.PRIVATE_INFO]: PrivateInfoSvg,
  [IconNames.SEARCH]: SearchSvg,
  [IconNames.SETTINGS]: SettingsSvg,
  [IconNames.SORT]: SortSvg,
  [IconNames.SWITCH]: SwitchSvg,
  [IconNames.UPLOAD]: UploadSvg,
  [IconNames.USER]: UserSvg,
};
