import { call, put, take, select, spawn, all } from 'redux-saga/effects';



import { Entity } from '../../common/constants';

import { LOGIN_SUCCESS } from '../auth/constants';

import { getActiveDirectoryId } from '../directory/selectors';
import { fbDirectoryRequest } from '../directory/actions';
import { getToken } from '../auth/selectors';
import { getId, getUser } from '../user/selectors';
import { getAdminsRequest } from '../panel/actions';
import { fileDownload, FileUtils, RequestUtils } from '../../utils';
import {
  FireFunctions,
  functionNames,
  FireDb,
  PubsubService,
  pubsubTopics,
} from '../services';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import * as parse from './parse';

/**
 * Send credentials to the server to be verified and exchanged for tokens.
 * @param token {string} Auth token
 * @param userId {number} Admin's user id
 * @param userShortCode {string} Requested user's short code identifier
 */
export function* requestUserData(token, userId, userShortCode) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const response = yield call(api.requestUserData, token, userId, dirId, userShortCode);

    if (response.error) {
      yield put(actions.adminUserDataFailure(response.error.message));
    } else {
      const user = response.data;
      yield put(actions.adminUserDataSuccess(user));
    }
  } catch (error) {
    yield put(actions.adminUserDataFailure(error.message));
  }
}

/**
 * Save edited/updated user data.
 * @param token {string} Auth token
 * @param userId {number} Admin's user id
 * @param userShortCode {string} Requested user's short code identifier
 * @param updates {object} User data updates object
 */
export function* requestSaveUserData(token, userId, userShortCode, updates) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const response = yield call(
      api.requestSaveUserUpdates,
      token,
      userId,
      dirId,
      userShortCode,
      updates,
    );

    if (response.error) {
      yield put(actions.adminSaveUserDataFailure(response.error.message));
    } else {
      const { user } = response.data;
      yield put(actions.adminSaveUserDataSuccess(user));
    }
  } catch (error) {
    yield put(actions.adminSaveUserDataFailure(error.message));
  }
}

/**
 * Add a new user.
 * @param token {string} Auth token
 * @param userId {number} Admin's user id
 * @param memberData {object} New member data
 */
export function* requestAddMember(token, userId, memberData) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const response = yield call(
      api.requestAddMember,
      token,
      userId,
      dirId,
      memberData,
    );

    if (response.error) {
      yield put(actions.adminAddMemberFailure(response.error.message));
    } else {
      const { user } = response.data;
      yield put(actions.adminAddMemberSuccess(user));
    }
  } catch (error) {
    yield put(actions.adminAddMemberFailure(error.response.data.errorMessage));
  }
}

/**
 * Add multiple new user.
 * @param members {object} New members data
 */
export function* requestAddMultiMember(members) {
  try {
    const token = yield select(getToken);
    const userId = yield select(getId);
    const dirId = yield select(getActiveDirectoryId);

    const response = yield call(
      api.requestAddMultiMember,
      token,
      userId,
      dirId,
      members,
    );

    if (response.error) {
      yield put(actions.adminAddMultiMemberFailure(response.error.message));
    } else {
      const { users } = response.data;
      const userMap = parse.addedUsersMap(users);
      yield put(actions.adminAddMultiMemberSuccess(userMap));
    }
  } catch (error) {
    yield put(actions.adminAddMultiMemberFailure(
      error.response.data.errorMessage,
      error.response.data.errorCode,
      error.response.data.data,
    ));
  }
}

/**
 * Update multiple users.
 * @param updates {object[]} Array of members data objects.
 */
export function* requestUpdateMultiMember(updates) {
  try {
    const token = yield select(getToken);
    const userId = yield select(getId);
    const dirId = yield select(getActiveDirectoryId);

    const response = yield call(
      api.requestUpdateMembers,
      token,
      userId,
      dirId,
      updates,
    );

    if (response.error) {
      yield put(actions.adminUpdateMultiMemberFailure(response.error.message));
    } else {
      yield put(actions.adminUpdateMultiMemberSuccess());
    }
  } catch (error) {
    yield put(actions.adminUpdateMultiMemberFailure(
      error.response.data.errorMessage,
      error.response.data.errorCode,
      error.response.data.data,
    ));
  }
}

/**
 * Add a new group.
 * @param token {string} Auth token
 * @param userId {number} Admin's user id
 * @param groupData {object} New member data
 */
export function* requestAddGroup(token, userId, groupData) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const response = yield call(
      api.requestAddGroup,
      token,
      userId,
      dirId,
      groupData,
    );

    if (response.error) {
      yield put(actions.adminAddGroupFailure(response.error.message));
    } else {
      const { group } = response.data;
      yield put(actions.adminAddGroupSuccess(group));
    }
  } catch (error) {
    yield put(actions.adminAddGroupFailure(error.response.data.errorMessage));
  }
}

/**
 * Add a new family.
 * @param token {string} Auth token
 * @param userId {number} Admin's user id
 * @param familyData {object} New member data
 */
export function* requestAddFamily(token, userId, familyData) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const response = yield call(
      api.requestAddFamily,
      token,
      userId,
      dirId,
      familyData,
    );

    if (response.error) {
      yield put(actions.adminAddFamilyFailure(response.error.message));
    } else {
      const { family } = response.data;
      yield put(actions.adminAddFamilySuccess(family));
    }
  } catch (error) {
    yield put(actions.adminAddFamilyFailure(error.response.data.errorMessage));
  }
}

/**
 * Request a list of all custom fields.
 * @param token {string} Auth token
 * @param userId {number} Admin's user id
 */
export function* requestCustomFields(token, userId) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const response = yield call(api.requestCustomFields, token, userId, dirId);

    if (response.error) {
      yield put(actions.adminCustomFieldsFailure(response.error.message));
    } else {
      const fieldsData = response.data;
      const fieldsMap = parse.customFieldsMap(fieldsData);
      const fieldsIds = parse.customFieldsIds(fieldsData);

      yield put(actions.adminCustomFieldsSuccess(fieldsMap, fieldsIds));
    }
  } catch (error) {
    yield put(actions.adminCustomFieldsFailure(error.message));
  }
}

/**
 * Request a list of all directory data fields.
 */
export function* requestDirectoryFields() {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getDirectoryFields,
      payload,
    );

    const { results } = response.data;
    const { custom, internal } = parse.directoryFields(results);

    yield put(actions.adminDirectoryFieldsSuccess(custom, internal));
  } catch (error) {
    yield put(actions.adminDirectoryFieldsFailure(error.message));
  }
}

/**
 * Request a list of all user categories.
 */
export function* requestUserCategories() {
  try {
    // const dirId = yield select(getActiveDirectoryId);
    // const response = yield call(api.requestUserCategories, token, userId, dirId);
    const directoryId = yield select(getActiveDirectoryId);

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getDirectoryCategories,
      payload,
    );

    const { results } = response.data;

    yield put(actions.adminUserCatsSuccess(results));
  } catch (error) {
    yield put(actions.adminUserCatsFailure(error.message));
  }
}

/**
 * Request to update a custom field.
 * @param fieldId {number} Id of the custom field
 * @param updates {{}} Updated field data object
 */
export function* requestSaveCustomField(fieldId, updates) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(
      api.requestSaveCustomField, token, userId, dirId, fieldId, updates,
    );
    if (response.error) {
      yield put(actions.saveCustomFieldFailure(response.error.message));
    } else {
      const fieldData = response.data;
      yield put(actions.saveCustomFieldSuccess(fieldData));
    }
  } catch (error) {
    yield put(actions.saveCustomFieldFailure(error.message));
  }
}

/**
 * Request to update an internal field.
 * @param fieldId {number} Id of the internal field
 * @param updates {{}} Updated field data object
 */
export function* requestSaveInternalField(fieldId, updates) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(
      api.requestSaveInternalField, token, userId, dirId, fieldId, updates,
    );
    if (response.error) {
      yield put(actions.saveInternalFieldFailure(response.error.message));
    } else {
      const fieldData = response.data;
      yield put(actions.saveInternalFieldSuccess(fieldData));
    }
  } catch (error) {
    yield put(actions.saveInternalFieldFailure(error.message));
  }
}

/**
 * Request to update a user category.
 * @param catId {number} Id of the category
 * @param updates {{}} Updated category data object
 */
export function* requestSaveCategory(catId, updates) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(api.requestSaveCategory, token, userId, dirId, catId, updates);
    if (response.error) {
      yield put(actions.saveCategoryFailure(response.error.message));
    } else {
      const catData = response.data;
      yield put(actions.saveCategorySuccess(catData));
    }
  } catch (error) {
    yield put(actions.saveCategoryFailure(error.message));
  }
}

/**
 * Request to delete a custom field.
 * @param fieldId {number} Id of the custom field
 */
export function* requestDeleteCustomField(fieldId) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(api.requestDeleteCustomField, token, userId, dirId, fieldId);
    if (response.error) {
      yield put(actions.deleteCustomFieldFailure(response.error.message));
    } else {
      yield put(actions.deleteCustomFieldSuccess(fieldId));
    }
  } catch (error) {
    yield put(actions.deleteCustomFieldFailure(error.message));
  }
}

/**
 * Request to delete an internal field.
 * @param fieldId {number} Id of the internal field
 */
export function* requestDeleteInternalField(fieldId) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(api.requestDeleteInternalField, token, userId, dirId, fieldId);
    if (response.error) {
      yield put(actions.deleteInternalFieldFailure(response.error.message));
    } else {
      yield put(actions.deleteInternalFieldSuccess(fieldId));
    }
  } catch (error) {
    yield put(actions.deleteInternalFieldFailure(error.message));
  }
}

/**
 * Request to delete a user category.
 * @param catId {number} Id of the category
 */
export function* requestDeleteCategory(catId) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(api.requestDeleteCategory, token, userId, dirId, catId);
    if (response.error) {
      yield put(actions.deleteCategoryFailure(response.error.message));
    } else {
      yield put(actions.deleteCategorySuccess(catId));
    }
  } catch (error) {
    yield put(actions.deleteCategoryFailure(error.message));
  }
}

/**
 * Request to create a custom field.
 * @param data {{}} Data for the custom field
 */
export function* requestCreateCustomField(data) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(api.requestCreateCustomField, token, userId, dirId, data);
    if (response.error) {
      yield put(actions.createCustomFieldFailure(response.error.message));
    } else {
      const field = response.data;
      yield put(actions.createCustomFieldSuccess(field));
    }
  } catch (error) {
    yield put(actions.createCustomFieldFailure(error.message));
  }
}

/**
 * Request to create an internal field.
 * @param data {{}} Data for the internal field
 */
export function* requestCreateInternalField(data) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(api.requestCreateInternalField, token, userId, dirId, data);
    if (response.error) {
      yield put(actions.createInternalFieldFailure(response.error.message));
    } else {
      const field = response.data;
      yield put(actions.createInternalFieldSuccess(field));
    }
  } catch (error) {
    yield put(actions.createInternalFieldFailure(error.message));
  }
}

/**
 * Request to delete a user category.
 * @param data {number} Id of the category
 */
export function* requestCreateCategory(data) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);
    const userId = yield select(getId);

    const response = yield call(api.requestCreateCategory, token, userId, dirId, data);
    if (response.error) {
      yield put(actions.createCategoryFailure(response.error.message));
    } else {
      const category = response.data;
      yield put(actions.createCategorySuccess(category));
    }
  } catch (error) {
    yield put(actions.createCategoryFailure(error.message));
  }
}

/**
 * Request all sent messages.
 * @param fromDate {?string} Beginning of requested date range
 * @param toDate {?string} End of requested date range
 */
export function* requestSentMessages(fromDate, toDate) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const user = yield select(getUser);
    const userId = user ? user.id : null;

    if (!userId) {
      yield put(actions.allSentMessagesFailure('No user'));
      return;
    }

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getSentMessages,
      payload,
    );

    const { error, results } = response.data;

    const userMessages = Object.keys(results || {}).reduce((obj, id) => {
      const message = results[id];

      return {
        ...obj,
        [id]: parse.message(id, message),
      };
    }, {});

    if (error) {
      yield put(actions.allSentMessagesFailure(error.message, error));
    } else {
      yield put(actions.allSentMessagesSuccess(userMessages));
    }
  } catch (error) {
    yield put(actions.allSentMessagesFailure(error.message, error));
  }
}

/**
 * Request all saved message drafts.
 * @param fromDate {?string} Beginning of requested date range
 * @param toDate {?string} End of requested date range
 */
export function* requestMessageDrafts(fromDate, toDate) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const user = yield select(getUser);
    const userId = user.id;

    const result = yield call(
      FireDb.getUserMessageDrafts,
      userId,
      directoryId,
    );

    const messageDrafts = parse.messageDrafts(result);

    if (result && result.error) {
      yield put(actions.messageDraftsFailure(result.error.message, result.error));
    } else {
      yield put(actions.messageDraftsSuccess(messageDrafts));
    }
  } catch (error) {
    yield put(actions.messageDraftsFailure(error.message, error));
  }
}

/**
 * Request csv data export.
 * @param exportType {ExportDataType}
 * @param exportOptions {?CsvDataExportOptions}
 * @param downloadOnComplete {?boolean}
 */
export function* requestCsvExport(
  exportType,
  exportOptions,
  downloadOnComplete = true,
) {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    const response = yield FireFunctions.httpsCallable(
      functionNames.exportData,
      { directoryId, exportType },
    );

    if (response.error) {
      yield put(actions.csvExportFailure(response.error.message));
    } else {
      const { data } = response;

      if (data && data.results && downloadOnComplete) {
        if (data.results.documents) {
          const zipBlob = yield FileUtils.zipDocuments(data.results.documents);
          FileUtils.saveBlob(zipBlob, 'exports.zip');
        } else {
          const filename = exportType.toLowerCase();
          fileDownload(data.results, `${filename}.csv`);
        }
      }

      yield put(actions.csvExportSuccess());
    }
  } catch (error) {
    yield put(actions.csvExportFailure(error.message));
  }
}

/**
 * Request csv data export.
 * @param type {ImportDataType}
 * @param data {*}
 */
export function* requestCsvImport(type, data) {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    const payload = RequestUtils.getReqPayload(directoryId, { data, type });

    let fnName;

    if (type === Entity.PERSON) {
      fnName = functionNames.importMembersFromCsv;
    }

    if (!fnName) {
      yield put(actions.csvImportFailure('No import type was provided'));

      return;
    }

    const response = yield FireFunctions.httpsCallable(
      fnName,
      payload,
    );

    if (response.error) {
      yield put(actions.csvExportFailure(response.error.message));
    } else {
      yield put(actions.csvImportSuccess());
    }
  } catch (error) {
    yield put(actions.csvExportFailure(error.message));
  }
}

/**
 * Request admin or other special access to be granted to a user.
 * @param memberId {string} Id of member to grant the access to
 * @param accessRoles {string[]} Access roles to grant
 */
export function* requestChangePermissions(
  memberId, accessRoles,
) {
  try {
    const dirId = yield select(getActiveDirectoryId);

    if (accessRoles.length) {
      const response = yield call(
        FireFunctions.httpsCallable,
        functionNames.changePermissions,
        { memberId, accessRoles, directoryId: dirId },
      );

      if (response.data && response.data.success) {
        PubsubService.publish(pubsubTopics.NOTIFS, {
          message: 'Permissions successfully updated!',
        });

        yield all([
          put(actions.changePermissionsSuccess(memberId)),
          put(getAdminsRequest()),
          put(fbDirectoryRequest()),
        ]);
      } else {
        PubsubService.publish(pubsubTopics.NOTIFS, {
          message: 'Permissions update unsuccessful. Please try again in a moment, and contact us if the issue persists.',
          type: 'error',
        });
        yield put(actions.changePermissionsFailure('Unsuccessful request'));
      }
    }
  } catch (error) {
    yield put(actions.changePermissionsFailure(error.message, error));
  }
}

/**
 * Request sending either a text or an email.
 * @param {Object} data
 * @property types {string[]} 'EMAIL', 'SMS'... message types
 * @property people {string[]} Id of the recipient
 * @property families {string[]} Id of the recipient
 * @property groups {string[]} Id of the recipient
 * @property categories {string} Id of the recipient
 * @property subject {string} Subject line
 * @property message {string} Message body
 */
export function* requestSendMessage(data) {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    const payload = RequestUtils.getEntityReqPayload(directoryId, data);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.sendMessage,
      payload,
    );

    const { results } = response.data;

    yield put(actions.sendMessageSuccess(results));
  } catch (error) {
    yield put(actions.sendMessageFailure(error.message, error));
  }
}

/**
 * Request sending either a text or an email.
 * @param {Object} messageData
 * @property types {string[]} 'EMAIL', 'SMS'... message types
 * @property people {string[]} People ids
 * @property groups {string[]} Group ids
 * @property families {string[]} family ids
 * @property categories {string[]} Category ids
 * @property subject {string} Subject line
 * @property message {string} Message body
 */
export function* requestSaveDraft(messageData) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const user = yield select(getUser);

    const result = yield call(
      FireFunctions.httpsCallable,
      functionNames.saveMessageDraft,
      { directoryId, userId: user.id, ...messageData },
    );

    if (result.error) {
      yield put(actions.saveMessageDraftFailure(result.error.message, result.error));
    } else {
      yield put(actions.saveMessageDraftSuccess());
    }
  } catch (error) {
    yield put(actions.saveMessageDraftFailure(error.message, error));
  }
}

/**
 * Request all fields available to include in members' data exports.
 */
export function* requestMemberExportFields() {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getMemberExportFields,
      { directoryId },
    );

    if (response.error) {
      yield put(actions.memberExportFieldsFailure(response.error.message, response.error));
    } else {
      const fields = response.data;
      yield put(actions.memberExportFieldsSuccess(fields));
    }
  } catch (error) {
    yield put(actions.memberExportFieldsFailure(error.message, error));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.ADMIN_ADD_FAMILY_REQUEST,
      constants.ADMIN_ADD_GROUP_REQUEST,
      constants.ADMIN_ADD_MEMBER_REQUEST,
      constants.ADMIN_ADD_MULTI_MEMBER_REQUEST,
      constants.ADMIN_CHANGE_PERMS_REQUEST,
      constants.ADMIN_CREATE_CATEGORY_REQUEST,
      constants.ADMIN_CREATE_CUSTOM_FIELD_REQUEST,
      constants.ADMIN_CREATE_INTERNAL_FIELD_REQUEST,
      constants.ADMIN_CSV_EXPORT_REQUEST,
      constants.ADMIN_CSV_IMPORT_REQUEST,
      constants.ADMIN_DELETE_CATEGORY_REQUEST,
      constants.ADMIN_DELETE_CUSTOM_FIELD_REQUEST,
      constants.ADMIN_DELETE_INTERNAL_FIELD_REQUEST,
      constants.ADMIN_DIR_FIELDS_REQUEST,
      constants.ADMIN_MEMBER_EXPORT_FIELDS_REQUEST,
      constants.ADMIN_MESSAGE_DRAFTS_REQUEST,
      constants.ADMIN_SAVE_CATEGORY_REQUEST,
      constants.ADMIN_SAVE_CUSTOM_FIELD_REQUEST,
      constants.ADMIN_SAVE_DRAFT_REQUEST,
      constants.ADMIN_SAVE_INTERNAL_FIELD_REQUEST,
      constants.ADMIN_SAVE_USER_DATA_REQUEST,
      constants.ADMIN_SEND_MESSAGE_REQUEST,
      constants.ADMIN_SENT_MESSAGES_REQUEST,
      constants.ADMIN_UPDATE_MULTI_MEMBER_REQUEST,
      constants.ADMIN_USER_CATS_REQUEST,
      constants.ADMIN_USER_DATA_REQUEST,
      LOGIN_SUCCESS,
    ]);

    switch (type) {
      case LOGIN_SUCCESS:
        if (payload.userRole === 'ADMIN') {
          yield spawn(requestCustomFields, payload.token, payload.userId);
        }
        break;

      case constants.ADMIN_USER_DATA_REQUEST:
        yield spawn(requestUserData, payload.token, payload.userId, payload.userShortCode);
        break;

      case constants.ADMIN_SAVE_USER_DATA_REQUEST:
        yield spawn(
          requestSaveUserData,
          payload.token,
          payload.userId,
          payload.userShortCode,
          payload.updates,
        );
        break;

      case constants.ADMIN_ADD_MEMBER_REQUEST:
        yield spawn(requestAddMember, payload.token, payload.userId, payload.memberData);
        break;

      case constants.ADMIN_ADD_MULTI_MEMBER_REQUEST:
        yield spawn(requestAddMultiMember, payload.members);
        break;

      case constants.ADMIN_UPDATE_MULTI_MEMBER_REQUEST:
        yield spawn(requestUpdateMultiMember, payload.updates);
        break;

      case constants.ADMIN_ADD_FAMILY_REQUEST:
        yield spawn(requestAddFamily, payload.token, payload.userId, payload.familyData);
        break;

      case constants.ADMIN_ADD_GROUP_REQUEST:
        yield spawn(requestAddGroup, payload.token, payload.userId, payload.groupData);
        break;

      case constants.ADMIN_DIR_FIELDS_REQUEST:
        yield spawn(requestDirectoryFields);
        break;

      case constants.ADMIN_USER_CATS_REQUEST:
        yield spawn(requestUserCategories, payload.token, payload.userId);
        break;

      case constants.ADMIN_SAVE_CUSTOM_FIELD_REQUEST:
        yield spawn(
          requestSaveCustomField,
          payload.id,
          payload.updates,
        );
        break;

      case constants.ADMIN_SAVE_INTERNAL_FIELD_REQUEST:
        yield spawn(
          requestSaveInternalField,
          payload.id,
          payload.updates,
        );
        break;

      case constants.ADMIN_SAVE_CATEGORY_REQUEST:
        yield spawn(
          requestSaveCategory,
          payload.id,
          payload.updates,
        );
        break;

      case constants.ADMIN_DELETE_CUSTOM_FIELD_REQUEST:
        yield spawn(
          requestDeleteCustomField,
          payload.id,
        );
        break;

      case constants.ADMIN_DELETE_INTERNAL_FIELD_REQUEST:
        yield spawn(
          requestDeleteInternalField,
          payload.id,
        );
        break;

      case constants.ADMIN_DELETE_CATEGORY_REQUEST:
        yield spawn(
          requestDeleteCategory,
          payload.id,
        );
        break;

      case constants.ADMIN_CREATE_CUSTOM_FIELD_REQUEST:
        yield spawn(
          requestCreateCustomField,
          payload.data,
        );
        break;

      case constants.ADMIN_CREATE_INTERNAL_FIELD_REQUEST:
        yield spawn(
          requestCreateInternalField,
          payload.data,
        );
        break;

      case constants.ADMIN_CREATE_CATEGORY_REQUEST:
        yield spawn(
          requestCreateCategory,
          payload.data,
        );
        break;

      case constants.ADMIN_SENT_MESSAGES_REQUEST:
        yield spawn(
          requestSentMessages,
          payload.fromDate,
          payload.toDate,
        );
        break;

      case constants.ADMIN_CSV_EXPORT_REQUEST:
        yield spawn(
          requestCsvExport,
          payload.type,
          payload.exportOptions,
          payload.downloadOnComplete,
        );
        break;

      case constants.ADMIN_CSV_IMPORT_REQUEST:
        yield spawn(
          requestCsvImport,
          payload.type,
          payload.data,
        );
        break;

      case constants.ADMIN_CHANGE_PERMS_REQUEST:
        yield spawn(
          requestChangePermissions,
          payload.memberId,
          payload.accessRoles,
        );
        break;

      case constants.ADMIN_SET_ACCOUNT_STATUS_REQUEST:
        yield spawn(
          requestChangePermissions,
          payload.directoryId,
          payload.memberId,
          payload.accessRoles,
        );
        break;

      case constants.ADMIN_SEND_MESSAGE_REQUEST:
        yield spawn(
          requestSendMessage,
          payload,
        );
        break;

      case constants.ADMIN_MESSAGE_DRAFTS_REQUEST:
        yield spawn(
          requestMessageDrafts,
        );
        break;

      case constants.ADMIN_SAVE_DRAFT_REQUEST:
        yield spawn(
          requestSaveDraft,
          payload,
        );
        break;

      case constants.ADMIN_MEMBER_EXPORT_FIELDS_REQUEST:
        yield spawn(
          requestMemberExportFields,
        );
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
