import PropTypes from 'prop-types';
import React from 'react';


const Mobile = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M29.08,0,10.9,0A3.64,3.64,0,0,0,7.28,3.64V36.36A3.64,3.64,0,0,0,10.9,40H29.08a3.65,3.65,0,0,0,3.64-3.64V3.64A3.64,3.64,0,0,0,29.08,0Zm0,32.71H10.9V7.27H29.08Z"
  />
);

Mobile.propTypes = {
  strokeWidth: PropTypes.number,
};

Mobile.defaultProps = {
  strokeWidth: 1,
};


export default Mobile;
