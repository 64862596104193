import React from 'react';
import { Button } from 'antd';

import SmallLoader from '../SmallLoader';

class LoadingButton extends React.Component {
  render() {
    const {
      isLoading,
      children,
      ...rest
    } = this.props;

    return (
      <Button {...rest}>
        {isLoading ?
          <SmallLoader show={true} /> : children}
      </Button>
    );
  }
}

export default LoadingButton;
