import _ from 'lodash';

export const parseAddressFromObject = (addressObj) => {
  let address = '';

  const s1 = _.get(addressObj, 'street1');
  const s2 = _.get(addressObj, 'street2');
  const c = _.get(addressObj, 'city');
  const s = _.get(addressObj, 'state');
  const z = _.get(addressObj, 'zip');

  if (s1 && s1.length) address += s1;
  if (s2 && s2.length) address = `${address}${address.length ? ',' : ''} ${s2}`;
  if (c && c.length) address = `${address}${address.length ? ',' : ''} ${c}`;
  if (s && s.length) address = `${address}${address.length ? ',' : ''} ${s}`;
  if (z && z.length) address = `${address} ${z}`;

  return address;
};

export const parseAddressFromObjectToMapsUrl = (addressObj) => {
  const baseUrl = 'https://www.google.com/maps/place';
  let path = '';

  const s1 = _.get(addressObj, 'street1');
  const s2 = _.get(addressObj, 'street2');
  const c = _.get(addressObj, 'city');
  const s = _.get(addressObj, 'state');
  const z = _.get(addressObj, 'zip');

  if (s1 && s1.length) path = `${path}${encodeURIComponent(s1)}`;
  if (s2 && s2.length) path = `${path}+${encodeURIComponent(s2)}`;
  if (c && c.length) path = `${path}+${encodeURIComponent(c)}`;
  if (s && s.length) path = `${path}+${encodeURIComponent(s)}`;
  if (z && z.length) path = `${path}+${encodeURIComponent(z)}`;

  return `${baseUrl}/${path}`;
};

export const parseAddressFromObjectToDisplayObject = (addressObj) => {
  const address = {
    cityLine: undefined,
    country: _.get(addressObj, 'country'),
    street1: _.get(addressObj, 'street1'),
    street2: _.get(addressObj, 'street2'),
  };

  const c = _.get(addressObj, 'city');
  const s = _.get(addressObj, 'state');
  const z = String(_.get(addressObj, 'zip') || '');

  let cityLine = '';

  if (c && c.length) {
    cityLine += c;
  }

  if (s && s.length) {
    cityLine = `${cityLine}${cityLine.length ? ',' : ''} ${s}`;
  }

  if (z && z.length) {
    cityLine = `${cityLine}${cityLine.length ? ' ' : ''}${z}`;
  }

  address.cityLine = cityLine.length ? cityLine : undefined;

  return address;
};

export const parsePhoneFromObject = (phoneObj) => {
  const p = _.get(phoneObj, 'number');

  if (p && p.length) return p;

  return '';
};

export const parseMemberPhoneFromObjects = (
  cellPhone,
  homePhone,
  workPhone,
) => {
  const cp = _.get(cellPhone, 'number');
  const hp = _.get(homePhone, 'number');
  const wp = _.get(workPhone, 'number');

  if (cp && cp.length) return cp;
  if (hp && hp.length) return hp;
  if (wp && wp.length) return wp;

  return '';
};

export const parseMemberFamiliesDescriptionFromConfigs = (
  familyLinkConfigs,
) => {
  let desc = '';

  if (familyLinkConfigs.length) {
    desc = `${familyLinkConfigs[0].name} Family`;
    if (familyLinkConfigs.length > 1) {
      desc += ', +';
    }
  }

  return desc;
};

export const parseCustomFields = (customFields) => {
  const keys = Object.keys(customFields);
  return keys.map(k => ({
    customFieldId: customFields[k].value.id,
    displayName: customFields[k].value.displayName,
    value: customFields[k].text,
    visible: true,
  }));
};

export const parseInternalFields = (internalFields) => {
  const keys = Object.keys(internalFields);
  return keys.map(k => ({
    internalFieldId: internalFields[k].value.id,
    displayName: internalFields[k].value.displayName,
    value: internalFields[k].text,
    visible: true,
  }));
};

export const parseMemberNames = (member) => {
  const firstName = _.startCase(member.firstName);
  const lastName = _.startCase(member.lastName);
  const prefix = _.startCase(member.prefix);
  const middleName = _.startCase(member.middleName);
  const suffix = _.startCase(member.suffix);

  return {
    firstName,
    lastName,
    suffix,
    prefix,
    middleName,
  };
};

export const parseMemberExportFieldsOneLevelDeep = (fields) => {
  const keys = Object.keys(fields);
  return keys.reduce((arr, k) => {
    if (_.isString(fields[k])) {
      return [...arr, fields[k]];
    }

    if (_.isObject(fields[k]) && fields[k].subfields) {
      const nextLevel = parseMemberExportFieldsOneLevelDeep(fields[k].subfields);
      return [...arr, nextLevel];
    }

    return arr;
  }, []);
};
