import eq from 'lodash/isEqual';

import * as constants from 'modules/core/constants';


export const isAppearanceDefault = (appearance) => (
  eq(appearance, constants.Appearance.DEFAULT)
);

export const isAppearanceGhost = (appearance) => (
  eq(appearance, constants.Appearance.GHOST)
);

export const isAppearanceNone = (appearance) => (
  eq(appearance, constants.Appearance.NONE)
);
