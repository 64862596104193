import PropTypes from 'prop-types';
import React from 'react';


const Business = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M20,10V2H0V38H40V10ZM8,34H4V30H8Zm0-8H4V22H8Zm0-8H4V14H8Zm0-8H4V6H8Zm8,24H12V30h4Zm0-8H12V22h4Zm0-8H12V14h4Zm0-8H12V6h4ZM36,34H20V30h4V26H20V22h4V18H20V14H36ZM32,18H28v4h4Zm0,8H28v4h4Z"
  />
);

Business.propTypes = {
  strokeWidth: PropTypes.number,
};

Business.defaultProps = {
  strokeWidth: 1,
};


export default Business;
