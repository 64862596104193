import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'rebass';

import { propTypeHelpers } from 'helpers';

import { ElementShape } from '../../constants';


const Avatar = ({ alt, shape, size, src, sx }) => {
  let width = size;
  let height = size;

  if (Array.isArray(size)) {
    [width, height] = size;
  }

  return (
    <Image
      alt={alt}
      src={src}
      sx={{
        flexShrink: '0',
        borderRadius: shape === ElementShape.RECT ? 'image' : undefined,
        height,
        width,
        maxWidth: 'unset',
        maxHeight: 'unset',
        objectFit: 'cover',
        ...sx,
      }}
      variant="avatar"
    />
  );
};

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  shape: PropTypes.oneOf(Object.values(ElementShape)),
  size: propTypeHelpers.oneOfOrArrayOfPropType(PropTypes.number, PropTypes.string),
  src: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

Avatar.defaultProps = {
  shape: ElementShape.RECT,
  size: undefined,
  sx: {},
};


export default Avatar;
