import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Loader } from 'modules/core/components';

import * as colors from 'modules/core/colors';


const withIsFetchingLoader = (Component) => {
  const ComponentWithIsFetchingLoader = ({ isFetching, ...rest }) => {
    const wrapperSx = {
      marginTop: 'grid32',
      paddingTop: 'grid64',
    };

    if (!isFetching) {
      wrapperSx.display = 'none';
      wrapperSx.paddingTop = 'grid0';
      wrapperSx.marginTop = '0';
    }

    const loader = (
      <Flex
        justifyContent="center"
        sx={wrapperSx}
      >
        <Loader
          color={colors.mamba}
          isFetching={isFetching}
        />
      </Flex>
    );

    return (
      <Component isFetching={isFetching} {...rest} loader={loader} />
    );
  };

  ComponentWithIsFetchingLoader.propTypes = {
    ...(Component.propTypes || {}),
    isFetching: PropTypes.bool,
  };

  ComponentWithIsFetchingLoader.defaultProps = {
    ...(Component.defaultProps || {}),
    isFetching: undefined,
  };

  return ComponentWithIsFetchingLoader;
};

export default withIsFetchingLoader;
