import PropTypes from 'prop-types';
import React from 'react';

import { useMqlListener } from 'hooks';

import Flex from '../Flex';

import { ListItem } from './components';

import * as styles from './styles';


const gridSizes = { tablet: 3, laptop: 4, desktop: 5 };

/**
 * A list of a type of entity, e.g, person, family, group.
 */
const EntityList = (props) => {
  const {
    avatarSize,
    forceListStyle,
    isStriped,
    showCarets,
    items,
  } = props;

  const background = isStriped ? 'background' : 'transparent';
  const foreground = isStriped ? 'foreground' : 'transparent';

  return (
    <Flex
      className="j-entity-list"
      sx={styles.getEntityListStyles({ forceListStyle })}
    >
      {items.map(({ key, ...item }, index) => (
        <ListItem
          {...item}
          avatarSx={{ width: avatarSize, height: avatarSize }}
          forceListStyle={forceListStyle}
          key={key}
          showCaret={showCarets}
          sx={styles.getListItemStyles({ background, foreground, forceListStyle, index, isStriped })}
        />
      ))}
    </Flex>
  );
};

EntityList.propTypes = {
  avatarSize: PropTypes.any,
  forceListStyle: PropTypes.bool,
  isStriped: PropTypes.bool,
  showCarets: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    AvatarComponent: PropTypes.elementType,
    href: PropTypes.string,
    imageUrl: PropTypes.string,
    key: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  })),
};

EntityList.defaultProps = {
  avatarSize: undefined,
  forceListStyle: undefined,
  isStriped: true,
  showCarets: true,
  items: [],
};


export default EntityList;
