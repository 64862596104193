import { messageComposeStates } from 'modules/admin/constants';

import { initialState as membersInitialState } from 'modules/members/reducer';
import { initialState as familiesInitialState } from 'modules/families/reducer';
import { initialState as filtersInitialState } from 'modules/filters/reducer';
import { initialState as groupsInitialState } from 'modules/groups/reducer';
import { initialState as messagesInitialState } from 'modules/messages/reducer';


export default {
  0: state => ({
    ...state,
    admin: {
      ...state.admin,
      exports: {
        exportDataType: undefined,
        data: undefined,
        isFetching: state.admin.exports ? state.admin.exports.isFetching : false,
        memberFields: state.admin.exports ? state.admin.exports.memberFields : {},
      },
    },
  }),
  1: state => ({
    ...state,
    admin: {
      ...state.admin,
      exports: {
        errorMessage: null,
        isFetching: state.admin.exports ? state.admin.exports.isFetching : false,
        memberFields: state.admin.exports ? state.admin.exports.memberFields : {},
      },
    },
  }),
  2: state => ({
    ...state,
    form: {
      ...(state.form || {}),
    },
  }),
  // 6/24/19 add to dir settings
  3: state => ({
    ...state,
    panel: {
      ...state.panel,
      directorySettings: {
        ...state.panel.directorySettings,
        defaultCountryCode: {
          code: null,
          alpha2: null,
        },
      },
    },
  }),
  // 6/24/19 add user led families/groups
  4: state => ({
    ...state,
    user: {
      ...state.user,
      ledGroups: {},
      ledFamilies: {},
    },
  }),
  // 8/24/19 remove members (separating persist configs)
  5: state => ({
    ...state,
    members: {
      ...membersInitialState,
    },
  }),
  // 9/3/19 remove families (separating persist configs)
  6: state => ({
    ...state,
    families: {
      ...familiesInitialState,
    },
  }),
  // 9/3/19 remove groups (separating persist configs)
  7: state => ({
    ...state,
    groups: {
      ...groupsInitialState,
    },
  }),
  8: state => ({
    ...state,
    admin: {
      ...state.admin,
      saveDraftState: messageComposeStates.IDLE,
      saveDraftFetching: undefined,
    },
  }),
  9: state => ({
    ...state,
    directory: {
      ...state.directory,
      config: {
        email: false,
        isFetching: false,
        sms: false,
      },
    },
  }),
  10: state => ({
    ...state,
    panel: {
      ...state.panel,
      data: {
        isUpdatingFields: false,
      },
    },
  }),
  11: state => ({
    ...state,
    panel: {
      ...state.panel,
      data: {
        ...state.panel.data,
        isUpdatingCategories: false,
      },
    },
  }),
  12: state => ({
    ...state,
    panel: {
      ...state.panel,
      directorySettings: {
        ...state.panel.directorySettings,
        defaultBasicsVisible: false,
        defaultPhoneVisible: false,
        defaultAddressVisible: false,
      },
    },
  }),
  13: state => ({
    ...state,
    admin: {
      ...state.admin,
      imports: {
        isFetching: false,
        errorMessage: null,
      },
    },
  }),
  // add panel.admins.isUpdating
  14: state => ({
    ...state,
    panel: {
      ...state.panel,
      admins: {
        ...state.panel.admins,
        isUpdating: false,
      },
    },
  }),
  // move messages to separate state
  15: state => ({
    ...state,
    admin: {
      ...state.admin,
      messages: null,
      messagesFetching: null,
      messageDrafts: null,
      draftsFetching: null,
      saveDraftState: null,
      composeState: null,
    },
    messages: messagesInitialState,
  }),
  // add new top-level state object for filters
  16: state => ({
    ...state,
    filters: filtersInitialState,
  }),
};
