import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// redux state migrations
import migrations from 'modules/root/migrations';
import membersMigrations from 'modules/root/members_migrations';
import familiesMigrations from 'modules/root/families_migrations';
import groupsMigrations from 'modules/root/groups_migrations';

const appEnv = process.env.APP_ENV;

const persistKey = appEnv === 'staging' ? 'staging-root1' : 'root1';

export const defaultPersistConfig = {
  key: persistKey,
  version: 16,
  stateReconciler: autoMergeLevel2,
  storage,
  blacklist: ['members', 'families', 'groups', 'search'],
  migrate: createMigrate(migrations, { debug: false }),
};

export const membersPersistConfig = {
  key: 'members',
  version: 5,
  stateReconciler: autoMergeLevel2,
  storage,
  blacklist: ['members', 'memberIds', 'personSummaries', 'lastFetched', 'isFetching'],
  migrate: createMigrate(membersMigrations, { debug: false }),
};

export const familiesPersistConfig = {
  key: 'families',
  version: 5,
  stateReconciler: autoMergeLevel2,
  storage,
  blacklist: ['families', 'familyIds', 'familySummaries', 'lastFetched', 'isFetching'],
  migrate: createMigrate(familiesMigrations, { debug: false }),
};

export const groupsPersistConfig = {
  key: 'groups',
  version: 5,
  stateReconciler: autoMergeLevel2,
  storage,
  blacklist: ['groups', 'groupIds', 'groupsSummaries', 'lastFetched', 'isFetching'],
  migrate: createMigrate(groupsMigrations, { debug: false }),
};
