import PropTypes from 'prop-types';
import React from 'react';


const Phone = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M7.87,4.44a26.7,26.7,0,0,0,1,5.76L6.2,12.87A33,33,0,0,1,4.51,4.44H7.87M29.78,31.16a29,29,0,0,0,5.78,1v3.31a34.23,34.23,0,0,1-8.45-1.67l2.67-2.64M10,0H2.22A2.23,2.23,0,0,0,0,2.22,37.77,37.77,0,0,0,37.78,40,2.23,2.23,0,0,0,40,37.78V30a2.23,2.23,0,0,0-2.22-2.22,25.15,25.15,0,0,1-7.94-1.27,1.8,1.8,0,0,0-.68-.11,2.24,2.24,0,0,0-1.58.65L22.69,32A33.72,33.72,0,0,1,8,17.31l4.89-4.89a2.22,2.22,0,0,0,.56-2.26,25.35,25.35,0,0,1-1.27-7.94A2.23,2.23,0,0,0,10,0Z"
  />
);

Phone.propTypes = {
  strokeWidth: PropTypes.number,
};

Phone.defaultProps = {
  strokeWidth: 1,
};


export default Phone;
