import React from 'react';

import {
  sortedCountries,
  countriesMap,
} from 'modules/definitions/constants';

import Box from '../Box';
import Flex from '../Flex';
import { MultiSelection } from '../MultiSelection';


class CountryProvinceSelect extends React.Component {
  selectRef = null;

  componentDidMount() {
    const { countryName, countryValue, onChangeCountry, provinces } = this.props;
    if (countryValue && !provinces.length) {
      onChangeCountry(countryValue, countryName);
    }
  }

  render() {
    let {
      countryValue,
      provinceValue,
      onChangeCountry,
      onChangeProvince,
      isDisabled,
      provinces,
      countryName,
      provinceName,
    } = this.props;

    const isLoading = Boolean((
      countryValue && countryValue.length)
      && !provinces.length
      && (!provinceValue || !provinceValue.length),
    );

    if ( countryValue.value && countryValue.label ) {
      countryValue = countryValue.value
    }

    const selectedCountry = countryValue ? {
      value: countriesMap[countryValue].alpha2,
      label: countriesMap[countryValue].name,
    } : null;

    let selectedProvince;
    if ( provinceValue.value && provinceValue.label ) {
      selectedProvince = provinceValue;
    } else {
      selectedProvince = provinceValue ? { value: provinceValue, label: provinceValue } : null;
    }

    if (!selectedProvince && this.selectRef) {
      this.selectRef.state.selectedItems = null;
    }

    const wrapperSx = { width: '100%' };

    return (
      <Flex sx={{ width: '100%', flexWrap: 'wrap' }}>
        <Box
          sx={{
            ...wrapperSx,
            marginBottom: 'grid8',
          }}
        >
          <MultiSelection
            isClearable={Boolean(countryValue && countryValue.length > 0)}
            isCreatable={false}
            isMulti={false}
            name={countryName}
            onChange={(val, name, prevVal, action) => onChangeCountry(val, countryName, action)}
            options={sortedCountries.map((c) => ({
              value: c.alpha2,
              label: c.name,
            }))}
            placeholder="Country"
            value={selectedCountry}
          />
        </Box>
        <Box
          sx={wrapperSx}
        >
          <MultiSelection
            isClearable={Boolean(provinceValue && provinceValue.length > 0)}
            isCreatable={false}
            isDisabled={!(countryValue && countryValue.length) || isLoading}
            isMulti={false}
            name={provinceName}
            onChange={(val) => onChangeProvince(val, provinceName)}
            options={provinces.map((p) => ({
              value: p,
              label: p,
            }))}
            placeholder="State/province"
            value={selectedProvince}
            ref={ref => {
              this.selectRef = ref;
            }}
          />
        </Box>
      </Flex>
    );
  }
}


export default CountryProvinceSelect;
