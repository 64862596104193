import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { getRole, getUser } from 'modules/user/selectors';
import { getDirectories, getActiveDirectoryId } from 'modules/directory/selectors';
import { fbDirectoryRequest } from 'modules/directory/actions';

import { getAppRoutes } from '../../selectors';

import presenter from './RoutesBlockLayout';

const mapStateToProps = createStructuredSelector({
  appRoutes: getAppRoutes,
  role: getRole,
  directoryId: getActiveDirectoryId,
  user: getUser,
  directories: getDirectories,
});

const mapDispatchToProps = {
  onDirectoryRequest: fbDirectoryRequest,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(presenter);
