import * as selectors from 'modules/families/selectors';
import { call, put, take, select, all, spawn } from 'redux-saga/effects';

import { FireDb, FireFunctions, functionNames, navService } from 'modules/services';
import { RequestUtils } from 'utils';

import { statusConstants } from '../../constants';

import { getId } from '../user/selectors';
import { fbUserTreeRequest } from '../user/actions';
import { fbFamiliesRequest, familySummariesRequest } from '../families/actions';
import { fbGroupsRequest, groupsSummariesRequest } from '../groups/actions';
import { fbMembersRequest, getPersonSummariesRequest } from '../members/actions';
import { historyPush } from '../router/actions';

import { dashboardRoutePaths } from '../router/constants';

import * as actions from './actions';
import * as api from './api';
import * as parse from './parse';
import * as constants from './constants';
import { getActiveDirectoryId } from './selectors';


const { DirectoryStatus } = statusConstants;

export function* requestActiveDirectoryTree() {
  try {
    const isFetching = yield select(selectors.getIsFetching);

    if (isFetching) {
      return;
    }

    const directoryId = yield select(getActiveDirectoryId);

    if (!directoryId) {
      yield put(actions.fbDirectoryFailure('No directory id'));
      return;
    }

    yield put(actions.fbDirectoryFetch());

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield FireFunctions.httpsCallable(
      functionNames.getDirectory,
      payload,
    );

    const { results } = response.data;

    yield put(actions.fbDirectorySuccess(results, directoryId));

    if (results) {
      const { status } = results;
      if (status === DirectoryStatus.removed || status === DirectoryStatus.suspended) {
        yield put(historyPush(dashboardRoutePaths.dirUnavailable));
      }
    }
  } catch (error) {
    yield put(actions.fbDirectoryFailure(error.message, error));
  }
}

export function* requestMultiDirectoryTrees(directoryIds, options = {}) {
  try {
    const { requestMembersForDirectory } = options;

    const directoriesData = {};

    for (let i = 0; i < directoryIds.length; i++) {
      const dirId = directoryIds[i];
      const payload = RequestUtils.getEntityReqPayload(dirId);
      const response = yield FireFunctions.httpsCallable(
        functionNames.getDirectory,
        payload,
      );
      const { results } = response.data;
      if (results) directoriesData[dirId] = results;
      else directoriesData[dirId] = {};
    }

    yield put(actions.fbMultiDirectorySuccess(directoriesData));

    const activeDirId = yield select(getActiveDirectoryId);
    const activeDir = directoriesData[activeDirId];
    if (activeDir && (
      activeDir.status === DirectoryStatus.suspended
      || activeDir.status === DirectoryStatus.removed
    )) {
      yield put(historyPush(dashboardRoutePaths.dirUnavailable));
      return;
    }

    if (requestMembersForDirectory) {
      yield put(fbMembersRequest(requestMembersForDirectory));
    }
  } catch (error) {
    yield put(actions.fbMultiDirectoryFailure(error.message, error));
  }
}

export function* requestSearchMembers(token, userId, directoryId, term) {
  try {
    const dirId = yield select(getActiveDirectoryId);
    const response = yield call(api.searchMembers, token, userId, dirId, directoryId, term);

    if (response.error) {
      yield put(actions.searchMembersFailure(response.error.message));
    } else {
      const { members } = response.data;
      const sortedMembers = parse.sortedMembers(members);

      yield put(actions.searchMembersSuccess(sortedMembers));
    }
  } catch (error) {
    yield put(actions.searchMembersFailure(error.message));
  }
}

export function* requestSearchFamilies(token, userId, directoryId, term) {
  try {
    const response = yield call(api.searchFamilies, token, userId, directoryId, term);

    if (response.error) {
      yield put(actions.searchFamiliesFailure(response.error.message));
    } else {
      const { families } = response.data;

      const sortedFams = parse.sortedFams(families);

      yield put(actions.searchFamiliesSuccess(sortedFams));
    }
  } catch (error) {
    yield put(actions.searchFamiliesFailure(error.message));
  }
}

export function* requestSearchGroups(token, userId, directoryId, term) {
  try {
    const response = yield call(api.searchGroups, token, userId, directoryId, term);

    if (response.error) {
      yield put(actions.searchGroupsFailure(response.error.message));
    } else {
      const { groups } = response.data;
      const sortedGroups = parse.sortedGroups(groups);

      yield put(actions.searchGroupsSuccess(sortedGroups));
    }
  } catch (error) {
    yield put(actions.searchGroupsFailure(error.message));
  }
}

export function* requestSwitchDir(directoryId) {
  try {
    const userId = yield select(getId);

    const response = yield call(
      FireDb.switchUserActiveDirectory,
      userId,
      directoryId,
    );

    if (response.error) {
      yield put(actions.switchDirectoryFailure(
        response.error.message, response.error,
      ));
    } else {
      yield all([
        put(fbUserTreeRequest(userId)),
        put(actions.switchDirectorySuccess(directoryId)),
      ]);
    }
  } catch (error) {
    yield put(actions.switchDirectoryFailure(
      error.message, error,
    ));
  }
}

export function* requestEntities(payload = {}) {
  const {
    getFamilies,
    getGroups,
    getPeople,
    families = [],
    familySummaries = [],
    groups = [],
    groupsSummaries = [],
    people = [],
    peopleSummaries = [],
  } = payload;

  if (getPeople) {
    yield all([
      put(fbMembersRequest(undefined, ...people)),
      put(getPersonSummariesRequest(undefined, ...peopleSummaries)),
    ]);
  }

  if (getFamilies) {
    yield put(fbFamiliesRequest(undefined, ...families));
    yield put(familySummariesRequest(undefined, ...familySummaries));
  }

  if (getGroups) {
    yield put(fbGroupsRequest(undefined, ...groups));
    yield put(groupsSummariesRequest(undefined, ...groupsSummaries));
  }
}

function* requestFeatures() {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    if (!directoryId) {
      yield put(actions.dirFeaturesFailure('No directory id'));
    }

    const payload = RequestUtils.getEntityReqPayload(directoryId);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getFeatures,
      payload,
    );

    const { results } = response.data;

    yield put(actions.dirFeaturesSuccess(results));
  } catch (error) {
    yield put(actions.dirFeaturesFailure(error, error.message));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.DIR_FEATURES_REQUEST,
      constants.FB_DIRECTORIES_REQUEST,
      constants.FB_MULTI_DIRECTORIES_REQUEST,
      constants.FETCH_ENTITIES_REQUEST,
      constants.SEARCH_FAMILIES_REQUEST,
      constants.SEARCH_GROUPS_REQUEST,
      constants.SEARCH_MEMBERS_REQUEST,
      constants.SWITCH_DIRECTORY_REQUEST,
    ]);

    switch (type) {
      case constants.FETCH_ENTITIES_REQUEST:
        yield spawn(
          requestEntities,
          payload,
        );
        break;

      case constants.FB_DIRECTORIES_REQUEST:
        yield spawn(
          requestActiveDirectoryTree,
          payload.directoryId,
        );
        break;

      case constants.FB_MULTI_DIRECTORIES_REQUEST:
        yield spawn(
          requestMultiDirectoryTrees,
          payload.directoryIds,
          payload.options,
        );
        break;

      case constants.SEARCH_MEMBERS_REQUEST:
        yield spawn(
          requestSearchMembers,
          payload.token,
          payload.userId,
          payload.directoryId,
          payload.term,
        );
        break;

      case constants.SEARCH_FAMILIES_REQUEST:
        yield spawn(
          requestSearchFamilies,
          payload.token,
          payload.userId,
          payload.directoryId,
          payload.term,
        );
        break;

      case constants.SEARCH_GROUPS_REQUEST:
        yield spawn(
          requestSearchGroups,
          payload.token,
          payload.userId,
          payload.directoryId,
          payload.term,
        );
        break;

      case constants.SWITCH_DIRECTORY_REQUEST:
        yield spawn(requestSwitchDir, payload.directoryId);
        break;

      case constants.DIR_FEATURES_REQUEST:
        yield spawn(requestFeatures);
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
