import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Modal from '../Modal';


const SideSheet = ({ className, ...rest }) => (
  <Modal
    className={classNames('j-sidesheet', className)}
    {...rest}
  />
);

SideSheet.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onToggleOpen: PropTypes.func,
  setRef: PropTypes.func,
  sx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

SideSheet.defaultProps = {
  children: undefined,
  className: undefined,
  defaultOpen: false,
  isOpen: undefined,
  onToggleOpen: undefined,
  setRef: undefined,
  sx: {},
  wrapperSx: {},
};

const SideSheetWithRef = React.forwardRef((props, ref) => (
  <SideSheet setRef={ref} {...props} />
));


export { SideSheet };
export default SideSheetWithRef;
