

import { all, call, fork, put, take, spawn, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as constants from './constants';
import { getId } from './selectors';

import { fbMultiDirectoryRequest, fbDirectorySuccess, switchDirectorySuccess } from '../directory/actions';
import { getActiveDirectoryId } from '../directory/selectors';

import { FireDb, FireFunctions, functionNames } from '../services';

/**
 * Send credentials to the server to be verified and exchanged for tokens.
 * @param uid {string} Given user id.
 */
export function* requestUserDataTree(uid) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getMe,
      { directoryId, userId: uid },
    );
    const { results } = response.data;
    yield put(actions.fbUserTreeSuccess(results));
    const { directories } = results;
    yield put(fbMultiDirectoryRequest(Object.keys(directories)));
  } catch (error) {
    yield put(actions.fbUserTreeFailure(error.message, error));
  }
}

/**
 * Get data trees for all families the user belongs to.
 * @param fids {string[]} Family ids array.
 */
export function* requestUserFamilyTrees(fids = []) {
  try {
    const userFamilies = {};

    for (let i = 0; i < fids.length; i++) {
      userFamilies[fids[i]] = yield call(FireDb.getFamilyTree, fids[i]);
    }

    yield put(actions.fbUserFamilyTreesSuccess(userFamilies));
  } catch (error) {
    yield put(actions.fbUserFamilyTreesFailure(error.message, error));
  }
}

/**
 * Get data trees for all groups the user belongs to.
 * @param gids {string[]} Group ids array.
 */
export function* requestUserGroupTrees(gids) {
  try {
    const userGroups = {};

    for (let i = 0; i < gids.length; i++) {
      userGroups[gids[i]] = yield call(FireDb.getGroupTree, gids[i]);
    }

    yield put(actions.fbUserGroupTreesSuccess(userGroups));
  } catch (error) {
    yield put(actions.fbUserGroupTreesFailure(error.message, error));
  }
}

/**
 * Send credentials to the server to be verified and exchanged for tokens.
 */
export function* requestMyProfile(includeFamilies, includeGroups) {
  try {
    const uid = yield select(getId);
    const directoryId = yield select(getActiveDirectoryId);
    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getMyProfile,
      { directoryId, userId: uid, includeFamilies, includeGroups },
    );
    const { results } = response.data;
    yield put(actions.fbMyProfileSuccess(directoryId, results));
  } catch (error) {
    yield put(actions.fbMyProfileFailure(error.message, error));
  }
}

/**
 * Get the families this user is a leader of.
 */
export function* requestMyLedFamilies() {
  try {
    const uid = yield select(getId);
    const directoryId = yield select(getActiveDirectoryId);
    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getMyLedFamilies,
      { directoryId, userId: uid },
    );
    const { results } = response.data;
    yield put(actions.fbMyLedFamiliesSuccess(results));
  } catch (error) {
    yield put(actions.fbMyLedFamiliesFailure(error.message, error));
  }
}

/**
 * Get the groups this user is a leader of.
 */
export function* requestMyLedGroups() {
  try {
    const uid = yield select(getId);
    const directoryId = yield select(getActiveDirectoryId);
    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getMyLedGroups,
      { directoryId, userId: uid },
    );
    const { results } = response.data;
    yield put(actions.fbMyLedGroupsSuccess(results));
  } catch (error) {
    yield put(actions.fbMyLedGroupsFailure(error.message, error));
  }
}

/**
 * Get the groups this user is a leader of.
 */
export function* requestSwitchActiveDirectory(directoryId) {
  try {
    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.switchMyActiveDirectory,
      { directoryId },
    );

    const { results } = response.data;

    yield all([
      put(fbDirectorySuccess(results, directoryId)),
      put(switchDirectorySuccess(directoryId)),
      put(actions.fbSwitchDirSuccess(directoryId)),
    ]);
  } catch (error) {
    yield put(actions.fbSwitchDirFailure(error.message, error));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.FB_USER_TREE_REQUEST,
      constants.FB_USER_FAMILY_TREES_REQUEST,
      constants.FB_USER_GROUP_TREES_REQUEST,
      constants.FB_MY_PROFILE_REQUEST,
      constants.FB_MY_LED_FAMILIES_REQUEST,
      constants.FB_MY_LED_GROUPS_REQUEST,
      constants.FB_SWITCH_DIR_REQUEST,
    ]);

    switch (type) {
      case constants.FB_USER_TREE_REQUEST:
        yield fork(requestUserDataTree, payload.uid);
        break;

      case constants.FB_USER_FAMILY_TREES_REQUEST:
        yield fork(requestUserFamilyTrees, payload.fids);
        break;

      case constants.FB_USER_GROUP_TREES_REQUEST:
        yield fork(requestUserGroupTrees, payload.gids);
        break;

      case constants.FB_MY_PROFILE_REQUEST:
        yield fork(requestMyProfile, payload.includeFamilies, payload.includeGroups);
        break;

      case constants.FB_MY_LED_FAMILIES_REQUEST:
        yield fork(requestMyLedFamilies);
        break;

      case constants.FB_MY_LED_GROUPS_REQUEST:
        yield fork(requestMyLedGroups);
        break;

      case constants.FB_SWITCH_DIR_REQUEST:
        yield spawn(requestSwitchActiveDirectory, payload.directoryId);
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
