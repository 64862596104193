import React from 'react';
import PropTypes from 'prop-types';
import { message, notification } from 'antd';
import _startCase from 'lodash/startCase';

import { SweetAlert, Text } from 'modules/core/components';

import { FireFunctions } from 'modules/services';

import { PersonUtils } from 'utils';


const withRemoveResource = (Component, entityType, removeFunctionName, displayType) => {
  const entityIdDataKey = `${entityType}Id`;

  const ComponentWithRemoveResource = (props) => {
    const { afterRemove, data, directoryId, getResources } = props;

    const [removeEntity, setRemoveEntity] = React.useState(null);
    const [showConfirmAlert, setShowConfirmAlert] = React.useState(false);

    const handleResetEntity = React.useCallback(() => {
      setRemoveEntity(null);
      setShowConfirmAlert(false);
    }, []);

    const handleConfirmRemove = React.useCallback(() => {
      const hide = message.loading('Working...', 0);
      setShowConfirmAlert(false);

      FireFunctions.httpsCallable(removeFunctionName, {
        [entityIdDataKey]: removeEntity?.id,
        directoryId,
      }).then((result) => {
        const { data: { success } } = result;
        hide();
        if (success) {
          getResources();
          handleResetEntity();
          notification.success({
            message: `${_startCase(entityType)} removed successfully`,
            duration: 8,
          });
          if (afterRemove) {
            afterRemove({ success: true });
          }
        } else {
          notification.error({
            message: `${_startCase(entityType)} could not be removed`,
            duration: 8,
          });
          if (afterRemove) {
            afterRemove({ success: false });
          }
        }
      });
    }, [afterRemove, removeEntity?.id]);

    const handleRemove = React.useCallback((entity) => {
      setRemoveEntity(entity);
      setShowConfirmAlert(true);
    }, []);

    return (
      <React.Fragment>
        <SweetAlert
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="info"
          dependencies={[removeEntity?.id]}
          onCancel={handleResetEntity}
          onConfirm={handleConfirmRemove}
          question
          show={showConfirmAlert}
          showCancel
          title="Are you sure?"
        >
          <Text>
            {`Do you want to permanently remove the ${displayType || entityType} "${removeEntity?.name ?? PersonUtils.getFullListName(removeEntity)}"? This cannot be undone.`}
          </Text>
        </SweetAlert>

        <Component {...props} data={data} getResources={getResources} onRemove={handleRemove} />
      </React.Fragment>
    );
  };

  ComponentWithRemoveResource.propTypes = {
    afterRemove: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    isFetching: PropTypes.bool,
  };

  ComponentWithRemoveResource.defaultProps = {
    afterRemove: undefined,
    data: [],
    isFetching: undefined,
  };

  return ComponentWithRemoveResource;
};

export default withRemoveResource;
