import { createSelector } from 'reselect';

import { name } from './reducer';

const getState = state => state[name];
const getProps = (state, props) => props;

export const getFilters = createSelector(
  [getState],
  state => state,
);

export const getFilterViews = createSelector(
  [getFilters],
  (filters) => filters.views,
);

export const getFilterViewsPeople = createSelector(
  [getFilterViews],
  (views) => views.people,
);

export const getFilterViewsPeopleType = createSelector(
  [getFilterViewsPeople],
  (people) => people.type,
);
