export const createHeaderStyles = (isLaptopOrLarger) => ({
  avatar: {
    alignSelf: isLaptopOrLarger ? 'flex-start' : 'center',
  },
  avatarContainer: {
    justifyContent: isLaptopOrLarger ? 'flex-start' : 'center',
    overflow: 'hidden',
  },
  header: {
    flexDirection: isLaptopOrLarger ? 'row' : 'column',
  },
  birthdateContainer: {
    justifyContent: isLaptopOrLarger ? 'flex-start' : 'center',
  },
  birthdateText: {
    color: 'subtext',
    fontSize: isLaptopOrLarger ? 'font18' : 'font16',
  },
  nameText: {
    alignSelf: 'center',
    color: 'text',
    fontSize: isLaptopOrLarger ? 'font24' : 'font20',
    fontWeight: 'bold',
    marginBottom: 'grid4',
    marginTop: isLaptopOrLarger ? 'grid8' : 'grid16',
    paddingRight: 'grid16',
    textAlign: 'center',
  },
  textContainer: {
    flexDirection: 'column',
    height: isLaptopOrLarger ? '160px' : undefined,
    justifyContent: isLaptopOrLarger ? 'center' : 'flex-start',
    paddingLeft: 'grid16',
  },
});

export const createDataPaneStyles = (isLaptopOrLarger) => ({
  full: {
    marginRight: 'grid0',
    marginLeft: 'grid0',
    marginTop: 'grid16',
    width: '100%',
  },
  left: {
    marginRight: isLaptopOrLarger ? 'grid8' : undefined,
  },
  pane: {
    backgroundColor: isLaptopOrLarger ? 'foregroundLarge' : 'foregroundSmall',
    borderRadius: 'pane',
    boxShadow: isLaptopOrLarger ? 'pane' : undefined,
    padding: isLaptopOrLarger ? 'grid32' : undefined,
    width: isLaptopOrLarger ? '50%' : '100%',
  },
  right: {
    marginLeft: isLaptopOrLarger ? 'grid8' : undefined,
  },
});

export const createDataBlockStyles = (isLaptopOrLarger) => ({
  dataBlock: {
    marginBottom: isLaptopOrLarger ? 'grid16' : 'grid24',
  },
});
