import PropTypes from 'prop-types';
import React from 'react';


const Switch = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={String(strokeWidth)}
    mask="url(#search-icon-mask)"
    fillRule="evenodd"
    d="M20,5.45V0L12.73,7.27,20,14.55V9.09A10.92,10.92,0,0,1,30.91,20a10.75,10.75,0,0,1-1.27,5.09l2.65,2.66A14.52,14.52,0,0,0,20,5.45Zm0,25.46A10.92,10.92,0,0,1,9.09,20a10.75,10.75,0,0,1,1.27-5.09L7.71,12.25A14.52,14.52,0,0,0,20,34.55V40l7.27-7.27L20,25.45Z"
    {...rest}
  />
);

Switch.propTypes = {
  strokeWidth: PropTypes.number,
};

Switch.defaultProps = {
  strokeWidth: 1,
};


export default Switch;
