import PropTypes from 'prop-types';
import React from 'react';
import { Prompt as RRPrompt } from 'react-router-dom';


const Prompt = (props) => (
  <RRPrompt
    message={props.message}
    when={props.when} />
);

Prompt.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  when: PropTypes.bool,
};

Prompt.defaultProps = {
  message: 'Are you sure? You may lose progress on this page.',
  when: undefined,
};


export default Prompt;
