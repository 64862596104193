import PropTypes from 'prop-types';
import React from 'react';


const Search = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={String(strokeWidth)}
    mask="url(#search-icon-mask)"
    fillRule="evenodd"
    d="M31.46,28.77a17.11,17.11,0,1,0-2.69,2.69l7,7a1.9,1.9,0,0,0,2.68-2.68Zm-3.78-1.44a13.29,13.29,0,1,0-.35.35,1.46,1.46,0,0,1,.16-.19Z"
    {...rest}
  />
);

Search.propTypes = {
  strokeWidth: PropTypes.number,
};

Search.defaultProps = {
  strokeWidth: 1,
};


export default Search;
