import styled from 'styled-components';


const size = 280;

const EntityCardSizerStyled = styled.div``;

const EntityCardWrapper = styled.div``;

const EntityCardStyled = styled.div``;

const EntityCardImageWrapperStyled = styled.div``;

const EntityCardImageStyled = styled.img``;

const EntityCardTextWrapper = styled.div``;

const EntityCardTitle = styled.h3``;

const EntityCardSubtitle = styled.h4``;

export {
  EntityCardStyled,
  EntityCardSizerStyled,
  EntityCardImageWrapperStyled,
  EntityCardImageStyled,
  EntityCardTextWrapper,
  EntityCardTitle,
  EntityCardSubtitle,
  EntityCardWrapper,
};
