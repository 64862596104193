import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { FontEmSize } from '../../sizes';

import { Text } from './presenter';


const TruncateMultiline = (props) => {
  const {
    className,
    fontSize,
    maxLines,
    setRef,
    sx,
    ...rest
  } = props;

  const size = FontEmSize[fontSize.toUpperCase()];
  const maxHeightEms = '3em';

  return (
    <div className="line-clamp">
      <Text
        as="p"
        className={classNames({
          [className]: className,
        })}
        setRef={setRef}
        sx={sx}
        {...rest}
      />
    </div>
  );
};

TruncateMultiline.propTypes = {
  maxLines: PropTypes.number,
  fontSize: PropTypes.string,
};

TruncateMultiline.defaultProps = {
  maxLines: 2,
  fontSize: 'font16',
};

const TruncateMultilineWithRef = React.forwardRef((props, ref) => (
  <TruncateMultiline setRef={ref} {...props} />
));


export { TruncateMultiline };
export default TruncateMultilineWithRef;
