export const LOGIN_SUCCESS = '@user/LOGIN_SUCCESS';
export const FB_LOGIN_SUCCESS = '@user/FB_LOGIN_SUCCESS';
export const RESUME_SESSION_SUCCESS = '@user/RESUME_SESSION_SUCCESS';

export const FB_USER_TREE_REQUEST = '@user/FB_USER_TREE_REQUEST';
export const FB_USER_TREE_SUCCESS = '@user/FB_USER_TREE_SUCCESS';
export const FB_USER_TREE_FAILURE = '@user/FB_USER_TREE_FAILURE';

export const FB_USER_FAMILY_TREES_REQUEST = '@user/FB_USER_FAMILY_TREES_REQUEST';
export const FB_USER_FAMILY_TREES_SUCCESS = '@user/FB_USER_FAMILY_TREES_SUCCESS';
export const FB_USER_FAMILY_TREES_FAILURE = '@user/FB_USER_FAMILY_TREES_FAILURE';

export const FB_USER_GROUP_TREES_REQUEST = '@user/FB_USER_GROUP_TREES_REQUEST';
export const FB_USER_GROUP_TREES_SUCCESS = '@user/FB_USER_GROUP_TREES_SUCCESS';
export const FB_USER_GROUP_TREES_FAILURE = '@user/FB_USER_GROUP_TREES_FAILURE';

export const SIGNUP_SUCCESS = '@user/SIGNUP_SUCCESS';

export const SETTINGS_CHANGE_THEME = '@user/SETTINGS_CHANGE_THEME';

export const FB_MY_PROFILE_REQUEST = '@user/FB_MY_PROFILE_REQUEST';
export const FB_MY_PROFILE_SUCCESS = '@user/FB_MY_PROFILE_SUCCESS';
export const FB_MY_PROFILE_FAILURE = '@user/FB_MY_PROFILE_FAILURE';

export const FB_MY_LED_FAMILIES_REQUEST = '@user/FB_MY_LED_FAMILIES_REQUEST';
export const FB_MY_LED_FAMILIES_SUCCESS = '@user/FB_MY_LED_FAMILIES_SUCCESS';
export const FB_MY_LED_FAMILIES_FAILURE = '@user/FB_MY_LED_FAMILIES_FAILURE';

export const FB_MY_LED_GROUPS_REQUEST = '@user/FB_MY_LED_GROUPS_REQUEST';
export const FB_MY_LED_GROUPS_SUCCESS = '@user/FB_MY_LED_GROUPS_SUCCESS';
export const FB_MY_LED_GROUPS_FAILURE = '@user/FB_MY_LED_GROUPS_FAILURE';

export const FB_SWITCH_DIR_REQUEST = '@user/FB_SWITCH_DIR_REQUEST';
export const FB_SWITCH_DIR_SUCCESS = '@user/FB_SWITCH_DIR_SUCCESS';
export const FB_SWITCH_DIR_FAILURE = '@user/FB_SWITCH_DIR_FAILURE';
