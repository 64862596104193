import { Enum } from 'enumify';

/**
 * Enumeration for account types
 */
class UserRole extends Enum {
  /**
   * Converts from stored db values to shareable enum
   * @param accessRoles
   * @returns {UserRole}
   */
  static fromAccessRoles(accessRoles) {
    if (!accessRoles || !accessRoles[0]) {
      return UserRole.MEMBER;
    }

    switch (accessRoles[0].name) {
      case 'dirAdmin':
        return UserRole.ADMIN;
      case 'systemAdmin':
        return UserRole.SYSTEM_ADMIN;
      default:
        return UserRole.MEMBER;
    }
  }

  /**
   * Converts from stored db values to shareable enum
   * @param accessRole {Object}
   * @returns {UserRole}
   */
  static fromAccessRole(accessRole) {
    if (!accessRole) {
      return UserRole.MEMBER;
    }

    switch (accessRole.name) {
      case 'dirAdmin':
        return UserRole.ADMIN;
      case 'systemAdmin':
        return UserRole.SYSTEM_ADMIN;
      default:
        return UserRole.MEMBER;
    }
  }

  /**
   * Returns the user's role that have the highest permissions.
   * @param accessRoles {UserRole[]}
   * @returns {UserRole}
   */
  static fromHighestPermissions(accessRoles) {
    if (!accessRoles || !accessRoles[0]) {
      return UserRole.MEMBER;
    }

    let highestPermsRole = UserRole.MEMBER;

    accessRoles.forEach((r) => {
      const role = UserRole.fromAccessRole(r);

      if (role && role.asInt > highestPermsRole.asInt) {
        highestPermsRole = role;
      }
    });

    return highestPermsRole;
  }

  static toAccessRoleName(role) {
    switch (role) {
      case UserRole.SYSTEM_ADMIN.name:
        return 'sysAdmin';
      case UserRole.ADMIN.name:
        return 'dirAdmin';
      case UserRole.EDITOR.name:
        return 'editor';
      case UserRole.MEMBER.name:
      default:
        return 'user';
    }
  }
}

/**
 * The values of the enumeration
 */
UserRole.initEnum({
  SYSTEM_ADMIN: {
    get isAdmin() {
      return true;
    },
    get accessRoleName() {
      return 'systemAdmin';
    },
    get asInt() {
      return 10000;
    },
  },
  ADMIN: {
    get isAdmin() {
      return true;
    },
    get accessRoleName() {
      return 'dirAdmin';
    },
    get asInt() {
      return 1000;
    },
  },
  EDITOR: {
    get isAdmin() {
      return false;
    },
    get accessRoleName() {
      return 'editor';
    },
    get asInt() {
      return 100;
    },
  },
  MEMBER: {
    get isAdmin() {
      return false;
    },
    get accessRoleName() {
      return 'member';
    },
    get asInt() {
      return 1;
    },
  },
});

export default UserRole;
