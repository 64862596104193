import PropTypes from 'prop-types';


export const oneOfOrArrayOfPropType = (...propTypes) => (
  PropTypes.oneOfType([...propTypes, PropTypes.arrayOf(PropTypes.oneOfType(propTypes))])
);

export const stringOrNumberPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);
