import * as constants from './constants';

export const name = 'meta';

const initialState = {
  nextRoute: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.CLEAR_NEXT_ROUTE:
      return {
        ...state,
        nextRoute: null,
      };

    default:
      return state;
  }
}
