//

import * as React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import styles from './styles.css';


class ConfirmDeleteDialog extends React.Component {
  static defaultProps = {
    message: 'This cannot be undone.',
    title: 'Are you sure?',
    confirmText: 'Yes, delete it!',
    cancelText: 'Cancel',
    onConfirm: () => {},
    onCancel: () => {},
  };

  state = {};

  render() {
    const {
      title,
      message,
      confirmText,
      cancelText,
      onConfirm,
      onCancel,
    } = this.props;

    return (
      <SweetAlert
        warning
        customClass={styles.alert}
        title={title}
        onConfirm={onConfirm}
        onCancel={onCancel}
        cancelBtnCssClass={styles.cancelButton}
        confirmBtnCssClass={styles.confirmButton}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={confirmText}
        cancelBtnText={cancelText}
        showCancel>
        {message}
      </SweetAlert>
    );
  }
}

export default ConfirmDeleteDialog;
