import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserRole } from '../../../../common/enums';
import { AppRoutes } from '../../constants';

import { propTypes, defaultProps } from './props';

class RoleRoute extends React.Component {
  render() {

    const {
      path,
      Component,
      isAuthenticated,
      isFetching,
      role,
      allowedRoles,
      redirectsTo,
      ...props
    } = this.props;

    if (isFetching) {
      return <div />;
    }

    if (!isAuthenticated) {
      return <Redirect to={AppRoutes.PUBLIC.LOGIN.path} />;
    }

    return (
      <Route path={path} render={() => (
        UserRole[role] && allowedRoles.includes(role) ?
          <Component {...props} /> :
          <Redirect to={redirectsTo} />
      )} />
    );
  }
}

RoleRoute.propTypes = propTypes;
RoleRoute.defaultProps = defaultProps;

export default RoleRoute;
