import React from 'react';

import { classie } from '../../../../utils';

import styles from './styles.css';

const CenterVertical = ({ className, children }) => (
  <div className={classie([styles.centered, className])}>
    {children}
  </div>
);

export default CenterVertical;
