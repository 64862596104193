import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getLedGroups } from '../../../user/selectors';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  ledGroups: getLedGroups,
});

export default connect(
  mapStateToProps,
)(presenter);
