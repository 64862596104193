export default {
  // 8/25/19 add groups pagination
  0: state => ({
    ...state.groups,
    groups: {},
    pagination: {
      count: 1,
      page: 1,
      pages: 1,
      pageSize: 3,
    },
  }),
  // 9/3/19 update group ids state
  1: state => ({
    ...state.groups,
    groupIds: [],
  }),
  2: state => ({
    ...(state.groups || {}),
    pagination: {
      count: 1,
      page: 1,
      pages: 1,
      pageSize: 25,
    },
  }),
  3: state => ({
    ...(state.groups || {}),
    groupsSummaries: {
      ids: [],
      isFetching: false,
      summaries: {},
    },
  }),
  4: state => ({
    ...(state.groups || {}),
    lastFetched: null,
    groupsSummaries: {
      ...(state.groups ? state.groups.groupsSummaries || {} : {}),
      lastFetched: null,
    },
  }),
  // 9/11/20 save sort selection in state
  5: state => ({
    ...(state.groups || {}),
    sort: {
      by: ['name'],
      dir: ['asc'],
    },
  }),
};
