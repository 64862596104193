import { createSelector } from 'reselect';

import { name } from './reducer';

const getState = state => state[name];

export const getTitle = createSelector(
  [getState],
  state => state.title,
);

export const getMessage = createSelector(
  [getState],
  state => state.message,
);

export const getLevel = createSelector(
  [getState],
  state => state.level,
);

export const getClearTimeout = createSelector(
  [getState],
  state => state.clearTimeout,
);
