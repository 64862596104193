import React from 'react';
import PropTypes from 'prop-types';

import Asterisk from '../Asterisk';
import Box from '../Box';
import Label from '../Label';


const FieldWithLabel = (props) => {
  const {
    children,
    Component,
    isRequired,
    label,
    sx,
    ...rest
  } = props;

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Label
        sx={{
          color: 'fieldLabel',
          fontSize: 'font14',
        }}
      >
        {label}
        {isRequired && (
          <React.Fragment>
            {` `}
            <Asterisk />
          </React.Fragment>
        )}
      </Label>

      {children || <Component {...rest} isRequired={isRequired} sx={{ marginTop: 'grid4' }} />}
    </Box>
  );
};

FieldWithLabel.propTypes = {
  children: PropTypes.node,
  Component: PropTypes.elementType,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
  value: PropTypes.any,
};

FieldWithLabel.defaultProps = {
  children: undefined,
  isRequired: undefined,
  onChange: undefined,
  setRef: undefined,
  sx: {},
  value: undefined,
};

const FieldWithLabelWithRef = React.forwardRef((props, ref) => (
  <FieldWithLabel setRef={ref} {...props} />
));


export { FieldWithLabel };
export default FieldWithLabelWithRef;
