import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { getRole, getUser, getUserWebappState } from 'modules/user/selectors';
import { logoutSuccess } from 'modules/auth/actions';
import { getActiveDirectory, getDirectories, getActiveDirectoryId } from 'modules/directory/selectors';
import {
  fbDirectoryRequest,
  switchDirectory,
  fetchEntitiesRequest,
  dirFeaturesRequest,
} from 'modules/directory/actions';
import { fbFamiliesRequest } from 'modules/families/actions';
import { fbGroupsRequest } from 'modules/groups/actions';
import { fbMembersRequest } from 'modules/members/actions';
import { fbUserTreeRequest, fbMyLedGroupsRequest, fbMyLedFamiliesRequest } from 'modules/user/actions';

import { withTheme } from 'modules/core/hoc';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  directories: getDirectories,
  directory: getActiveDirectory,
  directoryId: getActiveDirectoryId,
  role: getRole,
  user: getUser,
  webappState: getUserWebappState,
});

const mapDispatchToProps = {
  fbDirectoryRequest,
  fetchEntities: fetchEntitiesRequest,
  getFamilies: fbFamiliesRequest,
  getFeatures: dirFeaturesRequest,
  getGroups: fbGroupsRequest,
  getPeople: fbMembersRequest,
  logoutSuccess,
  refreshUserTreeRequest: fbUserTreeRequest,
  requestLedFamilies: fbMyLedFamiliesRequest,
  requestLedGroups: fbMyLedGroupsRequest,
  setDirectorySwitched: switchDirectory,
};

const enhance = compose(
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(presenter);
