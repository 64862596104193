global.BreakpointSizes = {
  tablet: 768,
  laptop: 960,
  desktop: 1360,
};

global.BreakpointPixels = {
  tablet: `${global.BreakpointSizes.tablet}px`,
  laptop: `${global.BreakpointSizes.laptop}px`,
  desktop: `${global.BreakpointSizes.desktop}px`,
};

global.BreakpointQuery = {
  tablet: `(min-width: ${global.BreakpointPixels.tablet})`,
  laptop: `(min-width: ${global.BreakpointPixels.laptop})`,
  desktop: `(min-width: ${global.BreakpointPixels.desktop})`,
};

global.Breakpoint = {
  tablet: `@media ${global.BreakpointQuery.tablet}`,
  laptop: `@media ${global.BreakpointQuery.laptop}`,
  desktop: `@media ${global.BreakpointQuery.desktop}`,
};

global.queryBreakpoints = {
  isLtTablet: () => window.innerWidth < global.BreakpointSizes.tablet,
  isLtLaptop: () => window.innerWidth < global.BreakpointSizes.laptop,
  isLtDesktop: () => window.innerWidth < global.BreakpointSizes.desktop,
  isGteTablet: () => window.innerWidth >= global.BreakpointSizes.tablet,
  isGteLaptop: () => window.innerWidth >= global.BreakpointSizes.laptop,
  isGteDesktop: () => window.innerWidth >= global.BreakpointSizes.desktop,
};

global.bpSwitch = (config) => {
  const bps = Object.keys(config);
  const w = window.innerWidth;

  if (bps.length) {
    let applicableBp;

    for (let i = 0, len = bps.length; i < len; i += 1) {
      const size = global.BreakpointSizes[bps[i]];

      if (!applicableBp || (size <= w && size > global.BreakpointSizes[applicableBp])) {
        applicableBp = bps[i];
      }
    }

    return config[applicableBp];
  }

  return undefined;
};

global.UserRole = {
  ADMIN: 'ADMIN',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
};
