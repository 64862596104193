import React from 'react';
import PropTypes from 'prop-types';

import { Breadcrumbs } from 'modules/core/components';
import { dashboardRoutePaths } from 'modules/router/constants';
import { routeHelpers } from 'helpers';


const Subheader = (props) => {
  const { directoryName, families, groups, history, location, members } = props;

  const onNavigate = url => history.push(url);

  const items = [];
  let listingId;

  if (routeHelpers.isListingIdRoute(location.pathname)) {
    listingId = location.pathname.split('/')[4];
  }

  if (routeHelpers.isPeopleRoute(location.pathname)) {
    items.push({
      label: 'People',
      command: () => onNavigate(dashboardRoutePaths.individuals),
    });

    if (listingId) {
      const person = members[listingId];

      if (person) {
        items.push({
          label: `${person.firstName} ${person.lastName}`,
          command: () => onNavigate(dashboardRoutePaths.individualsId(listingId)),
        });
      }
    }
  } else if (routeHelpers.isGroupsRoute(location.pathname)) {
    items.push({
      label: 'Groups',
      command: () => onNavigate(dashboardRoutePaths.groups),
    });

    if (listingId) {
      const group = groups[listingId];

      if (group) {
        items.push({
          label: group.name,
          command: () => onNavigate(dashboardRoutePaths.groupsId(listingId)),
        });
      }
    }
  } else if (routeHelpers.isFamiliesRoute(location.pathname)) {
    items.push({
      label: 'Families',
      command: () => onNavigate(dashboardRoutePaths.families),
    });

    if (listingId) {
      const family = families[listingId];

      if (family) {
        items.push({
          label: family.name,
          command: () => onNavigate(dashboardRoutePaths.familiesId(listingId)),
        });
      }
    }
  }

  return (
    <div>
      <Breadcrumbs model={items} />
    </div>
  );
};

Subheader.propTypes = {
  directoryName: PropTypes.string,
  families: PropTypes.shape(),
  groups: PropTypes.shape(),
  history: PropTypes.shape(),
  location: PropTypes.shape(),
  members: PropTypes.shape(),
};

Subheader.defaultProps = {
  members: {},
};

export default Subheader;
