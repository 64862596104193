import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import defaultImage from '../../../../assets/images/default-profile.png';

import { classie } from '../../../../utils';
import styles from './styles.css';

class CardAuthor extends React.Component {
  render() {
    return (
      <div className={classie(['author', styles.author])}>
        <Link
          to={this.props.link ? this.props.link : '#'}>
          <div
            style={{
              backgroundImage: this.props.avatar ?
                `url(${this.props.avatar})` : `url(${defaultImage})`,
              backgroundSize: this.props.avatar ? 'cover' : '50% 50%',
              backgroundPosition: this.props.avatar ?
                'top center' : 'center center',
            }}
            className={styles.authorImage} />
          <h5
            style={{ marginTop: '15px', marginBottom: '0' }}
            className="title">
            {this.props.title}
          </h5>
        </Link>
        <p
          style={{ minHeight: '23px' }}
          className={
            classie(['description'], {
              [styles.emptyDescription]: _.isEmpty(this.props.description),
            })}
        >
          {_.isEmpty(this.props.description) ? '––' : this.props.description}
        </p>
      </div>
    );
  }
}

export default CardAuthor;
