// 

import * as React from 'react';

import { classie } from '../../../../utils';

import styles from './styles.css';


const InfoBlurb = (props) => (
  <p className={classie([styles.info], {
    [styles.infoMargin]: props.margin,
  })}>
    <i className={classie(['now-ui-icons travel_info', styles.infoIcon])} />
    {props.children}
  </p>
);

export default InfoBlurb;
