import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Appearance, Intent } from '../../constants';
import { isAppearanceNone } from '../../helpers';
import { IconSize } from '../../sizes';

import { Button } from '../Button';
import Icon, { IconNames } from '../Icon';


const IconButton = (props) => {
  const {
    appearance,
    buttonColor,
    className,
    iconColor,
    iconHoverColor,
    iconName,
    iconSize,
    intent,
    onBlur,
    onClick,
    onFocus,
    setRef,
    sx,
    svgProps,
    type,
    ...rest
  } = props;

  const [isHover, setIsHover] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState(false);

  const isNone = isAppearanceNone(appearance);

  return (
    <Button
      appearance={appearance}
      className={classNames({
        'j-icon-button': true,
        [className]: className,
      })}
      intent={intent}
      onBlur={(evt) => {
        setIsFocus(false);
        onBlur?.(evt);
      }}
      onClick={onClick}
      onFocus={(evt) => {
        setIsFocus(true);
        onFocus?.(evt);
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {
        setIsHover(false);
        setIsFocus(false);
      }}
      ref={setRef}
      sx={{
        // borderColor: isNone ? 'transparent' : buttonColor,
        borderRadius: 'default',
        ':hover': {
          // backgroundColor: isNone ? 'transparent' : buttonColor,
        },
        ...sx,
      }}
      {...rest}
    >
      <Icon
        color={iconColor}
        hoverColor={isNone ? iconColor : iconHoverColor}
        isFocused={isFocus}
        isHovered={isHover}
        name={iconName}
        size={iconSize}
        svgProps={svgProps}
      />
    </Button>
  );
};

IconButton.propTypes = {
  appearance: PropTypes.oneOf(Object.values(Appearance)),
  buttonColor: PropTypes.string,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  iconHoverColor: PropTypes.string,
  iconName: PropTypes.oneOf(Object.values(IconNames)).isRequired,
  iconSize: PropTypes.oneOf(Object.values(IconSize)),
  intent: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  svgProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  type: PropTypes.string,
};

IconButton.defaultProps = {
  appearance: Appearance.GHOST,
  buttonColor: 'primary',
  className: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  iconName: undefined,
  iconSize: IconSize.ICON_MED,
  intent: Intent.DEFAULT,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  setRef: undefined,
  svgProps: {},
  sx: {},
  type: 'button',
};

const IconButtonWithRef = React.forwardRef((props, ref) => (
  <IconButton setRef={ref} {...props} />
));


export { IconButton };
export default IconButtonWithRef;
