import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Text from '../Text';


const MultiLineInfoBlock = (props) => {
  const {
    lines,
    LineComponent,
  } = props;

  return (
    <Box>
      {lines.map((line, idx) => (
        <LineComponent key={`${line}-${idx}`}>
          {line}
        </LineComponent>
      ))}
    </Box>
  );
};

MultiLineInfoBlock.propTypes = {
  LineComponent: PropTypes.elementType,
  lines: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MultiLineInfoBlock.defaultProps = {
  LineComponent: Text,
};


export default MultiLineInfoBlock;
