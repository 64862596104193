import axios from 'axios';
import { Routing } from 'common/constants';



export async function requestUploadImage({
  token, userId, dirId, shortCode, formData,
}) {
  const path = `${Routing.BASE_ROUTE}${Routing.AdminRoutes.userImages(
    userId,
    dirId,
    shortCode,
  )}`;

  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
    params: {
      shortCode,
    },
  };

  return axios.post(path, formData, options);
}
