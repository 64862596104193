import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { AppRoutes } from 'modules/router/constants';
import { FireAuth } from 'modules/services';


const Logout = () => {
  const history = useHistory();

  React.useEffect(() => {
    FireAuth.logout().then(() => {
      history.push(AppRoutes.PUBLIC.LOGIN);
    });
  }, []);

  return null;
};


export default Logout;
