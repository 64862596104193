import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { CaretLeft } from '../shapes';


const CaretLeftSvg = (props) => (
  <g>
    <IconBounds />
    <CaretLeft {...props} />
  </g>
);

CaretLeftSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

CaretLeftSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default CaretLeftSvg;
