import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useControlled } from 'hooks';

import Box from '../Box';

import { Segment } from './components';


const SegmentedControl = (props) => {
  const {
    className,
    items,
    onChange,
    value,
    SegmentComponent,
    sx,
  } = props;

  const [val, setVal] = useControlled({ default: 0, onChange, value });

  return (
    <Box
      className={classNames({
        'j-seg-control': true,
        [className]: className,
      })}
      sx={{
        ...sx,
        backgroundColor: 'primary',
        borderColor: 'primary',
        borderWidth: 'med',
        borderStyle: 'default',
        borderRadius: 'button',
        display: 'inline-block',
        transition: 'color',
      }}
    >
      {items.map((item) => {
        const isActive = item.value === val;

        return (
          <SegmentComponent
            isActive={isActive}
            key={item.value}
            onClick={() => {
              setVal(item.value);
            }}
          >
            {item.label}
          </SegmentComponent>
        );
      })}
    </Box>
  );
};

SegmentedControl.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  })),
  onChange: PropTypes.func,
  SegmentComponent: PropTypes.elementType,
  sx: PropTypes.shape({}),
  value: PropTypes.any,
};

SegmentedControl.defaultProps = {
  className: undefined,
  items: [],
  onChange: undefined,
  SegmentComponent: Segment,
  sx: {},
  value: undefined,
};


export { SegmentedControl };
export default SegmentedControl;
