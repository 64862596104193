import PropTypes from 'prop-types';
import React from 'react';


const Caret = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M8.48,5.42,22.84,20.1,8.2,34.49,12.62,39,31.8,20.18,13,1Z"
  />
);

Caret.propTypes = {
  strokeWidth: PropTypes.number,
};

Caret.defaultProps = {
  strokeWidth: 1,
};


export default Caret;
