let func;

const appEnv = process.env.APP_ENV;

/* eslint-disable */
export const functionNames = {
  billingReports: 'billingReports',
  changePermissions: 'changePermissions',
  convertPersonToAccountUser: 'convertPersonToAccountUser',
  createFamily: 'createFamily',
  createGroup: 'createGroup',
  createIcsEvent: 'createIcsEvent',
  createNewPhoneUser: 'createNewPhoneUser',
  createNewUser: 'createNewUser',
  createNewUserNoAccount: 'createNewUserNoAccount',
  deleteFamily: 'deleteFamily',
  deleteGroup: 'deleteGroup',
  deleteUser: 'deleteUser',
  editFamily: 'editFamily',
  editGroup: 'editGroup',
  editMember: 'editMember',
  editMemberNoAccount: 'editMemberNoAccount',
  exportData: 'exportData',
  familyLeader_editFamily: 'familyLeader_editFamily',
  generateFieldId: 'generateFieldId',
  getAdmins: 'getAdmins',
  getCountryCodes: 'getCountryCodes',
  getDirectory: 'getDirectory',
  getDirectoryCategories: 'getDirectoryCategories',
  getDirectoryFields: 'getDirectoryFields',
  getDirectorySettings: 'getDirectorySettings',
  getFamilies: 'getFamilies',
  getFamily: 'getFamily',
  getFamilyMembers: 'getFamilyMembers',
  getFamilySummaries: 'getFamilySummaries',
  getFeatures: 'getFeatures',
  getGroup: 'getGroup',
  getGroupLeaders: 'getGroupLeaders',
  getGroups: 'getGroups',
  getGroupSummaries: 'getGroupSummaries',
  getIpData: 'getIpData',
  getMe: 'getMe',
  getMember: 'getMember',
  getMemberExportFields: 'getMemberExportFields',
  getMembers: 'getMembers',
  getMembersForTags: 'getMembersForTags',
  getMessageFamilyRecipients: 'getMessageFamilyRecipients',
  getMessageGroupRecipients: 'getMessageGroupRecipients',
  getMessageGroupRecipientsForGroupOwner: 'getMessageGroupRecipientsForGroupOwner',
  getMessageRecipients: 'getMessageRecipients',
  getMessageRecipientsForGroupOwner: 'getMessageRecipientsForGroupOwner',
  getMyLedFamilies: 'getMyLedFamilies',
  getMyLedGroups: 'getMyLedGroups',
  getMyProfile: 'getMyProfile',
  getPersonSummaries: 'getPersonSummaries',
  getProvincesForCountry: 'getProvincesForCountry',
  getSavedDrafts: 'getSavedDrafts',
  getSentMessages: 'getSentMessages',
  getTags: 'getTags',
  groupLeader_editGroup: 'groupLeader_editGroup',
  handleUserCreated: 'handleUserCreated',
  handleUserDisconnect: 'handleUserDisconnect',
  importMembersFromCsv: 'importMembersFromCsv',
  removeImageUrl: 'removeImageUrl',
  saveDirectorySettings: 'saveDirectorySettings',
  saveMessageDraft: 'saveMessageDraft',
  searchDirectory: 'searchDirectory',
  sendMagicLink: 'sendMagicLink',
  sendMessage: 'sendMessage',
  sendMessageToCategory: 'sendMessageToCategory',
  setNewUserPassword: 'setNewUserPassword',
  setUserRecoveryEmail: 'setUserRecoveryEmail',
  switchMyActiveDirectory: 'switchMyActiveDirectory',
  updateDirectoryCategories: 'updateDirectoryCategories',
  updateDirectoryFields: 'updateDirectoryFields',
  updateDirectoryInfo: 'updateDirectoryInfo',
  userAccountRecovery: 'userAccountRecovery',
  verifyUserRecoveryEmail: 'verifyUserRecoveryEmail',
};

class FireFunctionsService {
  static async init(fbFunctions) {
    func = fbFunctions;
    window.FFS = FireFunctionsService;
  }

  static getFunctions = () => func;

  static httpsCallable = async (functionName, functionData) => {
    try {
      const fn = functionNames[functionName];
      const callable = func.httpsCallable(fn);
      return callable(functionData);
    } catch (err) {
      console.log(err, err.message);
      return undefined;
    }
  };
}

export default FireFunctionsService;
