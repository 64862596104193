import React from 'react';
import classNames from 'classnames';

import Icon, { IconNames } from './presenter';

import { iconDefaultProps, iconPropTypes } from './iconPropTypes';


const SwitchIcon = ({ className, size, ...rest }) => (
  <Icon
    className={classNames({
      'j-icon-switch': true,
      [className]: className,
    })}
    name={IconNames.SWITCH}
    size={size}
    {...rest}
  />
);

SwitchIcon.propTypes = {
  ...iconPropTypes,
};

SwitchIcon.defaultProps = {
  ...iconDefaultProps,
};


export default SwitchIcon;
