import { routeConstants } from '../constants';
import { getQuery } from '../utils';


export const isAdminRoute = pathname => (
  pathname && pathname.includes(routeConstants.ADMIN_PANEL_ROUTE_BASE)
);

export const isPeopleRoute = pathname => (
  pathname && pathname.includes(routeConstants.PEOPLE_ROUTE_BASE) && !isAdminRoute(pathname)
);

export const isListingIdRoute = pathname => (
  pathname
  && (pathname.includes(routeConstants.PEOPLE_ROUTE_BASE)
    || pathname.includes(routeConstants.GROUPS_ROUTE_BASE)
    || pathname.includes(routeConstants.FAMILIES_ROUTE_BASE))
  && !isAdminRoute(pathname)
  && pathname.split('/').length > 4
);

export const isGroupsRoute = pathname => (
  pathname && pathname.includes(routeConstants.GROUPS_ROUTE_BASE) && !isAdminRoute(pathname)
);

export const isFamiliesRoute = pathname => (
  pathname && pathname.includes(routeConstants.FAMILIES_ROUTE_BASE) && !isAdminRoute(pathname)
);

export const getPaginationPageQuery = (location = window.location) => {
  const page = getQuery(location.search, 'page');

  if (page) {
    return parseInt(page, 10);
  }

  return 1;
};

export const getLastLocationInfoExcludingPaths = (locationHistory, exclude = []) => {
  const searchLocations = locationHistory.slice(1).reverse();

  const lastLocation = searchLocations.find((loc, idx) => {
    const foundExcluded = exclude.find((path) => {
      console.log(path);
      return loc.pathname.includes(path);
    });

    if (foundExcluded) {
      return false;
    }

    return loc;
  });

  return {
    location: lastLocation,
    index: locationHistory.indexOf(lastLocation),
  };
};
