import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { Filter } from '../shapes';


const FilterSvg = (props) => (
  <g>
    <IconBounds />
    <Filter {...props} />
  </g>
);

FilterSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

FilterSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default FilterSvg;
