import { call, fork, put, take, select } from 'redux-saga/effects';

import { dashboardRoutePaths } from 'modules/router/constants';

import { getActiveDirectoryId } from '../directory/selectors';
import { getUser } from '../user/selectors';
import { FireFunctions, functionNames, navService } from '../services';

import { makeQuery } from '../../utils';

import * as actions from './actions';
import * as constants from './constants';
import * as parse from './parse';

/**
 * @param searchType {string} Resource type to search
 * @param searchTerm {string} Search term
 */
export function* requestSearch(searchType, searchTerm) {
  try {
    const directoryId = yield select(getActiveDirectoryId);
    const user = yield select(getUser);

    navService.push(dashboardRoutePaths.search.concat(`?${makeQuery({ s: searchTerm })}`));

    const result = yield call(
      FireFunctions.httpsCallable,
      functionNames.searchDirectory,
      { directoryId, userId: user.id, searchType, searchTerm },
    );

    const results = parse.searchResults(result.data.results);

    yield put(actions.searchSuccess(searchType, results));
  } catch (error) {
    yield put(actions.searchFailure(error.message, error));
  }
}

export function* clearSearch() {
  navService.push(dashboardRoutePaths.search);
  yield null;
}

function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.SEARCH_CLEAR,
      constants.SEARCH_REQUEST,
    ]);

    switch (type) {
      case constants.SEARCH_CLEAR:
        yield fork(
          clearSearch,
        );
        break;

      case constants.SEARCH_REQUEST:
        yield fork(
          requestSearch,
          payload.searchType,
          payload.term,
        );
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
