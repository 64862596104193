import PropTypes from 'prop-types';
import React from 'react';


const People = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M14,23.5c-4.68,0-14,2.34-14,7V34H28V30.5C28,25.84,18.68,23.5,14,23.5ZM4.68,30A19.28,19.28,0,0,1,14,27.5,19.28,19.28,0,0,1,23.32,30ZM14,20a7,7,0,1,0-7-7A7,7,0,0,0,14,20Zm0-10a3,3,0,1,1-3,3A3,3,0,0,1,14,10ZM28.08,23.62C30.4,25.3,32,27.54,32,30.5V34h8V30.5C40,26.46,33,24.16,28.08,23.62ZM26,20A7,7,0,0,0,26,6a6.89,6.89,0,0,0-3,.7,10.92,10.92,0,0,1,0,12.6A6.89,6.89,0,0,0,26,20Z"
  />
);

People.propTypes = {
  strokeWidth: PropTypes.number,
};

People.defaultProps = {
  strokeWidth: 1,
};


export default People;
