import * as R from 'ramda';


export const { last } = R;

export const makeValueIfTrueElse = (ifValue, elseValue) => R.cond([
  [R.equals(true), () => ifValue],
  [R.equals(false), () => elseValue],
]);

export const callWith = (fn, ...args) => () => fn(...args);
