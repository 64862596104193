import React from 'react';
import { render } from 'react-dom';
import firebase from 'firebase';
import * as Sentry from '@sentry/browser';

import './globals';

import App from './main';
import configureStore from './store';
import { firebaseConfig, sentryDsn } from './config';

import { FireAuth, FireDb, FireStorage, FireFunctions } from './modules/services';

import './css';


Sentry.init({
  dsn: sentryDsn,
});

firebase.initializeApp(firebaseConfig);
FireAuth.init(firebase.auth());
FireDb.init(firebase.database());
FireStorage.init(firebase.storage());
FireFunctions.init(firebase.functions());

window.firebase = firebase;
window.emptyFunction = () => {};

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
});

const { pathname, search } = window.location;

const { store, persistor } = configureStore(`${pathname}${search || ''}`);

const lsCleared = localStorage.getItem('cleared_9.14.20');
if (!lsCleared) {
  localStorage.clear();
  localStorage.setItem('cleared_9.14.20', 'true');
}

render(
  <App
    firebase={firebase}
    store={store}
    persistor={persistor} />,
  document.getElementById('app'),
);
