import PropTypes from 'prop-types';
import React from 'react';


const User = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0ZM10.14,32.56C11,30.76,16.24,29,20,29s9,1.76,9.86,3.56a15.85,15.85,0,0,1-19.72,0Zm22.58-2.9C29.86,26.18,22.92,25,20,25s-9.86,1.18-12.72,4.66A16,16,0,1,1,36,20,15.9,15.9,0,0,1,32.72,29.66ZM20,8a7,7,0,1,0,7,7A7,7,0,0,0,20,8Zm0,10a3,3,0,1,1,3-3A3,3,0,0,1,20,18Z"
  />
);

User.propTypes = {
  strokeWidth: PropTypes.number,
};

User.defaultProps = {
  strokeWidth: 1,
};


export default User;
