import React from 'react';
import PropTypes from 'prop-types';

import FieldWithLabel from '../FieldWithLabel';
import RadioButtons from './presenter';


const RadioButtonsWithLabel = (props) => (
  <FieldWithLabel
    {...props}
    Component={RadioButtons}
  />
);

RadioButtonsWithLabel.propTypes = {
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  value: PropTypes.string,
};

RadioButtonsWithLabel.defaultProps = {
  isRequired: undefined,
  onChange: undefined,
  setRef: undefined,
  value: undefined,
};

const RadioButtonsWithLabelWithRef = React.forwardRef((props, ref) => (
  <RadioButtonsWithLabel setRef={ref} {...props} />
));


export { RadioButtonsWithLabel };
export default RadioButtonsWithLabelWithRef;
