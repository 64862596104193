import React from 'react';
import PropTypes from 'prop-types';


const FieldErrors = (
  {
    errors,
    style,
  },
) => (
  <React.Fragment>
    {errors ? (errors.map(e => (
      <span
        key={e}
        style={{
          display: 'block',
          color: '#CA5486',
          fontWeight: 'bold',
        }}
      >
        {e}
      </span>
    ))) : null}
  </React.Fragment>
);

FieldErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.shape({}),
};

FieldErrors.defaultProps = {
  errors: undefined,
  style: {},
};


export default FieldErrors;
