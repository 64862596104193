import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Box from 'modules/core/components/Box';
import Flex from 'modules/core/components/Flex';
import SearchInput from '../SearchInput';

import { HeaderSwitchDir, LaptopMenu, MobileMenu, Title } from './components';


const Header = (props) => {
  const {
    className,
    displaySearch,
    filters,
    hasAnyMessaging,
    onMenuClick,
    onSearch,
    onSearchChange,
    onSearchClear,
    onSwitchClick,
    role,
    searchValue,
    sx,
    title,
    ...rest
  } = props;

  const isLaptopOrLarger = useMediaQuery({
    query: global.BreakpointQuery.laptop,
  });

  const input = React.useRef();

  const handleClear = React.useCallback((evt) => {
    onSearchClear(evt);
    input.current?.focus();
  }, [onSearchClear]);

  const switchAndTitleContent = (
    <React.Fragment>
      <HeaderSwitchDir onSwitchClick={onSwitchClick} />

      <Title>
        {title}
      </Title>
    </React.Fragment>
  );

  return (
    <Box
      className={classNames({
        'flex-col': true,
        'j-header': true,
        [className]: className,
      })}
      sx={{
        padding: 'grid16',
        ...sx,
      }}
      {...rest}
    >
      <Flex
        className="width-100"
        sx={{
          justifyContent: 'space-between',
          height: 'mobileHeaderTextBarHeight',
        }}
      >
        {!isLaptopOrLarger && switchAndTitleContent}

        {isLaptopOrLarger && (
          <Flex>{switchAndTitleContent}</Flex>
        )}

        {!isLaptopOrLarger && <MobileMenu onMenuClick={onMenuClick} />}

        {isLaptopOrLarger && <LaptopMenu filters={filters} hasAnyMessaging={hasAnyMessaging} role={role} />}
      </Flex>

      <Box
        className={classNames({
          'flex-col': true,
          'width-100': true,
        })}
        sx={{
          paddingTop: 'grid12',
        }}
      >
        {displaySearch && (
          <SearchInput
            onChange={onSearchChange}
            onClear={handleClear}
            onSearch={onSearch}
            ref={input}
            value={searchValue}
          />
        )}
      </Box>
    </Box>
  );
};

Header.propTypes = {
  displaySearch: PropTypes.bool,
  filters: PropTypes.shape().isRequired,
  hasAnyMessaging: PropTypes.bool,
  onMenuClick: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchClear: PropTypes.func.isRequired,
  onSwitchClick: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  title: PropTypes.string,
  searchValue: PropTypes.string,
};

Header.defaultProps = {
  displaySearch: true,
  hasAnyMessaging: undefined,
  searchValue: '',
  title: 'Directory',
};


export default Header;
