import PropTypes from 'prop-types';
import React from 'react';


const Folder = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M14.34,8l4,4H36V32H4V8H14.34M16,4H4A4,4,0,0,0,0,8L0,32a4,4,0,0,0,4,4H36a4,4,0,0,0,4-4V12a4,4,0,0,0-4-4H20Z"
  />
);

Folder.propTypes = {
  strokeWidth: PropTypes.number,
};

Folder.defaultProps = {
  strokeWidth: 1,
};


export default Folder;
