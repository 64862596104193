import styled from 'styled-components';


const StyledInfiniteScrollLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
`;


export { StyledInfiniteScrollLoader };
