import React from 'react';

import { SmallLoader } from 'modules/core/components';

import RoutesSwitch from './RoutesSwitch';


class Dashboard extends React.Component {
  componentDidMount() {
    this.handleGetDirectoryData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;
    if (user.id && !prevUser.id) {
      this.handleGetDirectoryData();
    }
  }

  handleGetDirectoryData = () => {
    const {
      directoryId,
      fbDirectoryRequest,
      fetchEntities,
      getFeatures,
      requestLedFamilies,
      requestLedGroups,
    } = this.props;

    if (directoryId) {
      fbDirectoryRequest(directoryId);

      getFeatures();

      fetchEntities({
        getFamilies: true,
        getGroups: true,
        getPeople: true,
        families: [1],
        groups: [1],
        people: [1, undefined, undefined, undefined, undefined, {
          families: true,
        }],
      });

      requestLedFamilies();
      requestLedGroups();
    }
  };

  render() {
    return (
      <React.Suspense fallback={<SmallLoader show={false} />}>
        <RoutesSwitch />
      </React.Suspense>
    );
  }
}

export default Dashboard;
