import * as React from 'react';

import { FireAuth } from '../../services';

function withAuth(Component) {
  return class ComponentWithAdminDb extends React.Component {
    render() {
      return (
        <Component
          loginWithEmail={FireAuth.sendMagicLinkEmail}
          loginWithPhone={FireAuth.loginWithPhone}
          {...this.props} />
      );
    }
  };
}

export default withAuth;
