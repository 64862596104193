import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { FilterAlt } from '../shapes';


const FilterAltSvg = (props) => (
  <React.Fragment>
    <defs>
      <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="40"
          id="filter-alt-icon-filter"
          width="40"
          x="0"
          y="0"
      >
        <feColorMatrix
            values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
            result="invert"
        />
        <feFlood floodColor="#fff" result="bg" />
        <feBlend in="invert" in2="bg" />
      </filter>
      <mask id="filter-alt-icon-mask" x="0" y="0" width="40" height="40" maskUnits="userSpaceOnUse">
        <polygon
            className="cls-1"
            filter="url(#filter-alt-icon-filter)"
            points="20 21 33 5 7 5 20 21"
        />
      </mask>
    </defs>
    <g>
      <IconBounds />
      <FilterAlt {...props} />
    </g>
  </React.Fragment>
);

FilterAltSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

FilterAltSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default FilterAltSvg;
