export const getReqPaginationPage = (
  totalPages = 1,
  currentPage = 1,
  options = {},
) => {
  const { isNext, isPrev, pageNumber: requestedPageNumber } = options;

  let pageNumber = requestedPageNumber || 1;

  if (isNext) {
    pageNumber = currentPage + 1;
  } else if (isPrev) {
    pageNumber = currentPage - 1;
  }

  if (pageNumber < 1) {
    pageNumber = 1;
  } else if (pageNumber > totalPages) {
    pageNumber = totalPages;
  }

  return pageNumber;
};

export const getEntityReqPayload = (dirId, data = {}, meta = {}) => ({
  ...(data || {}),
  directoryId: dirId,
  meta: meta || {},
});

export const getEntityReqContentMeta = (sortFallback = {}, options = {}) => {
  const { by = ['name'], dir = ['asc'] } = sortFallback;
  const { pageNumber, sort, sortDir, indexStart, indexEnd = 'Z' } = options;

  let contentIndex;
  let contentRange;

  if (indexStart) {
    contentIndex = `[${indexStart},${indexEnd}]`;
  } else {
    const numPerPage = 25;
    const rangeStart = pageNumber ? (pageNumber - 1) * numPerPage : 0;
    const rangeEnd = pageNumber ? pageNumber * numPerPage : numPerPage;
    contentRange = `[${rangeStart},${rangeEnd}]`;
  }

  return {
    contentIndex,
    contentRange,
    sort: sort || by,
    sortDir: sortDir || dir,
  };
};

export const getReqPayload = getEntityReqPayload;
