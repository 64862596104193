//
import * as React from 'react';
import Select from 'react-select';

import { classie } from '../../../../utils';

import styles from './styles.css';


class FieldTypeSelect extends React.Component {
  static defaultProps = {
    className: '',
    options: [],
  };

  handleChange = (opt) => {
    const { onChange } = this.props;
    onChange(opt.value);
  };

  render() {
    const {
      value,
      className,
      options,
      disabled,
    } = this.props;

    return (
      <Select
        isDisabled={disabled}
        className={classie(['react-select info', styles.select, className], {
          [styles.interactable]: !disabled,
        })}
        classNamePrefix="react-select"
        placeholder="Select Type..."
        name="fieldType"
        value={value}
        options={options}
        onChange={this.handleChange} />
    );
  }
}

export default FieldTypeSelect;
