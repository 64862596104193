export default {
  // 8/25/19 add members pagination
  0: state => ({
    ...state.members,
    members: {},
    pagination: {
      count: 1,
      page: 1,
      pages: 1,
      pageSize: 3,
    },
  }),
  // 9/3/19 update member ids state
  1: state => ({
    ...state.members,
    memberIds: [],
  }),
  2: state => ({
    ...(state.members || {}),
    pagination: {
      count: 1,
      page: 1,
      pages: 1,
      pageSize: 25,
    },
  }),
  // 9/21/19 add person summaries
  3: state => ({
    ...(state.members || {}),
    personSummaries: {
      ids: [],
      isFetching: false,
      summaries: {},
    },
  }),
  4: state => ({
    ...(state.members || {}),
    lastFetched: null,
    personSummaries: {
      ...(state.members ? state.members.personSummaries || {} : {}),
      lastFetched: null,
    },
  }),
  // 9/11/20 save sort selection in state
  5: state => ({
    ...(state.members || {}),
    sort: {
      by: ['lastName', 'firstName'],
      dir: ['asc', 'asc'],
    },
  }),
};
