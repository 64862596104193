import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import HeadingWithRef from './Heading';


const ProfileHeading = (props) => {
  const {
    className,
    setRef,
    sx,
    ...rest
  } = props;

  return (
    <HeadingWithRef
      className={classNames({
        'j-text': true,
        [className]: className,
      })}
      ref={setRef}
      sx={{
        color: 'systemTextLight',
        fontSize: 'font18',
        fontWeight: 'semibold',
        ...sx,
      }}
      {...rest}
    />
  );
};

ProfileHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
};

ProfileHeading.defaultProps = {
  children: undefined,
  className: undefined,
  setRef: undefined,
  sx: {},
};

const ProfileHeadingWithRef = React.forwardRef((props, ref) => (
  <ProfileHeading setRef={ref} {...props} />
));


export { ProfileHeading };
export default ProfileHeadingWithRef;
