import * as constants from './constants';

export const name = 'auth';

const initialState = {
  rememberMe: true,
  token: undefined,
  isAuthenticated: false,
  fbUser: {},
  expiration: undefined,
  isFetching: false,
  isFetchingSmsCode: false,
  setPasswordStatus: constants.setPasswordStatus.NOT_STARTED,
  smsCode: {
    phone: undefined,
    expiration: undefined,
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SIGNUP_REQUEST: // fallthrough
    case constants.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.SIGNUP_SUCCESS: // fallthrough
    case constants.LOGIN_SUCCESS:
      return {
        isFetching: false,
        isAuthenticated: true,
        token: action.payload.token,
        expiration: action.payload.expiration,
      };

    case constants.FB_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.FB_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        fbUser: action.payload.authUser,
      };

    case constants.FB_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        fbUser: {},
      };

    case constants.RESUME_SESSION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        expiration: action.payload.expiration,
      };

    case constants.LOGOUT_SUCCESS: // fallthrough
    case constants.SIGNUP_FAILURE: // fallthrough
    case constants.RESUME_SESSION_FAILURE: // fallthrough
    case constants.LOGIN_FAILURE:
      return initialState;

    case constants.START_PHONE_LOGIN_REQUEST:
      return {
        ...state,
        isFetchingSmsCode: true,
      };

    case constants.START_PHONE_LOGIN_SUCCESS:
      return {
        ...state,
        isFetchingSmsCode: false,
        smsCode: {
          phone: action.payload.phone,
          expiration: action.payload.expiration,
        },
      };

    case constants.START_PHONE_LOGIN_FAILURE:
      return {
        ...state,
        isFetchingSmsCode: false,
      };

    case constants.VERIFY_PHONE_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.VERIFY_PHONE_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: action.payload.token,
        expiration: action.payload.expiration,
        smsCode: {
          phone: undefined,
          expiration: undefined,
        },
      };

    case constants.VERIFY_PHONE_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case constants.SET_PASSWORD_REQUEST:
      return {
        ...state,
        setPasswordStatus: constants.setPasswordStatus.FETCHING,
      };

    case constants.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordStatus: constants.setPasswordStatus.SUCCESS,
      };

    case constants.SET_PASSWORD_FAILURE:
      return {
        ...state,
        setPasswordStatus: constants.setPasswordStatus.FAILURE,
      };

    case constants.TOGGLE_REMEMBER_ME:
      return {
        ...state,
        rememberMe: !state.rememberMe,
      };

    default:
      return state;
  }
}
