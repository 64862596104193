import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Input as RebassInput } from '@rebass/forms';


const Input = (props) => {
  const {
    className,
    errors,
    isDisabled,
    isRequired,
    isStatic,
    onChange,
    setRef,
    sx,
    value,
    ...rest
  } = props;

  const disabledSx = {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
    transition: 'none',
    paddingLeft: 'grid0',
    paddingRight: 'grid0',
    ':disabled': {
      backgroundColor: 'transparent',
      color: 'text',
    },
  };

  let inputSx = {
    borderColor: errors && errors.length ? 'danger' : 'border',
    borderRadius: 'input',
    boxShadow: 'inputLight',
    height: 'controlHeight',
    transition: 'borderColorBoxShadow',
    paddingLeft: 'grid8',
    paddingRight: 'grid8',
    ...sx,
    ':focus': {
      borderColor: 'focusBorder',
      boxShadow: 'inputLightHover',
      ...(sx[':focus'] || {}),
    },
    ':disabled': {
      backgroundColor: 'disabled',
      color: 'disabledText',
    },
  };

  if (isStatic) {
    inputSx = {
      ...inputSx,
      ...disabledSx,
    };
  }

  return (
    <RebassInput
      className={classNames({
        'j-input': true,
        [className]: className,
      })}
      onChange={onChange}
      disabled={isDisabled || isStatic}
      ref={setRef}
      required={isRequired}
      sx={inputSx}
      value={value}
      {...rest}
    />
  );
};

Input.propTypes = {
  className: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isStatic: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
  value: PropTypes.string,
};

Input.defaultProps = {
  className: undefined,
  errors: undefined,
  isDisabled: undefined,
  isRequired: undefined,
  isStatic: undefined,
  onBlur: undefined,
  onChange: undefined,
  setRef: undefined,
  sx: {},
  value: undefined,
};

const InputWithRef = React.forwardRef((props, ref) => (
  <Input setRef={ref} {...props} />
));


export { Input };
export default InputWithRef;
