import PropTypes from 'prop-types';
import React from 'react';


const Check = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M12.73,28.71,3.18,19.16,0,22.34,12.73,35.07,40,7.8,36.82,4.61Z"
  />
);

Check.propTypes = {
  strokeWidth: PropTypes.number,
};

Check.defaultProps = {
  strokeWidth: 1,
};


export default Check;
