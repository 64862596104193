import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { Check } from '../shapes';


const CheckSvg = (props) => (
  <g>
    <IconBounds />
    <Check {...props} />
  </g>
);

CheckSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

CheckSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default CheckSvg;
