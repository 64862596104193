import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { reducer as admin } from '../admin';
import { reducer as auth } from '../auth';
import { reducer as defs } from '../definitions';
import { reducer as directory } from '../directory';
import { reducer as error } from '../error';
import { reducer as families } from '../families';
import { reducer as files } from '../files';
import { reducer as filters } from '../filters';
import { reducer as groups } from '../groups';
import { reducer as members } from '../members';
import { reducer as messages } from '../messages';
import { reducer as meta } from '../core';
import { reducer as panel } from '../panel';
import { reducer as router } from '../router';
import { reducer as search } from '../search';
import { reducer as tags } from '../tags';
import { reducer as user } from '../user';

import {
  LOGOUT_SUCCESS,
  RESUME_SESSION_FAILURE,
} from '../auth/constants';

import {
  membersPersistConfig,
  defaultPersistConfig,
  familiesPersistConfig,
  groupsPersistConfig,
} from './persistence';

/**
 * rootReducer
 */
const appReducer = combineReducers({
  [admin.name]: admin.reducer,
  [auth.name]: auth.reducer,
  [defs.name]: defs.reducer,
  [directory.name]: directory.reducer,
  [error.name]: error.reducer,
  [families.name]: persistReducer(familiesPersistConfig, families.reducer),
  [files.name]: files.reducer,
  [filters.name]: filters.reducer,
  [groups.name]: persistReducer(groupsPersistConfig, groups.reducer),
  [members.name]: persistReducer(membersPersistConfig, members.reducer),
  [messages.name]: messages.reducer,
  [meta.name]: meta.reducer,
  [panel.name]: panel.reducer,
  [router.name]: router.reducer,
  [search.name]: search.reducer,
  [tags.name]: tags.reducer,
  [user.name]: user.reducer,
});

/**
 * rootReducer
 * Always returns the appReducer, but resets the state to undefined on logout.
 * This forces all reducers to use the default values in initialState.
 * @param {*} state
 * @param {{ type: string, payload: * }} action
 */
const rootReducer = (state, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      localStorage.clear();
      return appReducer(undefined, {});
    default:
      return appReducer(state, action);
  }
};

const persistedReducer = persistReducer(defaultPersistConfig, rootReducer);

export default persistedReducer;
