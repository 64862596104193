import { call, fork, put, take, select } from 'redux-saga/effects';

import { getActiveDirectoryId } from '../directory/selectors';
import { FireFunctions, functionNames } from '../services';

import * as actions from './actions';
import * as constants from './constants';
import * as parse from './parse';

export function* requestTags() {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    const result = yield call(
      FireFunctions.httpsCallable,
      functionNames.getTags,
      { directoryId },
    );

    const { results } = result.data;

    yield put(actions.tagsSuccess(results));
  } catch (error) {
    yield put(actions.tagsFailure(error.message, error));
  }
}

export function* requestMembersByTag(tags) {
  try {
    const directoryId = yield select(getActiveDirectoryId);

    if (!tags || tags.length === 0) {
      yield put(actions.membersByTagSuccess({}));
    }

    const result = yield call(
      FireFunctions.httpsCallable,
      functionNames.getMembersForTags,
      { directoryId, tags },
    );

    const { results } = result.data;

    yield put(actions.membersByTagSuccess(results));
  } catch (error) {
    yield put(actions.membersByTagFailure(error.message, error));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.TAGS_REQUEST,
      constants.MEMBERS_BY_TAG_REQUEST,
    ]);

    switch (type) {
      case constants.TAGS_REQUEST:
        yield fork(requestTags);
        break;

      case constants.MEMBERS_BY_TAG_REQUEST:
        yield fork(requestMembersByTag, payload.tags);
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
