import PropTypes from 'prop-types';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import jubileeTheme from 'config/colorTheme';

import { titleForUrlPathname } from 'utils';

import { TitleProvider, ThemeProvider } from 'modules/core/context';
import { SmallLoader } from 'modules/core/components';
import { SearchPortal, SearchContainer } from 'modules/search/components';

import { FireAuth, navService } from 'modules/services';

import { Auth, FinishEmailLogin } from 'modules/auth/components';

import AnonRoute from '../AnonRoute';
import Header from '../Header';
import RouteWatchdog from '../RouteWatchdog';

import { statusConstants } from '../../../../constants';
import { dashboardRoutePaths, panelRoutePaths } from '../../constants';

import RoutesBlockLayout from './RoutesBlockLayoutContainer';


const { Suspense } = React;
const { DirectoryStatus } = statusConstants;

const SearchWrapper = SearchContainer(SearchPortal);

class Routes extends React.Component {
  state = {
    showSearch: false,
  };

  componentDidMount() {
    const {
      appRoutes,
      history,
      location,
      nextRoute,
      onLoginSuccess,
      onLogoutRequest,
      onRequestDefs,
      onUserRequest,
    } = this.props;

    onRequestDefs();

    const auth = FireAuth.getAuth();

    if (location.pathname !== appRoutes.PUBLIC.WELCOME.path) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          onLoginSuccess(user);
          onUserRequest(user.uid);
          if (nextRoute) history.push(nextRoute);
        } else {
          onLogoutRequest();
        }
      });
    }

    navService.setNav(history);
  }

  componentDidUpdate(prevProps) {
    const { userDirs: lastUserDirs = {} } = prevProps;
    const {
      appRoutes,
      directory,
      history,
      location,
      onMultiDirectoryRequest,
      userDirs = {},
    } = this.props;

    const lastDirIds = Object.keys(lastUserDirs);
    const currentDirIds = Object.keys(userDirs);

    if (directory.status === DirectoryStatus.suspended || directory.status === DirectoryStatus.removed) {
      if (!location.pathname.includes(dashboardRoutePaths.dirUnavailable)) {
        history.push(dashboardRoutePaths.dirUnavailable);
      }
    }

    if (location.pathname !== appRoutes.PUBLIC.WELCOME.path) {
      if (lastDirIds.length !== currentDirIds.length) {
        onMultiDirectoryRequest(currentDirIds);
      }
    }
  }

  handleLogout = async () => {
    const { onLogoutSuccess } = this.props;
    const success = await FireAuth.logout();
    if (success) onLogoutSuccess();
  };

  toggleSearch = (evt) => {
    if (evt) evt.preventDefault();

    this.setState({ showSearch: !this.state.showSearch });
  };

  render() {
    const {
      appRoutes,
      directory = {},
      location,
      settingsTheme,
      theme,
    } = this.props;

    const { showSearch } = this.state;

    const directoryName = directory ? directory.name : '';
    const title = titleForUrlPathname(location.pathname, directoryName);
    const mergedTheme = {
      ...jubileeTheme[settingsTheme],
      ...jubileeTheme.all,
      accents: jubileeTheme.accents,
      brand: jubileeTheme.brand,
    };
    const searchType = location.pathname.split('/');

    return (
      <TitleProvider value={title}>
        <ThemeProvider value={mergedTheme}>
          <RouteWatchdog location={location} />

          <Route
            render={() => (
              <Header
                toggleSearch={this.toggleSearch}
                theme={theme}
                dirName={directoryName}
                location={location}
                pathname={location.pathname}
                onLogout={this.handleLogout}
              />
            )}
          />

          <Suspense fallback={<SmallLoader show={true} />}>
            <Switch>
              <Route
                path={appRoutes.PUBLIC.FINISH_EMAIL_LOGIN.path}
                component={FinishEmailLogin}
              />

              <AnonRoute path={appRoutes.PUBLIC.LOGIN.path} Component={Auth} />

              <Route
                component={RoutesBlockLayout}
                path={[appRoutes.AUTH.DASHBOARD.path, panelRoutePaths.panel]}
              />

              <Redirect from="/" to={appRoutes.AUTH.DIRECTORY.path} />
            </Switch>
          </Suspense>

          <SearchWrapper
            toggleSearch={this.toggleSearch}
            theme={theme}
            isOpen={showSearch}
          />
        </ThemeProvider>
      </TitleProvider>
    );
  }
}

Routes.propTypes = {
  appRoutes: PropTypes.shape(),
  directory: PropTypes.shape(),
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  nextRoute: PropTypes.string,
  onLoginSuccess: PropTypes.func.isRequired,
  onLogoutRequest: PropTypes.func.isRequired,
  onLogoutSuccess: PropTypes.func.isRequired,
  onMultiDirectoryRequest: PropTypes.func.isRequired,
  onRequestDefs: PropTypes.func.isRequired,
  onUserRequest: PropTypes.func.isRequired,
  onUserRequestTreeRequest: PropTypes.func.isRequired,
  role: PropTypes.string,
  settingsTheme: PropTypes.string,
  theme: PropTypes.shape(),
  userDirs: PropTypes.shape(),
};

Routes.defaultProps = {
  appRoutes: undefined,
  directory: {},
  nextRoute: undefined,
  role: undefined,
  settingsTheme: undefined,
  theme: {},
  userDirs: {},
};

export default Routes;
