import PropTypes from 'prop-types';
import React from 'react';


const CaretLeft = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M32.13,5.36,17,20.81,32.42,36l-4.65,4.75L7.58,20.9,27.39.71Z"
  />
);

CaretLeft.propTypes = {
  strokeWidth: PropTypes.number,
};

CaretLeft.defaultProps = {
  strokeWidth: 1,
};


export default CaretLeft;
