import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { getRole, getUser, getUserWebappState } from '../../../user/selectors';
import { logoutSuccess } from '../../../auth/actions';
import { getActiveDirectory, getDirectories, getActiveDirectoryId } from '../../../directory/selectors';
import { fbDirectoryRequest, switchDirectory } from '../../../directory/actions';
import { fbUserTreeRequest } from '../../../user/actions';

import { withTheme } from '../../../core/hoc';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  role: getRole,
  directory: getActiveDirectory,
  directoryId: getActiveDirectoryId,
  user: getUser,
  directories: getDirectories,
  webappState: getUserWebappState,
});

const mapDispatchToProps = {
  logoutSuccess,
  fbDirectoryRequest,
  setDirectorySwitched: switchDirectory,
  refreshUserTreeRequest: fbUserTreeRequest,
};

const enhance = compose(
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(presenter);
