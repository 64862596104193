import PropTypes from 'prop-types';
import React from 'react';

import { roleHelpers } from 'helpers';

import Flex from '../Flex';
import { IconNames } from '../Icon';
import Email from './Email';


const EmailList = ({ id, emails, isFetching, role }) => {
  const isAdmin = roleHelpers.isAdminRole(role);

  return (
    <Flex
      flexDirection="column"
    >
      {emails && emails.reduce((items, e, idx) => {
        if (!e.email) {
          return items;
        }

        const isLast = idx === emails.length - 1;

        return [...items, (
          <Email
            email={e}
            iconName={IconNames.MAIL}
            id={id}
            isAdmin={isAdmin}
            isFetching={isFetching}
            key={e.email}
            sx={{
              marginBottom: isLast ? 'grid0' : 'grid16',
            }}
          />
        )];
      }, [])}
    </Flex>
  );
};

EmailList.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  role: PropTypes.string,
};

EmailList.defaultProps = {
  emails: undefined,
  isFetching: undefined,
  role: undefined,
};


export default EmailList;
