import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router';

import * as colors from '../../../colors';
import { IconSize } from '../../../sizes';
import { usePrevious } from '../../../../../hooks';

import Box from '../../Box';
import Logo from '../../Logo';

import { ClearIcon } from '../../Icon';


const MobileMenuBase = (props) => {
  const {
    children,
    className,
    items,
    isOpen,
    onClose,
    sx,
    ...rest
  } = props;

  const location = useLocation();
  const [transitionClassName, setTransitionClassName] = React.useState('');

  const lastLocation = usePrevious(location);
  const wasOpen = usePrevious(isOpen);

  const handleClose = React.useCallback((...args) => {
    setTransitionClassName(isOpen ? 'j-closing' : '');
    setTimeout(() => {
      onClose(...args);
    }, 200);
  }, [isOpen, onClose]);

  React.useEffect(() => {
    if (lastLocation && location.pathname !== lastLocation.pathname) {
      if (isOpen) {
        handleClose();
      }
    }
  }, [isOpen, lastLocation, location.pathname, onClose]);

  React.useEffect(() => {
    if (!wasOpen && isOpen) {
      setTransitionClassName('');
      document.querySelector('#app').classList.add('scroll-lock');
    } else if (!isOpen) {
      document.querySelector('#app').classList.remove('scroll-lock');
    }
  }, [isOpen, wasOpen]);

  return (
    <Box
      className={classNames({
        'full-screen': true,
        'j-mobile-menu': true,
        'j-open': isOpen,
        [transitionClassName]: transitionClassName,
        [className]: className,
      })}
      sx={{
        backgroundColor: 'primary',
        transition: 'slideFromRight',
        ...sx,
      }}
      {...rest}
    >
      <Box
        className={classNames({
          'full-screen': true,
          'pos-rel': true,
        })}
      >
        <Box
          className={classNames({
            'pos-abs': true,
            'z-1000': true,
          })}
          onClick={handleClose}
          sx={{
            right: 'grid16',
            top: 'grid16',
          }}
        >
          <ClearIcon
            className="cursor-pointer"
            color={colors.white}
            hoverColor={colors.alto}
            size={IconSize.ICON_MED}
          />
        </Box>

        {typeof children === 'function' ? children({ onClose: handleClose }) : children}

        <Box
          className={classNames({
            'j-mobile-menu-logo': true,
            'pos-abs': true,
            'z-1000': true,
          })}
          sx={{
            bottom: 'grid16',
            height: 'logoDefaultHeight',
            left: 'grid16',
            width: 'logoDefaultWidth',
          }}
        >
          <Logo />
        </Box>
      </Box>
    </Box>
  );
};

MobileMenuBase.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  sx: PropTypes.shape({}),
};

MobileMenuBase.defaultProps = {
  children: undefined,
  className: undefined,
  isOpen: undefined,
  sx: {},
};


export default MobileMenuBase;
