import PropTypes from 'prop-types';
import React from 'react';


const Drafts = ({ strokeWidth, ...rest }) => (
  <React.Fragment>
    <path
      strokeWidth={strokeWidth}
      {...rest}
      d="M40,14.5a4,4,0,0,0-1.88-3.4L20,.5,1.9,11.1A4,4,0,0,0,0,14.5v20a4,4,0,0,0,4,4H36a4,4,0,0,0,4-4Zm-4,0v0l-16,10L4,14.5,20,5.14ZM4,34.5V19.18l16,10,16-10L36,34.5Z"
    />
  </React.Fragment>
);

Drafts.propTypes = {
  strokeWidth: PropTypes.number,
};

Drafts.defaultProps = {
  strokeWidth: 1,
};


export default Drafts;
