import PropTypes from 'prop-types';
import React from 'react';


const EyeOff = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M20,9.05a17.77,17.77,0,0,1,16,10,17.79,17.79,0,0,1-4.39,5.67l2.57,2.56A21.31,21.31,0,0,0,40,19.05,21.5,21.5,0,0,0,13.38,6.45l3,3A18.08,18.08,0,0,1,20,9.05Zm-1.95,2.07,3.77,3.76a4.6,4.6,0,0,1,2.33,2.33L27.91,21A8.81,8.81,0,0,0,28.16,19,8.15,8.15,0,0,0,20,10.86,7.51,7.51,0,0,0,18.05,11.12ZM1.84,5.17l4.87,4.88a21.28,21.28,0,0,0-6.71,9A21.51,21.51,0,0,0,20,32.68a21.37,21.37,0,0,0,7.85-1.49l6.22,6.22,2.57-2.56L4.4,2.59ZM15.47,18.81l4.75,4.74a.85.85,0,0,1-.22,0,4.55,4.55,0,0,1-4.55-4.54C15.45,19,15.47,18.9,15.47,18.81ZM9.29,12.63l3.18,3.18a8.33,8.33,0,0,0-.65,3.24,8.18,8.18,0,0,0,11.4,7.52L25,28.35a18.81,18.81,0,0,1-5,.7,17.78,17.78,0,0,1-16-10A18.07,18.07,0,0,1,9.29,12.63Z"
  />
);

EyeOff.propTypes = {
  strokeWidth: PropTypes.number,
};

EyeOff.defaultProps = {
  strokeWidth: 1,
};


export default EyeOff;
