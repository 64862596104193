import firebase from 'firebase';


let db;

const paths = {
  user: uid => `users/${uid}`,
  userProfile: profileId => `user_profiles/${profileId}`,
  directoryUser: (uid, did) => 'user_profiles',
  directory: did => `directories/${did}`,
  directoryData: did => `directories/${did}/data`,
  directoryFields: did => `directories/${did}/fields`,
  directoryCategories: did => `directories/${did}/categories`,
  directoryTags: did => `directories/${did}/tags`,
  pendingUsers: uid => `pendingUsers/${uid}`,
  family: fid => `families/${fid}`,
  group: gid => `groups/${gid}`,
  messages: did => `messages/${did}`,
  settings: did => `directory_settings/${did}`,
};

class FireDbService {
  static init(database) {
    db = database;
  }

  static async getUserTree(uid) {
    try {
      const snapshot = await db.ref(paths.user(uid)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async generateCustomFieldId(uid, did) {
    try {
      return db.ref(paths.directoryUser(uid, did)).child('customFields').push().key;
    } catch (err) {
      return null;
    }
  }

  static async getDirectoryUserTree(uid, did) {
    try {
      const snapshot = await db.ref(paths.directoryUser(uid, did)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async switchUserActiveDirectory(uid, did) {
    try {
      await db.ref(paths.user(uid)).child('webappState').update({
        activeDirectory: did,
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  static async getPendingUserTree(uid) {
    try {
      const snapshot = await db.ref(paths.pendingUsers(uid)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async setUserPhoneNumber(uid, phoneNumber = '') {
    try {
      await db.ref(paths.user(uid)).update({ phoneNumber });
      return true;
    } catch (err) {
      console.log(err, err.message);
      return false;
    }
  }

  static async setUserTree(uid, did, updates = {}) {
    try {
      const profileRef = await db.ref('users').child(uid).child('profiles').child(did)
        .once('value');
      const profileId = profileRef.val();
      await db.ref('user_profiles').child(profileId).set(updates);
      return true;
    } catch (err) {
      console.log(err, err.message);
      return false;
    }
  }

  static async updateUserTree(uid, did, updates = {}) {
    try {
      const profileRef = await db.ref('users').child(uid).child('profiles').child(did)
        .once('value');
      const profileId = profileRef.val();
      await db.ref('user_profiles').child(profileId).update(updates);
      return true;
    } catch (err) {
      return false;
    }
  }

  static async getDirectoryTree(did) {
    try {
      const snapshot = await db.ref(paths.directory(did)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async addDirectoryMember(did, uid) {
    try {
      await db
        .ref(paths.directory(did))
        .child(`members/${uid}`)
        .set(true);
      return true;
    } catch (err) {
      return false;
    }
  }

  static async getDirectoryFields(did) {
    try {
      const snapshot = await db.ref(paths.directoryFields(did)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async updateDirectoryFields(did, updates) {
    try {
      await db.ref(paths.directoryFields(did)).update(updates);
      return true;
    } catch (err) {
      return null;
    }
  }

  static async updateOneDirectoryField(
    did, fid, updates,
  ) {
    try {
      await db.ref(paths.directoryFields(did)).child(fid).set(updates);
      return true;
    } catch (err) {
      return null;
    }
  }

  static async generateFieldId(did) {
    try {
      return db.ref(paths.directoryFields(did)).push().key;
    } catch (err) {
      return null;
    }
  }

  static async getDirectoryCategories(did) {
    try {
      const snapshot = await db.ref(paths.directoryCategories(did)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async updateDirectoryCategories(did, updates) {
    try {
      await db.ref(paths.directoryCategories(did)).update(updates);
      return true;
    } catch (err) {
      return false;
    }
  }

  static async updateOneCategory(
    did, cid, updates,
  ) {
    try {
      await db.ref(paths.directoryCategories(did)).child(cid).set(updates);
      return true;
    } catch (err) {
      return null;
    }
  }

  static async getDirectoryTags(did) {
    try {
      const snapshot = await db.ref(paths.directoryTags(did)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async updateDirectoryCustomFields(did, updates) {
    try {
      await db.ref(paths.directoryData(did)).child('customFields').update(updates);
      return true;
    } catch (err) {
      return false;
    }
  }

  static async updateDirectoryAdminFields(did, updates) {
    try {
      await db.ref(paths.directoryData(did)).child('adminFields').update(updates);
      return true;
    } catch (err) {
      return false;
    }
  }

  static async getFamilyTree(fid) {
    try {
      const snapshot = await db.ref(paths.family(fid)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async updateFamilyTree(fid, updates = {}) {
    try {
      await db.ref(paths.family(fid)).update(updates);
      return true;
    } catch (err) {
      return false;
    }
  }

  static async getGroupTree(gid) {
    try {
      const snapshot = await db.ref(paths.group(gid)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async updateGroupTree(gid, updates = {}) {
    try {
      await db.ref(paths.group(gid)).update(updates);
      return true;
    } catch (err) {
      return false;
    }
  }

  static async getUserMessageDrafts(uid, did) {
    try {
      const snapshot = await db
        .ref('users')
        .child(uid)
        .child('profiles')
        .child(did)
        .child('drafts')
        .once('value');
      return snapshot.val();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getUserDirMessages(uid, did) {
    try {
      const snapshot = await db
        .ref('users')
        .child(uid)
        .child('profiles')
        .child(did)
        .child('messages')
        .once('value');
      return snapshot.val();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async getDirectoryMessage(did, mid) {
    try {
      const snapshot = await db
        .ref(paths.messages(did))
        .child(mid)
        .once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async getDirectoryMessages(did) {
    try {
      const snapshot = await db.ref(paths.messages(did)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }

  static async getDirectorySettings(did) {
    try {
      const snapshot = await db.ref(paths.settings(did)).once('value');
      return snapshot.val();
    } catch (err) {
      return null;
    }
  }
}

export default FireDbService;
