import * as React from 'react';
import { connect } from 'react-redux';

import { FireDb, FireAuth, FireFunctions, functionNames } from 'modules/services';

import * as actions from 'modules/admin/actions';
import * as panelActions from 'modules/panel/actions';


const mapDispatchToProps = {
  getAdmins: panelActions.getAdminsRequest,
  getAllSentMessages: actions.requestAllSentMessages,
  getDirectoryCategories: actions.adminUserCatsRequest,
  getDirectoryFields: actions.adminDirectoryFieldsRequest,
  getDirectorySettings: panelActions.requestDirSettings,
  getDraftMessages: actions.messageDraftsRequest,
  saveMessageDraft: actions.saveMessageDraftRequest,
  sendMessage: actions.sendMessageRequest,
  updateDirectoryAdminFields: panelActions.updateDirFieldsRequest,
  updateDirectoryCategories: panelActions.updateDirCategoriesRequest,
  updateDirectoryCustomFields: panelActions.updateDirFieldsRequest,
  updateDirectoryFields: panelActions.updateDirFieldsRequest,
  updateOneCategory: panelActions.updateDirCategoriesRequest,
  updateOneField: panelActions.updateDirFieldsRequest,
};

function withAdminDb(Component) {
  const ComponentWithAdminDb = (props) => {
    const { children, ...rest } = props;

    return (
      <Component
        addDirectoryMember={FireDb.addDirectoryMember}
        callRemoteFunction={FireFunctions.httpsCallable}
        createUser={FireAuth.createNewUser}
        functionNames={functionNames}
        getDirectoryData={() => {}}
        getDirectoryTags={FireDb.getDirectoryTags}
        setUser={FireDb.setUserTree}
        updateFamily={FireDb.updateFamilyTree}
        updateGroup={FireDb.updateGroupTree}
        updateUser={FireDb.updateUserTree}
        {...rest}
      >
        {children}
      </Component>
    );
  };

  return connect(null, mapDispatchToProps)(ComponentWithAdminDb);
}


export default withAdminDb;
