import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://localhost:3005/api',
});

class FetchService {
  static async setAuthHeader(uid, dirId, idToken) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${uid}:${dirId}:${idToken}`; // eslint-disable-line
  }

  static async get(url, config) {
    return instance.get(url, config);
  }

  static async post(url, data, config) {
    return instance.post(url, data, config);
  }
}

export default FetchService;
