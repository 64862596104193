import PropTypes from 'prop-types';
import * as React from 'react';

import Box from '../Box';
import { MultiSelection } from '../MultiSelection';


class Category extends React.Component {
  onSelectChange = (val) => {
    const { setValues } = this.props;

    const catsObj = val?.reduce?.((obj, cat) => ({
      ...obj,
      [cat.value]: true,
    }), {});

    setValues({ categories: catsObj });
  };

  render() {
    const { categories, value } = this.props;

    const options = Object.keys(categories || {}).map((key) => ({
      label: categories[key]?.name,
      value: key,
    }));

    let catKeys = Object.keys(value || {});

    if (!options.length) {
      catKeys = [];
    }

    return (
      <Box>
        <MultiSelection
          isCreatable={false}
          isClearable
          isMulti
          name="categories"
          onChange={this.onSelectChange}
          options={options}
          value={catKeys.map((cK) => ({
            value: cK,
            label: categories[cK]?.name,
          }))}
        />
      </Box>
    );
  }
}

Category.propTypes = {
  categories: PropTypes.shape({}),
  setValues: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
};

Category.defaultProps = {
  categories: undefined,
  value: undefined,
};


export default Category;
