import { roleConstants } from '../constants';


export const isAdminRole = role => (
  roleConstants.ADMIN_ROLES.includes(role)
);

export const isGroupLeaderRole = role => (
  roleConstants.GROUP_LEADER_ROLE.includes(role)
);

export const isFamilyLeaderRole = role => (
  roleConstants.FAMILY_LEADER_ROLE.includes(role)
);

export const isMemberRole = role => (
  roleConstants.MEMBER_ROLE.includes(role)
);

