import PropTypes from 'prop-types';
import React from 'react';


const Clear = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M39 4.83L35.17 1 20 16.17 4.83 1 1 4.83 16.17 20 1 35.17 4.83 39 20 23.83 35.17 39 39 35.17 23.83 20z"
  />
);

Clear.propTypes = {
  strokeWidth: PropTypes.number,
};

Clear.defaultProps = {
  strokeWidth: 1,
};


export default Clear;
