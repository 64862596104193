import { createRoutine } from 'redux-saga-routines';

import * as constants from './constants';

export const getDraftMessagesRoutine = createRoutine(constants.GET_DRAFT_MESSAGES);
export const getSentMessagesRoutine = createRoutine(constants.GET_SENT_MESSAGES);
export const saveDraftMessageRoutine = createRoutine(constants.SAVE_DRAFT);
export const sendMessageRoutine = createRoutine(constants.SEND_MESSAGE);

export const clearComposeState = () => ({
  type: constants.CLEAR_COMPOSE_STATE,
});
