import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { getIsAuthenticated } from 'modules/auth/selectors';
import {
  getActiveDirectory,
  getDirectories,
  getActiveDirectoryId,
  getIsFetching,
  getFeatures,
  getFeaturesAnyMessaging,
} from 'modules/directory/selectors';
import { getFilters } from 'modules/filters/selectors';
import { getUser, getRole } from 'modules/user/selectors';

import { SearchContainer } from 'modules/search/components';

import { withUserDb } from 'modules/core/hoc';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  directory: getActiveDirectory,
  directoryId: getActiveDirectoryId,
  directories: getDirectories,
  features: getFeatures,
  filters: getFilters,
  hasAnyMessaging: getFeaturesAnyMessaging,
  isAuthenticated: getIsAuthenticated,
  isFetchingDirectories: getIsFetching,
  user: getUser,
  role: getRole,
});

const enhance = compose(
  SearchContainer,
  withUserDb,
  connect(mapStateToProps),
);

export default enhance(presenter);
