import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';


const GoogleCal = ({ url }) => (
  <a
    className="icon-google"
    target="_blank"
    href={url}
    rel="noopener noreferrer"
    role="presentation"
  >
    <Button
      type="primary"
      size="small"
      style={{ width: '140px', textAlign: 'center', marginBottom: '4px' }}
    >
      Google Calendar
    </Button>
  </a>
);

GoogleCal.propTypes = {
  url: PropTypes.string.isRequired,
};


export default GoogleCal;
