import { all, call, spawn, put, take, takeLatest, select } from 'redux-saga/effects';

import { DataNormalizers, RequestUtils } from 'utils';

import { getActiveDirectoryId } from 'modules/directory/selectors';

import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';

import { FireFunctions, functionNames } from '../services';


export function* requestMemberTrees(directoryId, options = {}) {
  const sortByFallback = ['lastName', 'firstName'];
  const sortDirFallback = ['asc', 'asc'];

  try {
    const isFetching = yield select(selectors.getIsFetching);

    if (isFetching) {
      return;
    }

    const dirId = directoryId
      ? yield directoryId
      : yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.fbMembersFailure('No directory id'));
      return;
    }

    yield put(actions.fbMembersFetch());

    const reqMeta = RequestUtils.getEntityReqContentMeta({
      by: sortByFallback,
      dir: sortDirFallback,
    }, options);

    reqMeta.include = options.include;

    const payload = RequestUtils.getEntityReqPayload(dirId, {}, reqMeta);

    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getMembers,
      payload,
    );

    const { results, pagination } = response.data;

    const memberIds = results.map(member => member.id);

    const memberTrees = DataNormalizers.mapFromArray(results);

    yield put(actions.fbMembersSuccess(memberTrees, memberIds, pagination));
  } catch (error) {
    yield put(actions.fbMembersFailure(error.message, error));
  }
}

export function* requestPersonSummaries(include) {
  try {
    const isFetching = yield select(selectors.getIsFetchingPersonSummaries);

    if (isFetching) {
      return;
    }

    const dirId = yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.getPersonSummariesFailure('No directory id'));
      return;
    }

    yield put(actions.getPersonSummariesFetch());

    const payload = RequestUtils.getEntityReqPayload(dirId, { options: { include } });

    const response = yield FireFunctions.httpsCallable(
      functionNames.getPersonSummaries,
      payload,
    );

    const { results } = response.data;

    const ids = results.map(result => result.id);
    const summaries = DataNormalizers.mapFromArray(results);

    yield put(actions.getPersonSummariesSuccess(summaries, ids));
  } catch (error) {
    yield put(actions.getPersonSummariesFailure(error.message, error));
  }
}

function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      // constants.FB_MEMBERS_REQUEST,
      // constants.FB_MEMBERS_REQUEST_NEXT,
      // constants.FB_MEMBERS_REQUEST_PREV,
      constants.GET_PERSON_SUMMARIES_REQUEST,
    ]);

    switch (type) {
      // case constants.FB_MEMBERS_REQUEST:
      //   yield fork(
      //     requestMemberTrees,
      //     payload.directoryId,
      //     { pageNumber: payload.pageNumber },
      //   );
      //   break;
      //
      // case constants.FB_MEMBERS_REQUEST_NEXT:
      //   yield fork(
      //     requestMemberTrees,
      //     payload.directoryId,
      //     { isNext: true },
      //   );
      //   break;
      //
      // case constants.FB_MEMBERS_REQUEST_PREV:
      //   yield fork(
      //     requestMemberTrees,
      //     payload.directoryId,
      //     { isPrev: true },
      //   );
      //   break;

      case constants.GET_PERSON_SUMMARIES_REQUEST:
        yield spawn(
          requestPersonSummaries,
          payload.include,
        );
        break;

      default:
        yield null;
    }
  }
}

function* handleMembersRequest(action) {
  const { type, payload = {} } = action;

  switch (type) {
    case constants.FB_MEMBERS_REQUEST:
      yield spawn(
        requestMemberTrees,
        payload.directoryId,
        {
          include: payload.include,
          indexEnd: payload.indexEnd,
          indexStart: payload.indexStart,
          pageNumber: payload.pageNumber,
          sort: payload.sort,
          sortDir: payload.sortDir,
        },
      );
      break;

    case constants.FB_MEMBERS_REQUEST_NEXT:
      yield spawn(
        requestMemberTrees,
        payload.directoryId,
        { isNext: true },
      );
      break;

    case constants.FB_MEMBERS_REQUEST_PREV:
      yield spawn(
        requestMemberTrees,
        payload.directoryId,
        { isPrev: true },
      );
      break;

    default:
      yield null;
  }
}

function* watchRequestMembers() {
  yield takeLatest(constants.FB_MEMBERS_REQUEST, handleMembersRequest);
}

export default function* rootSaga() {
  yield all([watchRequestMembers(), watch()]);
}
