import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

import { getSx } from './sx';


const LinkNewPage = ({ children, sx, ...rest }) => (
  <Text sx={getSx(sx)}>
    <a
      rel="noopener noreferrer"
      target="_blank"
      role="presentation"
      style={{ color: 'inherit' }}
      {...rest}
    >
      {children}
    </a>
  </Text>
);

LinkNewPage.propTypes = {
  href: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

LinkNewPage.defaultProps = {
  sx: {},
};


export default LinkNewPage;
