import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { BulletList } from '../shapes';


const BulletListSvg = (props) => (
  <g>
    <IconBounds />
    <BulletList {...props} />
  </g>
);

BulletListSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

BulletListSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default BulletListSvg;
