import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Text from './presenter';


const Heading = (props) => {
  const {
    className,
    setRef,
    sx,
    ...rest
  } = props;

  return (
    <Text
      className={classNames({
        'j-text': true,
        [className]: className,
      })}
      ref={setRef}
      sx={{
        fontSize: 'font32',
        fontWeight: 'bold',
        ...sx,
      }}
      {...rest}
    />
  );
};

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
};

Heading.defaultProps = {
  children: undefined,
  className: undefined,
  setRef: undefined,
  sx: {},
};

const HeadingWithRef = React.forwardRef((props, ref) => (
  <Heading setRef={ref} {...props} />
));


export { Heading };
export default HeadingWithRef;
