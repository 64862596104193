import moment from 'moment';

/**
 * Returns whether a given birtdate is valid (formatting)
 * @param date {?string} Date string
 * @returns {boolean} Valid status
 */
export const isValidDateString = (date) => {
  if (date && date.length === 10) {
    const d = moment(date);

    return d.isValid();
  }

  return false;
};

/**
 * Returns whether a given birtdate is valid (temporally,
 * e.g. is the date equal to or before today, etc)
 * @param date {?string} Date string
 * @returns {boolean} Valid status
 */
export const isTemporallyValid = (date) => {
  if (date) {
    const d = moment(date);

    return d.isBefore(moment());
  }

  return false;
};
