// 

import * as React from 'react';
import { Typography } from 'antd';

import styles from './styles.css';


const ResourceHeading = (props) => (
  <Typography.Title
    style={props.style}
    level={4}
  >
    <span style={{ marginRight: props.hint ? '10px' : '0' }}>
      {props.text}
    </span>
  </Typography.Title>
);

export default ResourceHeading;
