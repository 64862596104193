import { createSelector } from 'reselect';
import _ from 'lodash';

import { name } from './reducer';

const getState = state => state[name];

export const getIsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getAdder = createSelector(
  [getState],
  state => state.adder,
);

export const getAddIsFetching = createSelector(
  [getAdder],
  adder => adder.isFetching,
);

export const getAddEntitiesError = createSelector(
  [getAdder],
  adder => adder.error,
);

export const getAdderAdding = createSelector(
  [getAdder],
  adder => adder.adding,
);

export const getAdderAdded = createSelector(
  [getAdder],
  adder => adder.added,
);

export const getViewingEntity = createSelector(
  [getState],
  state => state.viewingEntity,
);

export const getCustomFields = createSelector(
  [getState],
  state => state.customFields,
);

export const getCustomFieldIds = createSelector(
  [getCustomFields],
  fields => Object.keys(fields || {}),
);

export const getCustomFieldsList = createSelector(
  [getCustomFieldIds, getCustomFields],
  (ids, fields) => (
    ids.map(id => fields[id])
  ),
);

export const getCustomFieldsFetching = createSelector(
  [getState],
  state => state.customFieldsFetching,
);

export const getInternalFieldsFetching = createSelector(
  [getState],
  state => state.internalFieldsFetching,
);

export const getInternalFields = createSelector(
  [getState],
  state => state.internalFields,
);

export const getInternalFieldIds = createSelector(
  [getInternalFields],
  fields => Object.keys(fields || {}),
);

export const getInternalFieldsList = createSelector(
  [getInternalFieldIds, getInternalFields],
  (ids, fields) => (
    ids.map(id => fields[id])
  ),
);

export const getUserCategories = createSelector(
  [getState],
  state => state.userCategories,
);

export const getUserCatsFetching = createSelector(
  [getState],
  state => state.userCatsFetching,
);

export const getUserCategoryIds = createSelector(
  [getUserCategories],
  cats => Object.keys(cats) || [],
);

export const getUserCategoriesList = createSelector(
  [getUserCategoryIds, getUserCategories],
  (ids, cats) => (
    ids.map(id => cats[id])
  ),
);

export const getUserCategoriesArray = createSelector(
  [getUserCategories, getUserCategoryIds],
  (cats, ids) => ids.map(id => cats[id]),
);

export const getMessages = createSelector(
  [getState],
  state => state.messages,
);

export const getComposeState = createSelector(
  [getState],
  state => state.composeState,
);

export const getMessageIds = createSelector(
  [getMessages],
  messages => Object.keys(messages),
);

export const getMessageDrafts = createSelector(
  [getState],
  state => state.messageDrafts,
);

export const getMessageDraftIds = createSelector(
  [getMessageDrafts],
  drafts => Object.keys(drafts || {}),
);

export const getDraftsFetching = createSelector(
  [getState],
  state => state.draftsFetching,
);

export const getMessagesFetching = createSelector(
  [getState],
  state => state.messagesFetching,
);

export const getExports = createSelector(
  [getState],
  state => state.exports,
);

export const getExportsIsFetching = createSelector(
  [getExports],
  state => state.isFetching,
);

export const getExportsErrorMessage = createSelector(
  [getExports],
  state => state.errorMessage,
);

export const getExportsMemberFields = createSelector(
  [getExports],
  exports => exports.memberFields || {},
);

export const getMemberExportFieldNamesTopLevel = createSelector(
  [getExportsMemberFields],
  (fields) => {
    const keys = Object.keys(fields);
    return keys.reduce((arr, k) => {
      if (_.isString(fields[k])) {
        return [...arr, fields[k]];
      }

      return arr;
    }, []);
  },
);

export const getSaveDraftState = createSelector(
  [getState],
  state => state.saveDraftState,
);

export const getImports = createSelector(
  [getState],
  state => state.imports,
);

export const getImportsIsFetching = createSelector(
  [getImports],
  state => state.isFetching,
);

export const getImportsErrorMessage = createSelector(
  [getImports],
  state => state.errorMessage,
);
