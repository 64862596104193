export const phoneTypeOptions = [{
  value: 'cell',
  label: 'Mobile',
}, {
  value: 'home',
  label: 'Home',
}, {
  value: 'work',
  label: 'Work',
}];
