import * as React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import { EntityCardMenu } from './components';

import {
  EntityCardWrapper,
  EntityCardImageWrapperStyled,
  EntityCardImageStyled,
  EntityCardTextWrapper,
  EntityCardTitle,
  EntityCardSubtitle,
} from './styled';


const EntityCard = (props) => {
  const {
    description,
    id,
    imageAlt,
    imageUrl,
    isPhantom,
    link,
    menuSections,
    onClick,
    title,
    noData,
  } = props;

  if (isPhantom) {
    return <div style={{ cursor: 'pointer' }} />;
  }

  return (
    <EntityCardWrapper>
      <Card
        footer={
          <div onClick={onClick}>
            <EntityCardTextWrapper>
              <EntityCardTitle>
                {title}
              </EntityCardTitle>

              <EntityCardSubtitle>
                {description}
              </EntityCardSubtitle>
            </EntityCardTextWrapper>
          </div>
        }
        style={{ width: '280px' }}
      >
        <div>
          <div>
            <EntityCardMenu noData={noData} sections={menuSections} />
          </div>

          <EntityCardImageWrapperStyled onClick={onClick}>
            <EntityCardImageStyled src={imageUrl} alt={imageAlt} />
          </EntityCardImageWrapperStyled>
        </div>
      </Card>
    </EntityCardWrapper>
  );
};

EntityCard.propTypes = {
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  imageUrl: PropTypes.string,
  isPhantom: PropTypes.bool,
  link: PropTypes.string,
  menuSections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  noData: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

EntityCard.defaultProps = {
  description: undefined,
  imageAlt: undefined,
  imageUrl: undefined,
  isPhantom: false,
  link: undefined,
  noData: false,
};


export default EntityCard;
