import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Flex from '../Flex';

import { createDataBlockStyles } from './styles';


const DataBlock = (props) => {
  const {
    children,
    flexDirection,
    sx,
  } = props;

  const isLaptopOrLarger = useMediaQuery({
    query: global.BreakpointQuery.laptop,
  });

  const styles = createDataBlockStyles(isLaptopOrLarger);

  return (
    <Flex flexDirection={flexDirection} sx={{ ...styles.dataBlock, ...sx }}>
      {children}
    </Flex>
  );
};

DataBlock.propTypes = {
  children: PropTypes.node,
  flexDirection: PropTypes.string,
  sx: PropTypes.shape({}),
};

DataBlock.defaultProps = {
  children: undefined,
  flexDirection: 'column',
  sx: {},
};


export default DataBlock;
