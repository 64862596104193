import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';


const Logo = ({ className }) => (
  <Link
    className={classNames({
      'cursor-pointer': true,
      'j-logo-link': true,
      [className]: className,
    })}
    to="/"
  >
    <span
      className={classNames({
        'j-logo-link-text': true,
      })}
    >
      Jubilee
    </span>
  </Link>
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: undefined,
};


export default Logo;
