import PropTypes from 'prop-types';
import * as React from 'react';

import Box from '../Box';
import Checkbox from '../Checkbox';
import CountryProvinceSelect from '../CountryProvinceSelect';
import FieldWithLabel from '../FieldWithLabel';
import { InputWithLabel } from '../Input';


const blankState = {
  city: '',
  country: '',
  isVisible: true,
  state: '',
  street1: '',
  street2: '',
  zip: '',
};

class Address extends React.Component {
  state = {
    ...blankState,
  };

  countryTouched = false;
  regionTouched = false;

  setValuesFromDefaults = (overrideValue) => {
    const { defaultCountry, defaultRegion } = this.props;
    const nextState = overrideValue || {};

    if (!overrideValue) {
      if (defaultRegion?.name) {
        nextState.state = defaultRegion.name;
      }

      if (defaultCountry?.alpha2) {
        nextState.country = defaultCountry.alpha2;
      }
    }

    this.setState(nextState);
  };

  componentDidMount() {
    const { defaultAddressVisible, initial } = this.props;
    this.setState({ isVisible: defaultAddressVisible ?? true }, () => {
      this.setValuesFromDefaults(initial);
    });
  }

  componentDidUpdate(prevProps) {
    const { initial } = this.props;
    const { initial: prevInitial } = prevProps;

    if (!prevInitial && initial) {
      this.setState(initial);
    }
  }

  reset = () => {
    this.setState({ ...blankState });
    this.countryTouched = false;
    this.regionTouched = false;
    this.setValuesFromDefaults();
  };

  onInputChange = (evt) => {
    const { value, name } = evt.target;
    this.setState({
      [name]: value,
    });
  };

  onSelectChange = (val, name) => {
    const { country } = this.state;

    const valueString = typeof val === 'string' ? val : val?.value;

    let countryVal = country;
    let stateVal = valueString;

    if (name === 'country') {
      this.countryTouched = true;

      if (valueString) {
        this.props.loadRegions(valueString);
      }

      countryVal = valueString || '';
      stateVal = '';
    } else {
      this.regionTouched = true;
    }

    this.setState({
      country: countryVal,
      state: stateVal,
    });
  };

  handleVisible = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  render() {
    const {
      city,
      country,
      isVisible,
      state,
      street1,
      street2,
      zip,
    } = this.state;

    const {
      defaultCountry,
      defaultRegion,
      label,
      regions,
    } = this.props;

    const fallbackCountry = defaultCountry?.alpha2;
    const fallbackRegion = defaultCountry ? defaultRegion?.name : undefined;

    let selectedRegion = fallbackRegion;
    let selectedCountry = fallbackCountry;
    if (this.countryTouched) {
      selectedCountry = country;
    }
    if (this.regionTouched) {
      selectedRegion = state;
    }

    return (
      <Box>
        <Box
          sx={{
            marginBottom: 'grid24',
          }}
        >
          <Checkbox
            name="isVisible"
            onChange={this.handleVisible}
            value={isVisible}
          >
            Display {label.toLowerCase()} in the directory
          </Checkbox>
        </Box>

        <Box
          sx={{
            marginBottom: 'grid16',
          }}
        >
          <InputWithLabel
            label="Street address"
            name="street1"
            placeholder="Street address"
            onChange={this.onInputChange}
            value={street1}
          />
        </Box>

        <Box
          sx={{
            marginBottom: 'grid16',
          }}
        >
          <InputWithLabel
            label="Street address unit"
            name="street2"
            placeholder="Street address unit"
            onChange={this.onInputChange}
            value={street2}
          />
        </Box>

        <Box
          sx={{
            marginBottom: 'grid16',
          }}
        >
          <InputWithLabel
            label="City"
            name="city"
            placeholder="City"
            onChange={this.onInputChange}
            value={city}
          />
        </Box>

        <FieldWithLabel
          label="Country & state/province"
          sx={{ marginBottom: 'grid16' }}
        >
          <Box sx={{ marginTop: 'grid4' }}>
            <CountryProvinceSelect
              provinces={regions}
              countryValue={selectedCountry}
              provinceValue={selectedRegion}
              countryName="country"
              provinceName="state"
              onChangeCountry={this.onSelectChange}
              onChangeProvince={this.onSelectChange}
            />
          </Box>
        </FieldWithLabel>

        <Box
          sx={{
            marginBottom: 'grid16',
          }}
        >
          <InputWithLabel
            label="Postal code"
            name="zip"
            placeholder="Postal code"
            onChange={this.onInputChange}
            value={zip}
          />
        </Box>
      </Box>
    );
  }
}

Address.propTypes = {
  initial: PropTypes.shape({}),
  label: PropTypes.string,
};

Address.defaultProps = {
  initial: undefined,
  label: 'Primary address',
};

export default Address;
