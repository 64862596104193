import PropTypes from 'prop-types';
import React from 'react';

import { roleHelpers } from 'helpers';

import Flex from '../Flex';
import { IconNames } from '../Icon';
import Phone from './Phone';


const PhoneIcons = {
  cell: IconNames.MOBILE,
  home: IconNames.PHONE,
  work: IconNames.BUSINESS,
};

const PhoneList = ({ id, isFetching, phones, role }) => {
  const isAdmin = roleHelpers.isAdminRole(role);

  if (!phones) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
    >
      {phones.map((phone, idx) => {
        let label;
        if (phone.type === 'cell') {
          label = 'Mobile';
        } else if (phone.type === 'home') {
          label = 'Home';
        } else if (phone.type === 'work') {
          label = 'Work';
        } else {
          label = 'Other';
        }

        return (
          <Phone
            key={`phone-${idx}`}
            id={id}
            isAdmin={isAdmin}
            iconName={PhoneIcons[phone.type] || IconNames.PHONE}
            label={label}
            phone={phone}
          />
        );
      })}
    </Flex>
  );
};

PhoneList.propTypes = {
  id: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  phones: PropTypes.arrayOf(PropTypes.shape()),
  role: PropTypes.string,
};

PhoneList.defaultProps = {
  isFetching: undefined,
  phones: undefined,
  role: undefined,
};


export default PhoneList;
