import React from 'react';
import { useMediaQuery } from 'react-responsive';


export const DesktopQuery = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: global.BreakpointSizes.desktop });
  return isDesktop ? children : null;
};

export const TabletQuery = ({ children }) => {
  const isTablet = useMediaQuery({
    minWidth: global.BreakpointSizes.tablet,
    maxWidth: global.BreakpointSizes.desktop - 1,
  });
  return isTablet ? children : null;
};

export const MobileQuery = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: global.BreakpointSizes.tablet - 1 });
  return isMobile ? children : null;
};

export const TabletOrLargerQuery = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: global.BreakpointSizes.tablet });
  return isTablet ? children : null;
};
