import PropTypes from 'prop-types';
import React from 'react';

import * as colors from 'modules/core/colors';
import { Appearance } from 'modules/core/constants';
import { IconSize } from 'modules/core/sizes';

import { IconNames } from 'modules/core/components/svg';
import Box from 'modules/core/components/Box';
import IconButton from 'modules/core/components/IconButton';


const MobileMenu = (props) => {
  const { onMenuClick } = props;

  return (
    <Box sx={{ paddingBottom: 'grid4' }}>
      <IconButton
        appearance={Appearance.NONE}
        buttonColor="transparent"
        iconColor={colors.ebonyLight}
        iconHoverColor={colors.ebonyLightest}
        iconName={IconNames.MENU_LINES}
        iconSize={IconSize.ICON_LARGE}
        onClick={onMenuClick}
        sx={{
          color: 'text',
          padding: 'grid0',
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
      />
    </Box>
  );
};

MobileMenu.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
};


export default MobileMenu;
