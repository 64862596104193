import PropTypes from 'prop-types';
import React from 'react';

import Box from '../Box';


const InfoDivider = ({ sx, ...rest }) => (
  <Box
    {...rest}
    sx={{
      backgroundColor: 'sectionDivider',
      height: '4px',
      width: '100%',
      ...sx,
    }}
  />
);

InfoDivider.propTypes = {
  sx: PropTypes.shape({}),
};

InfoDivider.defaultProps = {
  sx: {},
};


export default InfoDivider;
