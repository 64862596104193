import * as constants from './constants';

export function requestTags() {
  return {
    type: constants.TAGS_REQUEST,
  };
}

export function tagsSuccess(tags) {
  return {
    type: constants.TAGS_SUCCESS,
    payload: {
      tags,
    },
  };
}

export function tagsFailure(message, error) {
  return {
    type: constants.TAGS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function newUserCreate(
  values,
) {
  return {
    type: constants.CREATE_USER_REQUEST,
    payload: {
      values,
    },
  };
}

export function requestDirSettings() {
  return {
    type: constants.DIR_SETTINGS_REQUEST,
  };
}

export function dirSettingsSuccess(settings) {
  return {
    type: constants.DIR_SETTINGS_SUCCESS,
    payload: {
      settings,
    },
  };
}

export function dirSettingsFailure(message, error) {
  return {
    type: constants.DIR_SETTINGS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export const deleteUserRequest = userId => ({
  type: constants.DELETE_USER_REQUEST,
  payload: {
    userId,
  },
});

export const deleteUserSuccess = userId => ({
  type: constants.DELETE_USER_SUCCESS,
  payload: {
    userId,
  },
});

export const deleteUserFailure = (message, error) => ({
  type: constants.DELETE_USER_FAILURE,
  payload: {
    message,
    error,
  },
});

export const deleteFamilyRequest = familyId => ({
  type: constants.DELETE_FAMILY_REQUEST,
  payload: {
    familyId,
  },
});

export const deleteFamilySuccess = familyId => ({
  type: constants.DELETE_FAMILY_SUCCESS,
  payload: {
    familyId,
  },
});

export const deleteFamilyFailure = (message, error) => ({
  type: constants.DELETE_FAMILY_FAILURE,
  payload: {
    message,
    error,
  },
});

export const deleteGroupRequest = groupId => ({
  type: constants.DELETE_GROUP_REQUEST,
  payload: {
    groupId,
  },
});

export const deleteGroupSuccess = groupId => ({
  type: constants.DELETE_GROUP_SUCCESS,
  payload: {
    groupId,
  },
});

export const deleteGroupFailure = (message, error) => ({
  type: constants.DELETE_GROUP_FAILURE,
  payload: {
    message,
    error,
  },
});

export const getAdminsRequest = () => ({
  type: constants.GET_ADMINS_REQUEST,
});

export const getAdminsSuccess = admins => ({
  type: constants.GET_ADMINS_SUCCESS,
  payload: {
    admins,
  },
});

export const getAdminsFailure = (message, error) => ({
  type: constants.GET_ADMINS_FAILURE,
  payload: {
    error,
    message,
  },
});

export const updateDirFieldsRequest = (fields, fieldId, updates) => ({
  type: constants.UPDATE_DIR_FIELDS_REQUEST,
  payload: {
    fields,
    fieldId,
    updates,
  },
});

export const updateDirFieldsSuccess = admins => ({
  type: constants.UPDATE_DIR_FIELDS_SUCCESS,
});

export const updateDirFieldsFailure = (message, error) => ({
  type: constants.UPDATE_DIR_FIELDS_FAILURE,
  payload: {
    error,
    message,
  },
});

export const updateDirCategoriesRequest = (categories, categoryId, updates) => ({
  type: constants.UPDATE_DIR_CATEGORIES_REQUEST,
  payload: {
    categories,
    categoryId,
    updates,
  },
});

export const updateDirCategoriesSuccess = admins => ({
  type: constants.UPDATE_DIR_CATEGORIES_SUCCESS,
});

export const updateDirCategoriesFailure = (message, error) => ({
  type: constants.UPDATE_DIR_CATEGORIES_FAILURE,
  payload: {
    error,
    message,
  },
});
