import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

import { dashboardRoutePaths } from '../../constants';

import { getQuery } from '../../../../utils';

import { propTypes, defaultProps } from './props';

class GroupLeaderRoute extends React.Component {
  render() {
    const {
      path,
      Component,
      ledGroups,
      location,
      ...props
    } = this.props;

    const id = getQuery(location.search, 'id');
    const accessAllowed = !!ledGroups[id];

    return (
      <Route path={path} render={() => (
        accessAllowed ?
          <Component {...props} /> :
          <Redirect to={dashboardRoutePaths.groupsId(id)} />
      )} />
    );
  }
}

GroupLeaderRoute.propTypes = propTypes;
GroupLeaderRoute.defaultProps = defaultProps;

export default withRouter(GroupLeaderRoute);
