import React from 'react';
import PropTypes from 'prop-types';

import Asterisk from '../Asterisk';
import Box from '../Box';
import Label from '../Label';
import Textarea from './Textarea';


const TextareaWithLabel = (props) => {
  const {
    isRequired,
    label,
    placeholder,
    ...rest
  } = props;

  return (
    <Box>
      <Label
        sx={{
          color: 'fieldLabel',
          fontSize: 'font14',
        }}
      >
        {label}
        {isRequired && (
          <React.Fragment>
            {` `}
            <Asterisk />
          </React.Fragment>
        )}
      </Label>
      <Textarea isRequired={isRequired} placeholder={placeholder} {...rest} />
    </Box>
  );
};

TextareaWithLabel.propTypes = {
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  value: PropTypes.string,
};

TextareaWithLabel.defaultProps = {
  isRequired: undefined,
  onChange: undefined,
  placeholder: undefined,
  setRef: undefined,
  value: undefined,
};

const TextareaWithLabelWithRef = React.forwardRef((props, ref) => (
  <TextareaWithLabel setRef={ref} {...props} />
));


export { TextareaWithLabel };
export default TextareaWithLabelWithRef;
