import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { PrivateInfo } from '../shapes';


const PrivateInfoSvg = (props) => (
  <g>
    <IconBounds />
    <PrivateInfo {...props} />
  </g>
);

PrivateInfoSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

PrivateInfoSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default PrivateInfoSvg;
