// 

import * as constants from './constants';



export function loginSuccess({
  id, email, firstName, lastName, userRole, imageUrl,
}) {
  return {
    type: constants.LOGIN_SUCCESS,
    payload: {
      id,
      email,
      firstName,
      lastName,
      userRole,
      imageUrl,
    },
  };
}

export function fbLoginSuccess(userData) {
  return {
    type: constants.FB_LOGIN_SUCCESS,
    payload: {
      userData,
    },
  };
}

export function signupSuccess(
  id, email, firstName, lastName,
) {
  return {
    type: constants.SIGNUP_SUCCESS,
    payload: {
      id,
      email,
      firstName,
      lastName,
    },
  };
}

export function fbUserTreeRequest(uid) {
  return {
    type: constants.FB_USER_TREE_REQUEST,
    payload: {
      uid,
    },
  };
}

export function fbUserTreeSuccess(userData) {
  return {
    type: constants.FB_USER_TREE_SUCCESS,
    payload: {
      userData,
    },
  };
}

export function fbUserTreeFailure(message, error) {
  return {
    type: constants.FB_USER_TREE_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function fbUserFamilyTreesRequest(fids) {
  return {
    type: constants.FB_USER_FAMILY_TREES_REQUEST,
    payload: {
      fids,
    },
  };
}

export function fbUserFamilyTreesSuccess(userFamilies) {
  return {
    type: constants.FB_USER_FAMILY_TREES_SUCCESS,
    payload: {
      userFamilies,
    },
  };
}

export function fbUserFamilyTreesFailure(message, error) {
  return {
    type: constants.FB_USER_FAMILY_TREES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function fbUserGroupTreesRequest(gids) {
  return {
    type: constants.FB_USER_GROUP_TREES_REQUEST,
    payload: {
      gids,
    },
  };
}

export function fbUserGroupTreesSuccess(userGroups) {
  return {
    type: constants.FB_USER_GROUP_TREES_SUCCESS,
    payload: {
      userGroups,
    },
  };
}

export function fbUserGroupTreesFailure(message, error) {
  return {
    type: constants.FB_USER_GROUP_TREES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function settingsChangeTheme(theme) {
  return {
    type: constants.SETTINGS_CHANGE_THEME,
    payload: {
      theme,
    },
  };
}

export function fbMyProfileRequest(
  { includeFamilies, includeGroups },
) {
  return {
    type: constants.FB_MY_PROFILE_REQUEST,
    payload: {
      includeFamilies,
      includeGroups,
    },
  };
}

export function fbMyProfileSuccess(directoryId, profile) {
  return {
    type: constants.FB_MY_PROFILE_SUCCESS,
    payload: {
      directoryId,
      profile,
    },
  };
}

export function fbMyProfileFailure(message, error) {
  return {
    type: constants.FB_MY_PROFILE_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function fbMyLedFamiliesRequest() {
  return {
    type: constants.FB_MY_LED_FAMILIES_REQUEST,
  };
}

export function fbMyLedFamiliesSuccess(families) {
  return {
    type: constants.FB_MY_LED_FAMILIES_SUCCESS,
    payload: {
      families,
    },
  };
}

export function fbMyLedFamiliesFailure(message, error) {
  return {
    type: constants.FB_MY_LED_FAMILIES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function fbMyLedGroupsRequest() {
  return {
    type: constants.FB_MY_LED_GROUPS_REQUEST,
  };
}

export function fbMyLedGroupsSuccess(groups) {
  return {
    type: constants.FB_MY_LED_GROUPS_SUCCESS,
    payload: {
      groups,
    },
  };
}

export function fbMyLedGroupsFailure(message, error) {
  return {
    type: constants.FB_MY_LED_GROUPS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function fbSwitchDirRequest(directoryId) {
  return {
    type: constants.FB_SWITCH_DIR_REQUEST,
    payload: { directoryId },
  };
}

export function fbSwitchDirSuccess(directoryId) {
  return {
    type: constants.FB_SWITCH_DIR_SUCCESS,
    payload: { directoryId },
  };
}

export function fbSwitchDirFailure(message, error) {
  return {
    type: constants.FB_SWITCH_DIR_FAILURE,
    payload: { message, error },
  };
}
