import { findKey } from 'lodash';
import qs from 'query-string';

import { MakeAppRoutes } from '../modules/router/constants';

export const titleForUrlPathname = (pathname, directoryName) => {
  const AppRoutes = MakeAppRoutes(directoryName);
  const route = findKey(AppRoutes.AUTH, r => r.path === pathname);
  return AppRoutes.AUTH[route] ? AppRoutes.AUTH[route].label : 'Jubilee';
};

export const getQueries = (search) => qs.parse(search);

export const getQuery = (search, query) => (
  getQueries(search)[query]
);

export const makeQuery = (params) => (
  qs.stringify(params)
);

export const asPath = (route = '') => {
  if (route) {
    if (route.startsWith('/')) {
      return route;
    }

    return `/${route}`;
  }

  return '/';
};

export const getJoinedPath = (...paths) => {
  const joined = `/${paths.join('/')}`;
  // strip additional slashes from the route (i.e. if one of the paths
  // already had a leading slash)
  return joined.replace(/(\/){2,}/g, '/');
};
