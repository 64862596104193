import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Button } from 'antd';

import {
  EntityCardTextWrapper,
  EntityCardSubtitle,
} from '../../styled';


const EntityCardMenu = (props) => {
  const {
    noData,
    sections,
  } = props;

  const lastSection = sections.length - 1;

  const menu = (
    <Menu>
      {sections.map((section, i) => (
        <React.Fragment key={section.text}>
          <Menu.Item key={section.text}>
            {section.text}
          </Menu.Item>

          <EntityCardTextWrapper maxWidth="220px" width="220px">
            {!!section.data && (
              section.data.map((d, idx) => {
                const itemKey = `section-data-${idx}`;
                const contentsKey = d.linkType ? undefined : itemKey;
                const link = d.linkType
                  ? `${d.linkType}:${d.linkType === 'mailto' ? '' : '//'}${d.text}`
                  : undefined;

                const contents = (
                  <EntityCardSubtitle
                    breakWord={section.type === 'email' ? 'break-all' : undefined}
                    key={contentsKey}
                  >
                    {d.text || d}
                  </EntityCardSubtitle>
                );

                if (d.linkType) {
                  return React.createElement('a', {
                    href: link,
                    key: itemKey,
                    target: '_blank',
                  }, contents);
                }

                return contents;
              })
            )}
          </EntityCardTextWrapper>

          {i < lastSection && <Menu.Divider />}
        </React.Fragment>
      ))}
    </Menu>
  );

  return (
    <Dropdown.Button onClick={() => {}} overlay={menu} />
  );
};

EntityCardMenu.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape()),
};

EntityCardMenu.defaultProps = {
  sections: [],
};


export default EntityCardMenu;
