import React from 'react';

import { Box, SmallLoader } from 'modules/core/components';

import { PanelOverview } from 'modules/panel/components';

import RoutesSwitch from './RoutesSwitch';


class PanelRoutes extends React.Component {
  state = {
    showSearch: false,
  };

  componentDidMount() {
    this.handleGetDirectoryData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;
    if (user.id && !prevUser.id) {
      this.handleGetDirectoryData();
    }
  }

  handleGetDirectoryData = () => {
    const { user = {}, fbDirectoryRequest, directoryId } = this.props;
    const { directories = {} } = user;

    const dirIds = Object.keys(directories);

    if (directoryId) {
      fbDirectoryRequest(directoryId);
    } else if (dirIds.length) {
      const dirId = dirIds[0];
      fbDirectoryRequest(dirId);
    }
  };

  render() {
    return (
      <Box sx={{ position: 'relative' }}>
        <PanelOverview />
        <React.Suspense fallback={<SmallLoader show={true} />}>
          <React.Fragment>
            <RoutesSwitch />
          </React.Fragment>
        </React.Suspense>
      </Box>
    );
  }
}

export default PanelRoutes;
