import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';

import { usePrevious } from 'hooks';

import Box from '../../Box';

import MobileMenuBase from '../MobileMenuBase';

import { MobileSwitcherLink } from './components';


const MobileMenuSwitcher = ({ activeKey, items, onClose, title, onSelect, ...rest }) => {
  const prevKey = usePrevious(activeKey);

  React.useEffect(() => {
    if (activeKey !== prevKey) {
      onClose();
    }
  }, [activeKey, prevKey, onClose]);

  return (
    createPortal((
      <MobileMenuBase onClose={onClose} {...rest}>
        <Box
          className={classNames({
            'flex-col-start': true,
            'full-screen': true,
            'j-mobile-switcher': true,
            'j-nav-links': true,
            'pos-abs': true,
          })}
          sx={{
            paddingBottom: 'grid48',
            paddingLeft: 'grid16',
            paddingRight: 'grid16',
            paddingTop: 'grid64',
          }}
        >
          <Box
            className={classNames({
              'pos-rel': true,
              'j-mnenu-links-scroll': true,
            })}
          >
            <Box
              sx={{
                marginTop: 'grid16',
              }}
            >
              {items.map((item) => (
                <MobileSwitcherLink
                  identifier={item.key}
                  isActive={item.key === activeKey}
                  key={item.key}
                  onClick={() => {
                    if (item.key === activeKey) {
                      setTimeout(() => {
                        onClose();
                      }, 200);
                    } else {
                      onSelect(item.key);
                    }
                  }}
                >
                  {item.text}
                </MobileSwitcherLink>
              ))}
            </Box>
          </Box>
        </Box>
      </MobileMenuBase>
    ), document.getElementById('modal') || document.getElementById('root'))
  );
};

MobileMenuSwitcher.propTypes = {
  activeKey: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
};

MobileMenuSwitcher.defaultProps = {
  activeKey: undefined,
  className: undefined,
  isOpen: undefined,
  items: [],
  sx: {},
};


export default MobileMenuSwitcher;
