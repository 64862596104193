import * as constants from './constants';

export function setError(message, title, level, clearTimeout) {
  return {
    type: constants.SET_ERROR,
    payload: {
      message,
      title,
      level,
      clearTimeout,
    },
  };
}

export function clearError() {
  return {
    type: constants.CLEAR_ERROR,
  };
}
