import { createSelector } from 'reselect';
import qs from 'query-string';

import { UserRole } from '../../common/enums';

import { name } from './reducer';
import { MakeAppRoutes, AppRoutes } from './constants';

import { getActiveDirectory } from '../directory/selectors';
import { getRole } from '../user/selectors';

const getState = state => state[name];
const getProps = (state, props) => props;

export const getShowNavigation = createSelector(
  [getState],
  state => state.showNavigation,
);

export const getLocation = createSelector(
  [getProps],
  props => props.location,
);

export const getLocationHistory = createSelector(
  [getState],
  (state) => state.locationHistory,
);

export const getLastLocationHistory = createSelector(
  [getLocationHistory],
  (locationHistory) => locationHistory[locationHistory.length - 2],
);

export const getPathname = createSelector(
  [getLocation],
  loc => (loc ? loc.pathname : ''),
);

export const getMatch = createSelector(
  [getProps],
  props => props.match,
);

export const getParams = createSelector(
  [getMatch],
  match => (match ? match.params : {}),
);

export const getQuery = createSelector(
  [getLocation],
  loc => (loc ? qs.parse(loc.search) : {}),
);

export const getAppRoutes = createSelector(
  [getActiveDirectory, getRole],
  (dir, role) => {
    const r = UserRole[role];
    if (r) {
      return MakeAppRoutes(dir.name, r.isAdmin);
    }
    return AppRoutes;
  },
);
