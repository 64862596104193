import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Switch as RebassSwitch } from '@rebass/forms';

import { useControlled } from 'hooks';


const Switch = (props) => {
  const {
    className,
    defaultValue,
    isRequired,
    onChange,
    setRef,
    sx,
    value,
    ...rest
  } = props;

  const [val, setVal] = useControlled({
    default: defaultValue,
    onChange,
    value,
  });

  return (
    <RebassSwitch
      checked={val}
      className={classNames({
        'j-switch': true,
        [className]: className,
      })}
      color="primary"
      onClick={setVal}
      ref={setRef}
      required={isRequired}
      sx={sx}
      {...rest}
    />
  );
};

Switch.propTypes = {
  className: PropTypes.bool,
  defaultValue: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
  value: PropTypes.bool,
};

Switch.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  isRequired: undefined,
  onChange: undefined,
  setRef: undefined,
  sx: {},
  value: undefined,
};

const SwitchWithRef = React.forwardRef((props, ref) => (
  <Switch setRef={ref} {...props} />
));


export { Switch };
export default SwitchWithRef;
