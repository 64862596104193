import * as React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import defaultAvatar from 'assets/images/default-profile.png';

import { getPaginationPageQuery } from 'helpers/route';

import { SortUtils } from 'utils';

import {
  Box,
  EntityList,
  Flex,
  LetterHeading,
  PageHead,
  PaginationBar,
  Picker,
  RectAvatar,
} from 'modules/core/components';

import * as styles from './styles';


class DirectoryEntitiesListing extends React.Component {
  componentDidUpdate(prevProps) {
    const { directoryId: lastDirId } = prevProps;
    const { directoryId } = this.props;

    if ((!lastDirId && directoryId) || lastDirId !== directoryId) {
      this.handleInitialDataRequest();
    }
  }

  handleInitialDataRequest = () => {
    const {
      location,
      onPaginationChange,
      onRequestInitialData,
      sort,
    } = this.props;

    const page = getPaginationPageQuery(location);

    onPaginationChange(page, () => onRequestInitialData({ page, sort }));
  };

  requestPage = (page, currentSort) => {
    const {
      currentPage,
      isFetching,
      onRequestData,
    } = this.props;

    if (!isFetching || page !== currentPage) {
      onRequestData({ page, sort: currentSort });
    }
  };

  handlePaginationChange = (page) => {
    const { onPaginationChange, sort } = this.props;
    onPaginationChange(page, () => this.requestPage(page, sort));
  };

  render() {
    const {
      AvatarComponent,
      children,
      currentPage,
      getEntityHref,
      getEntitySubtitle,
      getEntityTitle,
      isFetching,
      loader,
      entities,
      onSortChange,
      pageHeadTitle,
      pageHeadDesc,
      pagination,
      sort,
      sortOptions,
    } = this.props;

    const letters = Object.keys(entities);
    const sortedLetters = sort.dir[0] === 'desc' ? letters.sort().reverse() : letters.sort();

    const isMobile = global.queryBreakpoints.isLtTablet();
    const paginationSize = isMobile ? 'small' : undefined;
    const paginationShowQuickJump = global.queryBreakpoints.isGteTablet();

    const paginationBar = (
      <PaginationBar
        current={currentPage}
        hideOnSinglePage
        isFetching={isFetching}
        onChange={this.handlePaginationChange}
        pages={pagination.pages}
        pageSize={pagination.pageSize}
        showLessItems={isMobile}
        showSizeChanger={false}
        showQuickJumper={paginationShowQuickJump}
        size={paginationSize}
        total={pagination.count}
      />
    );

    return (
      <React.Fragment>
        <PageHead description={pageHeadDesc} title={pageHeadTitle} />

        <Flex sx={styles.contentContainerStyles}>
          {children}

          <Picker
            displayLabel
            label="Sort"
            onChange={onSortChange}
            options={sortOptions}
            placeholder="Sort by"
            sx={styles.sortPickerStyles}
            value={`${sort.by[0]}_${sort.dir[0]}`}
          />
        </Flex>

        <Box sx={styles.entitiesContentContainerStyles}>
          {paginationBar}

          <Box sx={styles.entitiesContainerStyle}>
            {loader}

            {!isFetching && sortedLetters.map((letter) => (
              <Box key={`entities-${letter}`}>
                <LetterHeading letter={letter} sx={styles.letterHeadingStyles} />

                <EntityList
                  items={SortUtils
                    .sortMainEntities(entities[letter], sort.by, sort.dir)
                    .map((entity) => ({
                      AvatarComponent,
                      href: getEntityHref(entity),
                      imageUrl: entity.imageUrl || defaultAvatar,
                      key: entity.id,
                      title: getEntityTitle(entity),
                      subtitle: getEntitySubtitle(entity),
                    }))
                  }
                  showCarets={global.queryBreakpoints.isLtTablet()}
                />
              </Box>
            ))}
          </Box>

          <Box sx={styles.bottomPaginationWrapperStyles}>
            {paginationBar}
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

DirectoryEntitiesListing.propTypes = {
  AvatarComponent: PropTypes.elementType,
  children: PropTypes.node,
  currentPage: PropTypes.number.isRequired,
  directoryId: PropTypes.string,
  entities: PropTypes.shape().isRequired,
  getEntityHref: PropTypes.func.isRequired,
  getEntitySubtitle: PropTypes.func.isRequired,
  getEntityTitle: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  loader: PropTypes.node,
  location: PropTypes.shape().isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onRequestData: PropTypes.func.isRequired,
  onRequestInitialData: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  pageHeadDesc: PropTypes.string.isRequired,
  pageHeadTitle: PropTypes.string.isRequired,
  pagination: PropTypes.shape().isRequired,
  sort: PropTypes.shape({
    by: PropTypes.arrayOf(PropTypes.string),
    dir: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DirectoryEntitiesListing.defaultProps = {
  AvatarComponent: RectAvatar,
  children: undefined,
  directoryId: undefined,
  loader: undefined,
};


export default withRouter(DirectoryEntitiesListing);
