import PropTypes from 'prop-types';
import React from 'react';
import Drift from 'react-driftjs';

import { roleHelpers } from 'helpers';


const RealtimeSupport = (props) => {
  const {
    directory,
    role,
    user,
  } = props;

  const isAdmin = roleHelpers.isAdminRole(role);

  if (process.env.IS_STORY || !isAdmin) {
    return null;
  }

  return (
    <Drift
      appId="u453pbcs3a92"
      userId={user?.id}
      attributes={{
        email: user?.email,
        directoryName: directory?.name,
        directoryId: directory?.id,
      }}
    />
  );
};

RealtimeSupport.propTypes = {
  directory: PropTypes.shape({}),
  role: PropTypes.string,
  user: PropTypes.shape({}),
};

RealtimeSupport.defaultProps = {
  directory: undefined,
  role: undefined,
  user: undefined,
};


export default RealtimeSupport;
