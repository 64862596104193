import * as React from 'react';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from './styles.css';


const ResourceButton = (props) => (
  <Link
    className={styles.link}
    to={props.to}>
    <Button
      htmlType="button"
      icon={<PlusOutlined />}
      type="primary"
    />
  </Link>
);


export default ResourceButton;
