import { createSelector } from 'reselect';
import { groupBy } from 'lodash';

import { name } from './reducer';

const getState = state => state[name];
const getUserState = state => state.user.userData;

export const getDirectories = createSelector(
  [getState],
  state => state.directories || {},
);

export const getDirectoryIds = createSelector(
  [getDirectories],
  dirs => Object.keys(dirs),
);

export const getActiveDirectoryId = createSelector(
  [getState, getUserState],
  (state, userState) => {
    if (userState && userState.webappState) {
      return userState.webappState.activeDirectory;
    }

    if (state.activeDirectory) {
      return state.activeDirectory.id;
    }

    return '';
  },
);

export const getActiveDirectory = createSelector(
  [getDirectories, getActiveDirectoryId],
  (dirs, dirId) => dirs[dirId] || {},
);

export const getActiveDirectoryName = createSelector(
  [getActiveDirectory],
  dir => dir.name,
);

export const getIsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getSearchResults = createSelector(
  [getState],
  state => state.searchResults,
);

export const getResultsShortCodes = createSelector(
  [getSearchResults],
  results => results.map(r => r.shortCode),
);

export const getSearchFetching = createSelector(
  [getState],
  state => state.searchFetching,
);

export const getSearchResultsByLetter = createSelector(
  [getSearchResults],
  results => groupBy(results, (o) => {
    if (o.firstName && o.firstName[0]) return o.firstName[0];
    if (o.lastName && o.lastName[0]) return o.lastName[0];
    if (o.displayName && o.displayName[0]) return o.displayName[0];
    if (o.name && o.name[0]) return o.name[0];
    return 'Unknown';
  }),
);

export const getDirectoryFieldsObject = createSelector(
  [getActiveDirectory],
  dir => (dir.fields ? dir.fields || {} : {}),
);

export const getDirectoryCustomFields = createSelector(
  [getDirectoryFieldsObject],
  fields => Object.keys(fields).reduce((obj, id) => {
    if (fields[id].private) return obj;
    return {
      ...obj,
      [id]: {
        id,
        type: fields[id].type,
        name: fields[id].name,
      },
    };
  }, {}),
);

export const getDirectoryPrivateFields = createSelector(
  [getDirectoryFieldsObject],
  fields => Object.keys(fields).reduce((obj, id) => {
    if (!fields[id].private) return obj;
    return {
      ...obj,
      [id]: {
        id,
        type: fields[id].type,
        name: fields[id].name,
      },
    };
  }, {}),
);

export const getActiveDirAdmins = createSelector(
  [getActiveDirectory],
  (dir) => {
    const { admins = {} } = dir;

    return Object.keys(admins).reduce((obj, adminId) => {
      if (admins[adminId]) {
        return {
          ...obj,
          [adminId]: true,
        };
      }

      return obj;
    }, {});
  },
);

export const getDirectorySettings = createSelector(
  [getActiveDirectory],
  directory => directory.settings,
);

export const getFeatures = createSelector(
  [getState],
  state => state.config,
);

export const getFeaturesEmail = createSelector(
  [getFeatures],
  config => config.email,
);

export const getFeaturesSms = createSelector(
  [getFeatures],
  config => config.sms,
);

export const getFeaturesAnyMessaging = createSelector(
  [getFeaturesSms, getFeaturesEmail],
  (sms, email) => Boolean(sms || email),
);
