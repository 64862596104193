// 

import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Container,
  Col,
  FormGroup,
  Input,
} from 'reactstrap';
import { Button } from 'antd';

import logoImage from '../../../../assets/images/logo.png';

import { FireAuth, FireFunctions, functionNames } from '../../../services';
import { classie } from '../../../../utils';

import { Header } from './components';
import styles from './styles.css';

// https://dev.jubileeocs.com/actions?apiKey=AIzaSyDh-pkd5YKEBCcN6mpYmCWZ2M8pE8moilc&mode=signIn&oobCode=wUqMOdoY--ySRm9iSkjB6ESEsHr5PjLe0z2aPLiqyf4AAAFmNB1vDg&continueUrl=https://dev.jubileeocs.com/FinishAccount&lang=en

class FinishAccount extends React.Component {
  state = {
    email: '',
    tempPassword: '',
    password: '',
    confirm: '',
    loading: false,
  };

  handleFinishAccount = async () => {
    const { email, password, confirm, tempPassword } = this.state;
    this.setState({ loading: true });
    if (email.length &&
      password.length >= 8 &&
      confirm === password &&
      tempPassword.length) {
      try {
        const result = await FireAuth.login(email, tempPassword);
        if (result) {
          const idToken = await result.getIdToken(true);
          const accountResult = await FireFunctions.httpsCallable(
            functionNames.setNewUserPassword,
            { email, idToken, newPassword: password },
          );
          const { data = {} } = accountResult;
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  render() {
    const { tempPassword, email, password, confirm, loading } = this.state;

    const buttonDisabled =
      loading ||
      !email.length ||
      password.length < 8 ||
      password !== confirm ||
      !tempPassword.length;

    return (
      <div className="FinishAccount">
        <Header {...this.props} />

        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            <div>
              <div className="full-page-content">
                <div className="lock-page">
                  <Container>
                    <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
                      <Card className="card-lock text-center">
                        <img
                          style={{
                            backgroundColor: 'white',
                            maxWidth: '120px',
                            maxHeight: '39px',
                            height: '39px',
                            width: 'auto',
                            marginTop: '30px',
                            marginBottom: '15px',
                          }}
                          src={logoImage}
                          alt="avatar-img" />
                        <CardBody>
                          <FormGroup>
                            <Input
                              onChange={e => this.setState({ email: e.target.value })}
                              value={email}
                              type="email"
                              placeholder="Confirm Email Address" />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              onChange={e => this.setState({ tempPassword: e.target.value })}
                              value={tempPassword}
                              type="password"
                              placeholder="Temporary Password" />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              onChange={e => this.setState({ password: e.target.value })}
                              value={password}
                              type="password"
                              placeholder="Choose a New Password" />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              onChange={e => this.setState({ confirm: e.target.value })}
                              value={confirm}
                              type="password"
                              placeholder="Retype New Password" />
                          </FormGroup>
                        </CardBody>
                        <CardFooter>
                          <Button
                            disabled={buttonDisabled}
                            onClick={this.handleFinishAccount}
                            color="info"
                            size="lg"
                            round>
                            Let's Go!
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Container>
                </div>
              </div>
              <div
                className={classie(['full-page-background', styles.background])} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FinishAccount;
