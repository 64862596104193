//
import * as React from 'react';
import { createPortal } from 'react-dom';
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Card,
  CardBody,
  Col,
  Row,
  Label,
} from 'reactstrap';
import Transition from 'react-transition-group/Transition';
import { Button } from 'antd';
import { InfoBlurb } from '../../../core/components';

import { classie } from '../../../../utils';

import styles from './styles.css';

const modalRoot = document.getElementById('modal');


const duration = 100;

class SearchBar extends React.Component {
  state = {
    searchTerm: '',
    searchFocused: false,
    typeSelection: '',
  };

  // $FlowFixMe
  inputRef = React.createRef();

  componentDidMount() {
    this.setInputFromUrl();
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isOpen: wasOpen } = prevProps;
    if (!wasOpen && isOpen) {
      this.setInputFromUrl();
    }
  }

  setInputFromUrl = () => {
    const { urlQuery, searchTypeName } = this.props;

    let searchTerm;
    if (urlQuery.term) searchTerm = urlQuery.term;
    else searchTerm = '';

    let typeSelection;
    if (urlQuery.type) typeSelection = urlQuery.type;
    else typeSelection = searchTypeName.toLowerCase();

    this.setState({ searchTerm, typeSelection });
  };

  assignInputRef = (el) => {
    this.inputRef.current = el;
  };

  onSearchChange = (evt) => {
    const { value } = evt.target;
    this.setState({ searchTerm: value });
  };

  getSearchType = () => {
    const { searchTypeName, urlQuery } = this.props;

    let searchType = searchTypeName.toLowerCase();

    if (urlQuery.type) searchType = urlQuery.type;
    else if (searchType === 'people') searchType = 'users';

    return searchType;
  };

  search = (searchTerm) => {
    const { search, searchTypeName, history } = this.props;

    const searchType = this.getSearchType();

    const searchUrl = '/app/directory/search';
    const urlParams = `type=${searchTypeName.toLowerCase()}&term=${searchTerm}`;

    search(searchType, searchTerm);

    history.push(`${searchUrl}?${urlParams}`);
  };

  handleSubmit = (evt) => {
    const { searchTerm } = this.state;
    const { toggleSearch } = this.props;

    if (evt) evt.preventDefault();

    this.search(searchTerm);

    toggleSearch();
  };

  handleEnteredTransition = (node, isAppearing) => {
    if (!isAppearing && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  render() {
    const {
      isOpen,
      theme,
      toggleSearch,
    } = this.props;

    const {
      searchTerm,
      searchFocused,
    } = this.state;

    const searchColStyles = {
      transition: `transform ${duration}ms ease-in`,
      maxHeight: '140px',
      transform: 'translateY(-160px)',
    };

    const transitionStyles = {
      entering: { transform: 'translateY(-160px)' },
      entered: { transform: 'translateY(65px)' },
      exiting: { transform: 'translateY(65px)' },
      exited: { transform: 'translateY(-160px)' },
    };

    const searchStyles = {
      boxShadow: theme.searchShadow,
      backgroundColor: '#fffffff',
      paddingTop: '15px',
    };

    const search = (
      <div
        className={classie([styles.searchPortal], {
          [styles.open]: isOpen,
        })}>
        <div
          onClick={toggleSearch}
          className={styles.searchUnderlay} />

        <Transition
          in={isOpen}
          onEntered={this.handleEnteredTransition}
          timeout={duration}>
          {
            state => (
              <Col
                xs={10}
                md={6}
                style={{ ...searchColStyles, ...transitionStyles[state] }}>
                <Card
                  style={searchStyles}>
                  <CardBody>
                    <InfoBlurb margin={true}>
                      {'Search by name, family name, group, or tag.'}
                    </InfoBlurb>

                    <form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col xs={12}>
                          <div>
                            <InputGroup
                              style={{
                                borderColor: 'transparent',
                              }}
                              className={classie(['no-border', styles.inputGroup], {
                                [styles.focusedGroup]: searchFocused,
                              })}>
                              <Input
                                style={{
                                  backgroundColor: 'rgba(222, 222, 222, 0.5)',
                                }}
                                id="searchField"
                                innerRef={this.assignInputRef}
                                autoFocus={true}
                                name="searchField"
                                onFocus={() => this.setState({ searchFocused: true })}
                                onBlur={() => this.setState({ searchFocused: false })}
                                className={styles.searchInput}
                                value={searchTerm}
                                onChange={this.onSearchChange}
                                placeholder="Search..." />

                              <InputGroupAddon
                                className={styles.searchIconAddon}
                                addonType="append">
                                <InputGroupText
                                  className={styles.searchIconAddon}>
                                  <i className="now-ui-icons ui-1_zoom-bold" />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12}>
                          <div className={styles.searchActions}>
                            <Button
                              className={styles.searchButton}
                              onClick={this.handleSubmit}
                              color="info"
                              type="submit">
                              Search
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )
          }
        </Transition>
      </div>
    );

    return null;
    // if (modalRoot) {
    //   return createPortal(search, modalRoot);
    // }
    //
    // return search;
  }
}

export default SearchBar;
