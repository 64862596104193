import PropTypes from 'prop-types';
import React from 'react';

import { IconSize } from 'modules/core/sizes';

import Text from 'modules/core/components/Text';


const Title = (props) => {
  const { children } = props;

  return (
    <Text
      className="j-header-title"
      sx={{
        color: 'systemText',
        height: IconSize.ICON_LARGE,
        lineHeight: IconSize.ICON_LARGE,
        paddingLeft: 'grid8',
        paddingRight: 'grid8',
      }}
    >
      {children}
    </Text>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};


export default Title;
