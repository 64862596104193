import PropTypes from 'prop-types';
import React from 'react';

import Flex from '../Flex';
import Box from '../Box';

import Address from './Address';


const AddressList = ({ addresses, isFetching }) => (
  <Flex>
    <Box>
      {addresses?.map?.((address, idx) => (
        <Address address={address} key={`user-address-${idx}`} />
      ))}
    </Box>
  </Flex>
);

AddressList.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape()),
  isFetching: PropTypes.bool,
};

AddressList.defaultProps = {
  addresses: undefined,
  isFetching: undefined,
};


export default AddressList;
