export const ADMIN_USER_DATA_REQUEST = '@admin/ADMIN_USER_DATA_REQUEST';
export const ADMIN_USER_DATA_SUCCESS = '@admin/ADMIN_USER_DATA_SUCCESS';
export const ADMIN_USER_DATA_FAILURE = '@admin/ADMIN_USER_DATA_FAILURE';

export const ADMIN_CLEAR_VIEWING_ENTITY = '@admin/ADMIN_CLEAR_VIEWING_ENTITY';

export const ADMIN_SAVE_USER_DATA_REQUEST = '@admin/ADMIN_SAVE_USER_DATA_REQUEST';
export const ADMIN_SAVE_USER_DATA_SUCCESS = '@admin/ADMIN_SAVE_USER_DATA_SUCCESS';
export const ADMIN_SAVE_USER_DATA_FAILURE = '@admin/ADMIN_SAVE_USER_DATA_FAILURE';

export const ADMIN_ADD_MEMBER_REQUEST = '@admin/ADMIN_ADD_MEMBER_REQUEST';
export const ADMIN_ADD_MEMBER_SUCCESS = '@admin/ADMIN_ADD_MEMBER_SUCCESS';
export const ADMIN_ADD_MEMBER_FAILURE = '@admin/ADMIN_ADD_MEMBER_FAILURE';

export const ADMIN_ADD_MULTI_MEMBER_REQUEST = '@admin/ADMIN_ADD_MULTI_MEMBER_REQUEST';
export const ADMIN_ADD_MULTI_MEMBER_SUCCESS = '@admin/ADMIN_ADD_MULTI_MEMBER_SUCCESS';
export const ADMIN_ADD_MULTI_MEMBER_FAILURE = '@admin/ADMIN_ADD_MULTI_MEMBER_FAILURE';

export const ADMIN_UPDATE_MULTI_MEMBER_REQUEST = '@admin/ADMIN_UPDATE_MULTI_MEMBER_REQUEST';
export const ADMIN_UPDATE_MULTI_MEMBER_SUCCESS = '@admin/ADMIN_UPDATE_MULTI_MEMBER_SUCCESS';
export const ADMIN_UPDATE_MULTI_MEMBER_FAILURE = '@admin/ADMIN_UPDATE_MULTI_MEMBER_FAILURE';

export const ADMIN_ADD_FAMILY_REQUEST = '@admin/ADMIN_ADD_FAMILY_REQUEST';
export const ADMIN_ADD_FAMILY_SUCCESS = '@admin/ADMIN_ADD_FAMILY_SUCCESS';
export const ADMIN_ADD_FAMILY_FAILURE = '@admin/ADMIN_ADD_FAMILY_FAILURE';

export const ADMIN_ADD_GROUP_REQUEST = '@admin/ADMIN_ADD_GROUP_REQUEST';
export const ADMIN_ADD_GROUP_SUCCESS = '@admin/ADMIN_ADD_GROUP_SUCCESS';
export const ADMIN_ADD_GROUP_FAILURE = '@admin/ADMIN_ADD_GROUP_FAILURE';

export const ADMIN_DIR_FIELDS_REQUEST = '@admin/ADMIN_DIR_FIELDS_REQUEST';
export const ADMIN_DIR_FIELDS_SUCCESS = '@admin/ADMIN_DIR_FIELDS_SUCCESS';
export const ADMIN_DIR_FIELDS_FAILURE = '@admin/ADMIN_DIR_FIELDS_FAILURE';

export const ADMIN_CUSTOM_FIELDS_REQUEST = '@admin/ADMIN_CUSTOM_FIELDS_REQUEST';
export const ADMIN_CUSTOM_FIELDS_SUCCESS = '@admin/ADMIN_CUSTOM_FIELDS_SUCCESS';
export const ADMIN_CUSTOM_FIELDS_FAILURE = '@admin/ADMIN_CUSTOM_FIELDS_FAILURE';

export const ADMIN_INTERNAL_FIELDS_REQUEST = '@admin/ADMIN_INTERNAL_FIELDS_REQUEST';
export const ADMIN_INTERNAL_FIELDS_SUCCESS = '@admin/ADMIN_INTERNAL_FIELDS_SUCCESS';
export const ADMIN_INTERNAL_FIELDS_FAILURE = '@admin/ADMIN_INTERNAL_FIELDS_FAILURE';

export const ADMIN_TAGS_REQUEST = '@admin/ADMIN_TAGS_REQUEST';
export const ADMIN_TAGS_SUCCESS = '@admin/ADMIN_TAGS_SUCCESS';
export const ADMIN_TAGS_FAILURE = '@admin/ADMIN_TAGS_FAILURE';

export const ADMIN_USER_CATS_REQUEST = '@admin/ADMIN_USER_CATS_REQUEST';
export const ADMIN_USER_CATS_SUCCESS = '@admin/ADMIN_USER_CATS_SUCCESS';
export const ADMIN_USER_CATS_FAILURE = '@admin/ADMIN_USER_CATS_FAILURE';

export const ADMIN_SAVE_CUSTOM_FIELD_REQUEST = '@admin/ADMIN_SAVE_CUSTOM_FIELD_REQUEST';
export const ADMIN_SAVE_CUSTOM_FIELD_SUCCESS = '@admin/ADMIN_SAVE_CUSTOM_FIELD_SUCCESS';
export const ADMIN_SAVE_CUSTOM_FIELD_FAILURE = '@admin/ADMIN_SAVE_CUSTOM_FIELD_FAILURE';

export const ADMIN_SAVE_INTERNAL_FIELD_REQUEST = '@admin/ADMIN_SAVE_INTERNAL_FIELD_REQUEST';
export const ADMIN_SAVE_INTERNAL_FIELD_SUCCESS = '@admin/ADMIN_SAVE_INTERNAL_FIELD_SUCCESS';
export const ADMIN_SAVE_INTERNAL_FIELD_FAILURE = '@admin/ADMIN_SAVE_INTERNAL_FIELD_FAILURE';

export const ADMIN_SAVE_CATEGORY_REQUEST = '@admin/ADMIN_SAVE_CATEGORY_REQUEST';
export const ADMIN_SAVE_CATEGORY_SUCCESS = '@admin/ADMIN_SAVE_CATEGORY_SUCCESS';
export const ADMIN_SAVE_CATEGORY_FAILURE = '@admin/ADMIN_SAVE_CATEGORY_FAILURE';

export const ADMIN_DELETE_CUSTOM_FIELD_REQUEST = '@admin/ADMIN_DELETE_CUSTOM_FIELD_REQUEST';
export const ADMIN_DELETE_CUSTOM_FIELD_SUCCESS = '@admin/ADMIN_DELETE_CUSTOM_FIELD_SUCCESS';
export const ADMIN_DELETE_CUSTOM_FIELD_FAILURE = '@admin/ADMIN_DELETE_CUSTOM_FIELD_FAILURE';

export const ADMIN_DELETE_INTERNAL_FIELD_REQUEST = '@admin/ADMIN_DELETE_INTERNAL_FIELD_REQUEST';
export const ADMIN_DELETE_INTERNAL_FIELD_SUCCESS = '@admin/ADMIN_DELETE_INTERNAL_FIELD_SUCCESS';
export const ADMIN_DELETE_INTERNAL_FIELD_FAILURE = '@admin/ADMIN_DELETE_INTERNAL_FIELD_FAILURE';

export const ADMIN_DELETE_CATEGORY_REQUEST = '@admin/ADMIN_DELETE_CATEGORY_REQUEST';
export const ADMIN_DELETE_CATEGORY_SUCCESS = '@admin/ADMIN_DELETE_CATEGORY_SUCCESS';
export const ADMIN_DELETE_CATEGORY_FAILURE = '@admin/ADMIN_DELETE_CATEGORY_FAILURE';

export const ADMIN_CREATE_CUSTOM_FIELD_REQUEST = '@admin/ADMIN_CREATE_CUSTOM_FIELD_REQUEST';
export const ADMIN_CREATE_CUSTOM_FIELD_SUCCESS = '@admin/ADMIN_CREATE_CUSTOM_FIELD_SUCCESS';
export const ADMIN_CREATE_CUSTOM_FIELD_FAILURE = '@admin/ADMIN_CREATE_CUSTOM_FIELD_FAILURE';

export const ADMIN_CREATE_INTERNAL_FIELD_REQUEST = '@admin/ADMIN_CREATE_INTERNAL_FIELD_REQUEST';
export const ADMIN_CREATE_INTERNAL_FIELD_SUCCESS = '@admin/ADMIN_CREATE_INTERNAL_FIELD_SUCCESS';
export const ADMIN_CREATE_INTERNAL_FIELD_FAILURE = '@admin/ADMIN_CREATE_INTERNAL_FIELD_FAILURE';

export const ADMIN_CREATE_CATEGORY_REQUEST = '@admin/ADMIN_CREATE_CATEGORY_REQUEST';
export const ADMIN_CREATE_CATEGORY_SUCCESS = '@admin/ADMIN_CREATE_CATEGORY_SUCCESS';
export const ADMIN_CREATE_CATEGORY_FAILURE = '@admin/ADMIN_CREATE_CATEGORY_FAILURE';

export const ADMIN_SENT_MESSAGES_REQUEST = '@admin/ADMIN_SENT_MESSAGES_REQUEST';
export const ADMIN_SENT_MESSAGES_SUCCESS = '@admin/ADMIN_SENT_MESSAGES_SUCCESS';
export const ADMIN_SENT_MESSAGES_FAILURE = '@admin/ADMIN_SENT_MESSAGES_FAILURE';

export const ADMIN_SEND_MESSAGE_REQUEST = '@admin/ADMIN_SEND_MESSAGE_REQUEST';
export const ADMIN_SEND_MESSAGE_SUCCESS = '@admin/ADMIN_SEND_MESSAGE_SUCCESS';
export const ADMIN_SEND_MESSAGE_FAILURE = '@admin/ADMIN_SEND_MESSAGE_FAILURE';
export const ADMIN_CLEAR_COMPOSE_STATE = '@admin/ADMIN_CLEAR_COMPOSE_STATE';

export const ADMIN_SAVE_DRAFT_REQUEST = '@admin/ADMIN_SAVE_DRAFT_REQUEST';
export const ADMIN_SAVE_DRAFT_SUCCESS = '@admin/ADMIN_SAVE_DRAFT_SUCCESS';
export const ADMIN_SAVE_DRAFT_FAILURE = '@admin/ADMIN_SAVE_DRAFT_FAILURE';

export const ADMIN_MESSAGE_DRAFTS_REQUEST = '@admin/ADMIN_MESSAGE_DRAFTS_REQUEST';
export const ADMIN_MESSAGE_DRAFTS_SUCCESS = '@admin/ADMIN_MESSAGE_DRAFTS_SUCCESS';
export const ADMIN_MESSAGE_DRAFTS_FAILURE = '@admin/ADMIN_MESSAGE_DRAFTS_FAILURE';

export const ADMIN_CSV_EXPORT_REQUEST = '@admin/ADMIN_CSV_EXPORT_REQUEST';
export const ADMIN_CSV_EXPORT_SUCCESS = '@admin/ADMIN_CSV_EXPORT_SUCCESS';
export const ADMIN_CSV_EXPORT_FAILURE = '@admin/ADMIN_CSV_EXPORT_FAILURE';
export const ADMIN_CSV_EXPORT_CLEAR = '@admin/ADMIN_CSV_EXPORT_CLEAR';

export const ADMIN_CSV_IMPORT_REQUEST = '@admin/ADMIN_CSV_IMPORT_REQUEST';
export const ADMIN_CSV_IMPORT_SUCCESS = '@admin/ADMIN_CSV_IMPORT_SUCCESS';
export const ADMIN_CSV_IMPORT_FAILURE = '@admin/ADMIN_CSV_IMPORT_FAILURE';
export const ADMIN_CSV_IMPORT_CLEAR = '@admin/ADMIN_CSV_IMPORT_CLEAR';

export const ADMIN_CHANGE_PERMS_REQUEST = '@admin/ADMIN_CHANGE_PERMS_REQUEST';
export const ADMIN_CHANGE_PERMS_SUCCESS = '@admin/ADMIN_CHANGE_PERMS_SUCCESS';
export const ADMIN_CHANGE_PERMS_FAILURE = '@admin/ADMIN_CHANGE_PERMS_FAILURE';

export const ADMIN_SET_ACCOUNT_STATUS_REQUEST = '@admin/ADMIN_SET_ACCOUNT_STATUS_REQUEST';
export const ADMIN_SET_ACCOUNT_STATUS_SUCCESS = '@admin/ADMIN_SET_ACCOUNT_STATUS_SUCCESS';
export const ADMIN_SET_ACCOUNT_STATUS_FAILURE = '@admin/ADMIN_SET_ACCOUNT_STATUS_FAILURE';

export const ADMIN_MEMBER_EXPORT_FIELDS_REQUEST = '@admin/ADMIN_MEMBER_EXPORT_FIELDS_REQUEST';
export const ADMIN_MEMBER_EXPORT_FIELDS_SUCCESS = '@admin/ADMIN_MEMBER_EXPORT_FIELDS_SUCCESS';
export const ADMIN_MEMBER_EXPORT_FIELDS_FAILURE = '@admin/ADMIN_MEMBER_EXPORT_FIELDS_FAILURE';

export const ExportOutputTypes = {
  CSV: 'CSV',
};

export const ExportOutputTypeDisplays = {
  CSV: 'Comma-delimited CSV',
};

export const ExportDataTypes = {
  ALL_MEMBERS: 'ALL_MEMBERS',
  MAILING_LIST_MEMBERS: 'MAILING_LIST_MEMBERS',
  MEMBER_EMAILS: 'MEMBER_EMAILS',
};

export const ExportDataTypeDisplays = {
  ALL_MEMBERS: 'All Directory Members',
  MAILING_LIST_MEMBERS: 'All Members on Mailing List',
  MEMBER_EMAILS: 'All Member Email Addresses',
};

export const messageComposeStates = {
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IDLE: 'IDLE',
};
