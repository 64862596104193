import PropTypes from 'prop-types';
import React from 'react';


const MapPin = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M20,20a4,4,0,1,1,4-4A4,4,0,0,1,20,20Zm12-3.6C32,9.14,26.7,4,20,4S8,9.14,8,16.4c0,4.68,3.9,10.88,12,18.28C28.1,27.28,32,21.08,32,16.4ZM20,0A15.91,15.91,0,0,1,36,16.4q0,10-16,23.6Q4,26.35,4,16.4A15.91,15.91,0,0,1,20,0Z"
  />
);

MapPin.propTypes = {
  strokeWidth: PropTypes.number,
};

MapPin.defaultProps = {
  strokeWidth: 1,
};


export default MapPin;
