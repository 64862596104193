import * as actions from './actions';
import * as constants from './constants';

export const name = 'messages';

export const initialState = {
  messages: {},
  messagesFetching: false,
  messageDrafts: {},
  draftsFetching: false,
  saveDraftState: constants.messageComposeStates.IDLE,
  composeState: constants.messageComposeStates.IDLE,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.getSentMessagesRoutine.TRIGGER:
      return {
        ...state,
        messagesFetching: true,
      };

    case actions.getSentMessagesRoutine.SUCCESS:
      return {
        ...state,
        messagesFetching: false,
        messages: action.payload.messages,
      };

    case actions.getSentMessagesRoutine.FAILURE:
      return {
        ...state,
        messagesFetching: false,
      };

    case actions.saveDraftMessageRoutine.TRIGGER:
      return {
        ...state,
        saveDraftState: constants.messageComposeStates.SENDING,
      };

    case actions.saveDraftMessageRoutine.SUCCESS:
      return {
        ...state,
        saveDraftState: constants.messageComposeStates.SUCCESS,
      };

    case actions.saveDraftMessageRoutine.FAILURE:
      return {
        ...state,
        saveDraftState: constants.messageComposeStates.ERROR,
      };

    case actions.getDraftMessagesRoutine.TRIGGER:
      return {
        ...state,
        draftsFetching: true,
      };

    case actions.getDraftMessagesRoutine.SUCCESS:
      return {
        ...state,
        draftsFetching: false,
        messageDrafts: action.payload.drafts,
      };

    case actions.getDraftMessagesRoutine.FAILURE:
      return {
        ...state,
        draftsFetching: false,
      };

    case actions.sendMessageRoutine.TRIGGER:
      return {
        ...state,
        composeState: constants.messageComposeStates.SENDING,
      };

    case actions.sendMessageRoutine.SUCCESS:
      return {
        ...state,
        composeState: constants.messageComposeStates.SUCCESS,
      };

    case actions.sendMessageRoutine.FAILURE:
      return {
        ...state,
        composeState: constants.messageComposeStates.ERROR,
      };

    case constants.CLEAR_COMPOSE_STATE:
      return {
        ...state,
        composeState: constants.messageComposeStates.IDLE,
        saveDraftState: constants.messageComposeStates.IDLE,
      };

    default:
      return state;
  }
}
