//

import * as React from 'react';

// $FlowFixMe
import defaultImage from 'assets/images/default-avatar.png';
import styles from './styles.css';


class PictureUpload extends React.Component {
  static defaultProps = {
    disabled: false,
  };

  state = {
    previewUrl: null,
    file: null,
  };

  handleImageChange = (e) => {
    const { onChange, name } = this.props;

    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file,
        previewUrl: reader.result,
      }, () => {
        if (onChange) {
          onChange({
            file: this.state.file,
            name,
          });
        }
      });
    };

    reader.readAsDataURL(file);
  };

  render() {
    const { imageUrl, disabled } = this.props;
    const { previewUrl } = this.state;

    return (
      <div className="picture-container">
        <div className="picture">
          <img
            src={previewUrl || imageUrl || defaultImage}
            className="picture-src"
            alt="..." />

          {!disabled &&
            <div className={styles.promptOverlay}>
              <i className="now-ui-icons media-1_camera-compact" />
            </div>}

          {!disabled &&
            <input type="file" onChange={this.handleImageChange} />}
        </div>
      </div>
    );
  }
}

export default PictureUpload;
