import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Intent } from 'modules/core/constants';

import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';


const defaultSx = {
  borderStyle: 'default',
  borderWidth: 'small',
  borderRadius: 'block',
  fontSize: 'font16',
  padding: 'grid8',
  paddingLeft: 'grid12',
  width: '100%',
};

const getSx = (color = 'primary', bg = 'primaryAlpha10') => ({
  ...defaultSx,
  backgroundColor: bg,
  borderColor: color,
  color: 'systemText',
});

const SxForIntent = {
  [Intent.INFO]: getSx('info', 'infoAlpha10'),
  [Intent.DEFAULT]: getSx('primary', 'primaryAlpha10'),
  [Intent.WARNING]: getSx('warning', 'warningAlpha10'),
  [Intent.DANGER]: getSx('danger', 'dangerAlpha10'),
  [Intent.SUCCESS]: getSx('success', 'successAlpha10'),
};

const Callout = (props) => {
  const {
    children,
    className,
    intent,
    setRef,
    sx,
    ...rest
  } = props;

  return (
    <Box
      className={classNames({
        'j-callout': true,
        [`intent-${intent?.toLowerCase()}`]: intent,
        [className]: className,
      })}
      sx={{
        ...SxForIntent[intent],
        ...sx,
      }}
      {...rest}
    >
      <Flex
        justifyContent="flex-start"
      >
        <Text>
          {children}
        </Text>
      </Flex>
    </Box>
  );
};

Callout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  intent: PropTypes.oneOf(Object.values(Intent)),
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
};

Callout.defaultProps = {
  children: undefined,
  className: undefined,
  intent: Intent.INFO,
  setRef: undefined,
  sx: {},
};

const CalloutWithRef = React.forwardRef((props, ref) => (
  <Callout setRef={ref} {...props} />
));


export { Callout };
export default CalloutWithRef;
