import * as React from 'react';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import { classie } from '../../../utils';


function withDeferredRender(Component) {
  return class ComponentWithUserDb extends React.Component{
    static defaultProps = {
      deferLength: 200,
      useFade: true,
      render: true,
      onRender: () => {},
    };

    render() {
      const { deferLength, useFade, render, onRender, ...rest } = this.props;

      const classNames = classie([], {
        withDeferredRenderFade: useFade,
      });

      return (
        <CSSTransition
          onEnter={onRender}
          in={render}
          timeout={deferLength}
          classNames={classNames}
          unmountOnExit>
          <Component {...rest} />
        </CSSTransition>
      );
    }
  };
}

export default withDeferredRender;
