export const GROUPS_REQUEST = '@groups/GROUPS_REQUEST';
export const GROUPS_SUCCESS = '@groups/GROUPS_SUCCESS';
export const GROUPS_FAILURE = '@groups/GROUPS_FAILURE';

export const FB_GROUPS_REQUEST = '@groups/FB_GROUPS_REQUEST';
export const FB_ALL_GROUPS_REQUEST = '@groups/FB_ALL_GROUPS_REQUEST';
export const FB_GROUPS_FETCH = '@groups/FB_GROUPS_FETCH';
export const FB_GROUPS_REQUEST_NEXT = '@groups/FB_GROUPS_REQUEST_NEXT';
export const FB_GROUPS_REQUEST_PREV = '@groups/FB_GROUPS_REQUEST_PREV';
export const FB_GROUPS_SUCCESS = '@groups/FB_GROUPS_SUCCESS';
export const FB_GROUPS_FAILURE = '@groups/FB_GROUPS_FAILURE';

export const GROUP_INFO_REQUEST = '@groups/GROUP_INFO_REQUEST';
export const GROUP_INFO_SUCCESS = '@groups/GROUP_INFO_SUCCESS';
export const GROUP_INFO_FAILURE = '@groups/GROUP_INFO_FAILURE';

export const GROUPS_SUMMARIES_REQUEST = '@groups/GROUPS_SUMMARIES_REQUEST';
export const GROUPS_SUMMARIES_FETCH = '@groups/GROUPS_SUMMARIES_FETCH';
export const GROUPS_SUMMARIES_SUCCESS = '@groups/GROUPS_SUMMARIES_SUCCESS';
export const GROUPS_SUMMARIES_FAILURE = '@groups/GROUPS_SUMMARIES_FAILURE';

export const SET_GROUPS_SORT = '@groups/SET_GROUPS_SORT';

export const APPEND_GROUP = '@groups/APPEND_GROUP';
