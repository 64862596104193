import { createSelector } from 'reselect';

import { getActiveDirectoryId } from '../directory/selectors';

import { name } from './reducer';

const getState = state => state[name];

export const getUser = createSelector(
  [getState],
  state => state.userData || {},
);

export const getUserWebappState = createSelector(
  [getUser],
  user => user.webappState || {},
);

export const getDirectoryUser = createSelector(
  [getUser, getUserWebappState],
  (user, webappState = {}) => {
    if (user && webappState) {
      const dirId = webappState.activeDirectory;
      return user.profiles[dirId];
    }

    return {};
  },
);

export const getUserDirs = createSelector(
  [getUser],
  userData => userData.directories || {},
);

export const getId = createSelector(
  [getUser],
  userData => userData.id,
);

export const getEmail = createSelector(
  [getState],
  state => state.email,
);

export const getFirstName = createSelector(
  [getState],
  state => state.firstName,
);

export const getLastName = createSelector(
  [getState],
  state => state.lastName,
);

export const getRole = createSelector(
  [getUser, getActiveDirectoryId],
  (user, did) => (user.profiles && user.profiles[did] ? user.profiles[did].role : ''),
);

export const getImageUrl = createSelector(
  [getState],
  state => state.imageUrl,
);

export const getUserFamilies = createSelector(
  [getDirectoryUser],
  state => state.families || {},
);

export const getUserFamilyIds = createSelector(
  [getUserFamilies],
  fams => Object.keys(fams),
);

export const getUserGroups = createSelector(
  [getDirectoryUser],
  state => state.groups || {},
);

export const getUserGroupIds = createSelector(
  [getUserGroups],
  groups => Object.keys(groups),
);

export const getSettings = createSelector(
  [getState],
  state => state.settings,
);

export const getSettingsTheme = createSelector(
  [getSettings],
  settings => settings.theme,
);

export const getIsFetchingUser = createSelector(
  [getState],
  state => state.isFetchingUser,
);

export const getIsFetchingProfile = createSelector(
  [getState],
  state => state.isFetchingProfile,
);

export const getLedFamilies = createSelector(
  [getState],
  state => state.ledFamilies,
);

export const getLedGroups = createSelector(
  [getState],
  state => state.ledGroups,
);

export const getBackupAuth = createSelector(
  [getUser],
  user => user.backupAuth,
);

export const getLoginMethod = createSelector(
  [getUser],
  user => user.loginMethod,
);

export const getLinkedSms = createSelector(
  [getUser],
  user => user.phoneNumber,
);
