import * as React from 'react';

import { ThemeConsumer } from '../context';

function withTheme(Component) {
  return class ComponentWithTheme extends React.Component {
    static defaultProps = {
      deferLength: 200,
      useFade: true,
      render: true,
      onRender: () => {},
    };

    render() {
      return (
          <ThemeConsumer>
            {
              theme => <Component theme={theme} {...this.props} />
            }
          </ThemeConsumer>
      );
    }
  };
}

export default withTheme;
