import PropTypes from 'prop-types';
import React from 'react';


const Sort = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M0,33.33H13.33V28.89H0ZM0,6.67v4.44H40V6.67ZM0,22.22H26.67V17.78H0Z"
  />
);

Sort.propTypes = {
  strokeWidth: PropTypes.number,
};

Sort.defaultProps = {
  strokeWidth: 1,
};


export default Sort;
