// import validator from 'validator';

const phoneLocales = ['en-US'];

// export const normalizedEmail = (email) => {
//   const trimmedEmail = validator.trim(email);
//
//   const options = {
//     all_lowercase: false,
//     gmail_lowercase: true,
//     outlookdotcom_lowercase: true,
//     yahoo_lowercase: true,
//     icloud_lowercase: true,
//   };
//
//   return validator.normalizeEmail(trimmedEmail, options);
// };

// export const normalizePhone = (
//   input, countryCode,
// ) => {
//   const trimmed = validator.trim(input);
//
//   const isStrictMobilePhone = validator.isMobilePhone(
//     trimmed,
//     phoneLocales,
//     { strictMode: true },
//   );
//
//   if (isStrictMobilePhone) {
//     return trimmed;
//   }
//
//   return '';
// };

export const mapFromArray = (data = []) => (
  data.reduce((obj, item) => ({
    [item.id]: item,
    ...obj,
  }), {})
);
