import React from 'react';
import PropTypes from 'prop-types';


const withIconProps = (Component) => {
  const ComponentWithIconProps = (props) => {
    const {
      color,
      fill,
      hoverColor,
      isFocused,
      isHovered,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      stroke,
      ...rest
    } = props;

    const [isHover, setIsHover] = React.useState(false);
    const [isFocus, setIsFocus] = React.useState(false);

    const derivedIsHover = isHovered ?? isHover;
    const derivedIsFocus = isFocused ?? isFocus;
    const isHoverOrFocus = hoverColor && (derivedIsHover || derivedIsFocus);

    const fillColor = isHoverOrFocus ? hoverColor : (color || fill);
    const strokeColor = isHoverOrFocus ? hoverColor : (color || stroke);

    return (
      <Component
        fill={fillColor}
        onBlur={isFocused === undefined ? (evt) => {
          setIsFocus(false);
          onBlur?.(evt);
        } : undefined}
        onFocus={isFocused === undefined ? (evt) => {
          setIsFocus(true);
          onFocus?.(evt);
        } : undefined}
        onMouseEnter={isHovered === undefined ? (evt) => {
          setIsHover(true);
          onMouseEnter?.(evt);
        } : undefined}
        onMouseLeave={isHovered === undefined ? (evt) => {
          setIsHover(false);
          onMouseLeave?.(evt);
        } : undefined}
        stroke={strokeColor}
        {...rest}
      />
    );
  };

  ComponentWithIconProps.propTypes = {
    color: PropTypes.string,
    fill: PropTypes.string,
    hoverColor: PropTypes.string,
    isFocused: PropTypes.bool,
    isHovered: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    stroke: PropTypes.string,
  };

  ComponentWithIconProps.defaultProps = {
    color: undefined,
    fill: '#000',
    hoverColor: undefined,
    isFocused: undefined,
    isHovered: undefined,
    onBlur: undefined,
    onFocus: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    stroke: '#000',
  };

  return ComponentWithIconProps;
};


export default withIconProps;
