import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { Person } from '../shapes';


const PersonSvg = (props) => (
  <g>
    <IconBounds />
    <Person {...props} />
  </g>
);

PersonSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

PersonSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default PersonSvg;
