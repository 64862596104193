export const DIRECTORIES_REQUEST = '@directory/DIRECTORIES_REQUEST';
export const DIRECTORIES_SUCCESS = '@directory/DIRECTORIES_SUCCESS';
export const DIRECTORIES_FAILURE = '@directory/DIRECTORIES_FAILURE';

export const FB_DIRECTORIES_REQUEST = '@directory/FB_DIRECTORIES_REQUEST';
export const FB_DIRECTORIES_FETCH = '@directory/FB_DIRECTORIES_FETCH';
export const FB_DIRECTORIES_SUCCESS = '@directory/FB_DIRECTORIES_SUCCESS';
export const FB_DIRECTORIES_FAILURE = '@directory/FB_DIRECTORIES_FAILURE';

export const FB_MULTI_DIRECTORIES_REQUEST = '@directory/FB_MULTI_DIRECTORIES_REQUEST';
export const FB_MULTI_DIRECTORIES_SUCCESS = '@directory/FB_MULTI_DIRECTORIES_SUCCESS';
export const FB_MULTI_DIRECTORIES_FAILURE = '@directory/FB_MULTI_DIRECTORIES_FAILURE';

export const SEARCH_MEMBERS_REQUEST = '@directory/SEARCH_MEMBERS_REQUEST';
export const SEARCH_MEMBERS_SUCCESS = '@directory/SEARCH_MEMBERS_SUCCESS';
export const SEARCH_MEMBERS_FAILURE = '@directory/SEARCH_MEMBERS_FAILURE';

export const SEARCH_FAMILIES_REQUEST = '@directory/SEARCH_FAMILIES_REQUEST';
export const SEARCH_FAMILIES_SUCCESS = '@directory/SEARCH_FAMILIES_SUCCESS';
export const SEARCH_FAMILIES_FAILURE = '@directory/SEARCH_FAMILIES_FAILURE';

export const SEARCH_GROUPS_REQUEST = '@directory/SEARCH_GROUPS_REQUEST';
export const SEARCH_GROUPS_SUCCESS = '@directory/SEARCH_GROUPS_SUCCESS';
export const SEARCH_GROUPS_FAILURE = '@directory/SEARCH_GROUPS_FAILURE';

export const SWITCH_DIRECTORY = '@directory/SWITCH_DIRECTORY';

export const SWITCH_DIRECTORY_REQUEST = '@directory/SWITCH_DIRECTORY_REQUEST';
export const SWITCH_DIRECTORY_SUCCESS = '@directory/SWITCH_DIRECTORY_SUCCESS';
export const SWITCH_DIRECTORY_FAILURE = '@directory/SWITCH_DIRECTORY_FAILURE';

export const FETCH_ENTITIES_REQUEST = '@directory/FETCH_ENTITIES_REQUEST';
export const FETCH_ENTITIES_SUCCESS = '@directory/FETCH_ENTITIES_SUCCESS';
export const FETCH_ENTITIES_FAILURE = '@directory/FETCH_ENTITIES_FAILURE';

export const DIR_FEATURES_REQUEST = '@directory/DIR_FEATURES_REQUEST';
export const DIR_FEATURES_SUCCESS = '@directory/DIR_FEATURES_SUCCESS';
export const DIR_FEATURES_FAILURE = '@directory/DIR_FEATURES_FAILURE';
