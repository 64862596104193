import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getIsAuthenticated, getIsFetching } from '../../../auth/selectors';
import { getRole } from '../../../user/selectors';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  isAuthenticated: getIsAuthenticated,
  isFetching: getIsFetching,
  role: getRole,
});

export default connect(
  mapStateToProps,
)(presenter);
