import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import {
  Checkbox,
  Flex,
  LinkNewPage,
  Text,
} from 'modules/core/components';


const CustomField = ({ field, isFetching, sx }) => {
  if (!field) {
    return null;
  }

  const { type, value } = field;

  let valElement;

  if (type === 'url') {
    const urlString = value.includes('http') ? value : `https://${value}`;
    const urlDisplay = urlString.replace(/^http(s*):\/\//g, '');

    valElement = (
      <LinkNewPage href={urlString}>
        {urlDisplay}
      </LinkNewPage>
    );
  } else if (type === 'boolean') {
    valElement = (
      <Checkbox
        id={field.name}
        readOnly
        value={value}
      />
    );
  } else if (type === 'date') {
    valElement = moment(value).format('MMMM DD, YYYY');
  } else {
    valElement = (
      <span>{value}</span>
    );
  }

  return (
    <Flex sx={sx}>
      <Text
        sx={{
          color: 'systemTextLight',
          width: '40%',
          fontWeight: 'semibold',
        }}
      >
        {field.name}
      </Text>

      <Text
        sx={{
          color: 'infoValue',
          width: '60%',
        }}
      >
        {valElement}
      </Text>
    </Flex>
  );
};

CustomField.propTypes = {
  field: PropTypes.shape({}),
  isFetching: PropTypes.bool,
  sx: PropTypes.shape({}),
};

CustomField.defaultProps = {
  field: undefined,
  isFetching: undefined,
  sx: {},
};


export default CustomField;
