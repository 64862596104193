import * as React from 'react';

import theme from '../../../config/colorTheme';

const { Provider, Consumer } = React.createContext(theme);

export {
  Provider,
  Consumer,
};
