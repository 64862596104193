import PropTypes from 'prop-types';
import React from 'react';


const Filter = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M15.56,33.33h8.88V28.89H15.56ZM0,6.67v4.44H40V6.67ZM6.67,22.22H33.33V17.78H6.67Z"
  />
);

Filter.propTypes = {
  strokeWidth: PropTypes.number,
};

Filter.defaultProps = {
  strokeWidth: 1,
};


export default Filter;
