import * as constants from './constants';

export function searchRequest(searchType, term) {
  return {
    type: constants.SEARCH_REQUEST,
    payload: {
      searchType,
      term,
    },
  };
}

export function searchSuccess(searchType, results) {
  return {
    type: constants.SEARCH_SUCCESS,
    payload: {
      searchType,
      results,
    },
  };
}

export function searchFailure(message, error) {
  return {
    type: constants.SEARCH_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function toggleShowSearch() {
  return {
    type: constants.TOGGLE_SHOW,
  };
}

export function clearSearch() {
  return {
    type: constants.SEARCH_CLEAR,
  };
}
