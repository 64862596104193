import * as constants from './constants';

export function setFamiliesSort(by, dir) {
  return {
    type: constants.SET_FAMILIES_SORT,
    payload: {
      by,
      dir,
    },
  };
}

export function familiesRequest() {
  return {
    type: constants.FAMILIES_REQUEST,
  };
}

export function familiesSuccess(families, activeFamilyId) {
  return {
    type: constants.FAMILIES_SUCCESS,
    payload: {
      families,
      activeFamilyId,
    },
  };
}

export function familiesFailure(message) {
  return {
    type: constants.FAMILIES_FAILURE,
    payload: {
      message,
    },
  };
}

export function fbFamiliesRequest(directoryId, pageNumber, sort, sortDir) {
  return {
    type: constants.FB_FAMILIES_REQUEST,
    payload: {
      directoryId,
      pageNumber,
      sort,
      sortDir,
    },
  };
}

export function fbAllFamiliesRequest(directoryId, pageNumber, sort, sortDir) {
  return {
    type: constants.FB_ALL_FAMILIES_REQUEST,
    payload: {
      directoryId,
      pageNumber,
      sort,
      sortDir,
    },
  };
}

export function fbFamiliesFetch() {
  return {
    type: constants.FB_FAMILIES_FETCH,
  };
}

export function fbFamiliesRequestNext(directoryId) {
  return {
    type: constants.FB_FAMILIES_REQUEST_NEXT,
    payload: {
      directoryId,
    },
  };
}

export function fbFamiliesRequestPrev(directoryId) {
  return {
    type: constants.FB_FAMILIES_REQUEST_PREV,
    payload: {
      directoryId,
    },
  };
}

export function fbFamiliesSuccess(families, familyIds, pagination) {
  return {
    type: constants.FB_FAMILIES_SUCCESS,
    payload: {
      families,
      familyIds,
      pagination,
    },
  };
}

export function fbFamiliesFailure(message, error) {
  return {
    type: constants.FB_FAMILIES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function familyInfoRequest(familyShortCode) {
  return {
    type: constants.FAMILY_INFO_REQUEST,
    payload: {
      familyShortCode,
    },
  };
}

export function familyInfoSuccess(family, activeFamilyShortCode) {
  return {
    type: constants.FAMILY_INFO_SUCCESS,
    payload: {
      family,
      activeFamilyShortCode,
    },
  };
}

export function familyInfoFailure(message) {
  return {
    type: constants.FAMILY_INFO_SUCCESS,
    payload: {
      message,
    },
  };
}

export function familySummariesRequest(include) {
  return {
    type: constants.FAMILY_SUMMARIES_REQUEST,
    payload: {
      include,
    },
  };
}

export function familySummariesFetch() {
  return {
    type: constants.FAMILY_SUMMARIES_FETCH,
  };
}

export function familySummariesSuccess(summaries, ids) {
  return {
    type: constants.FAMILY_SUMMARIES_SUCCESS,
    payload: {
      summaries,
      ids,
    },
  };
}

export function familySummariesFailure(message, error) {
  return {
    type: constants.FAMILY_SUMMARIES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}
