import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';


export default (...args) => {
  const classes = [];

  args.forEach((arg) => {
    if (arg) {
      if (Array.isArray(arg)) {
        arg.forEach((c) => {
          if (_isString(c) && c.length) {
            classes.push(c);
          }
        });
      } else if (_isObject(arg)) {
        const keys = Object.keys(arg);
        for (let i = 0; i < keys.length; i++) {
          if (arg[keys[i]]) {
            classes.push(keys[i]);
          }
        }
      } else if (_isString(arg)) {
        classes.push(arg);
      }
    }
  });

  return classes.join(' ');
};
