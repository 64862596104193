import PropTypes from 'prop-types';
import React from 'react';


const Settings = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M34.86,22A15.59,15.59,0,0,0,35,20a15.59,15.59,0,0,0-.14-2l4.22-3.3a1,1,0,0,0,.24-1.28l-4-6.92a1,1,0,0,0-.88-.5.93.93,0,0,0-.34.06l-5,2a14.61,14.61,0,0,0-3.38-2L25,.84A1,1,0,0,0,24,0H16a1,1,0,0,0-1,.84l-.76,5.3a15.37,15.37,0,0,0-3.38,2l-5-2A1.13,1.13,0,0,0,5.54,6a1,1,0,0,0-.86.5l-4,6.92a1,1,0,0,0,.24,1.28L5.14,18A15.86,15.86,0,0,0,5,20a15.86,15.86,0,0,0,.14,2L.92,25.26a1,1,0,0,0-.24,1.28l4,6.92a1,1,0,0,0,.88.5.93.93,0,0,0,.34-.06l5-2a14.61,14.61,0,0,0,3.38,2l.76,5.3A1,1,0,0,0,16,40h8a1,1,0,0,0,1-.84l.76-5.3a15.37,15.37,0,0,0,3.38-2l5,2a1.13,1.13,0,0,0,.36.06,1,1,0,0,0,.86-.5l4-6.92a1,1,0,0,0-.24-1.28Zm-4-3.42A10.69,10.69,0,0,1,31,20c0,.42,0,.86-.1,1.46l-.28,2.26,1.78,1.4,2.16,1.68-1.4,2.42-2.54-1-2.08-.84-1.8,1.36a11.71,11.71,0,0,1-2.5,1.46L22.12,31,21.8,33.3,21.4,36H18.6l-.38-2.7L17.9,31l-2.12-.86a11.35,11.35,0,0,1-2.46-1.42l-1.82-1.4-2.12.86-2.54,1-1.4-2.42L7.6,25.14l1.78-1.4L9.1,21.48C9,20.86,9,20.4,9,20s0-.86.1-1.46l.28-2.26L7.6,14.88,5.44,13.2l1.4-2.42,2.54,1,2.08.84,1.8-1.36a11.71,11.71,0,0,1,2.5-1.46L17.88,9,18.2,6.7,18.6,4h2.78l.38,2.7L22.08,9l2.12.86a11.35,11.35,0,0,1,2.46,1.42l1.82,1.4,2.12-.86,2.54-1,1.4,2.42-2.14,1.7-1.78,1.4ZM20,12a8,8,0,1,0,8,8A8,8,0,0,0,20,12Zm0,12a4,4,0,1,1,4-4A4,4,0,0,1,20,24Z"
  />
);

Settings.propTypes = {
  strokeWidth: PropTypes.number,
};

Settings.defaultProps = {
  strokeWidth: 1,
};


export default Settings;
