import { createSelector } from 'reselect';
import _ from 'lodash';

import { SortUtils } from 'utils';

import { name } from './reducer';

const getState = state => state[name];

export const getMembers = createSelector(
  [getState],
  state => state.members,
);

export const getMembersByLastInitial = createSelector(
  [getMembers],
  members => _.groupBy(members, m => m.lastName.substring(0, 1).toUpperCase()),
);

export const getMemberShortCodes = createSelector(
  [getMembers],
  members => Object.keys(members),
);

export const getMemberIds = createSelector(
  [getState],
  state => state.memberIds,
);

export const getViewing = createSelector(
  [getState],
  state => state.viewing,
);

export const getActiveMemberShortCode = createSelector(
  [getState],
  state => state.activeMemberShortCode,
);

export const getIsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getMembersPagination = createSelector(
  [getState],
  state => state.pagination,
);

export const getMembersPaginationPage = createSelector(
  [getMembersPagination],
  pagination => pagination.page,
);

export const getMembersPaginationPages = createSelector(
  [getMembersPagination],
  pagination => pagination.pages,
);

export const getMembersPaginationHasMore = createSelector(
  [getMembersPaginationPage, getMembersPaginationPages],
  (page, pages) => pages - page > 0,
);

export const getMembersPaginationDataRange = createSelector(
  [getMembersPagination],
  pagination => pagination.dataRange,
);

export const getPersonSummaries = createSelector(
  [getState],
  state => state.personSummaries,
);

export const getIsFetchingPersonSummaries = createSelector(
  [getPersonSummaries],
  pSum => pSum.isFetching,
);

export const getAllPersonSummaries = createSelector(
  [getPersonSummaries],
  pSum => pSum.summaries,
);

export const getAllPersonSummariesSortedByLastName = createSelector(
  [getPersonSummaries],
  (pSum) => {
    if (!pSum) return [];
    const { summaries } = pSum;
    const summaryList = Object.values(summaries);
    return SortUtils.sortMembers(summaryList);
  },
);

export const getPersonSummariesIds = createSelector(
  [getPersonSummaries],
  pSum => pSum.ids,
);

export const getMembersLastFetched = createSelector(
  [getState],
  state => state.lastFetched,
);

export const getMembersSort = createSelector(
  [getState],
  state => state.sort,
);
