import React from 'react';
import classNames from 'classnames';

import Icon, { IconNames } from './presenter';

import { iconDefaultProps, iconPropTypes } from './iconPropTypes';


const CheckIcon = ({ className, size, ...rest }) => (
  <Icon
    className={classNames({
      'j-check-icon': true,
      [className]: className,
    })}
    name={IconNames.CHECK}
    size={size}
    {...rest}
  />
);

CheckIcon.propTypes = {
  ...iconPropTypes,
};

CheckIcon.defaultProps = {
  ...iconDefaultProps,
};


export default CheckIcon;
