import * as React from 'react';
import PropTypes from 'prop-types';
import { Radio as RebassRadio } from '@rebass/forms';

import Flex from '../Flex';
import Box from '../Box';
import Label from '../Label';


const RadioButtons = (props) => {
  const {
    isHorizontal,
    name,
    onChange,
    options,
    setRef,
    value,
  } = props;

  return (
    <Flex
      flexDirection={isHorizontal ? 'row' : 'column'}
      ref={setRef}
    >
      {options.map((option, idx) => (
        <Label
          key={option.value}
          for={option.value}
          sx={{
            marginLeft: isHorizontal && idx > 0 ? 'grid4' : 'grid0',
            marginTop: !isHorizontal && idx > 0 ? 'grid4' : 'grid0',
          }}
        >
          <RebassRadio
            checked={option.value === value}
            name={name}
            onChange={onChange}
            id={option.value}
            value={option.value}
          />
          <Box as="span" sx={{ alignSelf: 'center' }}>
            {option.label}
          </Box>
        </Label>
      ))}
    </Flex>
  );
};

RadioButtons.propTypes = {
  isHorizontal: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  setRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  value: PropTypes.any,
};

RadioButtons.defaultProps = {
  isHorizontal: undefined,
  onChange: undefined,
  options: [],
  setRef: undefined,
  value: undefined,
};

const RadioButtonsWithRef = React.forwardRef((props, ref) => (
  <RadioButtons setRef={ref} {...props} />
));


export { RadioButtons };
export default RadioButtonsWithRef;
