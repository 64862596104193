import React from 'react';
import PropTypes from 'prop-types';

import { usePrevious } from 'hooks';

import { usePanelClassnames, usePeopleTypeViewFilter } from './hooks';

const RouteWatchdog = (props) => {
  const { location } = props;

  const prevPathname = usePrevious(location.pathname);

  usePanelClassnames(props, prevPathname);
  usePeopleTypeViewFilter(props, prevPathname);

  return null;
};

RouteWatchdog.propTypes = {
  location: PropTypes.shape({}).isRequired,
  pushLocationHistory: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};


export default RouteWatchdog;
