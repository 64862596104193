import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

// root app reducer and saga
import persistedReducer from '../modules/root/reducer';
import rootSaga from '../modules/root/saga';

import initStoreAccessor from './accessor';


// configure the redux devtools extension (only `true` in development)
const devtoolsEnabled = process.env.REACT_APP_DEVTOOLS_ENABLED;
const composeEnhancers = devtoolsEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

// our redux data store configuration
export default function configureStore(nextRoute) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  const store = createStore(
    persistedReducer,
    {
      meta: {
        nextRoute: nextRoute !== '/login' ? nextRoute : null,
      },
    },
    composeEnhancers(applyMiddleware(...middleware)),
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  initStoreAccessor(store);

  return { persistor, store };
}
