import PropTypes from 'prop-types';
import React from 'react';


const FilterAlt = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={String(strokeWidth)}
    mask="url(#filter-alt-icon-mask)"
    fillRule="evenodd"
    d="M7.44,5H32.58L20,20.75ZM.53,4C5.61,10.5,15,22.5,15,22.5v15A2.52,2.52,0,0,0,17.5,40h5A2.52,2.52,0,0,0,25,37.5v-15S34.39,10.5,39.47,4a2.5,2.5,0,0,0-2-4h-35A2.5,2.5,0,0,0,.53,4Z"
    {...rest}
  />
);

FilterAlt.propTypes = {
  strokeWidth: PropTypes.number,
};

FilterAlt.defaultProps = {
  strokeWidth: 1,
};


export default FilterAlt;
