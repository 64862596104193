import React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';

import FieldErrors from '../FieldErrors';


const { Option } = Select;

const CountryCodeSelector = (
  {
    codes,
    disabled,
    errors,
    name,
    onChange,
    style,
    value,
    ...rest
  },
) => (
  <span style={{ display: 'inline-block', width: '120px', ...style }}>
    <Select
      allowClear={!!value && value.length > 0}
      className={classNames({
        error: !!errors,
      })}
      disabled={disabled}
      placeholder="Country code"
      onChange={val => onChange(val || '', name)}
      optionFilterProp="children"
      value={value}
      showSearch
      style={{ width: '120px', border: errors ? '1px solid #CA5486' : undefined }}
      {...rest}
    >
      {codes.map(code => (
        <Option
          key={`${code.code}_${code.value}`}
          value={`${code.code}_${code.value}`}
        >
          {code.name}
        </Option>
      ))}
    </Select>

    <FieldErrors errors={errors} style={{ width: '120px' }} />
  </span>
);

CountryCodeSelector.defaultProps = {
  style: {},
};


export default CountryCodeSelector;
