import { createSelector } from 'reselect';

import { name } from './reducer';

import { getPathname } from '../router/selectors';

const getState = state => state[name];

export const getSearchFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getSearchType = createSelector(
  [getState],
  state => state.searchType,
);

export const getSearchTypeNameForPathname = createSelector(
  [getPathname],
  (pathname) => {
    if (pathname.includes('people')) return 'People';
    if (pathname.includes('families')) return 'Families';
    if (pathname.includes('groups')) return 'Groups';
    if (pathname.includes('messages')) return 'Messages';
    return '';
  },
);

export const getSearchResults = createSelector(
  [getState],
  state => state.results || [],
);
