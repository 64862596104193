import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import moment from 'moment';

import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import Box from '../Box';
import Icon, { IconNames } from '../Icon';

import { GoogleCal, ICal, Outlook } from './components';


const MS_IN_MINUTES = 60 * 1000;

const formatTime = (date, allDayEvent) => {
  if (allDayEvent) {
    return moment(date).format('YYYYMMDD');
  }

  return date.toISOString().replace(/-|:|\.\d+/g, '');
};

const calculateEndTime = (event) => {
  if (event.allDayEvent) {
    return moment(event.start).format('YYYYMMDD');
  }

  return (
    event.end ?
      formatTime(event.end) :
      formatTime(new Date(event.start.getTime() + (event.duration * MS_IN_MINUTES)))
  );
};

const calendarGenerators = {
  google(event) {
    const startTime = formatTime(event.start, event.allDayEvent);
    const endTime = calculateEndTime(event);

    const components = [
      'https://www.google.com/calendar/render?action=TEMPLATE',
      `&text=${event.title || ''}`,
      `&dates=${startTime || ''}`,
      `/${endTime || ''}`,
    ];

    if (event.description) {
      components.push(`&details=${event.description}`);
    }

    if (event.address) {
      components.push(`&location=${event.address}`);
    }

    if (event.recurringRule) {
      components.push(`&recur=${event.recurringRule}`);
    }

    components.push('&sprop=&sprop=name:');

    return encodeURI(components.join(''));
  },
  yahoo(event) {
    const eventDuration = event.end ?
      ((event.end.getTime() - event.start.getTime()) / MS_IN_MINUTES) :
      event.duration;

    // Yahoo dates are crazy, we need to convert the duration from minutes to hh:mm
    const yahooHourDuration = eventDuration < 600 ?
      `0${Math.floor((eventDuration / 60))}` :
      `${Math.floor((eventDuration / 60))}`;

    const yahooMinuteDuration = eventDuration % 60 < 10 ?
      `0${eventDuration % 60}` :
      `${eventDuration % 60}`;

    const yahooEventDuration = yahooHourDuration + yahooMinuteDuration;

    // Remove timezone from event time
    const st = formatTime(new Date(event.start - (event.start.getTimezoneOffset() *
      MS_IN_MINUTES)), event.allDayEvent) || '';

    return encodeURI([
      'http://calendar.yahoo.com/?v=60&view=d&type=20',
      `&title=${event.title || ''}`,
      `&st=${st}`,
      `&dur=${yahooEventDuration || ''}`,
      `&desc=${event.description || ''}`,
      `&in_loc=${event.address || ''}`,
    ].join(''));
  },
  ics(event) {
    const startTime = formatTime(event.start, event.allDayEvent);
    const endTime = calculateEndTime(event);

    const params = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${document.URL}`,
      `DTSTART:${startTime || ''}`,
      `DTEND:${endTime || ''}`,
      `SUMMARY:${event.title || ''}`,
      `DESCRIPTION:${event.description || ''}`,
      `LOCATION:${event.address || ''}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ];

    if (event.recurringRule) {
      params.push(event.recurringRule);
    }

    return encodeURI(
      `data:text/calendar;charset=utf8,${params.join('\n')}`);
  },
  ical(event) {
    return this.ics(event);
  },
  outlook(event) {
    return this.ics(event);
  },
};

const generateCalendars = event => ({
  google: calendarGenerators.google(event),
  yahoo: calendarGenerators.yahoo(event),
  ical: calendarGenerators.ical(event),
  outlook: calendarGenerators.outlook(event),
});

const validParams = params => (
  params.data !== undefined && params.data.start !== undefined &&
    (params.data.end !== undefined || params.data.duration !== undefined)
);

const createCalendars = (params) => {
  if (validParams(params)) {
    return generateCalendars(params.data);
  }

  return undefined;
};

class AddToCalendar extends React.Component {
  state = {
    calendars: undefined,
    visible: false,
  };

  calendarData;

  componentDidMount() {
    const { start, title } = this.props;

    if (start && title) {
      this.tryMakeCalendars();
    }
  }

  componentDidUpdate() {
    const { start, title } = this.props;
    const { calendars } = this.state;

    if (start && title && !calendars) {
      this.tryMakeCalendars();
    }
  }

  tryMakeCalendars = () => {
    const {
      allDayEvent,
      duration,
      recurringRule,
      start,
      title,
    } = this.props;

    const calendars = createCalendars({
      data: {
        allDayEvent,
        duration,
        recurringRule,
        start,
        title,
      },
    });

    this.calendarData = {
      allDayEvent,
      duration,
      recurringRule,
      start,
      title,
    };

    this.setState({ calendars });
  };

  handleTogglePopover = (evt) => {
    const { visible } = this.state;
    evt.preventDefault();
    this.setState({ visible: !visible });
  };

  renderContent = () => {
    const { isMobile, onIcsEvent } = this.props;
    const { calendars } = this.state;

    if (!calendars) {
      return null;
    }

    return (
      <div>
        <GoogleCal url={calendars.google} />
        <br />
        <ICal onClick={() => onIcsEvent(this.calendarData, 'ical', calendars.ical)} />
        <br />
        <Outlook onClick={() => onIcsEvent(this.calendarData, 'outlook', calendars.outlook)} />
      </div>
    );
  };

  render() {
    const { title } = this.props;

    const content = this.renderContent();

    return (
      <Popover
        content={content}
        placement="right"
        title={`Add "${title}" to calendar`}
        trigger="click"
      >
        <Box sx={{ marginRight: 'grid8' }}>
          <a onClick={this.handleTogglePopover}>
            <Icon
              className="j-bday-icon"
              color={colors.heliotrope}
              name={IconNames.CAKE}
              size={IconSize.ICON_MED}
              svgProps={{
                style: {
                  marginBottom: '4px',
                },
              }}
            />
          </a>
        </Box>
      </Popover>
    );
  }
}

AddToCalendar.propTypes = {
  allDayEvent: PropTypes.bool,
  duration: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  onIcsEvent: PropTypes.func.isRequired,
  recurringRule: PropTypes.string,
  start: PropTypes.instanceOf(Date),
  title: PropTypes.string,
};

AddToCalendar.defaultProps = {
  allDayEvent: false,
  isMobile: false,
  recurringRule: undefined,
  start: undefined,
  title: undefined,
};


export default AddToCalendar;
