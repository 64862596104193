// 
import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Button } from 'antd';

// $FlowFixMe
import logoImage from 'assets/images/logo.png';
// $FlowFixMe
import { FireAuth } from 'modules/services';
// $FlowFixMe
import { classie } from 'utils';

import { Header } from './components';
import styles from './styles.css';

class FinishSignup extends React.Component {
  state = {
    isMounted: false,
    isLoginUrl: false,
    email: '',
    password: '',
    confirm: '',
  };

  componentDidMount() {
    const storedEmail = window.localStorage.getItem('emailForSignIn');
    const url = window.location.href;
    const isLoginUrl = FireAuth.isUrlForEmailLogin(url);
    this.setState({ isLoginUrl, isMounted: true, email: storedEmail || '' });
  }

  handleFinishSignup = async () => {
    const { email, password } = this.state;
    const url = window.location.href;
    // const result = await FireAuth.loginWithEmailLink(email, url);
    const user = await FireAuth.linkToPasswordCredential(email, password);
  };

  render() {
    const { isMounted, isLoginUrl, email, password, confirm } = this.state;

    if (isMounted && !isLoginUrl) {
      return <Redirect to="/login" />;
    }

    const buttonDisabled = !email.length || password.length < 8 || password !== confirm;

    return (
      <div className="finishSignup">
        <Header {...this.props} />

        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            <div>
              <div className="full-page-content">
                <div className="lock-page">
                  <Container>
                    <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
                      <Card className="card-lock text-center">
                        <img
                          style={{
                            backgroundColor: 'white',
                            maxWidth: '120px',
                            maxHeight: '39px',
                            height: '39px',
                            width: 'auto',
                            marginTop: '30px',
                            marginBottom: '15px',
                          }}
                          src={logoImage}
                          alt="avatar-img" />
                        <CardBody>
                          <FormGroup>
                            <Input
                              onChange={e => this.setState({ email: e.target.value })}
                              value={email}
                              type="email"
                              placeholder="Confirm Email Address" />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              onChange={e => this.setState({ password: e.target.value })}
                              value={password}
                              type="password"
                              placeholder="Choose a Password" />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              onChange={e => this.setState({ confirm: e.target.value })}
                              value={confirm}
                              type="password"
                              placeholder="Retype Password" />
                          </FormGroup>
                        </CardBody>
                        <CardFooter>
                          <Button
                            disabled={buttonDisabled}
                            onClick={this.handleFinishSignup}
                            color="info"
                            size="lg"
                            round>
                            Create My Account
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Container>
                </div>
              </div>
              <div
                className={classie(['full-page-background', styles.background])} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FinishSignup;
