export const contentContainerStyles = {
  justifyContent: 'space-between',
  paddingLeft: 'grid16',
  paddingRight: 'grid16',
  marginBottom: 'grid16',
  marginTop: 'grid8',
  [global.Breakpoint.tablet]: {
    paddingLeft: 'grid0',
    paddingRight: 'grid0',
  },
};

export const entitiesContentContainerStyles = {
  marginBottom: 'grid32',
};

export const entitiesContainerStyle = {
  position: 'relative',
  minHeight: '80vh',
};

export const sortPickerStyles = {
  alignSelf: 'center',
};

export const letterHeadingStyles = {
  paddingTop: 'grid16',
  paddingBottom: 'grid16',
};

export const bottomPaginationWrapperStyles = {
  marginTop: 'grid16',
  marginBottom: 'grid16',
};
