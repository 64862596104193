import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';

import { mqlTablet } from 'mql';

import theme, { desktopTheme } from '../modules/core/theme';
import breakpoints from '../modules/core/breakpoints';

import { ScrollToTop, Routes } from '../modules/router/components';
import { ErrorBoundary } from '../modules/error/components';
import { NotifsWatchdog } from '../modules/notifications/components';


class App extends React.Component {
  state = {
    activeTheme: desktopTheme,
  };

  componentDidMount() {
    mqlTablet.addListener(this.handleMQEvent);
  }

  handleMQEvent = (evt) => {
    if (evt.matches) {
      this.setState({ activeTheme: theme });
    } else {
      this.setState({ activeTheme: desktopTheme });
    }
  };

  render() {
    const { store, persistor } = this.props;
    const { activeTheme } = this.state;

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ScrollToTop>
              <ErrorBoundary>
                <ThemeProvider theme={{ ...activeTheme, breakpoints }}>
                  <Routes />
                </ThemeProvider>
                <NotifsWatchdog />
              </ErrorBoundary>
            </ScrollToTop>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
