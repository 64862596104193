import React from 'react';
import { Button } from 'antd';

import defaultImage from 'assets/images/image_placeholder.jpg';


class ImageUpload extends React.Component {
  state = {
    file: null,
    imageUrl: defaultImage,
  };

  inputRef;

  setInputRef = (el) => {
    if (el) this.inputRef = el;
  };

  handleImageChange = (e) => {
    const { onChange, name } = this.props;

    if (e) e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file,
        imageUrl: reader.result,
      }, () => {
        if (onChange) {
          onChange({
            file: this.state.file,
            name,
          });
        }
      });
    };
    reader.readAsDataURL(file);
  };

  handleClick = () => {
    this.inputRef.click();
  };

  handleRemove = () => {
    const { onChange, name } = this.props;

    this.setState({
      file: null,
      imageUrl: defaultImage,
    }, () => {
      onChange({
        file: this.state.file,
        name,
      });
    });
    this.inputRef.value = null;
  };

  render() {
    const { buttonText } = this.props;

    return (
      <div className="fileinput text-center">
        <input
          type="file"
          onChange={this.handleImageChange}
          ref={this.setInputRef} />

        <div className="thumbnail">
          <img src={this.state.imageUrl} alt="..." />
        </div>

        <div>
          {this.state.file === null ? (
            <Button round onClick={() => this.handleClick()}>
              {buttonText || 'Select Image'}
            </Button>
          ) : (
            <span>
              <Button round onClick={() => this.handleClick()}>
                Change
              </Button>
              <Button color="danger" round onClick={this.handleRemove}>
                <i className="fa fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default ImageUpload;
