import PropTypes from 'prop-types';
import React from 'react';


const Person = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M8.33,25V20h5V16.67h-5v-5H5v5H0V20H5v5ZM20,22.92c-3.9,0-11.67,1.95-11.67,5.83v2.92H31.67V28.75C31.67,24.87,23.9,22.92,20,22.92Zm-7.77,5.41A16.17,16.17,0,0,1,20,26.25a16.17,16.17,0,0,1,7.77,2.08ZM20,20a5.84,5.84,0,1,0-5.83-5.83A5.84,5.84,0,0,0,20,20Zm0-8.33a2.5,2.5,0,1,1-2.5,2.5A2.49,2.49,0,0,1,20,11.67ZM28.33,20a5.84,5.84,0,1,0,0-11.67,5.76,5.76,0,0,0-1.18.12,9,9,0,0,1,0,11.42A6.14,6.14,0,0,0,28.33,20Zm3.87,3.37A6.79,6.79,0,0,1,35,28.75v2.92h5V28.75C40,25.93,35.93,24.15,32.2,23.37Z"
  />
);

Person.propTypes = {
  strokeWidth: PropTypes.number,
};

Person.defaultProps = {
  strokeWidth: 1,
};


export default Person;
