import * as constants from './constants';

export const name = 'admin';

const initialState = {
  adder: {
    isFetching: false,
    error: null,
    adding: {},
    added: {},
  },
  viewingEntity: {},
  isFetching: false,
  customFields: {},
  customFieldIds: [],
  customFieldsFetching: false,
  internalFields: {},
  internalFieldIds: [],
  internalFieldsFetching: false,
  tags: {},
  tagIds: [],
  tagsFetching: false,
  userCategories: {},
  userCategoryIds: [],
  userCatsFetching: false,
  messages: {},
  messagesFetching: false,
  messageDrafts: {},
  draftsFetching: false,
  saveDraftState: constants.messageComposeStates.IDLE,
  composeState: constants.messageComposeStates.IDLE,
  exports: {
    isFetching: false,
    // exportDataType: constants.ExportDataTypes.ALL_MEMBERS,
    // data: null,
    errorMessage: null,
    memberFields: {},
  },
  imports: {
    isFetching: false,
    errorMessage: null,
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.ADMIN_USER_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.ADMIN_USER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        viewingEntity: action.payload.user,
      };

    case constants.ADMIN_USER_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        viewingEntity: {},
      };

    case constants.ADMIN_CLEAR_VIEWING_ENTITY:
      return {
        ...state,
        viewingEntity: {},
      };

    case constants.ADMIN_SAVE_USER_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.ADMIN_SAVE_USER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        viewingEntity: action.payload.user,
      };

    case constants.ADMIN_SAVE_USER_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case constants.ADMIN_ADD_MEMBER_REQUEST:
      return {
        ...state,
        adder: {
          ...state.adder,
          isFetching: true,
          adding: action.payload.members,
        },
      };

    case constants.ADMIN_ADD_MEMBER_FAILURE:
      return {
        ...state,
        adder: {
          ...state.adder,
          isFetching: false,
          error: action.payload.message,
        },
      };

    case constants.ADMIN_ADD_MEMBER_SUCCESS:
      return {
        ...state,
        adder: {
          ...state.adder,
          isFetching: false,
          added: action.payload.added,
          adding: {},
        },
      };

    case constants.ADMIN_ADD_MULTI_MEMBER_REQUEST:
      return {
        ...state,
        adder: {
          ...state.adder,
          isFetching: true,
          adding: action.payload.members,
        },
      };

    case constants.ADMIN_ADD_MULTI_MEMBER_FAILURE:
      return {
        ...state,
        adder: {
          ...state.adder,
          isFetching: false,
          error: action.payload.message,
          adding: {},
          added: {},
        },
      };

    case constants.ADMIN_ADD_MULTI_MEMBER_SUCCESS:
      return {
        ...state,
        adder: {
          ...state.adder,
          isFetching: false,
          added: action.payload.members,
          adding: {},
        },
      };

    case constants.ADMIN_DIR_FIELDS_REQUEST:
      return {
        ...state,
        customFieldsFetching: true,
        internalFieldsFetching: true,
      };

    case constants.ADMIN_DIR_FIELDS_SUCCESS:
      return {
        ...state,
        customFieldsFetching: false,
        internalFieldsFetching: false,
        customFieldIds: Object.keys(action.payload.customFields),
        customFields: action.payload.customFields,
        internalFields: action.payload.internalFields,
        internalFieldIds: Object.keys(action.payload.customFields),
      };

    case constants.ADMIN_DIR_FIELDS_FAILURE:
      return {
        ...state,
        customFieldsFetching: false,
        internalFieldsFetching: false,
      };

    case constants.ADMIN_TAGS_REQUEST:
      return {
        ...state,
        tagsFetching: true,
      };

    case constants.ADMIN_TAGS_SUCCESS:
      return {
        ...state,
        tagsFetching: false,
        tags: action.payload.tags,
        tagIds: action.payload.tagIds,
      };

    case constants.ADMIN_TAGS_FAILURE:
      return {
        ...state,
        tagsFetching: false,
      };

    case constants.ADMIN_USER_CATS_REQUEST:
      return {
        ...state,
        userCatsFetching: true,
      };

    case constants.ADMIN_USER_CATS_SUCCESS:
      return {
        ...state,
        userCatsFetching: false,
        userCategories: action.payload.userCategories
          ? action.payload.userCategories
          : {},
        userCategoryIds: action.payload.userCategories
          ? Object.keys(action.payload.userCategories)
          : [],
      };

    case constants.ADMIN_USER_CATS_FAILURE:
      return {
        ...state,
        userCatsFetching: false,
      };

    case constants.ADMIN_SAVE_CATEGORY_SUCCESS:
      return {
        ...state,
        userCategories: {
          ...state.userCategories,
          [action.payload.id]: action.payload,
        },
      };

    case constants.ADMIN_SAVE_INTERNAL_FIELD_SUCCESS:
      return {
        ...state,
        internalFields: {
          ...state.internalFields,
          [action.payload.id]: action.payload,
        },
      };

    case constants.ADMIN_SAVE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          [action.payload.id]: action.payload,
        },
      };

    case constants.ADMIN_DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        userCategoryIds: state.userCategoryIds.filter(id => id !== action.payload.id),
        userCategories: state.userCategoryIds.reduce((obj, id) => {
          if (id !== action.payload.id) {
            const item = state.userCategories[id];
            return {
              ...obj,
              [id]: item,
            };
          }

          return obj;
        }, {}),
      };

    case constants.ADMIN_DELETE_INTERNAL_FIELD_SUCCESS:
      return {
        ...state,
        internalFieldIds: state.internalFieldIds.filter(id => id !== action.payload.id),
        internalFields: state.internalFieldIds.reduce((obj, id) => {
          if (id !== action.payload.id) {
            const item = state.internalFields[id];
            return {
              ...obj,
              [id]: item,
            };
          }

          return obj;
        }, {}),
      };

    case constants.ADMIN_DELETE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        customFieldIds: state.customFieldIds.filter(id => id !== action.payload.id),
        customFields: state.customFieldIds.reduce((obj, id) => {
          if (id !== action.payload.id) {
            const item = state.customFields[id];
            return {
              ...obj,
              [id]: item,
            };
          }

          return obj;
        }, {}),
      };

    case constants.ADMIN_CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        userCategoryIds: [...state.userCategoryIds, action.payload.category.id],
        userCategories: {
          ...state.userCategories,
          [action.payload.category.id]: action.payload.category,
        },
      };

    case constants.ADMIN_CREATE_INTERNAL_FIELD_SUCCESS:
      return {
        ...state,
        internalFieldIds: [...state.internalFieldIds, action.payload.field.id],
        internalFields: {
          ...state.internalFields,
          [action.payload.field.id]: action.payload.field,
        },
      };

    case constants.ADMIN_CREATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        customFieldIds: [...state.customFieldIds, action.payload.field.id],
        customFields: {
          ...state.customFields,
          [action.payload.field.id]: action.payload.field,
        },
      };

    case constants.ADMIN_SENT_MESSAGES_REQUEST:
      return {
        ...state,
        messagesFetching: true,
      };

    case constants.ADMIN_SENT_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesFetching: false,
        messages: action.payload.messages,
      };

    case constants.ADMIN_SENT_MESSAGES_FAILURE:
      return {
        ...state,
        messagesFetching: false,
      };

    case constants.ADMIN_SAVE_DRAFT_REQUEST:
      return {
        ...state,
        saveDraftState: constants.messageComposeStates.SENDING,
      };

    case constants.ADMIN_SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        saveDraftState: constants.messageComposeStates.SUCCESS,
      };

    case constants.ADMIN_SAVE_DRAFT_FAILURE:
      return {
        ...state,
        saveDraftState: constants.messageComposeStates.ERROR,
      };

    case constants.ADMIN_CSV_EXPORT_REQUEST:
      return {
        ...state,
        exports: {
          ...initialState.exports,
          exportDataType: action.payload.type,
          isFetching: true,
        },
      };

    case constants.ADMIN_CSV_EXPORT_SUCCESS:
      return {
        ...state,
        exports: {
          ...state.exports,
          isFetching: false,
        },
      };

    case constants.ADMIN_CSV_EXPORT_FAILURE:
      return {
        ...state,
        exports: {
          ...initialState.exports,
          errorMessage: action.payload.message,
        },
      };

    case constants.ADMIN_CSV_EXPORT_CLEAR:
      return {
        ...state,
        exports: initialState.exports,
      };

    case constants.ADMIN_MESSAGE_DRAFTS_REQUEST:
      return {
        ...state,
        draftsFetching: true,
      };

    case constants.ADMIN_MESSAGE_DRAFTS_SUCCESS:
      return {
        ...state,
        draftsFetching: false,
        messageDrafts: action.payload.drafts,
      };

    case constants.ADMIN_MESSAGE_DRAFTS_FAILURE:
      return {
        ...state,
        draftsFetching: false,
      };

    case constants.ADMIN_SEND_MESSAGE_REQUEST:
      return {
        ...state,
        composeState: constants.messageComposeStates.SENDING,
      };

    case constants.ADMIN_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        composeState: constants.messageComposeStates.SUCCESS,
      };

    case constants.ADMIN_SEND_MESSAGE_FAILURE:
      return {
        ...state,
        composeState: constants.messageComposeStates.ERROR,
      };

    case constants.ADMIN_CLEAR_COMPOSE_STATE:
      return {
        ...state,
        composeState: constants.messageComposeStates.IDLE,
        saveDraftState: constants.messageComposeStates.IDLE,
      };

    case constants.ADMIN_MEMBER_EXPORT_FIELDS_REQUEST:
      return {
        ...state,
        exports: {
          ...state.exports,
          isFetching: true,
        },
      };

    case constants.ADMIN_MEMBER_EXPORT_FIELDS_SUCCESS:
      return {
        ...state,
        exports: {
          ...state.exports,
          memberFields: action.payload.fields,
          isFetching: false,
        },
      };

    case constants.ADMIN_MEMBER_EXPORT_FIELDS_FAILURE:
      return {
        ...state,
        exports: {
          ...state.exports,
          isFetching: false,
        },
      };

    case constants.ADMIN_CSV_IMPORT_REQUEST:
      return {
        ...state,
        imports: {
          ...state.imports,
          isFetching: true,
          errorMessage: null,
        },
      };

    case constants.ADMIN_CSV_IMPORT_SUCCESS:
      return {
        ...state,
        imports: {
          ...state.imports,
          isFetching: false,
          errorMessage: null,
        },
      };

    case constants.ADMIN_CSV_IMPORT_FAILURE:
      return {
        ...state,
        imports: {
          ...state.imports,
          isFetching: false,
          errorMessage: action.payload.message,
        },
      };

    case constants.ADMIN_CSV_IMPORT_CLEAR:
      return {
        ...state,
        imports: initialState.imports,
      };

    default:
      return state;
  }
}
