import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Button from './presenter';


const FAB = ({ children, sx, wrapperSx, ...rest }) => (
  <Box
    sx={{
      bottom: 'grid16',
      position: 'absolute',
      right: 'grid16',
      ...wrapperSx,
    }}
  >
    <Button
      {...rest}
      sx={{
        borderRadius: 'circle',
        height: 'controlHeight',
        width: 'controlHeight',
        ...sx,
      }}
    >
      {children}
    </Button>
  </Box>
);

FAB.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape(),
  wrapperSx: PropTypes.shape(),
};

FAB.defaultProps = {
  children: undefined,
  sx: {},
  wrapperSx: {},
};


export default FAB;
