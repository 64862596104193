import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AppRoutes } from '../../constants';

import { propTypes, defaultProps } from './props';

class AuthRoute extends React.Component {
  render() {
    const {
      path,
      Component,
      isAuthenticated,
      ...props
    } = this.props;

    return (
      <Route path={path} render={() => (
        isAuthenticated ?
          <Component {...props} /> :
          <Redirect to={AppRoutes.PUBLIC.LOGIN.path} />
      )} />
    );
  }
}

AuthRoute.propTypes = propTypes;
AuthRoute.defaultProps = defaultProps;

export default AuthRoute;
