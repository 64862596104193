import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Flex from '../Flex';

import { createDataPaneStyles } from './styles';


const DataPane = (props) => {
  const {
    children,
    side,
    sx,
  } = props;

  const isLaptopOrLarger = useMediaQuery({
    query: global.BreakpointQuery.laptop,
  });

  const styles = createDataPaneStyles(isLaptopOrLarger);

  return (
    <Flex flexDirection="column" sx={{ ...styles.pane, ...styles[side], ...sx }}>
      {children}
    </Flex>
  );
};

DataPane.propTypes = {
  children: PropTypes.node,
  side: PropTypes.oneOf(['left', 'right', 'full']),
};

DataPane.defaultProps = {
  children: undefined,
  side: undefined,
};


export default DataPane;
