import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'modules/core/components/Button';
import Text from 'modules/core/components/Text';


const Segment = (props) => {
  const {
    children,
    className,
    isActive,
    onClick,
    sx,
  } = props;

  const _sx = {
    ...sx,
    boxShadow: 'none',
  };

  if (!isActive) {
    _sx.backgroundColor = 'white';
    _sx.borderColor = 'transparent';
    _sx.color = 'text';
  }

  return (
    <Button
      animate={false}
      className={classNames({
        'j-segment': true,
        'j-segment-active': isActive,
        [className]: className,
      })}
      onClick={onClick}
      sx={{
        ..._sx,
        bg: isActive ? 'activeContrast' : 'primary',
        fontWeight: 'semibold',
        fontSize: 'font14',
        paddingBottom: 'grid2',
        paddingTop: 'grid2',
        paddingLeft: '10px',
        paddingRight: '10px',
        ':hover': {
          bg: isActive ? 'activeHoverContrast' : 'primaryHover',
          boxShadow: 'none',
        },
      }}
    >
      <Text
        sx={{
          color: isActive ? 'text' : 'lightText',
        }}
      >
        {children}
      </Text>
    </Button>
  );
};

Segment.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
};

Segment.defaultProps = {
  children: undefined,
  className: undefined,
  onClick: undefined,
  sx: {},
};


export default Segment;
