import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { getIsAuthenticated, getIsFetching } from '../../../auth/selectors';
import { getRole } from '../../../user/selectors';

import presenter from './presenter';

const getProps = (state, props) => props;
const getAllowedRoles = createSelector(
  [getProps],
  props => props.allowedRoles,
);

const mapStateToProps = createStructuredSelector({
  isAuthenticated: getIsAuthenticated,
  isFetching: getIsFetching,
  role: getRole,
  allowedRoles: getAllowedRoles,
});

export default connect(
  mapStateToProps,
)(presenter);
