import React from 'react';
import PropTypes from 'prop-types';

import { FormattingUtils } from 'utils';

import Input from '../Input';


class DateTextField extends React.PureComponent {
  static defaultProps = {
    errors: [],
    name: '',
    onBlur: () => {},
    onFocus: () => {},
    placeholder: 'MM/DD/YYYY',
    value: '',
  };

  static propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  };

  state = {
    datePasted: false,
  };

  locale = window.jubileeLocale || (navigator ? navigator.language : undefined);

  updateText = (evt, handleBlur) => {
    const { onBlur, onChange, value: lastValue } = this.props;

    const { value } = evt.target;

    let autoDetectLocale = false;
    if (!!handleBlur || ((!lastValue || !lastValue.length) && value.length >= 8)) {
      autoDetectLocale = true;
    }

    const formatted = FormattingUtils.formatDateString(value, this.locale, {
      autoDetectLocale,
      useStrict: !!handleBlur,
    });

    onChange(formatted);

    if (handleBlur && onBlur) {
      onBlur(evt);
    }
  };

  handleChange = (evt) => {
    this.updateText(evt);
  };

  handleBlur = (evt) => {
    this.updateText(evt, true);
  };

  render() {
    const {
      errors,
      name,
      onFocus,
      placeholder,
      value,
    } = this.props;

    return (
      <Input
        errors={errors}
        name={name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={onFocus}
        placeholder={placeholder}
        type="text"
        value={value}
      />
    );
  }
}


export default DateTextField;
