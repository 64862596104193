import { Enum } from 'enumify';

/**
 * Enumeration for date types
 */
class EntityType extends Enum {}

/**
 * The values of the enumeration
 */
EntityType.initEnum({
  PERSON: {
    get displayName() {
      return 'Person';
    },
  },
  FAMILY: {
    get displayName() {
      return 'Family';
    },
  },
  GROUP: {
    get displayName() {
      return 'Group';
    },
  },
});

export default EntityType;
