import React from 'react';
import { Switch } from 'react-router-dom';

import { panelRoutePaths, AppRoutes } from 'modules/router/constants';

import { Box, Footer } from 'modules/core/components';

import DashboardRoutes from '../DashboardRoutes';
import AuthRoute from '../AuthRoute';
import PanelRoutes from '../PanelRoutes';

import * as styles from './styles';
import { Redirect } from 'react-router';
import { roleHelpers } from 'helpers';


class RoutesBlockLayout extends React.Component {
  componentDidMount() {
    this.handleGetDirectoryData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;
    if (user.id && !prevUser.id) {
      this.handleGetDirectoryData();
    }
  }

  handleGetDirectoryData = () => {
    const { user = {}, onDirectoryRequest, directoryId } = this.props;
    const { directories = {} } = user;

    const dirIds = Object.keys(directories);

    if (directoryId) {
      onDirectoryRequest(directoryId);
    } else if (dirIds.length) {
      const dirId = dirIds[0];
      onDirectoryRequest(dirId);
    }
  };

  render() {
    const {
      appRoutes,
      pageHead,
      role,
    } = this.props;

    const isAdmin = roleHelpers.isAdminRole(role);

    return (
      <React.Fragment>
        {pageHead}

        <Box>
          <Box sx={styles.contentBodyStyles}>
            <Switch>
              <AuthRoute
                role={role}
                appRoutes={appRoutes}
                path={appRoutes.AUTH.DASHBOARD.path}
                Component={DashboardRoutes}
              />

              <AuthRoute
                role={role}
                path={panelRoutePaths.panel}
                Component={PanelRoutes}
              />

              {/* {isAdmin ?  (
                <AuthRoute
                  role={role}
                  path={panelRoutePaths.panel}
                  Component={PanelRoutes}
                />) : 
                (
                  <Redirect to={AppRoutes.AUTH.DIRECTORY.path} />
              )} */}
            </Switch>
          </Box>

          <Footer />
        </Box>
      </React.Fragment>
    );
  }
}

export default RoutesBlockLayout;
