import firebase from 'firebase';
import shortid from 'shortid';

import FireDb from './firebaseDb';

let auth;

const makeSafeEmailString = (email) => {
  const swapChars = ['.', '$', '#', '[', ']', '/'];
  const safeChars = ['%2E', '%24', '%23', '%5B', '%5D', '%2F'];

  let safeEmailString = '';

  for (let i = 0; i < email.length; i++) {
    const char = email[i];
    const indexOfSwap = swapChars.indexOf(char);
    if (indexOfSwap >= 0) {
      const safeChar = safeChars[indexOfSwap];
      safeEmailString = `${safeEmailString}${safeChar}`;
    } else {
      safeEmailString = `${safeEmailString}${char}`;
    }
  }

  return safeEmailString;
};

class FireAuthService {
  static async init(fbAuth) {
    auth = fbAuth;
  }

  static getAuth = () => auth;

  static getStaticAuth = () => firebase.auth;

  static getCurrentUser = () => auth.currentUser || {};

  static getIdToken = async () => (
    auth.currentUser ? auth.currentUser.getIdToken() : undefined
  );

  static async login(email, password) {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      return auth.currentUser;
    } catch (err) {
      return null;
    }
  }

  static async logout() {
    try {
      await auth.signOut();
      return true;
    } catch (err) {
      return false;
    }
  }

  static async createNewUser(email) {
    try {
      const tempId = shortid.generate();
      const result = auth.createUserWithEmailAndPassword(email, tempId);
      return result.user;
    } catch (err) {
      console.log(err, err.message);
      return undefined;
    }
  }

  static async sendMagicLinkEmail(
    email,
    actionCodeSettings,
  ) {
    try {
      await auth.sendSignInLinkToEmail(email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  static isUrlForEmailLogin(url) {
    return auth.isSignInWithEmailLink(url);
  }

  static async loginWithEmailLink(email, url) {
    try {
      const result = await auth.signInWithEmailLink(email, url);
      return result;
    } catch (err) {
      // none
      return null;
    }
  }

  static async createEmailPasswordUser(email, password) {
    try {
      return auth.createUserWithEmailAndPassword(email, password);
    } catch (err) {
      return err;
    }
  }

  static async linkToPasswordCredential(
    email, directoryId, password,
  ) {
    try {
      await FireAuthService.createEmailPasswordUser(email, password);
      const credential = firebase.auth.EmailAuthProvider.credential(email, password);
      const user = await auth.signInWithCredential(credential);
      const identifier = makeSafeEmailString(email);
      const userData = await FireDb.getPendingUserTree(identifier);
      const userDataWithId = { ...userData, id: user.uid };
      await FireDb.updateUserTree(user.uid, directoryId, userDataWithId);
      return FireDb.getUserTree(user.uid);
    } catch (err) {
      return err;
    }
  }

  static async loginWithPhone(phone, verifier) {
    try {
      return auth.signInWithPhoneNumber(phone, verifier);
    } catch (err) {
      return err;
    }
  }

  static getCredentialFromVerifyUrl(email, url) {
    return firebase.auth.EmailAuthProvider.credentialWithLink(
      email,
      url,
    );
  }

  static async linkBackupEmailAsCredential(email, url) {
    try {
      const credential = FireAuthService.getCredentialFromVerifyUrl(email, url);
      return auth.currentUser.linkWithCredential(credential);
    } catch (err) {
      console.log(err);
      return err;
    }
  }
}

export default FireAuthService;
