import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../Flex';
import Label from '../Label';
import Switch from './Switch';


const SwitchWithLabel = (props) => {
  const {
    isRequired,
    label,
    labelLeft,
    ...rest
  } = props;

  return (
    <Flex>
      <Switch {...rest} isRequired={isRequired} />
      {labelLeft ?? null}

      <Label
        sx={{
          alignSelf: 'center',
          color: 'fieldLabel',
          fontSize: 'font14',
          marginLeft: 'grid8',
        }}
      >
        {`${label}${isRequired ? ' (Required)' : ''}`}
      </Label>
    </Flex>
  );
};

SwitchWithLabel.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelLeft: PropTypes.string,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  value: PropTypes.bool,
};

SwitchWithLabel.defaultProps = {
  isRequired: undefined,
  label: undefined,
  labelLeft: undefined,
  onChange: undefined,
  setRef: undefined,
  value: undefined,
};

const SwitchWithLabelWithRef = React.forwardRef((props, ref) => (
  <SwitchWithLabel setRef={ref} {...props} />
));


export { SwitchWithLabel };
export default SwitchWithLabelWithRef;
