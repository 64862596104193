// 


import * as constants from './constants';

export function uploadImageRequest(shortCode, formData) {
  return {
    type: constants.UPLOAD_IMAGE_REQUEST,
    payload: {
      shortCode,
      formData,
    },
  };
}

export function uploadImageSuccess(userId, imageUrl) {
  return {
    type: constants.UPLOAD_IMAGE_SUCCESS,
    payload: {
      userId,
      imageUrl,
    },
  };
}

export function uploadImageFailure(message) {
  return {
    type: constants.UPLOAD_IMAGE_FAILURE,
    payload: {
      message,
    },
  };
}

export function uploadMultiImageRequest(
  imagesData,
) {
  return {
    type: constants.UPLOAD_MULTI_IMAGE_REQUEST,
    payload: {
      imagesData,
    },
  };
}

export function uploadMultiImageSuccess(
  results,
) {
  return {
    type: constants.UPLOAD_MULTI_IMAGE_SUCCESS,
    payload: {
      results,
    },
  };
}

export function uploadMultiImageFailure(message) {
  return {
    type: constants.UPLOAD_MULTI_IMAGE_FAILURE,
    payload: {
      message,
    },
  };
}
