import PropTypes from 'prop-types';
import React from 'react';

import * as colors from 'modules/core/colors';

import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
} from 'modules/core/components';
import { IconSize } from 'modules/core/sizes';

import { dashboardRoutePaths } from 'modules/router/constants';

import { FormattingUtils } from 'utils';


const Phone = ({ iconName, id, isAdmin, label, phone, sx }) => {
  const fullPhone = FormattingUtils.formatE164(
    phone.number,
    phone.countryCode,
  );

  const phoneDisplay = FormattingUtils.formatPhoneForDisplay(
    fullPhone.number,
    phone.countryCode?.split?.('_')[1],
  );

  let linkElement = 'a';
  const linkProps = {
    style: { color: colors.mamba },
  };

  if (phone.sendMessages) {
    if (isAdmin) {
      linkElement = Link;
      linkProps.href = dashboardRoutePaths.composeTo(id, 'sms');
      linkProps.sx = { color: 'infoValue' };
      linkProps.textSx = { color: 'infoValue' };
    } else {
      linkProps.href = `sms:${fullPhone.number}`;
    }
  } else {
    linkProps.href = `tel:${fullPhone.number}`;
  }

  if (!phone.number) {
    return null;
  }

  return (
    <Flex
      sx={{
        marginBottom: 'grid16',
        ...sx,
      }}
    >
      <Flex
        sx={{
          width: '35%',
        }}
      >
        <Icon
          color={colors.ebonyLightest}
          name={iconName}
          size={IconSize.ICON_MED}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: 'grid8',
          }}
        />

        <Text sx={{ color: 'systemTextLight' }}>
          {label}
        </Text>
      </Flex>

      <Box
        sx={{
          width: '65%',
        }}
      >
        <Text sx={{ color: 'infoValue', display: 'flex' }}>
          {React.createElement(linkElement, linkProps, phoneDisplay)}

          {/* {!phone.isVisible && isAdmin && ( */}
          {/*  <EyeOffIcon */}
          {/*    color={colors.mambaLight} */}
          {/*    size={IconSize.ICON_MED} */}
          {/*    svgProps={{ */}
          {/*      style: { */}
          {/*        marginBottom: '2px', */}
          {/*      }, */}
          {/*    }} */}
          {/*    sx={{ */}
          {/*      display: 'inline-block', */}
          {/*      marginLeft: 'grid4', */}
          {/*    }} */}
          {/*  /> */}
          {/* )} */}
        </Text>
      </Box>
    </Flex>
  );
};

Phone.propTypes = {
  iconName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  label: PropTypes.string.isRequired,
  phone: PropTypes.shape({}).isRequired,
  sx: PropTypes.shape({}),
};

Phone.defaultProps = {
  isAdmin: undefined,
  sx: {},
};


export default Phone;
