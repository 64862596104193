export const mqlTablet = window.matchMedia('(min-width: 768px)');
export const mqlLaptop = window.matchMedia('(min-width: 960px)');
export const mqlDesktop = window.matchMedia('(min-width: 1360px)');

const mqls = {
  tablet: mqlTablet,
  laptop: mqlLaptop,
  desktop: mqlDesktop,
};

export const addMQLListeners = (config) => {
  Object.entries(config).forEach(([bp, listener]) => {
    mqls[bp]?.addEventListener?.('change', listener);
  });
};

export const removeMQLListeners = (config) => {
  Object.entries(config).forEach(([bp, listener]) => {
    mqls[bp]?.removeEventListener?.('change', listener);
  });
};
