import React from 'react';
import PropTypes from 'prop-types';

import * as colors from '../../colors';

import Text from '../Text';


const Asterisk = ({ sx }) => (
  <Text
    sx={{
      color: colors.salmonDark,
      fontWeight: 'semibold',
      marginLeft: 'grid2',
      sx,
    }}>
    *
  </Text>
);

Asterisk.propTypes = {
  sx: PropTypes.shape({}),
};

Asterisk.defaultProps = {
  sx: {},
};


export default Asterisk;
