import * as React from 'react';

import { white } from '../../colors';
import { IconSize } from '../../sizes';

import Flex from '../Flex';
import { UploadIcon } from '../Icon';
import Text from '../Text';

import Button from './presenter';


const UploadPhotoButton = () => (
  <Button
   sx={{
     borderWidth: 'small',
   }}
  >
    <Flex sx={{ alignItems: 'center' }}>
      <UploadIcon
        color={white}
        size={IconSize.ICON_SMALL}
      />
      <Text
        sx={{
          fontSize: 'font14',
          fontWeight: 'normal',
          marginLeft: 'grid8',
        }}
      >
        Upload
      </Text>
    </Flex>
  </Button>
);


export default UploadPhotoButton;
