import { spawn, put, take, select } from 'redux-saga/effects';

import { DataNormalizers, RequestUtils } from 'utils';

import { getActiveDirectoryId } from 'modules/directory/selectors';

import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';

import { FireFunctions, functionNames } from '../services';

export function* requestGroupTrees(directoryId, options = {}) {
  try {
    const isFetching = yield select(selectors.getIsFetching);

    if (isFetching) {
      return;
    }

    const dirId = directoryId
      ? yield directoryId
      : yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.fbGroupsFailure('No directory id'));
      return;
    }

    yield put(actions.fbGroupsFetch());

    let reqMeta, payload;
    if (!options.pageNumber && !options.sort && !options.sortDir) {
      payload = { 'directoryId': dirId, 'meta': {'contentIndex': undefined, 'contentRange': undefined, 'sort': ['name'], 'sortDir': ['asc']} };
    } else {
      reqMeta = RequestUtils.getEntityReqContentMeta(undefined, options);
      payload = RequestUtils.getEntityReqPayload(dirId, {}, reqMeta);
    }

    // const reqMeta = RequestUtils.getEntityReqContentMeta(undefined, options);
    // const payload = RequestUtils.getEntityReqPayload(dirId, {}, reqMeta);

    const response = yield FireFunctions.httpsCallable(
      functionNames.getGroups,
      payload,
    );

    if (response.data) {
      const { results = [], pagination } = response.data;

      const groupIds = results.map(group => group.id);

      const groupTrees = DataNormalizers.mapFromArray(results);

      yield put(actions.fbGroupsSuccess(groupTrees, groupIds, pagination));
    }
  } catch (error) {
    yield put(actions.fbGroupsFailure(error.message, error));
  }
}

export function* requestGroupsSummaries(include) {
  try {
    const isFetching = yield select(selectors.getIsFetching);

    if (isFetching) {
      return;
    }

    const dirId = yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.groupsSummariesFailure('No directory id'));
      return;
    }

    yield put(actions.groupsSummariesFetch());

    const payload = RequestUtils.getEntityReqPayload(dirId, { include });

    const response = yield FireFunctions.httpsCallable(
      functionNames.getGroupSummaries,
      payload,
    );

    const { results } = response.data;

    const ids = results.map(result => result.id);

    const summaries = DataNormalizers.mapFromArray(results);

    yield put(actions.groupsSummariesSuccess(summaries, ids));
  } catch (error) {
    yield put(actions.groupsSummariesFailure(error.message, error));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.FB_GROUPS_REQUEST,
      constants.FB_ALL_GROUPS_REQUEST,
      constants.FB_GROUPS_REQUEST_NEXT,
      constants.FB_GROUPS_REQUEST_PREV,
      constants.GROUPS_SUMMARIES_REQUEST,
    ]);

    switch (type) {
      case constants.FB_GROUPS_REQUEST:
        yield spawn(
          requestGroupTrees,
          payload.directoryId,
          { pageNumber: payload.pageNumber, sort: payload.sort, sortDir: payload.sortDir },
        );
        break;
      
      case constants.FB_ALL_GROUPS_REQUEST:
        yield spawn(
          requestGroupTrees,
          "all_groups",
        );
        break;

      case constants.FB_GROUPS_REQUEST_NEXT:
        yield spawn(
          requestGroupTrees,
          payload.directoryId,
          { isNext: true },
        );
        break;

      case constants.FB_GROUPS_REQUEST_PREV:
        yield spawn(
          requestGroupTrees,
          payload.directoryId,
          { isPrev: true },
        );
        break;

      case constants.GROUPS_SUMMARIES_REQUEST:
        yield spawn(
          requestGroupsSummaries,
          payload.include,
        );
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
