import PropTypes from 'prop-types';
import React from 'react';


const PrivateInfo = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M20,4,32.73,9.64v8.54C32.73,26.4,27.31,34,20,36.24,12.69,34,7.27,26.4,7.27,18.18V9.64L20,4m0-4L3.64,7.27V18.18c0,10.09,7,19.53,16.36,21.82,9.38-2.29,16.36-11.73,16.36-21.82V7.27L20,0ZM18.18,10.91h3.64v3.64H18.18Zm0,7.27h3.64V29.09H18.18Z"
  />
);

PrivateInfo.propTypes = {
  strokeWidth: PropTypes.number,
};

PrivateInfo.defaultProps = {
  strokeWidth: 1,
};


export default PrivateInfo;
