//

import * as React from 'react';
import { Input } from 'reactstrap';

import FieldErrors from '../FieldErrors';

import styles from './styles.css';


class ToggleEditField extends React.Component {
  static defaultProps = {
    colClasses: 'col-sm-12',
    errors: undefined,
    isEditable: false,
    isRequired: false,
    label: '',
    name: '',
    numCols: 1,
    objectPropPath: null,
    onChange: () => {},
    onFocus: () => {},
    onToggle: () => {},
    placeholder: '',
    style: {},
    transformFalse: true,
    type: 'text',
    value: '',
  };

  handleChange = (evt) => {
    let { value } = evt.target;
    const {
      name,
      onChange,
      objectPropPath,
      isEditable,
      transformFalse,
    } = this.props;

    if (!isEditable) return;

    if (value === '' && transformFalse) value = false;

    onChange({ name, value, objectPropPath });
  };

  handleFocus = () => {
    const { onFocus } = this.props;
    if (onFocus) onFocus();
  };

  render() {
    const {
      errors,
      isEditable,
      isRequired,
      label,
      name,
      placeholder,
      style,
      transformFalse,
      type,
    } = this.props;

    let { value } = this.props;

    const inputInvalid = isRequired && (!value || value === '');

    if (value === false && transformFalse) value = '';

    return (
      <span style={{ display: 'inline-block', width: '100%' }}>
        <Input
          style={{
            border: errors ? '1px solid #CA5486' : undefined,
            ...style,
          }}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          className={styles.field}
          required={isRequired}
          value={value}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={!isEditable}
          invalid={inputInvalid}
        />

        <FieldErrors errors={errors} />
      </span>
    );
  }
}

export default ToggleEditField;
