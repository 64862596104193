import axios from 'axios';
import { Routing } from '../../common/constants';

export async function requestLogin(email, password) {
  const path = `${Routing.BASE_ROUTE}${Routing.AuthRoutes.login()}`;
  const options = {
    email,
    password,
  };

  return axios.post(path, options);
}

export async function requestSetPassword(token, password) {
  const path = `${Routing.BASE_ROUTE}${Routing.AuthRoutes.setPassword()}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, { token, password }, options);
}

export async function requestMe(token, dirId) {
  const path = `${Routing.BASE_ROUTE}${Routing.UserRoutes.usersMe(dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.get(path, options);
}

export async function requestPhoneLogin(phone) {
  const path = `${Routing.BASE_ROUTE}${Routing.AuthRoutes.loginWithSms()}`;
  const data = {
    phone,
  };

  return axios.post(path, data);
}

export async function verifyPhoneLogin(phone, code) {
  const path = `${Routing.BASE_ROUTE}${Routing.AuthRoutes.verifyLoginWithSms()}`;
  const data = {
    phone,
    code,
  };

  return axios.post(path, data);
}

export async function requestLogout() {
  const path = `${Routing.BASE_ROUTE}${Routing.AuthRoutes.logout()}`;

  return axios.post(path, {});
}

export async function requestSignup(
  email,
  password,
  firstName,
  lastName,
) {
  const path = `${Routing.BASE_ROUTE}${Routing.UserRoutes.users()}`;
  const options = {
    email,
    password,
    firstName,
    lastName,
  };

  return axios.post(path, options);
}
