import React from 'react';
import PropTypes from 'prop-types';

import FieldWithLabel from '../FieldWithLabel';
import MultiSelection from './presenter';


const MultiSelectionWithLabel = (props) => (
  <FieldWithLabel
    {...props}
    Component={MultiSelection}
  />
);

MultiSelectionWithLabel.propTypes = {
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  value: PropTypes.any,
};

MultiSelectionWithLabel.defaultProps = {
  isRequired: undefined,
  onChange: undefined,
  setRef: undefined,
  value: undefined,
};

const MultiSelectionWithLabelWithRef = React.forwardRef((props, ref) => (
  <MultiSelectionWithLabel setRef={ref} {...props} />
));


export { MultiSelectionWithLabel };
export default MultiSelectionWithLabelWithRef;
