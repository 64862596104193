import * as constants from './constants';

export function setFilter(keyPath, value) {
  return {
    type: constants.SET_FILTER,
    payload: {
      keyPath,
      value,
    },
  };
}
