import { createSelector } from 'reselect';

import { name } from './reducer';

const getState = state => state[name];

export const getMessages = createSelector(
  [getState],
  state => state.messages,
);

export const getComposeState = createSelector(
  [getState],
  state => state.composeState,
);

export const getMessageIds = createSelector(
  [getMessages],
  messages => Object.keys(messages),
);

export const getMessageDrafts = createSelector(
  [getState],
  state => state.messageDrafts,
);

export const getMessageDraftIds = createSelector(
  [getMessageDrafts],
  drafts => Object.keys(drafts || {}),
);

export const getDraftsFetching = createSelector(
  [getState],
  state => state.draftsFetching,
);

export const getMessagesFetching = createSelector(
  [getState],
  state => state.messagesFetching,
);

export const getSaveDraftState = createSelector(
  [getState],
  state => state.saveDraftState,
);
