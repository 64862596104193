import { spawn, put, take, select } from 'redux-saga/effects';

import { DataNormalizers, RequestUtils } from 'utils';

import { getActiveDirectoryId } from 'modules/directory/selectors';

import { FireFunctions, functionNames } from '../services';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';


export function* requestFamilyTrees(directoryId, options = {}) {
  try {
    const isFetching = yield select(selectors.getIsFetching);

    if (isFetching) {
      return;
    }

    const dirId = directoryId
      ? yield directoryId
      : yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.fbFamiliesFailure('No directory id'));
      return;
    }

    yield put(actions.fbFamiliesFetch());

    let reqMeta, payload;
    if (!options.pageNumber && !options.sort && !options.sortDir) {
      payload = { 'directoryId': dirId, 'meta': {'contentIndex': undefined, 'contentRange': undefined, 'sort': ['name'], 'sortDir': ['asc']} };
    } else {
      reqMeta = RequestUtils.getEntityReqContentMeta(undefined, options);
      payload = RequestUtils.getEntityReqPayload(dirId, {}, reqMeta);
    }

    // const reqMeta = RequestUtils.getEntityReqContentMeta(undefined, options);
    // const payload = RequestUtils.getEntityReqPayload(dirId, {}, reqMeta);

    const response = yield FireFunctions.httpsCallable(
      functionNames.getFamilies,
      payload,
    );

    const { results, pagination = {} } = response.data;

    const familyIds = results.map(fam => fam.id);

    const familyTrees = DataNormalizers.mapFromArray(results);

    yield put(actions.fbFamiliesSuccess(familyTrees, familyIds, pagination));
  } catch (error) {
    yield put(actions.fbFamiliesFailure(error.message, error));
  }
}

export function* requestFamilySummaries(include) {
  try {
    const isFetching = yield select(selectors.getIsFetching);

    if (isFetching) {
      return;
    }

    const dirId = yield select(getActiveDirectoryId);

    if (!dirId) {
      yield put(actions.familySummariesFailure('No directory id'));
      return;
    }

    yield put(actions.familySummariesFetch());

    const payload = RequestUtils.getEntityReqPayload(dirId, { include });

    const response = yield FireFunctions.httpsCallable(
      functionNames.getFamilySummaries,
      payload,
    );

    const { results } = response.data;

    const ids = results.map(result => result.id);

    const summaries = DataNormalizers.mapFromArray(results);

    yield put(actions.familySummariesSuccess(summaries, ids));
  } catch (error) {
    yield put(actions.familySummariesFailure(error.message, error));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.FAMILY_SUMMARIES_REQUEST,
      constants.FB_FAMILIES_REQUEST,
      constants.FB_ALL_FAMILIES_REQUEST,
      constants.FB_FAMILIES_REQUEST_NEXT,
      constants.FB_FAMILIES_REQUEST_PREV,
    ]);

    switch (type) {
      case constants.FB_FAMILIES_REQUEST:
        yield spawn(
          requestFamilyTrees,
          payload.directoryId,
          { pageNumber: payload.pageNumber, sort: payload.sort, sortDir: payload.sortDir },
        );
        break;

      case constants.FB_ALL_FAMILIES_REQUEST:
        yield spawn(
          requestFamilyTrees,
          "all_families",
        );
        break;

      case constants.FB_FAMILIES_REQUEST_NEXT:
        yield spawn(
          requestFamilyTrees,
          payload.directoryId,
          { isNext: true },
        );
        break;

      case constants.FB_FAMILIES_REQUEST_PREV:
        yield spawn(
          requestFamilyTrees,
          payload.directoryId,
          { isPrev: true },
        );
        break;

      case constants.FAMILY_SUMMARIES_REQUEST:
        yield spawn(
          requestFamilySummaries,
          payload.include,
        );
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
