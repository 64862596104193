import * as constants from './constants';


export function setGroupsSort(by, dir) {
  return {
    type: constants.SET_GROUPS_SORT,
    payload: {
      by,
      dir,
    },
  };
}

export function groupsRequest(term) {
  return {
    type: constants.GROUPS_REQUEST,
    payload: {
      term,
    },
  };
}

export function groupsSuccess(groups, activeGroupId) {
  return {
    type: constants.GROUPS_SUCCESS,
    payload: {
      groups,
      activeGroupId,
    },
  };
}

export function groupsFailure(message) {
  return {
    type: constants.GROUPS_FAILURE,
    payload: {
      message,
    },
  };
}

export function groupInfoRequest(groupShortCode) {
  return {
    type: constants.GROUP_INFO_REQUEST,
    payload: {
      groupShortCode,
    },
  };
}

export function groupInfoSuccess(group, activeGroupShortCode) {
  return {
    type: constants.GROUP_INFO_SUCCESS,
    payload: {
      group,
      activeGroupShortCode,
    },
  };
}

export function groupInfoFailure(message) {
  return {
    type: constants.GROUP_INFO_FAILURE,
    payload: {
      message,
    },
  };
}

export function fbGroupsRequest(directoryId, pageNumber, sort, sortDir) {
  return {
    type: constants.FB_GROUPS_REQUEST,
    payload: {
      directoryId,
      pageNumber,
      sort,
      sortDir,
    },
  };
}

export function fbAllGroupsRequest(directoryId, pageNumber, sort, sortDir) {
  return {
    type: constants.FB_ALL_GROUPS_REQUEST,
    payload: {
      directoryId,
      pageNumber,
      sort,
      sortDir,
    },
  };
}

export function fbGroupsFetch() {
  return {
    type: constants.FB_GROUPS_FETCH,
  };
}

export function fbGroupsRequestNext(directoryId) {
  return {
    type: constants.FB_GROUPS_REQUEST_NEXT,
    payload: {
      directoryId,
    },
  };
}

export function fbGroupsRequestPrev(directoryId) {
  return {
    type: constants.FB_GROUPS_REQUEST_PREV,
    payload: {
      directoryId,
    },
  };
}

export function fbGroupsSuccess(groups, groupIds, pagination) {
  return {
    type: constants.FB_GROUPS_SUCCESS,
    payload: {
      groups,
      groupIds,
      pagination,
    },
  };
}

export function fbGroupsFailure(message, error) {
  return {
    type: constants.FB_GROUPS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function groupsSummariesRequest(include) {
  return {
    type: constants.GROUPS_SUMMARIES_REQUEST,
    payload: {
      include,
    },
  };
}

export function groupsSummariesFetch() {
  return {
    type: constants.GROUPS_SUMMARIES_FETCH,
  };
}

export function groupsSummariesSuccess(summaries, ids) {
  return {
    type: constants.GROUPS_SUMMARIES_SUCCESS,
    payload: {
      summaries,
      ids,
    },
  };
}

export function groupsSummariesFailure(message, error) {
  return {
    type: constants.GROUPS_SUMMARIES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function appendGroup(group) {
  return {
    type: constants.APPEND_GROUP,
    payload: group,
  };
}
