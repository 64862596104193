import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { useControlled, useOnClickOutside } from 'hooks';

import Box from 'modules/core/components/Box';
import { Button } from 'modules/core/components/Button';
import Flex from 'modules/core/components/Flex';
import Icon, { IconNames } from 'modules/core/components/Icon';
import Text from 'modules/core/components/Text';

import { Appearance } from 'modules/core/constants';
import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import { PickerOption } from './components';


const Picker = (props) => {
  const {
    defaultValue,
    displayLabel,
    iconName,
    label,
    onChange,
    options,
    placeholder,
    showValue,
    sx,
    value,
  } = props;

  const ref = React.useRef();

  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback((isMobile, delay = 0, callback) => {
    setOpen(!open);

    setTimeout(() => {
      if (callback) {
        callback();
      }
    }, delay);
  }, [open]);

  const handleClickOutside = React.useCallback(() => {
    if (open) {
      handleOpen();
    }
  }, [open]);

  const [val, setVal] = useControlled({
    default: defaultValue,
    onChange,
    value,
  });

  useOnClickOutside(ref, handleClickOutside);

  const isLaptopOrLarger = useMediaQuery({
    query: global.BreakpointQuery.laptop,
  });

  let displayText = placeholder;
  if (val && showValue) {
    const valueText = options.find((opt) => opt.value === val)?.label;
    if (valueText) {
      displayText = valueText;
    }

    if (displayLabel) {
      displayText = `${label || placeholder}: ${displayText}`;
    }
  }

  const optionChildren = options.map((option) => (
    <PickerOption
      isActive={val === option.value}
      isMobile={!isLaptopOrLarger}
      key={option.value}
      label={option.label}
      onClick={() => {
        handleOpen(!isLaptopOrLarger, 200, () => setVal(option.value));
      }}
    />
  ));

  return (
    <Flex ref={ref} sx={{ position: 'relative', ...sx }}>
      <Button
        animate={false}
        appearance={Appearance.NONE}
        onClick={handleOpen}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          marginBottom: 'grid0',
          alignSelf: 'center',
          paddingLeft: 'grid0',
          paddingRight: 'grid0',
        }}
      >
        <Icon
          className="j-picker-icon"
          color={colors.heliotrope}
          name={iconName || IconNames.SORT}
          size={IconSize.ICON_SMALL}
        />

        <Text
          sx={{
            color: 'systemTextLight',
            fontWeight: 'semibold',
            fontSize: 'font14',
            marginLeft: 'grid8',
          }}
        >
          {displayText}
        </Text>
      </Button>

      {!isLaptopOrLarger && (
        <React.Fragment>
          <Box
            className={classNames({
              'j-picker-backdrop': true,
              open,
            })}
            onClick={handleOpen}
            sx={{
              bg: 'rgba(43,46,56,0.9)',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          />
          <Flex
            className={classNames({
              'j-picker-opts': true,
              'j-bottom-sheet': true,
              open,
            })}
            flexDirection="column"
            justifyContent="center"
            sx={{
              bg: 'primary',
              boxShadow: 'bottomSheet',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              paddingTop: 'grid48',
              paddingBottom: 'grid48',
            }}
          >
            {optionChildren}
          </Flex>
        </React.Fragment>
      )}

      {isLaptopOrLarger && (
        <Flex
          className={classNames({
            'j-picker-opts': true,
            'j-picker-menu': true,
            open,
          })}
          flexDirection="column"
          justifyContent="center"
          sx={{
            bg: 'systemMenu',
            borderRadius: 'block',
            boxShadow: 'cardSmall',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '100%',
            padding: 'grid16',
          }}
        >
          {optionChildren}
        </Flex>
      )}
    </Flex>
  );
};

Picker.propTypes = {
  defaultValue: PropTypes.any,
  displayLabel: PropTypes.bool,
  iconName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({}),
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  placeholder: PropTypes.string,
  showValue: PropTypes.bool,
  sx: PropTypes.shape(),
  value: PropTypes.any,
};

Picker.defaultProps = {
  defaultValue: 0,
  displayLabel: undefined,
  iconName: undefined,
  label: undefined,
  onChange: undefined,
  options: [],
  placeholder: 'Select',
  showValue: true,
  sx: {},
  value: undefined,
};


export default Picker;
