import Papa from 'papaparse';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

export const zipDocuments = (documents) => {
  const zip = new JSZip();
  return new Promise((resolve, reject) => {
    documents.forEach((doc) => {
      const { name, contents, type } = doc;
      zip.file(`${name}.${type}`, contents);
    });

    zip
      .generateAsync({ type: 'blob' })
      .then((blob) => {
        resolve(blob);
      })
      .catch(err => reject(err));
  });
};

export const saveBlob = (blob, filename) => {
  FileSaver.saveAs(blob, filename);
};

/**
 * Creates filename appropriate for storing in FirebaseStorage.
 * @param {File} file - Uploaded file
 * @param {string} entityId - Accessible via EditGroup.props.userId
 * @param {string} dirId - Accessible via EditGroup.props.directoryId
 * @returns {string} Concatenated file name with extension.
 */
export const getFileName = (file, entityId, dirId) => {
  const ext = file.name.split('.').pop();
  return `users/${entityId}/${dirId}/profileImage.${ext}`;
};

export const parseCsvFile = csv => new Promise((resolve, reject) => {
  Papa.parse(csv, {
    complete: (results, file) => {
      resolve({ results, file });
    },
    error: (error, file) => {
      reject(error);
    },
  });
});

export const csvToJson = async (csv) => {
  const { results } = await parseCsvFile(csv);

  const [headers, ...rows] = results.data;

  return rows.reduce((arr, row) => {
    const rowData = headers.reduce((data, header, idx) => ({
      ...data,
      [header]: row[idx],
    }), {});

    return [...arr, rowData];
  }, []);
};

export const getRemoteImageSize = (url) => {
  const image = new Image();

  return new Promise((resolve) => {
    // eslint-disable-next-line prefer-arrow-callback,func-names
    image.addEventListener('load', function () {
      resolve({
        height: this.naturalHeight,
        width: this.naturalWidth,
      });
    });

    image.src = url;
  });
};
