import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../Box';


const FadeOverlay = ({ className, isVertical, startsLeftOrBottom, sx }) => (
  <Box
    className={classNames({
      'j-fade-overlay': true,
      'j-fade-hor': !isVertical,
      'j-fade-vert': isVertical,
      'j-fade-flipped': startsLeftOrBottom,
      'pos-abs': true,
      [className]: className,
    })}
    sx={{
      ...sx,
    }}
  />
);

FadeOverlay.propTypes = {
  className: PropTypes.string,
  isVertical: PropTypes.bool,
  startsLeftOrBottom: PropTypes.bool,
  sx: PropTypes.shape(),
};

FadeOverlay.defaultProps = {
  className: undefined,
  isVertical: true,
  startsLeftOrBottom: false,
  sx: {},
};


export default FadeOverlay;
