import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Select as RebassSelect } from '@rebass/forms';

import Box from '../Box';


const Select = (props) => {
  const {
    className,
    defaultValue,
    errors,
    isDisabled,
    isRequired,
    onChange,
    options,
    placeholder,
    sx,
    value,
    wrapperSx,
    ...rest
  } = props;

  const ref = React.useRef();

  return (
    <Box className="j-select-wrapper" sx={wrapperSx}>
      <RebassSelect
        {...rest}
        className={classNames({
          'j-select': true,
          [className]: className,
        })}
        defaultValue={defaultValue}
        disabled={isDisabled}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        required={isRequired}
        sx={{
          borderColor: errors && errors.length ? 'danger' : 'border',
          borderRadius: 'input',
          boxShadow: 'inputLight',
          height: 'controlHeight',
          transition: 'borderColorBoxShadow',
          paddingLeft: 'grid8',
          paddingRight: 'grid8',
          ...sx,
          ':focus': {
            borderColor: 'focusBorder',
            boxShadow: 'inputLightHover',
            ...(sx[':focus'] || {}),
          },
        }}
        value={value}
      >
        {options.map((option) => (
          <option key={option.value}>
            {option.label}
          </option>
        ))}
      </RebassSelect>
    </Box>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  errors: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({}),
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  placeholder: PropTypes.string,
  sx: PropTypes.shape(),
  value: PropTypes.any,
  wrapperSx: PropTypes.shape(),
};

Select.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  errors: undefined,
  isDisabled: undefined,
  isRequired: undefined,
  options: [],
  onChange: undefined,
  placeholder: 'Select',
  sx: {},
  value: undefined,
  wrapperSx: {},
};

const SelectWithRef = React.forwardRef((props, ref) => (
  <Select setRef={ref} {...props} />
));


export { Select };
export default SelectWithRef;
