import { entity } from '../../../shared/constants';


export const customFieldsMap = data => data.reduce((obj, d) => ({
  ...obj,
  [d.id.toString()]: d,
}), {});

export const addedUsersMap = data => data.reduce((obj, d) => ({
  ...obj,
  [d.id.toString()]: d,
}), {});

export const customFieldsIds = data => data.map(d => d.id.toString());

export const messageText = (data) => {
  const { messageData } = data;

  if (data.type === entity.MessageType.EMAIL) {
    return messageData ? messageData.text : '(none)';
  }
  if (data.type === entity.MessageType.SMS) {
    return messageData ? messageData.body : '(none)';
  }

  return '(none)';
};

export const messageSubject = (data) => {
  const { messageData } = data;

  if (data.type === entity.MessageType.EMAIL) {
    return messageData ? messageData.subject : '(none)';
  }
  if (data.type === entity.MessageType.SMS) {
    return '(n/a)';
  }

  return '(none)';
};

export const message = (messageId, data) => ({
  id: messageId,
  clientId: data.clientId,
  createdAt: data.createdAt,
  recipientId: data.recipientId,
  senderId: data.senderId,
  type: data.type,
  archived: data.archived,
  to: data.messageData ? data.messageData.to : '',
  from: data.messageData ? data.messageData.from : '',
  message: messageText(data),
  subject: messageSubject(data),
  recipientName: data.recipientName || '',
});

export const messageDraft = (messageId, data) => ({
  id: messageId,
  createdAt: data.createdAt,
  people: data.recipients ? data.recipients.people || [] : [],
  families: data.recipients ? data.recipients.families || [] : [],
  groups: data.recipients ? data.recipients.groups || [] : [],
  types: data.types || [],
  message: data.message || '',
  subject: data.subject || '',
});

export const messageDrafts = (data) => {
  const ids = Object.keys(data);
  return ids.reduce((obj, id) => ({
    ...obj,
    [id]: messageDraft(id, data[id]),
  }), {});
};

export const csv = data => JSON.parse(data);

export const directoryFields = (fields) => {
  const fieldIds = Object.keys(fields);

  const custom = fieldIds.reduce((obj, id) => {
    const isInternal = fields[id].private;

    if (!isInternal) {
      return {
        ...obj,
        [id]: fields[id],
      };
    }

    return obj;
  }, {});

  const internal = fieldIds.reduce((obj, id) => {
    const isInternal = fields[id].private;

    if (isInternal) {
      return {
        ...obj,
        [id]: fields[id],
      };
    }

    return obj;
  }, {});

  return { custom, internal };
};
