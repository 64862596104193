import PropTypes from 'prop-types';

import * as colors from '../../colors';
import { IconSize } from '../../sizes';


export const iconPropTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export const iconDefaultProps = {
  className: undefined,
  color: colors.mamba,
  fill: undefined,
  size: IconSize.ICON_HUGE,
  stroke: undefined,
  strokeWidth: 1,
};
