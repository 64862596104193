export const styles = {
  container: {
    backgroundColor: 'foreground',
    borderTopLeftRadius: 'sheetView',
    borderTopRightRadius: 'sheetView',
    boxShadow: 'sheetView',
    minHeight: '100vh',
    paddingBottom: 'grid64',
    position: 'absolute',
    top: 16,
    width: '100vw',
    [global.Breakpoint.laptop]: {
      borderTopLeftRadius: 'grid0',
      borderTopRightRadius: 'grid0',
      boxShadow: 'none',
      top: 88,
    },
  },
};
