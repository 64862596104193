import * as React from 'react';
import PropTypes from 'prop-types';

import { roleHelpers } from 'helpers';
import { dashboardRoutePaths, panelRoutePaths } from 'modules/router/constants';

import { Header, MobileMenuNav } from 'modules/core/components';

import { statusConstants } from '../../../../constants';

import SwitchDir from './SwitchDir';


class AppHeader extends React.Component {
  state = {
    isMenuOpen: false,
    isSwitcherOpen: false,
  };

  mail;
  account;

  setMailRef = (el) => {
    this.mail = el;
  };

  setAccountRef = (el) => {
    this.account = el;
  };

  handleMailToggle= (evt) => {
    this.mail.toggle(evt);
  };

  handleAccountToggle= (evt) => {
    this.account.toggle(evt);
  };

  switchDirectory = async (directoryId) => {
    const { switchDirectory } = this.props;

    switchDirectory(directoryId);
  };

  handleMenuRoute = (route) => {
    const { history } = this.props;
    history.push(route);
  };

  render() {
    const {
      clearSearch,
      directory,
      directories = {},
      directoryId,
      dirName,
      filters,
      history,
      isAuthenticated,
      isFetchingDirectories,
      onLogout,
      pathname,
      role,
      search,
      searchType,
      toggleSearch,
    } = this.props;

    const { isMenuOpen, isSwitcherOpen } = this.state;
    const hasAnyMessaging = Boolean(directory?.config?.email || directory?.config?.sms);
    
    if (!isAuthenticated) {
      return null;
    }
    //
    const isAdminRole = roleHelpers.isAdminRole(role);

    const items = [
      {
        href: `${dashboardRoutePaths.people}/${filters.views.people.type}`,
        text: 'People',
      },
      {
        href: dashboardRoutePaths.groups,
        text: 'Groups',
      },
      {
        href: dashboardRoutePaths.dirInfo,
        text: 'Directory',
      },
    ];

    if (isAdminRole) {
      if (hasAnyMessaging) {
        items.push({
          href: dashboardRoutePaths.messages,
          text: 'Messages',
        });
      }

      items.push({
        href: panelRoutePaths.panel,
        text: 'Admin',
      });
    }

    items.push(
      {
        href: dashboardRoutePaths.userAccount,
        text: 'Account',
      },
      {
        href: dashboardRoutePaths.userAccount,
        text: 'Logout',
      },
    );

    const directoryOptions = Object.values(directories).map((dir) => {
      if (dir?.name !== undefined && dir?.id !== undefined) {
        return {
          ...dir,
          text: dir.name,
          key: dir.id,
        }
      }
    });

    return (
      <React.Fragment>
        <Header
          displaySearch={directory.status === statusConstants.DirectoryStatus.active}
          filters={filters}
          hasAnyMessaging={hasAnyMessaging}
          onMenuClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
          onSearch={(term) => search(searchType, term)}
          onSearchChange={() => {}}
          onSearchClear={clearSearch}
          onSwitchClick={() => this.setState({ isSwitcherOpen: true })}
          pathname={pathname}
          role={role}
          title={dirName}
        />
        <MobileMenuNav
          isOpen={isMenuOpen}
          items={items}
          onClose={() => this.setState({ isMenuOpen: !isMenuOpen })}
        />
        <SwitchDir
          activeKey={directoryId}
          isOpen={isSwitcherOpen}
          items={directoryOptions}
          onClose={() => this.setState({ isSwitcherOpen: false })}
          onSelect={() => this.setState({ isSwitcherOpen: !isSwitcherOpen })}
          title={dirName}
        />
      </React.Fragment>
    );
  }
}

AppHeader.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  directories: PropTypes.shape(),
  directory: PropTypes.shape(),
  directoryId: PropTypes.string,
  dirName: PropTypes.string,
  hasAnyMessaging: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  isFetchingDirectories: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  role: PropTypes.string,
  search: PropTypes.func.isRequired,
  searchType: PropTypes.string,
  toggleSearch: PropTypes.func.isRequired,
};

AppHeader.defaultProps = {
  directories: {},
  directory: {},
  directoryId: '',
  dirName: '',
  hasAnyMessaging: undefined,
  isFetchingDirectories: true,
  pathname: '',
  role: '',
  searchType: undefined,
};

export default AppHeader;
