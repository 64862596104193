import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { Search } from '../shapes';


const SearchSvg = (props) => (
  <React.Fragment>
    <defs>
      <filter
        id="search-icon-filter"
        width="39"
        height="39"
        x="0.5"
        y="0.5"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feColorMatrix
          result="invert"
          values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
        />
        <feFlood floodColor="#fff" result="bg" />
        <feBlend in="invert" in2="bg" />
      </filter>
      <mask width="39" height="39" x="0.5" y="0.5" maskUnits="userSpaceOnUse">
        <path
          id="search-icon-mask"
          filter="url(#search-icon-filter)"
          d="M27.53 27.23a12.87 12.87 0 10-.35.36"
          className="cls-1"
        />
      </mask>
    </defs>
    <g>
      <IconBounds />
      <Search {...props} />
    </g>
  </React.Fragment>
);

SearchSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

SearchSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default SearchSvg;
