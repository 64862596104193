import { fork, put, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as actions from './actions';
import * as constants from './constants';

export function* setError(clearTimeout = 6000) {
  yield delay(clearTimeout);
  yield put(actions.clearError());
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.SET_ERROR,
    ]);

    switch (type) {
      case constants.SET_ERROR:
        yield fork(
          setError,
          payload.clearTimeout,
        );
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
