import React from 'react';


const useControlled = ({ default: defaultProp, onChange, value }) => {
  const { current: isControlled } = React.useRef(value !== undefined);
  const [valueState, setValue] = React.useState(defaultProp);

  const derivedValue = isControlled ? value : valueState;

  const setValueIfUncontrolled = React.useCallback((evt, newValue) => {
    if (!isControlled) {
      setValue(newValue);
    } else {
      onChange?.(evt, newValue);
    }
  }, []);

  return [derivedValue, setValueIfUncontrolled];
};


export default useControlled;
