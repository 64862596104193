import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'rebass';

import * as colors from 'modules/core/colors';
import { IconSize } from 'modules/core/sizes';

import Box from '../../../../Box';
import { CaretRightIcon } from '../../../../Icon';


const MobileMenuLink = ({ children, href, onClose }) => {
  const [isHover, setHover] = React.useState(false);

  return (
    <Link
      as={RouterLink}
      className="j-mobile-menu-link"
      to={href}
      onClick={() => {
        if (window.location.pathname.includes(href)) {
          onClose();
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        color: 'lightText',
        fontSize: 'font40',
        fontWeight: 'semibold',
        marginBottom: 'grid16',
        lineHeight: 'navLinkMobile',
        ':last-child': {
          marginBottom: 'grid0',
        },
        ':hover': {
          color: 'lightSubtext',
        },
      }}
      variant="nav"
    >
      <Box sx={{ marginRight: 'grid24' }}>
        {children}
      </Box>

      <CaretRightIcon
        color={colors.white}
        hoverColor={colors.alto}
        isHovered={isHover}
        size={IconSize.ICON_LARGE}
      />
    </Link>
  );
};

MobileMenuLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

MobileMenuLink.defaultProps = {
  children: undefined,
  href: undefined,
};


export default MobileMenuLink;
