import * as constants from './constants';

import { SWITCH_DIRECTORY } from '../directory/constants';

export const name = 'members';

export const initialState = {
  activeMemberShortCode: undefined,
  isFetching: false,
  memberIds: [],
  members: {},
  lastFetched: null,
  pagination: {
    count: 0,
    dataRange: [],
    page: 1,
    pages: 1,
    pageSize: 25,
  },
  personSummaries: {
    ids: [],
    isFetching: false,
    summaries: {},
  },
  sort: {
    by: ['lastName', 'firstName'],
    dir: ['asc', 'asc'],
  },
  viewing: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_MEMBERS_SORT:
      return {
        ...state,
        sort: {
          by: action.payload.by || state.sort.by,
          dir: action.payload.dir || state.sort.dir,
        },
      };

    case constants.FB_MEMBERS_FETCH:
    case constants.FB_MEMBERS_REQUEST_NEXT:
    case constants.FB_MEMBERS_REQUEST_PREV:
      return {
        ...state,
        isFetching: true,
      };

    case constants.FB_MEMBERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastFetched: new Date(),
        members: action.payload.members,
        memberIds: action.payload.memberIds,
        pagination: action.payload.pagination,
      };

    case constants.FB_MEMBERS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case constants.MEMBER_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.MEMBER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        viewing: action.payload.member,
        activeMemberShortCode: action.payload.activeMemberShortCode,
      };

    case constants.MEMBER_INFO_FAILURE:
      return {
        ...state,
        activeMemberShortCode: undefined,
        viewing: {},
        isFetching: false,
      };

    case SWITCH_DIRECTORY:
      return {
        ...state,
        members: {},
      };

    case constants.GET_PERSON_SUMMARIES_FETCH:
      return {
        ...state,
        personSummaries: {
          ...state.personSummaries,
          isFetching: true,
        },
      };

    case constants.GET_PERSON_SUMMARIES_SUCCESS:
      return {
        ...state,
        personSummaries: {
          ...state.personSummaries,
          ids: action.payload.ids,
          isFetching: false,
          lastFetched: new Date(),
          summaries: action.payload.summaries,
        },
      };

    case constants.GET_PERSON_SUMMARIES_FAILURE:
      return {
        ...state,
        personSummaries: {
          ...state.personSummaries,
          isFetching: false,
        },
      };

    default:
      return state;
  }
}
