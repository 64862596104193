import * as constants from './constants';

export function directoriesRequest(token, userId) {
  return {
    type: constants.DIRECTORIES_REQUEST,
    payload: {
      token,
      userId,
    },
  };
}

export function directoriesSuccess(directories, activeDirectoryId) {
  return {
    type: constants.DIRECTORIES_SUCCESS,
    payload: {
      directories,
      activeDirectoryId,
    },
  };
}

export function directoriesFailure(message) {
  return {
    type: constants.DIRECTORIES_FAILURE,
    payload: {
      message,
    },
  };
}

export function fbDirectoryRequest() {
  return {
    type: constants.FB_DIRECTORIES_REQUEST,
  };
}

export function fbDirectoryFetch() {
  return {
    type: constants.FB_DIRECTORIES_FETCH,
  };
}

export function fbDirectorySuccess(directoryData, directoryId) {
  return {
    type: constants.FB_DIRECTORIES_SUCCESS,
    payload: {
      directoryData,
      directoryId,
    },
  };
}

export function fbDirectoryFailure(message, error) {
  return {
    type: constants.FB_DIRECTORIES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function fbMultiDirectoryRequest(directoryIds, options = {}) {
  return {
    type: constants.FB_MULTI_DIRECTORIES_REQUEST,
    payload: {
      directoryIds,
      options,
    },
  };
}

export function fbMultiDirectorySuccess(directoriesData) {
  return {
    type: constants.FB_MULTI_DIRECTORIES_SUCCESS,
    payload: {
      directoriesData,
    },
  };
}

export function fbMultiDirectoryFailure(message, error) {
  return {
    type: constants.FB_MULTI_DIRECTORIES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function searchMembersRequest(token, userId, directoryId, term) {
  return {
    type: constants.SEARCH_MEMBERS_REQUEST,
    payload: {
      token,
      userId,
      directoryId,
      term,
    },
  };
}

export function searchMembersSuccess(members) {
  return {
    type: constants.SEARCH_MEMBERS_SUCCESS,
    payload: {
      members,
    },
  };
}

export function searchMembersFailure(message) {
  return {
    type: constants.SEARCH_MEMBERS_FAILURE,
    payload: {
      message,
    },
  };
}

export function searchFamiliesRequest(token, userId, directoryId, term) {
  return {
    type: constants.SEARCH_FAMILIES_REQUEST,
    payload: {
      token,
      userId,
      directoryId,
      term,
    },
  };
}

export function searchFamiliesSuccess(families) {
  return {
    type: constants.SEARCH_FAMILIES_SUCCESS,
    payload: {
      families,
    },
  };
}

export function searchFamiliesFailure(message) {
  return {
    type: constants.SEARCH_FAMILIES_FAILURE,
    payload: {
      message,
    },
  };
}

export function switchDirectory(directoryId) {
  return {
    type: constants.SWITCH_DIRECTORY,
    payload: {
      directoryId,
    },
  };
}

export function switchDirectoryRequest(directoryId) {
  return {
    type: constants.SWITCH_DIRECTORY_REQUEST,
    payload: {
      directoryId,
    },
  };
}

export function switchDirectorySuccess(directoryId) {
  return {
    type: constants.SWITCH_DIRECTORY_SUCCESS,
    payload: {
      directoryId,
    },
  };
}

export function switchDirectoryFailure(message, error = {}) {
  return {
    type: constants.SWITCH_DIRECTORY_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function searchGroupsRequest(token, userId, directoryId, term) {
  return {
    type: constants.SEARCH_GROUPS_REQUEST,
    payload: {
      token,
      userId,
      directoryId,
      term,
    },
  };
}

export function searchGroupsSuccess(groups) {
  return {
    type: constants.SEARCH_GROUPS_SUCCESS,
    payload: {
      groups,
    },
  };
}

export function searchGroupsFailure(message) {
  return {
    type: constants.SEARCH_GROUPS_FAILURE,
    payload: {
      message,
    },
  };
}

export function fetchEntitiesRequest(payload) {
  return {
    type: constants.FETCH_ENTITIES_REQUEST,
    payload,
  };
}

export function fetchEntitiesSuccess(entities, ids) {
  return {
    type: constants.FETCH_ENTITIES_SUCCESS,
    payload: {
      entities,
      ids,
    },
  };
}

export function fetchEntitiesFailure(message, error) {
  return {
    type: constants.FETCH_ENTITIES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function dirFeaturesRequest() {
  return {
    type: constants.DIR_FEATURES_REQUEST,
  };
}

export function dirFeaturesSuccess({ email, sms }) {
  return {
    type: constants.DIR_FEATURES_SUCCESS,
    payload: {
      email,
      sms,
    },
  };
}

export function dirFeaturesFailure(message, error) {
  return {
    type: constants.DIR_FEATURES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}
