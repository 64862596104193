import * as constants from './constants';

export const name = 'error';

const defaultClearTimeout = 6000;

const initialState = {
  title: null,
  message: null,
  level: null,
  clearTimeout: defaultClearTimeout,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_ERROR:
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        level: action.payload.level,
        clearTimeout: action.payload.clearTimeout,
      };

    case constants.CLEAR_ERROR:
      return {
        ...state,
        title: null,
        message: null,
        level: null,
        clearTimeout: defaultClearTimeout,
      };

    default:
      return state;
  }
}
