import * as constants from './constants';

export function showNotify(message) {
  return {
    type: constants.SHOW_NOTIFY,
    payload: {
      message,
    },
  };
}
