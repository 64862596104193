import PropTypes from 'prop-types';

const propTypes = {
  path: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
  role: PropTypes.string,
  allowedRoles: PropTypes.array,
  redirectsTo: PropTypes.string.isRequired,
};

const defaultProps = {
  isAuthenticated: false,
  role: null,
  allowedRoles: [],
};

export { propTypes, defaultProps };
