//
import * as React from 'react';
import {
  Card,
  CardBody,
  Table,
} from 'reactstrap';

import { classie } from '../../../../utils';
import styles from './styles.css';


class ResultsRow extends React.PureComponent {
  handleClick = () => {
    const { onClick, id, type } = this.props;
    onClick(type, id);
  };

  render() {
    const { text, imageUrl } = this.props;
    return (
      <tr
        onClick={this.handleClick}
        className={styles.row}>
        <td className={styles.rowCell}>
          <img
            alt={text}
            src={imageUrl} />
          {text}
        </td>
      </tr>
    );
  }
}

class Results extends React.Component {
  render() {
    const { results, searchType, onRowClick, onSeeAllClick } = this.props;

    const resultsSlice = results.slice(0, 4);

    return (
      <Card className={styles.card}>
        <CardBody>
          <Table responsive>
            <tbody>
              {resultsSlice.map((result, idx) => {
                const key = `search-result-${idx}`;
                let text;

                if (searchType === 'messages') {
                  text = result.to;
                } else {
                  text = result.name;
                }

                return (
                  <ResultsRow
                    imageUrl={result.imageurl}
                    key={key}
                    text={text}
                    id={result.id}
                    onClick={onRowClick}
                    type={searchType} />
                );
              })}

              <tr className={styles.row} onClick={onSeeAllClick}>
                <td className={styles.rowCell}>
                  > See all results
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

export default Results;
