import PropTypes from 'prop-types';
import React from 'react';

import { propTypeHelpers } from 'helpers';

import { ElementShape } from '../../constants';

import Avatar from './Avatar';


const RectAvatar = ({ alt, size, src, sx }) => (
  <Avatar
    alt={alt}
    shape={ElementShape.RECT}
    size={size}
    src={src}
    sx={sx}
  />
);

RectAvatar.propTypes = {
  alt: PropTypes.string.isRequired,
  size: propTypeHelpers.oneOfOrArrayOfPropType(PropTypes.number, PropTypes.string),
  src: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

RectAvatar.defaultProps = {
  size: undefined,
  sx: {},
};


export default RectAvatar;
