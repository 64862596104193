import PropTypes from 'prop-types';
import React from 'react';

import EntityList from '../EntityList';
import { RectAvatar } from '../Avatar';


const ConnectedEntities = (props) => {
  const {
    AvatarComponent,
    avatarSize,
    entities,
    forceListStyle,
    getTitle,
    getImage,
    getSubtitle,
    getHref,
    showCarets,
  } = props;

  return (
    <EntityList
      avatarSize={avatarSize}
      forceListStyle={forceListStyle}
      isStriped={false}
      items={entities.map((entity, idx) => ({
        AvatarComponent,
        href: getHref(entity, idx),
        key: entity.id,
        imageUrl: getImage(entity, idx),
        title: getTitle(entity, idx),
        subtitle: getSubtitle(entity, idx),
      }))}
      showCarets={showCarets}
    />
  );
};

ConnectedEntities.propTypes = {
  AvatarComponent: PropTypes.elementType,
  avatarSize: PropTypes.any,
  entities: PropTypes.arrayOf(PropTypes.shape()),
  getAvatarSize: PropTypes.func,
  getImage: PropTypes.func.isRequired,
  getSubtitle: PropTypes.func.isRequired,
  getTitle: PropTypes.func.isRequired,
  getHref: PropTypes.func.isRequired,
  forceListStyle: PropTypes.bool,
  showCarets: PropTypes.bool,
};

ConnectedEntities.defaultProps = {
  AvatarComponent: RectAvatar,
  avatarSize: undefined,
  entities: undefined,
  forceListStyle: undefined,
  showCarets: undefined,
};


export default ConnectedEntities;
