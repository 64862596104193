import * as React from 'react';
import PropTypes from 'prop-types';

import Button from './presenter';
import Link from '../Link';


const ButtonLink = ({ buttonProps, children, href, sx }) => (
  <Link href={href} sx={sx}>
    <Button {...buttonProps}>
      {children}
    </Button>
  </Link>
);

ButtonLink.propTypes = {
  buttonProps: PropTypes.shape(),
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  sx: PropTypes.shape(),
};

ButtonLink.defaultProps = {
  buttonProps: {},
  children: undefined,
  sx: {},
};


export default ButtonLink;
