import { } from 'modules/groups/constants';
import {} from 'modules/members/constants';

import * as constants from './constants';

import { SWITCH_DIRECTORY } from '../directory/constants';

export const name = 'families';

export const initialState = {
  activeFamilyId: undefined,
  activeFamShortCode: undefined,
  families: {},
  familyIds: [],
  familySummaries: {
    ids: [],
    isFetching: false,
    lastFetched: null,
    summaries: {},
  },
  isFetching: false,
  lastFetched: null,
  pagination: {
    count: 0,
    dataRange: [],
    page: 1,
    pages: 1,
    pageSize: 25,
  },
  sort: {
    by: ['name'],
    dir: ['asc'],
  },
  viewing: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_FAMILIES_SORT:
      return {
        ...state,
        sort: {
          by: action.payload.by || state.sort.by,
          dir: action.payload.dir || state.sort.dir,
        },
      };

    case constants.FB_FAMILIES_FETCH:
      return {
        ...state,
        isFetching: true,
        needsFetch: false,
      };

    case constants.FB_FAMILIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        families: action.payload.families,
        familyIds: action.payload.familyIds,
        lastFetched: new Date(),
        pagination: action.payload.pagination,
      };

    case constants.FB_FAMILIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case constants.FAMILY_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.FAMILY_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        viewing: action.payload.family,
        activeFamShortCode: action.payload.activeFamShortCode,
      };

    case constants.FAMILY_INFO_FAILURE:
      return {
        ...state,
        activeFamShortCode: undefined,
        viewing: {},
        isFetching: false,
      };

    case constants.FAMILY_SUMMARIES_FETCH:
      return {
        ...state,
        familySummaries: {
          ...state.familySummaries,
          isFetching: true,
        },
      };

    case constants.FAMILY_SUMMARIES_SUCCESS:
      return {
        ...state,
        familySummaries: {
          ...state.familySummaries,
          isFetching: false,
          lastFetched: new Date(),
          summaries: action.payload.summaries,
          ids: action.payload.ids,
        },
      };

    case constants.FAMILY_SUMMARIES_FAILURE:
      return {
        ...state,
        familySummaries: {
          ...state.familySummaries,
          isFetching: false,
        },
      };

    case SWITCH_DIRECTORY:
      return {
        ...state,
        families: {},
      };

    default:
      return state;
  }
}
