import React from 'react';
import PropTypes from 'prop-types';
import { Label as RebassLabel } from '@rebass/forms'


const Label = (props) => {
  const {
    className,
    children,
    isRequired,
    setRef,
    sx,
  } = props;

  return (
    <RebassLabel
      className={className}
      ref={setRef}
      sx={{
        color: 'fieldLabel',
        fontSize: 'font14',
        ...sx,
      }}
    >
      {children}
      {isRequired ? ' (Required)' : ''}
    </RebassLabel>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
};

Label.defaultProps = {
  children: undefined,
  isRequired: undefined,
  setRef: undefined,
  sx: {},
};

const LabelWithRef = React.forwardRef((props, ref) => (
  <Label setRef={ref} {...props} />
));


export { Label };
export default LabelWithRef;
