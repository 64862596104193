import * as React from 'react';
import PropTypes from 'prop-types';

import { Breadcrumb } from 'antd';


const Crumbs = ({ model }) => (
  <Breadcrumb>
    {model.map(item => (
      <Breadcrumb.Item
        key={item.label}
        onClick={item.command}
      >
        {item.label}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

Crumbs.propTypes = {
  model: PropTypes.arrayOf(PropTypes.shape()),
};

Crumbs.defaultProps = {
  model: [],
};


export default Crumbs;
