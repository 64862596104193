import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex as RebassFlex } from 'rebass';


/**
 * Generic flexbox div. Accepts normal DOM props, as well as Rebass props + flex props.
 * @param {ThemedProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const Flex = (props) => {
  const { className, setRef, sx, ...rest } = props;

  return (
    <RebassFlex
      className={classNames({
        'j-flex': true,
        [className]: className,
      })}
      ref={setRef}
      sx={{ ...sx }}
      {...rest}
    />
  );
};

Flex.propTypes = {
  /**
   * @type {ClassName}
   */
  className: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape({}),
};

Flex.defaultProps = {
  className: undefined,
  sx: {},
};

const FlexWithRef = React.forwardRef((props, ref) => (
  <Flex {...props} setRef={ref} />
));


export { Flex };
export default FlexWithRef;
