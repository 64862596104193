import PropTypes from 'prop-types';
import React from 'react';

import Text from '../Text';


const About = ({ description, isFetching }) => {
  if (!description) {
    return null;
  }

  return (
    <Text
      sx={{
        color: 'infoValue',
      }}
    >
      {description}
    </Text>
  );
};

About.propTypes = {
  description: PropTypes.string,
  isFetching: PropTypes.bool,
};

About.defaultProps = {
  description: undefined,
  isFetching: undefined,
};


export default About;
