import * as React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tabs } from 'antd';


const { TabPane } = Tabs;

class TabPanes extends React.PureComponent {
  static defaultProps = {
    defaultActiveKey: 1,
    tabs: [],
  };

  handleChange = (tabKey, evt) => {
    const { value, activeKey, name } = this.props;
    this.props.onChange(evt, tabKey, value || activeKey, name);
  };

  render() {
    const { value, activeKey, defaultActiveKey, tabs } = this.props;
    const panes = tabs.map(t => (
      <TabPane
        key={t.key}
        tab={(
          t.icon ? <span><LegacyIcon type={t.icon} />{t.tab}</span> : t.tab
        )}>
        {t.tab}
      </TabPane>
    ));
    return (
      <Tabs
        onTabClick={this.handleChange}
        defaultActiveKey={defaultActiveKey}
        activeKey={value || activeKey}>
        {panes}
      </Tabs>
    );
  }
}

export default TabPanes;
