import * as constants from './constants';

export function adminUserDataRequest(token, userId, userShortCode) {
  return {
    type: constants.ADMIN_USER_DATA_REQUEST,
    payload: {
      token,
      userId,
      userShortCode,
    },
  };
}

export function adminUserDataSuccess(user) {
  return {
    type: constants.ADMIN_USER_DATA_SUCCESS,
    payload: {
      user,
    },
  };
}

export function adminUserDataFailure(message) {
  return {
    type: constants.ADMIN_USER_DATA_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminClearViewingEntity() {
  return {
    type: constants.ADMIN_CLEAR_VIEWING_ENTITY,
  };
}

export function adminSaveUserDataRequest(token, userId, userShortCode, updates) {
  return {
    type: constants.ADMIN_SAVE_USER_DATA_REQUEST,
    payload: {
      token,
      userId,
      userShortCode,
      updates,
    },
  };
}

export function adminSaveUserDataSuccess(user) {
  return {
    type: constants.ADMIN_SAVE_USER_DATA_SUCCESS,
    payload: {
      user,
    },
  };
}

export function adminSaveUserDataFailure(message) {
  return {
    type: constants.ADMIN_SAVE_USER_DATA_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminAddMemberRequest(token, userId, memberData) {
  return {
    type: constants.ADMIN_ADD_MEMBER_REQUEST,
    payload: {
      token,
      userId,
      memberData,
    },
  };
}

export function adminAddMemberSuccess() {
  return {
    type: constants.ADMIN_ADD_MEMBER_SUCCESS,
  };
}

export function adminAddMemberFailure(message) {
  return {
    type: constants.ADMIN_ADD_MEMBER_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminAddMultiMemberRequest(members) {
  return {
    type: constants.ADMIN_ADD_MULTI_MEMBER_REQUEST,
    payload: {
      members,
    },
  };
}

export function adminAddMultiMemberSuccess(members) {
  return {
    type: constants.ADMIN_ADD_MULTI_MEMBER_SUCCESS,
    payload: {
      members,
    },
  };
}

export function adminAddMultiMemberFailure(message, code, data) {
  return {
    type: constants.ADMIN_ADD_MULTI_MEMBER_FAILURE,
    payload: {
      message,
      code,
      data,
    },
  };
}

export function adminUpdateMultiMemberRequest(updates) {
  return {
    type: constants.ADMIN_UPDATE_MULTI_MEMBER_REQUEST,
    payload: {
      updates,
    },
  };
}

export function adminUpdateMultiMemberSuccess(updates) {
  return {
    type: constants.ADMIN_UPDATE_MULTI_MEMBER_SUCCESS,
    payload: {
      updates,
    },
  };
}

export function adminUpdateMultiMemberFailure(message, code, data) {
  return {
    type: constants.ADMIN_UPDATE_MULTI_MEMBER_FAILURE,
    payload: {
      message,
      code,
      data,
    },
  };
}

export function adminAddFamilyRequest(token, userId, familyData) {
  return {
    type: constants.ADMIN_ADD_FAMILY_REQUEST,
    payload: {
      token,
      userId,
      familyData,
    },
  };
}

export function adminAddFamilySuccess() {
  return {
    type: constants.ADMIN_ADD_FAMILY_SUCCESS,
  };
}

export function adminAddFamilyFailure(message) {
  return {
    type: constants.ADMIN_ADD_FAMILY_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminAddGroupRequest(token, userId, groupData) {
  return {
    type: constants.ADMIN_ADD_GROUP_REQUEST,
    payload: {
      token,
      userId,
      groupData,
    },
  };
}

export function adminAddGroupSuccess() {
  return {
    type: constants.ADMIN_ADD_GROUP_SUCCESS,
  };
}

export function adminAddGroupFailure(message) {
  return {
    type: constants.ADMIN_ADD_GROUP_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminCustomFieldsRequest() {
  return {
    type: constants.ADMIN_CUSTOM_FIELDS_REQUEST,
  };
}

export function adminCustomFieldsSuccess(fields, fieldIds) {
  return {
    type: constants.ADMIN_CUSTOM_FIELDS_SUCCESS,
    payload: {
      fields,
      fieldIds,
    },
  };
}

export function adminCustomFieldsFailure(message) {
  return {
    type: constants.ADMIN_CUSTOM_FIELDS_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminInternalFieldsRequest(token, userId) {
  return {
    type: constants.ADMIN_INTERNAL_FIELDS_REQUEST,
    payload: {
      token,
      userId,
    },
  };
}

export function adminInternalFieldsSuccess(fields, fieldIds) {
  return {
    type: constants.ADMIN_INTERNAL_FIELDS_SUCCESS,
    payload: {
      fields,
      fieldIds,
    },
  };
}

export function adminInternalFieldsFailure(message) {
  return {
    type: constants.ADMIN_INTERNAL_FIELDS_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminTagsRequest(token, userId) {
  return {
    type: constants.ADMIN_TAGS_REQUEST,
    payload: {
      token,
      userId,
    },
  };
}

export function adminTagsSuccess(tags, tagIds) {
  return {
    type: constants.ADMIN_TAGS_SUCCESS,
    payload: {
      tags,
      tagIds,
    },
  };
}

export function adminTagsFailure(message) {
  return {
    type: constants.ADMIN_TAGS_FAILURE,
    payload: {
      message,
    },
  };
}

export function adminUserCatsRequest() {
  return {
    type: constants.ADMIN_USER_CATS_REQUEST,
  };
}

export function adminUserCatsSuccess(userCategories) {
  return {
    type: constants.ADMIN_USER_CATS_SUCCESS,
    payload: {
      userCategories,
    },
  };
}

export function adminUserCatsFailure(message, error) {
  return {
    type: constants.ADMIN_USER_CATS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function adminDirectoryFieldsRequest() {
  return {
    type: constants.ADMIN_DIR_FIELDS_REQUEST,
  };
}

export function adminDirectoryFieldsSuccess(customFields, internalFields) {
  return {
    type: constants.ADMIN_DIR_FIELDS_SUCCESS,
    payload: {
      customFields,
      internalFields,
    },
  };
}

export function adminDirectoryFieldsFailure(message, error) {
  return {
    type: constants.ADMIN_DIR_FIELDS_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function saveCustomFieldRequest(id, updates) {
  return {
    type: constants.ADMIN_SAVE_CUSTOM_FIELD_REQUEST,
    payload: {
      id,
      updates,
    },
  };
}

export function saveCustomFieldSuccess(field) {
  return {
    type: constants.ADMIN_SAVE_CUSTOM_FIELD_SUCCESS,
    payload: field,
  };
}

export function saveCustomFieldFailure(message) {
  return {
    type: constants.ADMIN_SAVE_CUSTOM_FIELD_FAILURE,
    payload: {
      message,
    },
  };
}

export function saveInternalFieldRequest(id, updates) {
  return {
    type: constants.ADMIN_SAVE_INTERNAL_FIELD_REQUEST,
    payload: {
      id,
      updates,
    },
  };
}

export function saveInternalFieldSuccess(field) {
  return {
    type: constants.ADMIN_SAVE_INTERNAL_FIELD_SUCCESS,
    payload: field,
  };
}

export function saveInternalFieldFailure(message) {
  return {
    type: constants.ADMIN_SAVE_INTERNAL_FIELD_FAILURE,
    payload: {
      message,
    },
  };
}

export function saveCategoryRequest(id, updates) {
  return {
    type: constants.ADMIN_SAVE_CATEGORY_REQUEST,
    payload: {
      id,
      updates,
    },
  };
}

export function saveCategorySuccess(category) {
  return {
    type: constants.ADMIN_SAVE_CATEGORY_SUCCESS,
    payload: category,
  };
}

export function saveCategoryFailure(message) {
  return {
    type: constants.ADMIN_SAVE_CATEGORY_FAILURE,
    payload: {
      message,
    },
  };
}

export function deleteCustomFieldRequest(id) {
  return {
    type: constants.ADMIN_DELETE_CUSTOM_FIELD_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteCustomFieldSuccess(id) {
  return {
    type: constants.ADMIN_DELETE_CUSTOM_FIELD_SUCCESS,
    payload: {
      id,
    },
  };
}

export function deleteCustomFieldFailure(message) {
  return {
    type: constants.ADMIN_DELETE_CUSTOM_FIELD_FAILURE,
    payload: {
      message,
    },
  };
}

export function deleteInternalFieldRequest(id) {
  return {
    type: constants.ADMIN_DELETE_INTERNAL_FIELD_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteInternalFieldSuccess(id) {
  return {
    type: constants.ADMIN_DELETE_INTERNAL_FIELD_SUCCESS,
    payload: {
      id,
    },
  };
}

export function deleteInternalFieldFailure(message) {
  return {
    type: constants.ADMIN_DELETE_INTERNAL_FIELD_FAILURE,
    payload: {
      message,
    },
  };
}

export function deleteCategoryRequest(id) {
  return {
    type: constants.ADMIN_DELETE_CATEGORY_REQUEST,
    payload: {
      id,
    },
  };
}

export function deleteCategorySuccess(id) {
  return {
    type: constants.ADMIN_DELETE_CATEGORY_SUCCESS,
    payload: {
      id,
    },
  };
}

export function deleteCategoryFailure(message) {
  return {
    type: constants.ADMIN_DELETE_CATEGORY_FAILURE,
    payload: {
      message,
    },
  };
}

export function createCustomFieldRequest(data) {
  return {
    type: constants.ADMIN_CREATE_CUSTOM_FIELD_REQUEST,
    payload: {
      data,
    },
  };
}

export function createCustomFieldSuccess(field) {
  return {
    type: constants.ADMIN_CREATE_CUSTOM_FIELD_SUCCESS,
    payload: {
      field,
    },
  };
}

export function createCustomFieldFailure(message) {
  return {
    type: constants.ADMIN_CREATE_CUSTOM_FIELD_FAILURE,
    payload: {
      message,
    },
  };
}

export function createInternalFieldRequest(data) {
  return {
    type: constants.ADMIN_CREATE_INTERNAL_FIELD_REQUEST,
    payload: {
      data,
    },
  };
}

export function createInternalFieldSuccess(field) {
  return {
    type: constants.ADMIN_CREATE_INTERNAL_FIELD_SUCCESS,
    payload: {
      field,
    },
  };
}

export function createInternalFieldFailure(message) {
  return {
    type: constants.ADMIN_CREATE_INTERNAL_FIELD_FAILURE,
    payload: {
      message,
    },
  };
}

export function createCategoryRequest(data) {
  return {
    type: constants.ADMIN_CREATE_CATEGORY_REQUEST,
    payload: {
      data,
    },
  };
}

export function createCategorySuccess(category) {
  return {
    type: constants.ADMIN_CREATE_CATEGORY_SUCCESS,
    payload: {
      category,
    },
  };
}

export function createCategoryFailure(message) {
  return {
    type: constants.ADMIN_CREATE_CATEGORY_FAILURE,
    payload: {
      message,
    },
  };
}

export function requestAllSentMessages(fromDate, toDate) {
  return {
    type: constants.ADMIN_SENT_MESSAGES_REQUEST,
    payload: {
      fromDate,
      toDate,
    },
  };
}

export function allSentMessagesSuccess(messages, messageIds) {
  return {
    type: constants.ADMIN_SENT_MESSAGES_SUCCESS,
    payload: {
      messages,
      messageIds,
    },
  };
}

export function allSentMessagesFailure(message) {
  return {
    type: constants.ADMIN_SENT_MESSAGES_FAILURE,
    payload: {
      message,
    },
  };
}

export function requestCsvExport(type, exportOptions, downloadOnComplete) {
  return {
    type: constants.ADMIN_CSV_EXPORT_REQUEST,
    payload: {
      type,
      exportOptions,
      downloadOnComplete,
    },
  };
}

export function csvExportSuccess() {
  return {
    type: constants.ADMIN_CSV_EXPORT_SUCCESS,
  };
}

export function csvExportFailure(message) {
  return {
    type: constants.ADMIN_CSV_EXPORT_FAILURE,
    payload: {
      message,
    },
  };
}

export function requestCsvImport(type, data) {
  return {
    type: constants.ADMIN_CSV_IMPORT_REQUEST,
    payload: {
      type,
      data,
    },
  };
}

export function csvImportSuccess() {
  return {
    type: constants.ADMIN_CSV_IMPORT_SUCCESS,
  };
}

export function csvImportFailure(message) {
  return {
    type: constants.ADMIN_CSV_IMPORT_FAILURE,
    payload: {
      message,
    },
  };
}

export function clearCsvExport() {
  return {
    type: constants.ADMIN_CSV_EXPORT_CLEAR,
  };
}

export function clearCsvImport() {
  return {
    type: constants.ADMIN_CSV_IMPORT_CLEAR,
  };
}

export function changePermissionsRequest(memberId, accessRoles) {
  return {
    type: constants.ADMIN_CHANGE_PERMS_REQUEST,
    payload: {
      memberId,
      accessRoles,
    },
  };
}

export function changePermissionsSuccess(memberId) {
  return {
    type: constants.ADMIN_CHANGE_PERMS_SUCCESS,
    payload: {
      memberId,
    },
  };
}

export function changePermissionsFailure(message, error) {
  return {
    type: constants.ADMIN_CHANGE_PERMS_FAILURE,
    payload: { message, error },
  };
}

export function setAccountStatusRequest(directoryId, memberId, status) {
  return {
    type: constants.ADMIN_SET_ACCOUNT_STATUS_REQUEST,
    payload: {
      directoryId,
      memberId,
      status,
    },
  };
}

export function setAccountStatusSuccess() {
  return {
    type: constants.ADMIN_SET_ACCOUNT_STATUS_SUCCESS,
  };
}

export function setAccountStatusFailure(message, error) {
  return {
    type: constants.ADMIN_SET_ACCOUNT_STATUS_FAILURE,
    payload: { message, error },
  };
}

/* {
  people: messageData.people,
  families: messageData.families,
  groups: messageData.groups,
  categories: messageData.category,
  subject: messageData.subject,
  types: messageData.types,
  message: messageData.message,
} */
export function sendMessageRequest(payload) {
  return {
    type: constants.ADMIN_SEND_MESSAGE_REQUEST,
    payload,
  };
}

export function sendMessageSuccess(sentMessageLog) {
  return {
    type: constants.ADMIN_SEND_MESSAGE_SUCCESS,
    payload: {
      sentMessageLog,
    },
  };
}

export function sendMessageFailure(message) {
  return {
    type: constants.ADMIN_SEND_MESSAGE_FAILURE,
    payload: { message },
  };
}

export function clearComposeState() {
  return {
    type: constants.ADMIN_CLEAR_COMPOSE_STATE,
  };
}

/**
 * @param {Object} payload
 * @property recipients
 * @property subject
 * @property message
 * @property types
 * @return {Object}
 */
export function saveMessageDraftRequest(payload) {
  return {
    type: constants.ADMIN_SAVE_DRAFT_REQUEST,
    payload,
  };
}

export function saveMessageDraftSuccess(sentMessageLog) {
  return {
    type: constants.ADMIN_SAVE_DRAFT_SUCCESS,
    payload: {
      sentMessageLog,
    },
  };
}

export function saveMessageDraftFailure(message, error) {
  return {
    type: constants.ADMIN_SAVE_DRAFT_FAILURE,
    payload: { message, error },
  };
}

export function messageDraftsRequest() {
  return {
    type: constants.ADMIN_MESSAGE_DRAFTS_REQUEST,
  };
}

export function messageDraftsSuccess(drafts) {
  return {
    type: constants.ADMIN_MESSAGE_DRAFTS_SUCCESS,
    payload: {
      drafts,
    },
  };
}

export function messageDraftsFailure(message, error) {
  return {
    type: constants.ADMIN_MESSAGE_DRAFTS_FAILURE,
    payload: { message, error },
  };
}

export function memberExportFieldsRequest() {
  return {
    type: constants.ADMIN_MEMBER_EXPORT_FIELDS_REQUEST,
  };
}

export function memberExportFieldsSuccess(fields) {
  return {
    type: constants.ADMIN_MEMBER_EXPORT_FIELDS_SUCCESS,
    payload: {
      fields,
    },
  };
}

export function memberExportFieldsFailure(message, error) {
  return {
    type: constants.ADMIN_MEMBER_EXPORT_FIELDS_FAILURE,
    payload: { message, error },
  };
}
