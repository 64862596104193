import countryData from 'country-data';
import _ from 'lodash';


export const COUNTRY_CODES_REQUEST = '@defs/COUNTRY_CODES_REQUEST';
export const COUNTRY_CODES_SUCCESS = '@defs/COUNTRY_CODES_SUCCESS';
export const COUNTRY_CODES_FAILURE = '@defs/COUNTRY_CODES_FAILURE';

export const COUNTRIES_REQUEST = '@defs/COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = '@defs/COUNTRIES_SUCCESS';
export const COUNTRIES_FAILURE = '@defs/COUNTRIES_FAILURE';

export const STATE_PROV_REQUEST = '@defs/STATE_PROV_REQUEST';
export const STATE_PROV_SUCCESS = '@defs/STATE_PROV_SUCCESS';
export const STATE_PROV_FAILURE = '@defs/STATE_PROV_FAILURE';

export const DEFS_REQUEST = '@defs/DEFS_REQUEST';

export const countries = countryData.countries.all.reduce((arr, c) => {
  const country = {
    name: c.name,
    alpha2: c.alpha2,
    ioc: c.ioc,
    emoji: c.emoji,
    value: c.alpha2,
  };

  if (c.alpha2 === 'US') {
    return [country, ...arr];
  }

  return [...arr, country];
}, []);

export const sortedCountries = _.uniqBy(_.sortBy(countries, c => (c.alpha2 === 'US' ? 'A' : c.name)), o => o.alpha2);

export const countriesMap = countryData.countries.all.reduce((obj, c) => ({
  ...obj,
  [c.alpha2]: {
    name: c.name,
    alpha2: c.alpha2,
    ioc: c.ioc,
    emoji: c.emoji,
    value: c.alpha2,
  },
}), {});
