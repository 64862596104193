export default {
  dark: {
    background: '#1e1e2f',
    backgroundDarker: '#1e1e24',
    inactiveBackground: '#191f31',
    panelBackground: '#27293d',
    panelShadow: '0 1px 20px 0 rgba(0,0,0,.1)',
    panelActiveShadow: '0 1px 25px 0 rgba(0,0,0,.15)',
    text: '#ffffff',
    searchInputBackground: '#ffffff',
    searchText: '#222a42',
  },
  light: {
    background: '#f5f6fa',
    backgroundDarker: '#555555',
    inactiveBackground: '#d3d7e9',
    panelBackground: '#ffffff',
    panelShadow: '0 1px 15px 0 hsla(0,0%,48.2%,.05)',
    panelActiveShadow: '0 1px 20px 0 rgba(0,0,0,.1)',
    text: '#1d253b',
    searchInputBackground: '#ffffff',
    searchText: '#222a42',
  },
  argon: {
    background: '#f8f9fe',
    backgroundDarker: '#5e72e4',
    inactiveBackground: '#d3d7e9',
    panelBackground: '#ffffff',
    panelShadow: '0 0 2rem 0 rgba(136,152,170,.15)',
    panelActiveShadow: '0 1px 25px 0 rgba(0,0,0,.20)',
    text: '#32325d',
    searchInputBackground: 'rgba(23,43,77,.8)',
    searchText: 'rgba(255,255,255,.9)',
  },
  all: {
    default: '#344675',
    primary: '#e14eca',
    secondary: '#f4f5f7',
    success: '#00f2c3',
    successAlt: '#00bf9a',
    info: '#1d8cf8',
    warning: '#ff8d72',
    danger: '#fd5d93',
    black: '#222a42',
    defaultStates: '#263148',
    primaryStates: '#ba54f5',
    successStates: '#0098f0',
    infoStates: '#3358f4',
    warningStates: '#ff6491',
    dangerStates: '#ec250d',
    blackStates: '#1d253b',
    borderRadius: '6px',
    defaultGradient: 'linear-gradient(to bottom left,#344675,#263148,#344675)',
    primaryGradient: 'linear-gradient(to bottom left,#e14eca,#ba54f5,#e14eca)',
    infoGradient: 'linear-gradient(to bottom left,#1d8cf8,#3358f4,#1d8cf8)',
    successGradient: 'linear-gradient(to bottom left,#00f2c3,#0098f0,#00f2c3)',
    warningGradient: 'linear-gradient(to bottom left,#ff8d72,#ff6491,#ff8d72)',
    dangerGradient: 'linear-gradient(to bottom left,#fd5d93,#ec250d,#fd5d93)',
    activeShadow: '2px 2px 6px rgba(0,0,0,.4)',
    menuShadow: '0 2px 22px 0 rgba(0,0,0,.1), 0 4px 20px 0 rgba(0,0,0,.15)',
    dropdownShadow: '0 10px 50px 0 rgba(0,0,0,.2)',
    searchShadow: '0 15px 35px rgba(50,50,93,.2), 0 5px 15px rgba(0,0,0,.17)',
    navButtonBackground: 'transparent',
    menuText: '#9a9a9a',
  },
  accents: {
    card: '#5e72e4',
  },
  brand: {
    primary: '#fdb600',
    secondary: '#000066',
    secondaryLight: '#9999cc',
  },
};
