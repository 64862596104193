import React from 'react';


class ChildrenIfRole extends React.Component {
  render() {
    const { role, allowedRoles, children } = this.props;
    if (allowedRoles.includes(role)) return children;
    return null;
  }
}


export default ChildrenIfRole;
