//
import * as React from 'react';
import * as Sentry from '@sentry/browser';


class ErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: {},
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <div>
          <p>Oh no! You've found an error.</p>

          <button onClick={Sentry.showReportDialog}>
            Report it here
          </button>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
