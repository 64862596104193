import * as constants from './constants';

export function toggleNavigation() {
  return {
    type: constants.TOGGLE_NAVIGATION,
  };
}

export function pushLocationHistory(location) {
  return {
    type: constants.PUSH_LOC_HISTORY,
    payload: location,
  };
}

export const historyPush = (url, state) => ({
  payload: { url, state },
  type: constants.HISTORY_PUSH,
});
