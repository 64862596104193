import * as constants from './constants';

export const name = 'tags';

const initialState = {
  tags: [],
  membersByTag: {},
  isFetching: false,
  isFetchingQuery: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.TAGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.TAGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tags: action.payload.tags,
      };

    case constants.TAGS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case constants.MEMBERS_BY_TAG_REQUEST:
      return {
        ...state,
        isFetchingQuery: true,
      };

    case constants.MEMBERS_BY_TAG_SUCCESS:
      return {
        ...state,
        membersByTag: action.payload.members,
        isFetchingQuery: false,
      };

    case constants.MEMBERS_BY_TAG_FAILURE:
      return {
        ...state,
        isFetchingQuery: false,
      };

    default:
      return state;
  }
}
