//


import * as React from 'react';
import moment from 'moment';

// $FlowFixMe
import { Stats } from 'modules/core/components';

import styles from './styles.css';


class TimeAgo extends React.Component {
  static defaultProps = {
    updateInterval: 6000,
    iconClass: 'loader_refresh',
    onClick: () => {},
  };

  state = {
    text: '',
  };


  componentDidMount() {
    const { updateInterval } = this.props;

    this.setText();

    this.interval = setInterval(() => {
      this.setText();
    }, updateInterval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setText = () => {
    const timeAgo = moment(this.props.time).fromNow();
    const text = `Updated ${timeAgo}`;
    this.setState({ text });
  };

  render() {
    const { iconClass, onClick, overrideText } = this.props;
    const { text } = this.state;

    const iconClasses = `now-ui-icons ${iconClass || ''}`;
    const statsText = overrideText || text;

    return (
      <div
        className={styles.timeAgo}
        onClick={onClick}>
        <Stats>
          {[
            {
              i: iconClasses,
              t: statsText,
            },
          ]}
        </Stats>
      </div>
    );
  }
}

export default TimeAgo;
