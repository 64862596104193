export const TOGGLE_NAVIGATION = '@router/TOGGLE_NAVIGATION';

export const PUSH_LOC_HISTORY = '@router/PUSH_LOC_HISTORY';

export const HISTORY_PUSH = '@router/HISTORY_PUSH';

export const panelRoutePaths = {
  data: '/panel/data',
  dataCategories: '/panel/data/categories',
  dataCreate: (type = ':type') => `/panel/data/${type}/create`,
  dataCustom: '/panel/data/custom_fields',
  dataPrivate: '/panel/data/private_fields',
  exports: '/panel/exports',
  families: '/panel/families',
  familiesId: (id = ':id') => `/panel/families/${id}`,
  groupCreate: '/panel/groups/create',
  groups: '/panel/groups',
  groupsId: (id = ':id') => `/panel/groups/${id}`,
  imports: '/panel/imports',
  members: '/panel/people',
  membersId: (id = ':id') => `/panel/people/${id}`,
  messages: '/panel/messages',
  messagesId: (id = ':id') => `/panel/messages/${id}`,
  panel: '/panel',
  permissions: '/panel/permissions',
  resourceCreate: (type = ':type') => `/panel/${type}/create`,
  resourceEdit: (type = ':type', id = ':id') => `/panel/${type}/edit/${id}`,
  settings: '/panel/settings',
};

export const dashboardRoutePaths = {
  adminData: '/app/admin/data',
  adminDir: '/app/admin/dir',
  adminDirFamilies: '/app/admin/dir/families',
  adminDirFamiliesId: (id = ':shortCode') => `/app/admin/dir/families/${id}`,
  adminDirGroups: '/app/admin/dir/groups',
  adminDirGroupsId: (id = ':shortCode') => `/app/admin/dir/groups/${id}`,
  adminDirMembers: '/app/admin/dir/members',
  adminDirSettings: '/app/admin/settings',
  adminEditMember: (id = ':shortCode') => `/app/admin/members/${id}`,
  adminExports: '/app/admin/exports',
  adminWizard: '/app/admin/wizard',
  compose: '/app/messages/compose',
  composeId: (id = ':id') => `/app/messages/compose/${id}`,
  composeTo: (to = '', type = 'email', kind = 'person') => (
    `/app/messages/compose?to=${to}&type=${type}&kind=${kind}`
  ),
  dirInfo: '/app/dir/info',
  dirSwitch: '/app/dir/switch',
  dirUnavailable: '/app/dir/unavailable',
  drafts: '/app/messages/drafts',
  draftsId: (id = ':id') => `/app/messages/drafts/${id}`,
  families: '/app/directory/people/families',
  familiesId: (id = ':shortCode') => `/app/directory/people/families/${id}`,
  groups: '/app/directory/groups',
  groupsId: (id = ':shortCode') => `/app/directory/groups/${id}`,
  individuals: '/app/directory/people/individuals',
  individualsId: (id = ':shortCode') => `/app/directory/people/individuals/${id}`,
  logout: '/app/logout',
  messages: '/app/messages/sent',
  messagesBase: '/app/messages',
  messagesId: (id = ':id') => `/app/messages/sent/${id}`,
  people: '/app/directory/people',
  peopleId: (id = ':shortCode') => `/app/directory/people/${id}`,
  permissions: '/app/admin/permissions',
  resourcesId: (resourceType, id) => `/app/directory/${resourceType}/${id}`,
  search: '/app/directory/search',
  tags: '/app/directory/tags',
  tagsId: (tag = ':tags') => `/app/directory/tags/${tag}`,
  userAccount: '/app/me/account',
  userAccountRecovery: '/app/me/account/recovery',
  userProfile: '/app/me/profile',
};

export const leaderRoutePaths = {
  manageFamily: '/app/directory/family/manage',
  manageFamilyId: (id = ':id') => `/app/directory/family/manage?id=${id}`,
  manageGroup: '/app/directory/group/manage',
  manageGroupId: (id = ':id') => `/app/directory/group/manage?id=${id}`,
};

export const AppRoutes = {
  PUBLIC: {
    LOGIN: {
      path: '/login',
      label: 'Login',
    },
    LOGIN_WITH_PHONE: {
      path: '/login/phone',
      label: 'Login',
    },
    VERIFY_SMS_CODE: {
      path: '/login/phone/verify',
      label: 'Verify SMS Code',
    },
    SIGNUP: {
      path: '/signup',
      label: 'Sign Up',
    },
    WELCOME: {
      path: '/welcome',
      label: 'Welcome',
    },
    FINISH_SIGNUP: {
      path: '/finish',
      label: 'Welcome',
    },
    FINISH_EMAIL_LOGIN: {
      path: '/actions',
      label: 'Finish Login',
    },
  },
  AUTH: {
    DASHBOARD: {
      path: '/app',
      label: 'Dashboard',
    },
    ME: {
      path: '/app/me/profile',
      label: 'My Profile',
    },
    DIRECTORY: {
      path: '/app/directory',
      label: 'Directory',
    },
    DIRECTORY_FAMILIES: {
      path: '/app/directory/families',
      label: 'Directory',
    },
    DIRECTORY_GROUPS: {
      path: '/app/directory/groups',
      label: 'Directory',
    },
    UPDATE_MEMBER_PROFILE: {
      path: '/app/directory/members/:userShortCode/update',
      label: 'Update Profile',
    },
    MEMBER_PROFILE: {
      path: '/app/directory/members/:userShortCode',
      label: 'Profile',
    },
    FAMILY_PROFILE: {
      path: '/app/directory/families/:familyShortCode',
      label: 'Family',
    },
    GROUP_PROFILE: {
      path: '/app/directory/groups/:groupShortCode',
      label: 'Group',
    },
    DIRECTORY_MEMBERS: {
      path: '/app/directory/members',
      label: 'Directory',
    },
    SWITCH: {
      path: '/app/switch',
      label: 'Switch Directory',
    },
    ADMIN_DASHBOARD: {
      path: '/app/admin/dash',
      label: 'Dashboard',
    },
    ADMIN_ADD: {
      path: '/app/admin/add',
      label: 'Add to Directory',
    },
    ADMIN_REVIEW_ADD: {
      path: '/app/admin/review',
      label: 'Members Created',
    },
    ADMIN_EDIT_MEMBER: {
      path: '/app/admin/members/:userShortCode',
      label: 'Edit Person',
    },
    ADMIN_ORGANIZER: {
      path: '/app/admin/settings',
      label: 'Admin PanelOverview',
    },
    ADMIN_VIEW_MESSAGE: {
      path: '/app/admin/messages/:messageId',
      label: 'Sent Message',
    },
    ADMIN_MESSAGES: {
      path: '/app/admin/messages',
      label: 'Messages',
    },
    ADMIN_EXPORTER: {
      path: '/app/admin/exports',
      label: 'Exports',
    },
  },
};

export const MakeAppRoutes = (directoryName, isAdmin) => ({
  PUBLIC: {
    LOGIN: {
      path: '/login',
      label: 'Login',
    },
    LOGIN_WITH_PHONE: {
      path: '/login/phone',
      label: 'Login',
    },
    VERIFY_SMS_CODE: {
      path: '/login/phone/verify',
      label: 'Verify SMS Code',
    },
    SIGNUP: {
      path: '/signup',
      label: 'Sign Up',
    },
    WELCOME: {
      path: '/welcome',
      label: 'Welcome',
    },
    FINISH_SIGNUP: {
      path: '/finish',
      label: 'Welcome',
    },
    FINISH_EMAIL_LOGIN: {
      path: '/actions',
      label: 'Finish Login',
    },
  },
  AUTH: {
    DASHBOARD: {
      path: '/app',
      label: 'Dashboard',
    },
    ME: {
      path: '/app/me',
      label: 'My Profile',
    },
    DIRECTORY: {
      path: '/app/directory',
      label: directoryName,
    },
    DIRECTORY_FAMILIES: {
      path: '/app/directory/families',
      label: directoryName,
    },
    DIRECTORY_GROUPS: {
      path: '/app/directory/groups',
      label: directoryName,
    },
    UPDATE_MEMBER_PROFILE: {
      path: '/app/directory/members/:userShortCode/update',
      label: 'Update Profile',
    },
    MEMBER_PROFILE: {
      path: '/app/directory/members/:userShortCode',
      label: 'Profile',
    },
    FAMILY_PROFILE: {
      path: '/app/directory/families/:familyShortCode',
      label: 'Family',
    },
    GROUP_PROFILE: {
      path: '/app/directory/groups/:groupShortCode',
      label: 'Group',
    },
    DIRECTORY_MEMBERS: {
      path: '/app/directory/members',
      label: directoryName,
    },
    SWITCH: {
      path: '/app/switch',
      label: 'Switch Directory',
    },
    ADMIN_DASHBOARD: {
      path: '/app/admin/dash',
      label: 'Dashboard',
    },
    ADMIN_ADD: {
      path: '/app/admin/add',
      label: `Add to ${directoryName}`,
    },
    ADMIN_REVIEW_ADD: {
      path: '/app/admin/review',
      label: 'Members Created',
    },
    ADMIN_EDIT_MEMBER: {
      path: '/app/admin/members/:userShortCode',
      label: 'Edit Person',
    },
    ADMIN_ORGANIZER: {
      path: '/app/admin/settings',
      label: 'Admin PanelOverview',
    },
    ADMIN_VIEW_MESSAGE: {
      path: '/app/admin/messages/:messageId',
      label: 'Sent Message',
    },
    ADMIN_MESSAGES: {
      path: '/app/admin/messages',
      label: 'Messages',
    },
    ADMIN_EXPORTER: {
      path: '/app/admin/exports',
      label: 'Exports',
    },
  },
});
