import * as constants from './constants';

export const name = 'defs';

const initialState = {
  countryCodes: [],
  stateProvinces: [],
  countries: [],
  isFetching: false,
  isFetchingStateProvs: false,
  isFetchingCountries: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.COUNTRY_CODES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case constants.COUNTRY_CODES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        countryCodes: action.payload.codes,
      };

    case constants.COUNTRY_CODES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case constants.COUNTRIES_REQUEST:
      return {
        ...state,
        isFetchingCountries: true,
      };

    case constants.COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetchingCountries: false,
        countries: action.payload.countries,
      };

    case constants.COUNTRIES_FAILURE:
      return {
        ...state,
        isFetchingCountries: false,
      };

    case constants.STATE_PROV_REQUEST:
      return {
        ...state,
        isFetchingStateProvs: true,
      };

    case constants.STATE_PROV_SUCCESS:
      return {
        ...state,
        isFetchingStateProvs: false,
        stateProvinces: action.payload.stateProvs,
      };

    case constants.STATE_PROV_FAILURE:
      return {
        ...state,
        isFetchingStateProvs: false,
      };

    default:
      return state;
  }
}
