// 

import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

// $FlowFixMe
import { Stats } from 'modules/core/components';

import styles from './styles.css';


const Loader = (
  { loadingText },
) => (
  <Stats>
    {[
      { i: 'now-ui-icons arrows-1_refresh-69', t: loadingText },
    ]}
  </Stats>
);

const TextSpan = ({ children, className }) => (
  <span className={className}>{children}</span>
);

class LoaderOrText extends React.PureComponent {
  static defaultProps = {
    text: '',
    loadingText: null,
  };

  render() {
    const {
      isLoading,
      text,
      loadingText,
      textClassName = '',
    } = this.props;

    return (
      <div className={styles.loaderOrText}>
        <CSSTransition>
          {isLoading ?
            <Loader
              loadingText={loadingText} /> :
            <TextSpan className={textClassName}>
              {text}
            </TextSpan>}
        </CSSTransition>
      </div>
    );
  }
}

export default LoaderOrText;
