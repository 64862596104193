import PropTypes from 'prop-types';
import * as React from 'react';

import Box from '../Box';
import { MultiSelection } from '../MultiSelection';


class PersonSelect extends React.Component {
  state = {
    hasInput: false,
  };

  selectRef = React.createRef();

  onDropdownVisibleChange = (isOpen) => {
    const { isSearchable } = this.props;

    if (isSearchable && isOpen && this.selectRef.current) {
      setTimeout(() => {
        if (this && this.selectRef.current) {
          this.selectRef.current.rcSelect.inputRef.focus();
        }
      }, 130);
    }
  };

  handleSearch = (inputValue) => {
    const { onSearch } = this.props;

    const { hasInput } = this.state;

    const willHaveInput = inputValue && inputValue.length;

    if (willHaveInput !== hasInput) {
      this.setState({ hasInput: willHaveInput });
    }

    if (onSearch) {
      onSearch(inputValue);
    }
  };

  render() {
    const {
      defaultValue,
      isMulti,
      isOpen,
      name,
      onBlur,
      onChange,
      onFocus,
      options,
      value,
      sx,
    } = this.props;

    const placeholder = isMulti ? 'Select People...' : 'Select Person...';

    return (
      <Box
        sx={{
          width: '100%',
          ...sx,
        }}
      >
        <MultiSelection
          defaultValue={defaultValue}
          isCreatable={false}
          isClearable={true}
          isMulti={isMulti}
          isOpen={isOpen}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          options={options}
          placeholder={placeholder}
          ref={this.selectRef}
          value={value}
        />
      </Box>
    );
  }
}

PersonSelect.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.shape()),
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  sx: PropTypes.shape(),
};

PersonSelect.defaultProps = {
  name: 'people',
  defaultValue: [],
  isMulti: true,
  sx: {},
};

export default PersonSelect;
