export const BreakpointLowerBoundEm = {
  MOBILE: 0,
  TABLET: 40,
  LAPTOP: 52,
  DESKTOP: 64,
};

export const BreakpointConfigNames = {
  mobile: 'mobile',
  tablet: 'tablet',
  laptop: 'laptop',
  desktop: 'desktop',
};

export const BreakpointBoundsForConfigName = {
  [BreakpointConfigNames.mobile]: BreakpointLowerBoundEm.MOBILE,
  [BreakpointConfigNames.tablet]: BreakpointLowerBoundEm.TABLET,
  [BreakpointConfigNames.laptop]: BreakpointLowerBoundEm.LAPTOP,
  [BreakpointConfigNames.desktop]: BreakpointLowerBoundEm.DESKTOP,
};
