//
import React from 'react';
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Col,
  Row,
} from 'reactstrap';
import _ from 'lodash';
import { Button } from 'antd';

import { classie } from '../../../../utils';

import styles from './styles.css';


class SearchBar extends React.Component {
  state = {
    searchTerm: '',
    searchFocused: false,
    typeSelection: '',
  };


  componentDidMount() {
    this.setInputFromUrl();
  }

  componentDidUpdate(prevProps) {
    const { urlQuery } = this.props;
    const { urlQuery: lastQuery } = prevProps;
    if (urlQuery.term !== lastQuery.term) {
      this.setInputFromUrl();
    }
  }

  setInputFromUrl = () => {
    const { urlQuery, searchTypeName } = this.props;

    let searchTerm;
    if (urlQuery.term) searchTerm = urlQuery.term;
    else searchTerm = '';

    let typeSelection;
    if (urlQuery.type) typeSelection = urlQuery.type;
    else typeSelection = searchTypeName.toLowerCase();

    this.setState({ searchTerm, typeSelection });
  };

  setPeopleRadio = (el) => {
    this.peopleRadio = el;
  };

  setGroupsRadio = (el) => {
    this.groupsRadios = el;
  };

  setFamiliesRadio = (el) => {
    this.familiesRadio = el;
  };

  onSearchChange = (evt) => {
    const { value } = evt.target;
    this.setState({ searchTerm: value }, () => {
      // real-time search
      // this.throttleSearch(this.state.searchTerm);
    });
  };

  getSearchType = () => {
    const { searchTypeName, urlQuery } = this.props;

    let searchType = searchTypeName.toLowerCase();

    if (urlQuery.type) searchType = urlQuery.type;
    else if (searchType === 'people') searchType = 'users';

    return searchType;
  };

  search = (searchTerm) => {
    const { search, searchTypeName, history } = this.props;

    const searchType = this.getSearchType();

    const searchUrl = '/app/directory/search';
    const urlParams = `type=${searchTypeName.toLowerCase()}&term=${searchTerm}`;

    search(searchType, searchTerm);

    history.push(`${searchUrl}?${urlParams}`);
  };

  throttleSearch = _.throttle(this.search, 800);

  handleSearchRowClick = (searchType, id) => {
    const { history } = this.props;

    if (searchType === 'users') {
      history.push(`/app/directory/people/${id}`);
    } else if (searchType === 'families') {
      history.push(`/app/directory/families/${id}`);
    } else if (searchType === 'groups') {
      history.push(`/app/directory/groups/${id}`);
    } if (searchType === 'messages') {
      history.push(`/app/messages/${id}`);
    }
  };

  handleSearchSeeAllClick = () => {};

  handleSubmit = (evt) => {
    const { searchTerm } = this.state;

    if (evt) evt.preventDefault();

    this.throttleSearch(searchTerm);
  };

  render() {
    const {
      search,
      searchType,
      searchResults,
    } = this.props;

    const {
      searchTerm,
      searchFocused,
      typeSelection,
    } = this.state;

    return null;
    // return (
    //   <Row className={styles.searchRow}>
    //     <Col xs={12}>
    //       <form onSubmit={this.handleSubmit}>
    //         <Row>
    //           <Col xs={12}>
    //             <div>
    //               <InputGroup
    //                 style={{
    //                   borderColor: 'transparent',
    //                 }}
    //                 className={classie(['no-border', styles.inputGroup], {
    //                   [styles.focusedGroup]: searchFocused,
    //                 })}>
    //                 <Input
    //                   style={{
    //                     backgroundColor: 'rgba(222, 222, 222, 0.5)',
    //                   }}
    //                   onFocus={() => this.setState({ searchFocused: true })}
    //                   onBlur={() => this.setState({ searchFocused: false })}
    //                   className={styles.searchInput}
    //                   value={searchTerm}
    //                   onChange={this.onSearchChange}
    //                   placeholder="Search..." />
    //
    //                 <InputGroupAddon
    //                   className={styles.searchIconAddon}
    //                   addonType="append">
    //                   <InputGroupText
    //                     className={styles.searchIconAddon}>
    //                     <i className="now-ui-icons ui-1_zoom-bold" />
    //                   </InputGroupText>
    //                 </InputGroupAddon>
    //               </InputGroup>
    //             </div>
    //           </Col>
    //         </Row>
    //
    //         <Row>
    //           <Col xs={12}>
    //             <div className={styles.searchActions}>
    //               <Button
    //                 className={styles.searchButton}
    //                 color="info"
    //                 onClick={this.handleSubmit}
    //                 type="submit">
    //                 Search
    //               </Button>
    //             </div>
    //           </Col>
    //         </Row>
    //       </form>
    //     </Col>
    //   </Row>
    // );
  }
}

export default SearchBar;
