import axios from 'axios';
import { Routing } from '../../common/constants';

export async function requestDirectories(token, userId, dirId) {
  const path = `${Routing.BASE_ROUTE}${Routing.UserRoutes.userDirectories(userId, dirId)}`;
  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.get(path, options);
}

export async function searchMembers(token, userId, directoryId, term) {
  const path = `${Routing.BASE_ROUTE}${Routing.UserRoutes.searchDirectory(directoryId, 'members')}`;

  const data = {
    term,
  };

  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, data, options);
}

export async function searchFamilies(token, userId, directoryId, term) {
  const path = `${Routing.BASE_ROUTE}${Routing.UserRoutes.searchDirectory(directoryId, 'families')}`;
  const data = {
    term,
    includeMembers: true,
  };

  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, data, options);
}

export async function searchGroups(token, userId, directoryId, term) {
  const path = `${Routing.BASE_ROUTE}${Routing.UserRoutes.searchDirectory(directoryId, 'groups')}`;
  const data = {
    term,
  };

  const options = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return axios.post(path, data, options);
}
