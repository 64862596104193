import PhoneNumber from 'awesome-phonenumber';
import moment from 'moment-timezone';
import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js';


// date constants
const separators = ['/', '-', '.', ' '];
const midEndianPartIndices = [0, 1];
const midEndianSepIndices = [2, 4];
const midEndianPartLens = [1, 2];

const bigEndianPartIndices = [1, 2];
const bigEndianSepIndices = [4, 6];
const bigEndianPartLens = [2, 2];

const configLocales = {
  'en-US': 'mid',
  en: 'mid',
  default: 'big',
};

const dateConfigs = {
  big: {
    partIndices: bigEndianPartIndices,
    sepIndices: bigEndianSepIndices,
    partLengths: bigEndianPartLens,
    numChecks: 1,
    numChecksStrict: bigEndianPartLens.length,
  },
  mid: {
    partIndices: midEndianPartIndices,
    sepIndices: midEndianSepIndices,
    partLengths: midEndianPartLens,
    numChecks: 2,
    numChecksStrict: midEndianPartLens.length,
  },
};

export const formatE164 = (number, countryCode) => {
  const code = countryCode ? (countryCode.split?.('_')[0] ?? '') : '';
  const full = number ? `${code}${number || ''}` : '';
  const p = new PhoneNumber(full);
  const details = p.toJSON();

  if (details.number) {
    if (details.number.e164) {
      return {
        number: details.number.e164,
        isValid: details.valid,
      };
    }

    if (details.number.input) {
      return {
        number: details.number.input,
        isValid: details.valid,
      };
    }
  }

  return {
    number: number || '',
    isValid: details.valid,
  };
};

export const formatPhoneForDisplay = (fullNumber, country) => {
  try {
    const pn = parsePhoneNumberFromString(fullNumber, country);
    if (pn) {
      if (country === 'US' || country === 'CA') {
        return pn.formatNational();
      }
      return pn.formatInternational();
    }
  } catch (err) {
    // nothing
  }

  return '';
};

/**
 * Return a digits only
 * @param value {?string}
 * @return {string}
 */
export const digitsOnly = (value) => {
  if (!value) {
    return value;
  }

  return String(value).replace(/[^\d]/g, '');
};

/**
 * Return a string containing only characters allowed in date strings
 * @param value {?string}
 * @return {string}
 */
export const dateCharsOnly = (value) => {
  if (!value) {
    return value;
  }

  return String(value).replace(/[^\d-/.\s]/g, '');
};

/**
 * Format a string to include separators
 * @param str {?string}
 * @param sep {?string}
 * @param config {Object}
 * @return {string}
 */
export const dateStringWithSeparators = (str, sep, config) => {
  const digits = digitsOnly(str) || '';

  const formatted = digits.split('').reduce((s, char, idx) => {
    if (idx === config.sepIndices[0] || idx === config.sepIndices[1]) {
      return `${s}${sep}${char}`;
    }
    return `${s}${char}`;
  }, '');

  if (str.length > 2) {
    const lastChar = str[str.length - 1];
    if (separators.includes(lastChar)) {
      return `${formatted}${sep}`;
    }
  }

  return formatted;
};

/**
 * Format a full or in-progress date string to MM/DD/YYYY
 * @param value {?string}
 * @param locale {?string}
 * @param options {Object}
 * @return {string}
 */
export const formatDateString = (
  value,
  locale,
  options = {},
) => {
  const { autoDetectLocale, useStrict, separator = '/' } = options;

  let formattedDateString = '';

  let configLocale = configLocales[locale || 'default'];

  if (autoDetectLocale) {
    if (value
      && value.length > 4
      && value.substring(0, 4) !== digitsOnly(value.substring(0, 4))) {
      configLocale = configLocales['en-US'];
    } else {
      configLocale = configLocales.default;
    }
  }

  const config = dateConfigs[configLocale] || dateConfigs.mid;
  const numChecks = useStrict ? config.numChecksStrict : config.numChecks;

  const str = dateCharsOnly(value);

  if (str) {
    const lastChar = str[str.length - 1];

    const seps = str.match(/[-/.\s]/g);
    const sep = seps && seps.length ? seps[0] : undefined;

    const endsWithSeparator = separators.includes(lastChar);

    if (sep) {
      const parts = str.split(/[-/.\s]/g);

      if (parts.length === 1) return parts.join('');

      const newParts = parts.map((part, idx) => {
        if (idx < numChecks) {
          if (config.partIndices.includes(idx)
            && part.length === 1 &&
            parts.length > config.partLengths[idx]) {
            return `0${part}`;
          }
        }

        return part;
      });

      formattedDateString = dateStringWithSeparators(newParts.join(''), separator, config);

      if (endsWithSeparator) {
        formattedDateString = `${formattedDateString}${separator}`;
      }
    } else {
      formattedDateString = dateStringWithSeparators(str, separator, config);
    }
  }

  return formattedDateString.substring(0, 10);
};

/**
 * Return a string up to max characters in length.
 * @param value {?string}
 * @param max {?number} -1 is default (no max)
 * @return {string}
 */
export const maxLength = (value, max = -1) => {
  if (!value || max < 0) {
    return value;
  }

  return String(value).slice(0, max);
};

export const getObjDate = (obj, dateKey, format = 'MM/DD/YY') => {
  if (!obj || !dateKey || !obj[dateKey]) {
    return null;
  }

  let returnDate;
  const desiredDate = obj[dateKey];

  if (format === 'calendar') {
    returnDate = moment(desiredDate).calendar();
  } else {
    returnDate = moment(desiredDate).format(format);
  }

  return returnDate;
};

export const formatDateSimple = (date, format = 'MM/DD/YYYY') => (
  moment(date).format(format)
);

export const isDateToday = (date) => moment(date).isSame(moment(), 'day');

export const getTimeAgo = (date, removeSuffix = false) => moment(date).fromNow(removeSuffix);

export const formatFieldDate = (dateFormat = 'MM/DD/YYYY') => (value) => {
  if (!value || typeof value !== 'string') {
    return undefined;
  }

  if (value.length < 10) {
    return undefined;
  }

  return getObjDate({ date: value }, 'date', dateFormat);
};

export const valueOrEmptyString = (value) => value ?? '';
