import PropTypes from 'prop-types';
import React from 'react';


const Upload = ({ strokeWidth, ...rest }) => (
  <path
    strokeWidth={strokeWidth}
    {...rest}
    d="M22.35,35.29h-4.7V21.18H14.89L20,16.07l5.11,5.11H22.35V35.29m4.71-9.41h9.41L20,9.41,3.53,25.88h9.41V40H27.06ZM36.47,0H3.53V4.71H36.47Z"
  />
);

Upload.propTypes = {
  strokeWidth: PropTypes.number,
};

Upload.defaultProps = {
  strokeWidth: 1,
};


export default Upload;
