import { spawn, put, take, call, all } from 'redux-saga/effects';

import { FireFunctions, functionNames } from '../services';

import * as actions from './actions';
import * as constants from './constants';

export function* getCountryCodes() {
  try {
    const response = yield call(
      FireFunctions.httpsCallable,
      functionNames.getCountryCodes,
    );

    const { results } = response.data;

    yield put(actions.countryCodesSuccess(results));
  } catch (error) {
    yield put(actions.countryCodesFailure(error.message, error));
  }
}

export function* getStateProvinces(alpha2) {
  try {
    if (!alpha2 || !alpha2.length) {
      yield put(actions.stateProvSuccess([]));
    } else {
      const response = yield call(
        FireFunctions.httpsCallable,
        functionNames.getProvincesForCountry,
        { alpha2 },
      );

      const { data } = response;

      yield put(actions.stateProvSuccess(data));
    }
  } catch (error) {
    yield put(actions.stateProvFailure(error.message, error));
  }
}

export function* getDefs() {
  yield all([
    yield put(actions.getCountryCodes()),
  ]);
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.DEFS_REQUEST,
      constants.COUNTRY_CODES_REQUEST,
      constants.STATE_PROV_REQUEST,
    ]);

    switch (type) {
      case constants.DEFS_REQUEST:
        yield spawn(getDefs);
        break;

      case constants.COUNTRY_CODES_REQUEST:
        yield spawn(getCountryCodes);
        break;

      case constants.STATE_PROV_REQUEST:
        yield spawn(getStateProvinces, payload.alpha2);
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
