export const MEMBER_INFO_REQUEST = '@members/MEMBER_INFO_REQUEST';
export const MEMBER_INFO_SUCCESS = '@members/MEMBER_INFO_SUCCESS';
export const MEMBER_INFO_FAILURE = '@members/MEMBER_INFO_FAILURE';

export const FB_MEMBERS_REQUEST = '@members/FB_MEMBERS_REQUEST';
export const FB_MEMBERS_FETCH = '@members/FB_MEMBERS_FETCH';
export const FB_MEMBERS_SUCCESS = '@members/FB_MEMBERS_SUCCESS';
export const FB_MEMBERS_FAILURE = '@members/FB_MEMBERS_FAILURE';

export const FB_MEMBERS_REQUEST_NEXT = '@members/FB_MEMBERS_REQUEST_NEXT';
export const FB_MEMBERS_REQUEST_PREV = '@members/FB_MEMBERS_REQUEST_PREV';

export const GET_MEMBERS_REQUEST = '@members/GET_MEMBERS_REQUEST';
export const GET_MEMBERS_SUCCESS = '@members/GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAILURE = '@members/GET_MEMBERS_FAILURE';

export const GET_PERSON_SUMMARIES_REQUEST = '@members/GET_PERSON_SUMMARIES_REQUEST';
export const GET_PERSON_SUMMARIES_FETCH = '@members/GET_PERSON_SUMMARIES_FETCH';
export const GET_PERSON_SUMMARIES_SUCCESS = '@members/GET_PERSON_SUMMARIES_SUCCESS';
export const GET_PERSON_SUMMARIES_FAILURE = '@members/GET_PERSON_SUMMARIES_FAILURE';

export const SET_MEMBERS_SORT = '@members/SET_MEMBERS_SORT';
