import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { Folder } from '../shapes';


const FolderSvg = (props) => (
  <g>
    <IconBounds />
    <Folder {...props} />
  </g>
);

FolderSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

FolderSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default FolderSvg;
