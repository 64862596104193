import { createSelector } from 'reselect';

import { name } from './reducer';

const getState = state => state[name];

export const getDefsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getStateProvsFetching = createSelector(
  [getState],
  state => state.isFetchingStateProvs,
);

export const getDefsCountryCodes = createSelector(
  [getState],
  state => state.countryCodes,
);

export const getStateProvs = createSelector(
  [getState],
  state => state.stateProvinces,
);
