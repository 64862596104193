export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FB_DB_URL,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
};

export const googleMapsConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
