import React from 'react';


const IconBounds = () => (
  <path
    fill="none"
    stroke="none"
    d="M0 0H40V40H0z"
  />
);


export default IconBounds;
