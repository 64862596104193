import * as constants from './constants';

export function getCountryCodes() {
  return {
    type: constants.COUNTRY_CODES_REQUEST,
  };
}

export function countryCodesSuccess(codes) {
  return {
    type: constants.COUNTRY_CODES_SUCCESS,
    payload: {
      codes,
    },
  };
}

export function countryCodesFailure(message, error) {
  return {
    type: constants.COUNTRY_CODES_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function getStateProv(alpha2) {
  return {
    type: constants.STATE_PROV_REQUEST,
    payload: {
      alpha2,
    },
  };
}

export function stateProvSuccess(stateProvs) {
  return {
    type: constants.STATE_PROV_SUCCESS,
    payload: {
      stateProvs,
    },
  };
}

export function stateProvFailure(message, error) {
  return {
    type: constants.STATE_PROV_FAILURE,
    payload: {
      message,
      error,
    },
  };
}

export function requestDefs() {
  return {
    type: constants.DEFS_REQUEST,
  };
}
