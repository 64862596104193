import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getIsAuthenticated } from '../../../auth/selectors';

import presenter from './presenter';

const mapStateToProps = createStructuredSelector({
  isAuthenticated: getIsAuthenticated,
});

export default connect(
  mapStateToProps,
)(presenter);
