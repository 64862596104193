import React from 'react';

import Box from '../Box';
import { MultiSelection, MultiSelectionWithLabel } from '../MultiSelection';


const PhoneInputWithCode = (
  {
    value,
    label,
    onSelect,
    keyExtractor,
    isDisabled,
    codes,
    valueKey,
    defaultValue,
    name,
    placeholder,
    sx,
    style = {},
    ...rest
  },
) => {
  const Component = label ? MultiSelectionWithLabel : MultiSelection;

  return (
    <Box sx={sx}>
      <Component
        isClearable={!!value && value.length > 0}
        isCreatable={false}
        isDisabled={isDisabled}
        isMulti={false}
        label={label}
        name={name}
        onChange={val => onSelect(val || '', name)}
        options={codes.map((code) => ({
          label: code.name,
          value: `${code.code}_${code.value}`,
        }))}
        placeholder="Country code"
        style={{ width: '140px', ...style }}
        value={value}
        {...rest}
      />
    </Box>
  );
};

export default PhoneInputWithCode;
