import { createSelector } from 'reselect';
import _ from 'lodash';

import { SortUtils } from 'utils';

import { name } from './reducer';

const getState = state => state[name];

export const getFamilies = createSelector(
  [getState],
  state => state.families,
);

export const getFamiliesByLastInitial = createSelector(
  [getFamilies],
  families => _.groupBy(families, f => f.name.substring(0, 1).toUpperCase()),
);

export const getFamilyIds = createSelector(
  [getState],
  state => state.familyIds,
);

export const getActiveFamilyId = createSelector(
  [getState],
  state => state.activeFamilyId,
);

export const getActiveFamilyShortCode = createSelector(
  [getState],
  state => state.activeFamilyShortCode,
);

export const getIsFetching = createSelector(
  [getState],
  state => state.isFetching,
);

export const getViewing = createSelector(
  [getState],
  state => state.viewing,
);

export const getFamiliesPagination = createSelector(
  [getState],
  state => state.pagination,
);

export const getFamiliesPaginationPage = createSelector(
  [getFamiliesPagination],
  pagination => pagination.page,
);

export const getFamiliesPaginationPages = createSelector(
  [getFamiliesPagination],
  pagination => pagination.pages,
);

export const getFamiliesPaginationCount = createSelector(
  [getFamiliesPagination],
  pagination => pagination.count,
);

export const getFamiliesPaginationPageSize = createSelector(
  [getFamiliesPagination],
  pagination => pagination.pageSize,
);

export const getFamiliesPaginationHasMore = createSelector(
  [getFamiliesPaginationPage, getFamiliesPaginationPages],
  (page, pages) => pages - page > 0,
);

export const getFamiliesPaginationDataRange = createSelector(
  [getFamiliesPagination],
  pagination => pagination.dataRange,
);

export const getFamilySummaries = createSelector(
  [getState],
  state => state.familySummaries,
);

export const getAllFamilySummaries = createSelector(
  [getFamilySummaries],
  summ => summ.summaries,
);

export const getAllFamilySummariesSortedByName = createSelector(
  [getFamilySummaries],
  (summ) => {
    const summariesList = Object.values(summ.summaries);
    return SortUtils.sortByName(summariesList);
  },
);

export const getFamilySummariesIds = createSelector(
  [getFamilySummaries],
  summ => summ.ids,
);

export const getFamilySummariesIsFetching = createSelector(
  [getFamilySummaries],
  summ => summ.isFetching,
);

export const getFamiliesLastFetched = createSelector(
  [getState],
  state => state.lastFetched,
);

export const getFamiliesSort = createSelector(
  [getState],
  state => state.sort,
);
