import PropTypes from 'prop-types';
import React from 'react';

import IconBounds from '../IconBounds';
import { MapPin } from '../shapes';


const MapPinSvg = (props) => (
  <g>
    <IconBounds />
    <MapPin {...props} />
  </g>
);

MapPinSvg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

MapPinSvg.defaultProps = {
  fill: '#000',
  stroke: '#000',
  strokeWidth: 1,
};


export default MapPinSvg;
