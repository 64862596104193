import * as React from 'react';

import { classie } from 'utils';

import styles from './styles.css';
import stylesAlt from './stylesAlt.css';

const SmallLoader = (props) => {
  if (!props.show) return null;

  return (
    <div className={styles.appLoading}>
      <div className={classie([
        stylesAlt.laLineSpinClockwiseFade,
        stylesAlt.laSm,
      ])}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default SmallLoader;
