import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';

import { useControlled, usePrevious } from 'hooks';

import { Appearance } from 'modules/core/constants';

import Box from '../Box';
import IconButton from '../IconButton';
import { IconNames } from '../Icon';


const Modal = (props) => {
  const {
    children,
    className,
    closeOnEscape,
    closeOnOverlayClick,
    defaultOpen,
    isOpen,
    onToggleOpen,
    setRef,
    sx,
    wrapperSx,
  } = props;

  const root = React.useRef(document.getElementById('modal'));

  React.useEffect(() => {
    if (!root.current) {
      root.current = document.getElementById('modal');
    }
  }, []);

  const [open, setOpen] = useControlled({
    default: defaultOpen,
    onChange: onToggleOpen,
    value: isOpen,
  });

  const handleToggleOpen = React.useCallback((nextOpenValue) => {
    if (nextOpenValue) {
      document.body.classList.add('scroll-lock');
    } else {
      document.body.classList.remove('scroll-lock');
    }
    setOpen(nextOpenValue);
  }, [open]);

  React.useEffect(() => {
    document.body.classList.add('scroll-lock');
    return () => {
      document.body.classList.remove('scroll-lock');
    };
  }, [handleToggleOpen, open]);

  const handleKeyDown = React.useCallback((evt) => {
    if (evt.key === 'Escape') {
      handleToggleOpen(false);
    }
  }, [handleToggleOpen]);

  React.useEffect(() => {
    if (closeOnEscape) {
      window.addEventListener('keydown', (evt) => {
        handleKeyDown(evt);
      });
    }

    return () => {
      window.removeEventListener('keydown');
    };
  }, [closeOnEscape, handleKeyDown]);

  const contents = (
    <Box
      className={classNames({
        'j-modal': true,
        'j-modal--open': open,
        'j-modal--close': !open,
        [className]: className,
      })}
      sx={wrapperSx}
    >
      <Box
        className={classNames({
          'j-modal__overlay': true,
          'j-modal__closer': closeOnOverlayClick,
        })}
        onClick={closeOnOverlayClick ? () => handleToggleOpen(false) : undefined}
        sx={{
          backgroundColor: 'overlay',
        }}
      />

      {open && (
        <Box
          className={classNames({ 'j-modal__content': true })}
          setRef={setRef}
          sx={{
            backgroundColor: 'foreground',
            padding: 'grid16',
            position: 'relative',
            ...sx,
          }}
        >
          <IconButton
            appearance={Appearance.NONE}
            iconName={IconNames.CLEAR}
            onClick={() => handleToggleOpen(false)}
            sx={{
              position: 'absolute',
              right: 'grid8',
            }}
          />

          {children}
        </Box>
      )}
    </Box>
  );

  if (root.current) {
    return createPortal(contents, root.current);
  }

  return contents;
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnEscape: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onToggleOpen: PropTypes.func,
  setRef: PropTypes.func,
  sx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

Modal.defaultProps = {
  children: undefined,
  className: undefined,
  closeOnEscape: true,
  closeOnOverlayClick: true,
  defaultOpen: false,
  isOpen: undefined,
  onToggleOpen: undefined,
  setRef: undefined,
  sx: {},
  wrapperSx: {},
};

const ModalWithRef = React.forwardRef((props, ref) => (
  <Modal setRef={ref} {...props} />
));


export { Modal };
export default ModalWithRef;
