import * as R from 'ramda';


export const baseEntitySort = (
  entities = [],
  sortBy = [],
  sortDir = [],
) => {
  const sorters = sortBy.map((by, idx) => {
    if (sortDir[idx] === 'desc') {
      return R.descend(R.prop(by));
    }
    return R.ascend(R.prop(by));
  });
  const entitiesSort = R.sortWith(sorters);
  return entitiesSort(entities);
};

export const sortMembers = (
  members = [],
  sortBy = ['lastName', 'firstName'],
  sortDir = ['asc', 'asc'],
) => baseEntitySort(members, sortBy, sortDir);

export const sortByName = (objects = [], sortDir = ['asc']) => baseEntitySort(
  objects,
  ['name'],
  sortDir,
);

export const sortMainEntities = (
  entities,
  sortBy,
  sortDir,
) => {
  const probablyMembers = Boolean(entities?.[0]?.firstName);
  if (probablyMembers) {
    return sortMembers(entities, sortBy, sortDir);
  }
  return baseEntitySort(entities, sortBy, sortDir);
};
