import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Label from '../Label';
import PhoneInputWithCode from './container';
import PhoneInputWithCodePresenter from './presenter';


const PhoneInputWithCodeWithLabel = (props) => {
  const {
    codeValue,
    isRequired,
    label,
    sx,
    value,
    ...rest
  } = props;

  return (
    <Box sx={{ ...sx, width: '100%' }}>
      <Label
        sx={{
          color: 'fieldLabel',
          fontSize: 'font14',
        }}
      >
        {`${label}${isRequired ? ' (Required)' : ''}`}
      </Label>
      <PhoneInputWithCode
        {...rest}
        codeValue={codeValue}
        isRequired={isRequired}
        sx={{ marginTop: 'grid4' }}
        value={value}
      />
    </Box>
  );
};

PhoneInputWithCodeWithLabel.propTypes = {
  ...PhoneInputWithCodePresenter.propTypes,
  codeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  setRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  sx: PropTypes.shape(),
  value: PropTypes.string,
};

PhoneInputWithCodeWithLabel.defaultProps = {
  ...PhoneInputWithCodePresenter.defaultProps,
  codeValue: undefined,
  isRequired: undefined,
  onChange: undefined,
  setRef: undefined,
  sx: {},
  value: undefined,
};

const PhoneInputWithCodeWithLabelWithRef = React.forwardRef((props, ref) => (
  <PhoneInputWithCodeWithLabel setRef={ref} {...props} />
));


export { PhoneInputWithCodeWithLabel };
export default PhoneInputWithCodeWithLabelWithRef;
