import PropTypes from 'prop-types';

const propTypes = {
  path: PropTypes.string.isRequired,
  ledGroups: PropTypes.shape(),
  location: PropTypes.shape().isRequired,
};

const defaultProps = {
  ledGroups: {},
};

export { propTypes, defaultProps };
