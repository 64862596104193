import moment from 'moment';
import { isEmpty } from 'lodash';


export const canHaveAccount = (birthdate) => {
  const born = moment(birthdate);
  return moment().diff(born, 'years') >= 13;
};

export const hasAnyCustomFields = (profile = {}) => {
  const { customFields = {} } = profile;
  return Object.keys(customFields).length > 0;
};

export const hasAnyPrivateFields = (profile = {}) => {
  const { adminNotes = '', privateFields = {} } = profile;
  return !!adminNotes || Object.keys(privateFields).length > 0;
};

export const hasCellPhone = (profile = {}, isAdminViewer) => {
  const { cellPhone = {} } = profile;
  const dataExists = !isEmpty(cellPhone.number) && !isEmpty(cellPhone.countryCode);
  const isDisplayOn = isAdminViewer || cellPhone.isVisible;
  return isDisplayOn && dataExists;
};

export const hasHomePhone = (profile = {}, isAdminViewer) => {
  const { homePhone = {} } = profile;
  const dataExists = !isEmpty(homePhone.number) && !isEmpty(homePhone.countryCode);
  const isDisplayOn = isAdminViewer || homePhone.isVisible;
  return isDisplayOn && dataExists;
};

export const hasWorkPhone = (profile = {}, isAdminViewer) => {
  const { workPhone = {} } = profile;
  const dataExists = !isEmpty(workPhone.number) && !isEmpty(workPhone.countryCode);
  const isDisplayOn = isAdminViewer || workPhone.isVisible;
  return isDisplayOn && dataExists;
};

export const hasAnyPhone = (profile = {}, isAdminViewer) => {
  const hasCell = hasCellPhone(profile, isAdminViewer);
  const hasHome = hasHomePhone(profile, isAdminViewer);
  const hasWork = hasWorkPhone(profile, isAdminViewer);
  return Boolean(hasCell || hasHome || hasWork);
};

export const hasAnyEmail = (profile = {}, isAdminViewer) => {
  const { emails = [], email = {} } = profile;
  const hasMainEmail = email.email && (email.isVisible || isAdminViewer);
  const hasOtherEmail = emails.reduce((result, e) => {
    const hasE = e.email && (e.isVisible || isAdminViewer);
    return result || hasE;
  }, false);
  return hasMainEmail || hasOtherEmail;
};

export const hasAnyAddress = (profile = {}, isAdminViewer) => {
  const { addresses = [] } = profile;

  return addresses.reduce((result, address) => {
    const {
      city = '',
      country = '',
      state = '',
      street1 = '',
      street2 = '',
      zip = '',
      isVisible,
    } = address;

    const hasInfo = (isVisible || isAdminViewer)
      && Boolean(city || country || state || street1 || street2 || zip);

    return result || hasInfo;
  }, false);
};

export const getFullListName = (profileMaybe, options = {}) => {
  const profile = profileMaybe || {};

  const { id, firstName, lastName } = profile;
  const { userId } = options;

  const fullName = `${firstName} ${lastName}`;

  if (userId && id === userId) {
    return `${fullName} (me)`;
  }

  return fullName;
};

export const getFullProfileName = (profile = {}, options = {}) => {
  const { id, firstName, lastName, middleName, prefix, suffix } = profile;
  const { userId } = options;

  let full = firstName;

  if (prefix) {
    full = `${prefix} ${full}`;
  }

  if (middleName) {
    full = `${full} ${middleName}`;
  }

  full = `${full} ${lastName}`;

  if (suffix) {
    full = `${full} ${suffix}`;
  }

  if (id === userId) {
    full = `${full} (me)`;
  }

  return full;
};

export const getFamilySummariesTruncated = (memberFamilies, families) => {
  const familyIds = Array.isArray(memberFamilies) ? memberFamilies : Object.keys(memberFamilies);
  const count = familyIds.length;
  const max = Math.min(count, 2);
  let summaryString = '';
  for (let i = 0; i < max; i += 1) {
    if (i === 0) {
      const family = families?.[familyIds[i]];
      summaryString += `${family?.name}`;
    } else {
      summaryString += ` (+${count - 1} more)`;
    }
  }
};
