import pluralize from 'pluralize';

import { entity } from '../../../shared/constants';

export const messageText = (data) => {
  const { messageData } = data;

  if (data.type === entity.MessageType.EMAIL) {
    return messageData ? messageData.text : '(none)';
  }
  if (data.type === entity.MessageType.SMS) {
    return messageData ? messageData.body : '(none)';
  }

  return '(none)';
};

export const messageSubject = (data) => {
  const { messageData } = data;

  if (data.type === entity.MessageType.EMAIL) {
    return messageData ? messageData.subject : '(none)';
  }
  if (data.type === entity.MessageType.SMS) {
    return '(n/a)';
  }

  return '(none)';
};

export const message = (messageId, data) => ({
  id: messageId,
  clientId: data.clientId,
  createdAt: data.createdAt,
  recipientId: data.recipientId,
  senderId: data.senderId,
  type: data.type,
  archived: data.archived,
  to: data.messageData ? data.messageData.to : '',
  from: data.messageData ? data.messageData.from : '',
  message: messageText(data),
  subject: messageSubject(data),
  recipientName: data.recipientName || '',
});

const getDraftRecipientNameNextSeparator = (recipient) => (recipient.length ? ', ' : '');
const getDraftRecipientNameNextLine = (recipientString, nextRecipients, recipientType) => (
  `${getDraftRecipientNameNextSeparator(recipientString)}${nextRecipients.length} ${pluralize(recipientType, nextRecipients.length)}`
);

const getDraftRecipientName = (data) => {
  let recipient = '';

  if (data.people?.length) {
    recipient += getDraftRecipientNameNextLine(recipient, data.people, 'people');
  }

  if (data.categories?.length) {
    recipient += getDraftRecipientNameNextLine(recipient, data.categories, 'categories');
  }

  if (data.families?.length) {
    recipient += getDraftRecipientNameNextLine(recipient, data.families, 'families');
  }

  if (data.groups?.length) {
    recipient += getDraftRecipientNameNextLine(recipient, data.groups, 'groups');
  }

  return recipient;
};

export const messageDraft = (messageId, data) => ({
  createdAt: data.createdAt,
  families: data.families || [],
  groups: data.groups || [],
  id: messageId,
  message: data.message || '',
  people: data.people || [],
  recipientName: getDraftRecipientName(data),
  subject: data.subject || '',
  types: data.types || [],
});

export const messageDrafts = (data) => {
  const ids = Object.keys(data);
  return ids.reduce((obj, id) => ({
    ...obj,
    [id]: messageDraft(id, data[id]),
  }), {});
};
