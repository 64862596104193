let nav; // eslint-disable-line import/no-mutable-exports

export const setNav = (history) => {
  nav = nav || history;
};

export const push = (url, state) => {
  nav.push(url, state);
};


export { nav };
