import PropTypes from 'prop-types';
import * as React from 'react';

import { Intent } from 'modules/core/constants';

import Box from '../Box';
import Callout from '../Callout';
import { TextArea } from '../Textarea';


const blankState = {
  notes: '',
};

class AdminNotes extends React.Component {
  state = {
    ...blankState,
  };

  onChange = (evt) => {
    this.setState({ notes: evt.target.value });
  }

  reset = () => {
    this.setState({ ...blankState });
  };

  render() {
    const { onChange, value } = this.props;
    const { notes } = this.state;

    return (
      <Box sx={{ marginBottom: 'grid16' }}>
        <Callout intent={Intent.DEFAULT} sx={{ marginBottom: 'grid16' }}>
          Private notes will only be visible to admins of your directory
        </Callout>

        <TextArea
          name="notes"
          onChange={onChange || this.onChange}
          placeholder="Type anything.."
          value={value || notes}
        />
      </Box>
    );
  }
}

AdminNotes.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

AdminNotes.defaultProps = {
  onChange: undefined,
  value: '',
};


export default AdminNotes;
