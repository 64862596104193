import { detect, detectOS, OperatingSystem } from 'detect-browser';

import { BreakpointConfigNames, BreakpointLowerBoundEm, BreakpointBoundsForConfigName } from 'const';


const browser = detect();

export const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () => (
    isMobile.Android() ||
    isMobile.BlackBerry() ||
    isMobile.iOS() ||
    isMobile.Opera() ||
    isMobile.Windows()
  ),
};

export const getBrowser = () => browser && browser.name;

export const isSafari = () => {
  const b = getBrowser();
  return b && b.includes('safari');
};

export const getBreakpointName = () => {
  const html = document.querySelector('html');
  const style = window.getComputedStyle(html, null).getPropertyValue('font-size');
  const fontSize = parseFloat(style);
  const widthEms = window.innerWidth / fontSize;

  if (widthEms >= BreakpointBoundsForConfigName[BreakpointConfigNames.DESKTOP]) {
    return BreakpointConfigNames.desktop;
  }

  if (widthEms >= BreakpointBoundsForConfigName[BreakpointConfigNames.laptop]) {
    return BreakpointConfigNames.laptop;
  }

  if (widthEms >= BreakpointBoundsForConfigName[BreakpointConfigNames.tablet]) {
    return BreakpointConfigNames.tablet;
  }

  return BreakpointConfigNames.mobile;
};

export const choose = (config = {}) => (config[getBreakpointName()]);
