import React from 'react';
import classNames from 'classnames';

import Icon, { IconNames } from './presenter';

import { iconDefaultProps, iconPropTypes } from './iconPropTypes';


const EyeOffIcon = ({ className, size, ...rest }) => (
  <Icon
    className={classNames({
      'j-icon-eye_off': true,
      [className]: className,
    })}
    name={IconNames.EYE_OFF}
    size={size}
    {...rest}
  />
);

EyeOffIcon.propTypes = {
  ...iconPropTypes,
};

EyeOffIcon.defaultProps = {
  ...iconDefaultProps,
};


export default EyeOffIcon;
