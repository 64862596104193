import { call, fork, put, take, all, select } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

import { getToken } from '../auth/selectors';
import { getActiveDirectoryId } from '../directory/selectors';
import { getId } from '../user/selectors';

/**
 * @param shortCode {string} Short code for the user owning this photo.
 * @param formData {formData} Form data with image file in `files`.
 */
export function* requestUploadImage(shortCode, formData) {
  try {
    const uid = yield select(getId);
    const dirId = yield select(getActiveDirectoryId);
    const token = yield select(getToken);

    const response = yield call(api.requestUploadImage, {
      userId: uid,
      dirId,
      token,
      shortCode,
      formData,
    });

    if (response.error) {
      yield put(actions.uploadImageFailure(response.error.message));
    } else {
      const { userId, imageUrl } = response.data;
      yield put(actions.uploadImageSuccess(userId, imageUrl));
    }
  } catch (error) {
    yield put(actions.uploadImageFailure(error.message));
  }
}

/**
 * Generator function to listen for redux actions
 * Handles any action api requests as non-blocking calls
 * and returns the appropriate action responses.
 */
function* watch() {
  while (true) {
    const { type, payload = {} } = yield take([
      constants.UPLOAD_IMAGE_REQUEST,
    ]);

    switch (type) {
      case constants.UPLOAD_IMAGE_REQUEST:
        yield fork(requestUploadImage, payload.shortCode, payload.formData);
        break;

      default:
        yield null;
    }
  }
}

export default function* rootSaga() {
  yield watch();
}
