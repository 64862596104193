export const FAMILIES_REQUEST = '@families/FAMILIES_REQUEST';
export const FAMILIES_SUCCESS = '@families/FAMILIES_SUCCESS';
export const FAMILIES_FAILURE = '@families/FAMILIES_FAILURE';

export const FB_FAMILIES_REQUEST = '@families/FB_FAMILIES_REQUEST';
export const FB_ALL_FAMILIES_REQUEST = '@families/FB_ALL_FAMILIES_REQUEST';
export const FB_FAMILIES_FETCH = '@families/FB_FAMILIES_FETCH';
export const FB_FAMILIES_REQUEST_NEXT = '@families/FB_FAMILIES_REQUEST_NEXT';
export const FB_FAMILIES_REQUEST_PREV = '@families/FB_FAMILIES_REQUEST_PREV';
export const FB_FAMILIES_SUCCESS = '@families/FB_FAMILIES_SUCCESS';
export const FB_FAMILIES_FAILURE = '@families/FB_FAMILIES_FAILURE';

export const FAMILY_INFO_REQUEST = '@families/FAMILY_INFO_REQUEST';
export const FAMILY_INFO_SUCCESS = '@families/FAMILY_INFO_SUCCESS';
export const FAMILY_INFO_FAILURE = '@families/FAMILY_INFO_FAILURE';

export const FAMILY_SUMMARIES_REQUEST = '@families/FAMILY_SUMMARIES_REQUEST';
export const FAMILY_SUMMARIES_FETCH = '@families/FAMILY_SUMMARIES_FETCH';
export const FAMILY_SUMMARIES_SUCCESS = '@families/FAMILY_SUMMARIES_SUCCESS';
export const FAMILY_SUMMARIES_FAILURE = '@families/FAMILY_SUMMARIES_FAILURE';

export const SET_FAMILIES_SORT = '@families/SET_FAMILIES_SORT';
