import * as React from 'react';

import Box from '../Box';


const FormWrapper = (props) => {
  const { children, sx = {} } = props;

  return (
    <Box sx={{
      padding: 'grid16',
      paddingBottom: 'grid32',
      ...sx,
      [global.Breakpoint.tablet]: {
        margin: '0 auto',
        maxWidth: '720px',
        ...(sx[global.Breakpoint.tablet] || {}),
      },
    }}
    >
      {children}
    </Box>
  );
};


export default FormWrapper;
